import React, { useEffect, useState } from 'react';
import { Button, Flex, Space, Table } from 'antd';
import useApiClient from '../../Settings/useApiClient';
import { ticketApi } from '../../API/ticketApi';
import { useNavigate } from 'react-router-dom';

const Tickets = () => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  const apiClient = useApiClient();
  const [tickets, setTickets] = useState([]);
  const navigate=useNavigate();
  useEffect(() => {
    ticketApi.departments(apiClient)
    .then((r) => {
      let _deps=[]
      r.map(dep=> _deps.push({text:dep.name,value:dep.name}))
      setDepartments(_deps)
    })
    .catch((error) => console.error('Error:', error));
    handlePagination(1);
  }, []);
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const ticket=()=>{
      navigate('/tickets/new');
  }
  const columns = [
    {
      title: '',
      dataIndex: 'important',
      width: 55,
      key: 'important',
      sorter: (a, b) => a.important.length - b.important.length,
      sortOrder: sortedInfo.columnKey === 'important' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: (
        <Flex className="gap-2">
          <img src="/images/title.svg" /> Title{' '}
        </Flex>
      ),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
      sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : null,
      ellipsis: false,
    },
    {
      title: (
        <Flex className="gap-2">
          <img src="/images/lamp.svg" /> Status
        </Flex>
      ),

      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.age - b.age,
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: (
        <Flex className="gap-2">
          <img src="/images/department.svg" /> Department
        </Flex>
      ),
      dataIndex: 'dep',
      key: 'dep',
      filters: departments,
      filteredValue: filteredInfo.dep || null,
      onFilter: (value, record) => record.dep.includes(value),
      sorter: (a, b) => a.dep.length - b.dep.length,
      sortOrder: sortedInfo.columnKey === 'dep' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: (
        <Flex className="gap-2">
          <img src="/images/date.svg" /> Date created
        </Flex>
      ),
      dataIndex: 'date_create',
      key: 'date_create',

      sorter: (a, b) => new Date(a.date_create) - new Date(b.date_create),
      sortOrder: sortedInfo.columnKey === 'date_create' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: (
        <Flex className="gap-2">
          <img src="/images/history.svg" /> Last update
        </Flex>
      ),
      dataIndex: 'date_update',
      key: 'date_update',

      sorter: (a, b) => new Date(a.date_update) - new Date(b.date_update),
      sortOrder: sortedInfo.columnKey === 'date_update' ? sortedInfo.order : null,
      ellipsis: true,
    },

  ];
  const handlePagination = page => {
    //This should be called only when pagination changes
   // dispatch(PaginateData(page));
  //  console.log(page)
  setLoading(true)
   ticketApi
      .tickets(apiClient,page)
      .then((r) => {
        r.map(t=>{
         if( t.important)
         {
            t.important=<img  src="/images/urgent.svg" />
         }
         else{
          t.important=''
         }
        })
        setTickets(r);
      })
      .catch((error) => console.error('Error:', error))
      .finally(()=>setLoading(false));
};

  return (
    <div className="px-20 mt-36">
      <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button className="bg-[#234E70] text-white" onClick={ticket}>
          New Ticket
        </Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space>
      <Table rowClassName="cursor-pointer"
        columns={columns}
        dataSource={tickets}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {navigate(`/tickets/${record.id}`)}, // click row
          };
        }}
        onChange={handleChange}
        loading={loading}
        pagination={{
          onChange: handlePagination,
          // pageSize: tickets.per_page,
          pageSize: 10,
          // total: tickets.total,
          total: tickets.length,
        }}
      />
    </div>
  );
};
export default Tickets;
