import Global from "../Settings/config.json";
export const creteriaApi= {
    async submit(apiClient,project,sizing,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/design/insert/${project}${sizing?'/'+sizing:''}`,data)
            .then((json) => {
                switch (json.status) {
                    case 201:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async show(apiClient,project,sizing) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/design/project/${project}${sizing?'/'+sizing:''}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
}