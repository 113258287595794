import { atot, vol_hor, vol_hor_L, vol_hor_dish } from "./Helper"

export const K32f=(I40,K32)=>{
    return (I40=== 7 || I40 === 8)?"-":K32[0].toFixed(2)??"-"
}
export const K33f=(I40,K33)=>{
    return (I40=== 7 || I40 === 8)?"-":K33[0].toFixed(2)??"-"
}
export const K34f=(I40,K34)=>{
    return (I40=== 7 || I40 === 8)?"-":K34[0].toFixed(2)??"-"
}
export const AF19f=(I40,O35, R35, V35, K35)=>{
    if (I40 === 7 || I40 === 8) {
        if (O35 && R35) {
            return K35 + " (for Gas & L. Liq.)";
        } else if (O35 && !R35) {
            return K35 + " (for Gas)";
        } else if (!O35 && R35) {
            return K35 + " (for L. Liq.)";
        } else {
            return "-";
        }
    } else {
        if (O35 && R35 && V35) {
            return K35 + " (for Gas & L. Liq. & H. Liq.)";
        } else if (O35 && R35 && !V35) {
            return K35 + " (for Gas & L. Liq.)";
        } else if (O35 && !R35 && V35) {
            return K35 + " (for Gas & H. Liq.)";
        } else if (!O35 && R35 && V35) {
            return K35 + " (for L. Liq. & H. Liq.)";
        } else if (O35 && !R35 && !V35) {
            return K35 + " (for Gas)";
        } else if (!O35 && R35 && !V35) {
            return K35 + " (for L. Liq.)";
        } else if (!O35 && !R35 && V35) {
            return K35 + " (for H. Liq.)";
        } else {
            return "-";
        }
    }
}
export const AL39f=(I40, AV45, AT45)=>{
        if (I40 === 1 || I40 === 2 || I40 === 7) {
          return AV45;
        } else {
          return AT45;
        }
}
export const I106f=(I40, BD75, AH71, BH79, AV79)=>{
    const PI = Math.PI;

    if (I40 === 1 || I40 === 2 || I40 === 7) {
      // Calculate volume for cases 1, 2, or 7
      return 0.25 * PI * Math.pow(BD75, 2) * AH71 / 1000;
    } else if (I40 === 3 || I40 === 4 || I40 === 8) {
      // Calculate volume using vol_hor for cases 3, 4, or 8
      return vol_hor(AH71, BD75, BH79);
    } else if (I40 === 5 || I40 === 6) {
      // Calculate volume using vol_hor_L for cases 5 or 6
      return vol_hor_L(AH71, BD75, AV79);
    } else {
      // Default case if none of the conditions are met
      return null;
    }
}
export const O106f=(I40, I106, BD75, AH71, AR83)=>{
    if (I40 === 1 || I40 === 2 || I40 === 7) {
        // Calculate for cases 1, 2, or 7
        return I106 + vol_hor_dish(BD75, BD75, I40, AR83);
      } else if (I40 === 3 || I40 === 4 || I40 === 8) {
        // Calculate for cases 3, 4, or 8
        return I106 + 2 * vol_hor_dish(AH71, BD75, I40, AR83);
      } else if (I40 === 5) {
        // Calculate for case 5
        return I106 + vol_hor_dish(AH71, BD75, I40, AR83);
      } else {
        // Default case
        return "-";
      }
}
export const I111f=(I40, N71, BD75, BH79, AR79, BD79, AR75)=>{
    const PI = Math.PI;

    if (I40 === 7 || I40 === 8) {
      // Return "-" for cases 7 or 8
      return "-";
    } else if (I40 === 1 || I40 === 2) {
      // Calculate for cases 1 or 2
      return 0.25 * PI * N71 / 1000 * Math.pow(BD75, 2);
    } else if (I40 === 3) {
      // Calculate using vol_hor for case 3
      return vol_hor(N71, BD75, BH79);
    } else if (I40 === 5) {
      // Calculate using vol_hor_L for case 5
      return vol_hor_L(N71, BD75, AR79);
    } else if (I40 === 6) {
      // Calculate using vol_hor_L for case 6
      return vol_hor_L(N71, BD75, BD79);
    } else {
      // Calculate using atot for other cases
      return atot(AR75) * N71 / 1000;
    }
}
export const O111f=(I40, I111, AR75, BD75, N71, AR83)=>{
    const PI = Math.PI;

    if (I40 === 7 || I40 === 8) {
      return "-";
    } else if (I40 === 4) {
      return I111 + (PI / 24) * Math.pow(AR75, 3);
    } else {
      let additionalValue;
      if (I40 === 1 || I40 === 2) {
        additionalValue = (PI / 24) * Math.pow(BD75, 3);
      } else if (I40 === 3) {
        additionalValue = 2;
      } else if (I40 === 5 || I40 === 6) {
        additionalValue = 1;
      } else {
        additionalValue = 0; // Handle unexpected I40 values
      }
      return I111 + additionalValue * vol_hor_dish(N71, BD75, I40, AR83);
    }
}