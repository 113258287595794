import Global from "../Settings/config.json";
export const userApi= {
    
    async plan(apiClient) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/plan`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
   
}