import React from 'react';
import { toFixed } from '../../helpers/Converts';

function Case5_8({ info }) {
  return (
    <>
       
       <div className="HHLL-H-5 grid text-start">
          <label>HHLL          {toFixed(info.AN71, 0)}</label>
          <label>HLL           {toFixed(info.AK71, 0)}</label>
          <label>NLL           {toFixed(info.AH71, 0)}</label>
          <label>LLL           {toFixed(info.AA71, 0)}</label>
          <label>LLLL          {toFixed(info.X71, 0)}</label>
        </div>
        <div className="HHLIL-H-5 grid text-start">
          <label>HHLIL         {toFixed(info.T71, 0)}</label>
          <label>HLIL           {toFixed(info.Q71, 0)}</label>
          <label>NLIL           {toFixed(info.N71, 0)}</label>
          <label>LLIL           {toFixed(info.G71, 0)}</label>
          <label>LLLIL          {toFixed(info.D71, 0)}</label>
        </div>
      <div className="L-H-5">
        <label>L </label>
        {toFixed(info.BH75, 3)}
      </div>
      <div className="D-H-5">
        <label>D </label>
        {toFixed(info.BD75, 3)}
      </div>

      <div className="hv-H-5 text-center">
        <label>
          H<sub className="align-sub ">v</sub>
        </label>
        <br />
        {toFixed(info.AR71, 0)}
      </div>

      <div className="L1-H-5 text-center">
        <label>L1</label>
        <br />
        {toFixed(info.AR79, 3)}
      </div>
      <div className="L2-H-5 text-center">
        <label>L2</label>
        <br />
        {toFixed(info.AV79, 3)}
      </div>
      <div className="Hw-H-5 ">
        <label>
          H <sub className="align-sub ">w</sub>
        </label>
        {toFixed(info.AV71, 0)}
      </div>
    </>
  );
}

export default Case5_8;
