export const atot = (d) => {
  return 0.25 * Math.PI * Math.pow(d, 2);
};
export const H_DtoA_AT = (height, dia) => {
  const x = height / dia;

  const a = -0.0000475593;
  const B = 3.924091;
  const c = 0.174875;
  const d = -6.358805;
  const E = 5.668973;
  const f = 4.018448;
  const G = -4.916411;
  const h = -1.801705;
  const i = -0.145348;

  const numerator =
    a + c * x + E * Math.pow(x, 2) + G * Math.pow(x, 3) + i * Math.pow(x, 4);
  const denominator =
    1 + B * x + d * Math.pow(x, 2) + f * Math.pow(x, 3) + h * Math.pow(x, 4);

  return numerator / denominator;
};
export const vol_hor_L = (h, d, L) => {
  var h_d_ratio = h / 1000 / d;
  var term1 = Math.acos(1 - 2 * h_d_ratio) / 4;
  var term2 = (0.5 - h_d_ratio) * Math.sqrt(h_d_ratio - Math.pow(h_d_ratio, 2));

  return L * Math.pow(d, 2) * (term1 - term2);
};
export const vol_hor = (h, d, l_d) => {
  const h_d = h / 1000 / d;
  const acosPart = Math.acos(1 - 2 * h_d) / 4;
  const sqrtPart = Math.sqrt(h_d - Math.pow(h_d, 2));
  const volHor = l_d * Math.pow(d, 3) * (acosPart - (0.5 - h_d) * sqrtPart);

  return volHor;
};
export const vol_hor_dish = (h, d, stype, dtype) => {
  var vol_hor_dish;
  var hc;

  if (stype === 1 || stype === 2 || stype === 7) {
    if (dtype === 1) {
      vol_hor_dish = (Math.PI / 24) * Math.pow(d, 3);
    } else if (dtype === 2) {
      vol_hor_dish = 0.45 * (Math.PI / 12) * Math.pow(d, 3);
    } else {
      vol_hor_dish = (Math.PI / 12) * Math.pow(d, 3);
    }
  } else {
    if (dtype === 1) {
      vol_hor_dish =
        (Math.PI / 24) *
        Math.pow(d, 3) *
        (3 * Math.pow(h / 1000 / d, 2) - 2 * Math.pow(h / 1000 / d, 3));
    } else if (dtype === 2) {
      vol_hor_dish =
        0.45 *
        (Math.PI / 12) *
        Math.pow(d, 3) *
        (3 * Math.pow(h / 1000 / d, 2) - 2 * Math.pow(h / 1000 / d, 3));
    } else {
      hc = d - h / 1000;
      vol_hor_dish =
        Math.PI *
        (Math.pow(d, 3) / 12 - (d * Math.pow(hc, 2)) / 4 + Math.pow(hc, 3) / 6);
    }
  }

  return vol_hor_dish;
};
export const A_ATtoh_D = (a, at) => {
  var x = a / at;
  if (x >= 1) return 1;
  var a = 0.00153756;
  var B = 26.787101;
  var c = 3.299201;
  var d = -22.923932;
  var E = 24.353518;
  var f = -14.844824;
  var G = -36.999376;
  var h = 10.529572;
  var i = 9.892851;

  var numerator =
    a + c * x + E * Math.pow(x, 2) + G * Math.pow(x, 3) + i * Math.pow(x, 4);
  var denominator =
    1 + B * x + d * Math.pow(x, 2) + f * Math.pow(x, 3) + h * Math.pow(x, 4);

  return numerator / denominator;
};
export const ceilToTen = (value) => Math.ceil(value / 10) * 10;
export const ceilToFifty = (value) => Math.ceil(value / 50) * 50;
export const ceiling = (value, significance) => {
  return Math.ceil(value / significance) * significance;
};
export const ifError = (value, defaultValue) => {
  return isNaN(value) ? defaultValue : value;
};
export const fetchData = (cell, returnType = 'float') => {
  if (returnType === 'float') return parseFloat(localStorage.getItem(cell));
  return parseInt(localStorage.getItem(cell));
};
export const  shell_thickness=(P, d, S, E, t_c)=>{
  const P_psi = P * 14.5038;
  const d_feet = d / 0.3048;
  const S_psi = S * 14.5038;
  const t_c_inch = t_c / 25.4;

  const thickness = (P_psi * d_feet) / (2 * S_psi * E - 1.2 * P_psi) + t_c_inch;

  return thickness;
}
      
export const dishThickness=(P, d, S, E, t_c)=>{
  const AR83 = parseInt(localStorage.getItem('AR83'));
  let dishThickness;
  if (AR83 === 1) {
      dishThickness = ((P * 14.5038) * (d / 0.3048) / (2 * (S * 14.5038) * E - 0.2 * (P * 14.5038))) + t_c / 25.4;
  } else if (AR83 === 2) {
      dishThickness = (0.885 * (P * 14.5038) * (d / 0.3048) / ((S * 14.5038) * E - 0.1 * (P * 14.5038))) + t_c / 25.4;
  } else if (AR83 === 3) {
      dishThickness = ((P * 14.5038) * (d / 0.3048) / (4 * (S * 14.5038) * E - 0.4 * (P * 14.5038))) + t_c / 25.4;
  }

  return dishThickness;
}

export const dishSurface=(d) =>{
  const AR83 = parseInt(localStorage.getItem('AR83'));

  
  let dishSurface;
  if (AR83 === 1) {
      dishSurface = 1.09 * Math.pow(d, 2);
  } else if (AR83 === 2) {
      dishSurface = 0.842 * Math.pow(d, 2);
  } else if (AR83 === 3) {
      dishSurface = 1.571 * Math.pow(d, 2);
  }
  
  return dishSurface;
}

