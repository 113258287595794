import { Affix, Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import CaseStudy from './CaseStudy';
import CaseChart from './CaseChart';
import Table2 from './tables/Table2';
import { ProjectContext } from '../Context/ProjectContext';
import Table5 from './tables/Table5';
import Table1 from './tables/Table1';
import Table3 from './tables/Table3';
import Schema from './Schema';
import ResultCaseStudy from './ResultCaseStudy';

const ResultSizing = () => {
  const [top, setTop] = React.useState(120);
  const { tipTableNumber, sizingLoading, caseStudyShow } =
    useContext(ProjectContext);
  // const [caseStudyShow, setCaseStudyShow] = useState(false);

  // useEffect(() => {
  //   if (analyzeLoading) setCaseStudyShow(true);
  // }, [analyzeLoading]);

  return (
    <Affix offsetTop={top} className="ms-3 me-3">
      <div className=" h-[85vh]  overflow-y-auto overflow-x-hidden">
        {[1, 2, 3, 5].find((e) => e == tipTableNumber?.id) && (
          <Card title={'Details'}>
            {tipTableNumber?.id == 1 && <Table1 refresh={Math.random()} />}
            {tipTableNumber?.id == 2 && <Table2 />}
            {tipTableNumber?.id == 3 && <Table3 refresh={Math.random()} />}
            {tipTableNumber?.id == 5 && (
              <Table5 params={tipTableNumber.params} />
            )}
          </Card>
        )}
        <Schema />
        {caseStudyShow && <ResultCaseStudy />}
      </div>
    </Affix>
  );
};

export default ResultSizing;
