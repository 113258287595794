import Global from "../Settings/config.json";
export const ticketApi= {
    
    async tickets(apiClient,page) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/tickets?page=${page}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async departments(apiClient) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/departments`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async load(apiClient,ticket) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/ticket/${ticket}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async insert(apiClient,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/ticket`,data)
            .then((json) => {
                switch (json.status) {
                    case 201:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async reply(apiClient,tid,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/ticket/reply/${tid}`,data)
            .then((json) => {
                switch (json.status) {
                    case 201:
                        res = json.data
                        break;
                }
            })
        return res;
    },
}