import Global from "../Settings/config.json";
export const weightApi= {
    
    async load(apiClient,id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/weight/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async analyze(apiClient,id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/weight/analyze/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
}