const hanldeMSGErrors = (data) => {
  const _keys = Object.keys(data);

  let message = '';
  _keys.forEach((key) => {
    if (key == 'message') return;
    if (typeof data[key] == 'array') {
      console.log(data, 44444444444, key, 234, data[key], 22222222222222);
    //  message += key + ':\n';

      data[key].map((msg) => {
        message += msg + '\n';
      });
    } else if (typeof data[key] == 'object') {
      const __keys = Object.keys(data[key]);
      __keys.forEach((k) => {
//message += k + ':\n';
        data[key][k].map((msg) => {
          message += msg + '\n';
        });
      });
    } else message += data[key] + '\n';
  });
  return message;
};
export const ParsError = (data) => {
  console.log(data, 222);
  switch (data?.data?.type ?? '') {
    case 'error':
      return data.data;
    default:
      return (
        <>
          {hanldeMSGErrors(data.data)
            .split('\n')
            .map((e,index) => {
              return (
                <div key={index}>
                  {e}
                  <br />
                </div>
              );
            })}
        </>
      );
  }
};
