import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import useApiClient from '../Settings/useApiClient';
import { useParams } from 'react-router-dom';
import { orderApi } from '../API/orderAPi';
import { monthToName } from '../Tools/tools';
import useNotify from '../hooks/useNotify';
import { ParsError } from '../Tools/ParsError';

export const AsidePayment = ({submit,loading,setLoading}) => {
  const { pid } = useParams();
  const apiClient = useApiClient();
  const [price, setPrice] = useState(0);
  const [name, setName] = useState(null);
  const [month, setMonth] = useState(null);
  const {openNotification}=useNotify();
  useEffect(() => {
    setLoading(true)
    orderApi.order(apiClient, pid).then((res) => {
      console.log(res);
      setPrice(res.price);
      setName(res.plan.title);
      setMonth(monthToName(res.months))
    }).finally(()=>setLoading(false));
  }, [pid]);
  // const pay=()=>{
  //   setLoading(true)
  //   orderApi.pay(apiClient, pid).then((res) => {
  //     document.location.href=res;
  //   }).catch(e=>
  //     {
  //       if(e?.response?.status==422){
  //         openNotification("Error",ParsError(e.response),"Error")
  //       }
  //     })
  //   .finally(()=>setLoading(false));
  // }
  // const subscribe=()=>{
  //   setLoading(true)
  //   orderApi.subscribe(apiClient, pid).then((res) => {
  //     document.location.href=res;
  //   }).catch(e=>console.log(e))
  //   .finally(()=>setLoading(false));
  // }
  return (
    <aside className="grid  bg-white gap-y-5 w-full p-10 place-items-start   ">
      <h6 className="font-semibold">■ Payment details</h6>
      <h6 className="font-bold">
        {name} Plan ({month})
      </h6>
      <div className="grid grid-cols-2 justify-between w-full gap-y-2 ">
        <div>Price</div>
        <strong className="text-end">{price}£</strong>
        <div>Discount</div>
        <strong className="text-end"> 0.00£</strong>
      </div>
      <Divider />
      <div className="grid grid-cols-2 justify-between w-full ">
        <strong className="text-lg">Total Price</strong>
        <strong className="text-lg text-end">{price}£</strong>
      </div>
      <div className="w-full text-center  mt-10 gap-5 grid place-items-center justify-stretch ">
        <Button
          htmlType="button"
          disabled={loading}
          loading={loading}
          onClick={()=>submit("pay")}
          className="w-full  m-auto grid h-16 overflow-hidden  rounded  text-white bg-[--color-3] hover:bg-gray-900  font-medium  text-lg  py-2 me-2 "
        >
         
          <span className='-mb-3'>  Pay Now</span>
          <sub className='text-xs'>One-time payment</sub>
        </Button>
        <Button
          htmlType="button"
          disabled={loading}
          loading={loading}
          onClick={()=>submit("subscribe")}
          className=" w-full m-auto grid h-16 leading-none    rounded  text-white bg-[--color-1] hover:bg-gray-900  font-medium  text-lg  py-2 me-2 "
        >
        <span className='-mb-4'>  Subscribe</span>
          <sub className='text-xs'>Automatic withdrawal as a subscription</sub>
        </Button>
        <Button
          onClick={()=>window.history.back()}
          className=" w-full m-auto   rounded  text-black bg-transparent  hover:bg-gray-900  font-medium  text-lg  py-5 me-2 "
        >
          Return to Panel
        </Button>
      </div>
    </aside>
  );
};
