
export const preAnalyze = async (data) => {
  
  //////////design_sepecific
  //////////////////////////////////////////////////////////
  localStorage.setItem(
    'WA_D5',
    parseFloat(data['design_specification'].design_pressure)
  );
  localStorage.setItem(
    'WA_D10',
    parseFloat(data['design_specification'].allowable_stress)
  );
  localStorage.setItem(
    'WA_O5',
    parseFloat(data['design_specification'].joint_efficiency)
  );
  localStorage.setItem(
    'WA_I5',
    parseFloat(data['design_specification'].corrosion_allowance)
  );
  localStorage.setItem(
    'WA_L10',
    parseFloat(data['design_specification'].material_density)
  );
  localStorage.setItem('AX83', parseFloat(data['design_specification'].from));
  localStorage.setItem('BA83', parseFloat(data['design_specification'].to));
  localStorage.setItem('BE83', parseFloat(data['design_specification'].step));
  localStorage.setItem('revise_analyze', parseInt(data['design_specification'].revise));
  localStorage.setItem('dataSources',"");

  /////////////////////////////////////////////////////////////
 
};
