import {createContext} from "react"

export const ProjectContext=createContext({
    setTipTableNumber:()=>{},
    tipTableNumber:null,
    project:null,
    setProject:()=>{},
    sizing:null,
    active:'design',
    setActive:()=>{},
    setSizing:()=>{},
    updateSizing:()=>{},
    sizingLoading:false,
    analyzeLoading:false,
    setSizingLoading:()=>{},
    setAnalyzeLoading:()=>{},
    config:null,
    setConfig:()=>{},
    caseStudyShow:false,
    setCaseStudyShow:()=>{},
    dataInput:{section:""},
    setDataInput:()=>{}
})