// src/pages/Login.js
import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useNotify from '../../hooks/useNotify';
import useApiClient from '../../Settings/useApiClient';
import { AuthApi } from '../../API/AuthApi';
import { ParsError } from '../../Tools/ParsError';

const Reset = () => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPass, setShowPass] = useState(false)
  const [showPass2, setShowPass2] = useState(false)
  const [loading, setLoading] = useState(false);
  const {openNotification}=useNotify()
  const navigate=useNavigate()
  const {code}=useParams();
  const apiClient = useApiClient();
  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    AuthApi.reset(apiClient, { code, password,password_confirmation:password2 })
      .then((res) => {
        openNotification('success',"Your password reset successfully");
        setTimeout(()=>{
            navigate("/")
        },1000)
     
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response));
      })
      .finally(() => setLoading(false));
  };
  const togglePass=()=>{
    setShowPass(!showPass)
  }
  const togglePass2=()=>{
    setShowPass2(!showPass2)
  }
  return (
    <div className="grid lg:grid-cols-2 h-lvh gap-10 bg-red-50 p-5 w-full">
      <div className="lg:col-span grid content-center  rounded-3xl   space-y-12 bg-white p-14 m-20 me-0 ms-40">
        <img src="/images/logo.svg" alt="omnisep"  className='m-auto block'/>
        <a className='!text-[#386B91] text-center text-[18px] gap-2 items-center flex justify-self-center font-semibold !mt-5' href='https://omnisep.co.uk/'>
          <img src="images/arrow-left.svg" />
          Back to homepage</a>
        <h1 className='text-3xl m-auto block font-bold'>Reset Password</h1>
        <form className="w-100 p-10 " onSubmit={handleLogin}>
          <div className="mb-8">
            {/* <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label> */}
            <input
              type="text"
              value={code}
              id="code"
              className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled
            />
          </div>
          <div className="mb-2 flex items-center">
          
            <input
              type={showPass?"text":"password"}
              value={password}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              
            />
            
             <button type="button" id="togglePassword"  onClick={togglePass}
                                class="focus:outline-none -ml-8">
                            <img src="/images/eyeClose.svg" 
                                 alt="" class="w-4"/>
                        </button>
          </div>
          <div className='mb-8 flex items-center'>
          <input
              type={showPass2?"text":"password"}
              value={password2}
              placeholder="Enter your password again"
              onChange={(e) => setPassword2(e.target.value)}
              id="password_confirm"
              className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              
            />
             <button type="button" id="togglePassword"  onClick={togglePass2}
                                class="focus:outline-none -ml-8">
                            <img src="/images/eyeClose.svg" 
                                 alt="" class="w-4"/>
                        </button>
          </div>
          {/* <div className="flex items-start mb-5">
            <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              />
            </div>
            {/* <label
              htmlFor="remember"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember me
            </label> 
          </div> */}
          <Button
            loading={loading}
            onClick={handleLogin}
            className="text-white h-14 mb-8 bg-brown hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg rounded-lg  w-full px-5 py-2.5 text-center "
          >
            Reset Password
          </Button>
        </form>
      </div>
      <div className="col-span-2 lg:col-span-1 bg-gray-100"></div>
    </div>
  );
};

export default Reset;
