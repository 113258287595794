// src/pages/Login.js
import React, { useContext, useEffect, useState } from 'react';
import { AuthApi } from '../API/AuthApi';
import { ParsError } from '../Tools/ParsError';
import { Button } from 'antd';
import useAuth from '../hooks/useAuth';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { configApi } from '../API/configApi';
import RegisterIndividual from '../components/RegisterIndividual';
import RegisterEnterprise from '../components/RegisterEnterprise';

const Register = () => {
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState(0);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { openNotification } = useNotify();
  const apiClient = useApiClient();
  const { saveToken } = useAuth();
 
  return (
    <div className="m-10 mx-20">
      <div className="grid gap-5 text-center m-auto relative ">
        <h1 className="text-3xl font-bold m-auto block text-center">Welcome to</h1>
        <img src="/images/logo.svg" className="block m-auto" />
        <p>Please enter your information to get started</p>
        <div className="flex bg-white p-3  m-auto rounded border broder-[#D9D9D9] gap-x-5  ">
          <div className="flex items-center me-4  cursor-pointer ">
            <input
              id="Individual"
              type="radio"
              value={0}
              onClick={(e)=>setUserType(0)}
              checked={userType===0}
              name="userType"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="Individual"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
            >
              Individual
            </label>
          </div>

          <div className="flex items-center me-4 cursor-pointer">
            <input
              
              id="Enterprise"
              type="radio"
              value={1}
              onClick={(e)=>setUserType(1)}
              name="userType"
              checked={userType===1}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="Enterprise"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
            >
              Enterprise
            </label>
          </div>
        </div>
        <Link to="/login" className='absolute  bottom-0 flex justify-start items-center float-start text-[#386B91]'> <img  src="/images/back.svg"/> Back to homepage </Link>

      </div>
      {!userType ?
      <RegisterIndividual />
      :
      <RegisterEnterprise />
}
    </div>
  );
};

export default Register;
