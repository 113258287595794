import { Spin, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '../../Context/ProjectContext';
import useApiClient from '../../Settings/useApiClient';
import { processConditionApi } from '../../API/processConditionApi';
const dataSource = [
  {
    key: '1',
    Light: 'Hydrocarbons',
    Heavy: '-',
    Min: '-',
    KS: '-',
  },
  {
    key: '2',
    Light: 'SG @ 60 °F >0.85',
    Heavy: 'Water or Caustic',
    Min: '127',
    KS: '0.333',
  },
  {
    key: '3',
    Light: 'SG @ 60 °F <0.85',
    Heavy: 'Water or Caustic',
    Min: '89',
    KS: '0.163',
  },
  {
    key: '4',
    Light: 'Water',
    Heavy: 'Furfural',
    Min: '89',
    KS: '0.163',
  },
  {
    key: '5',
    Light: 'Methylethyl ketone',
    Heavy: 'Water',
    Min: '89',
    KS: '0.163',
  },
  {
    key: '6',
    Light: 'sec-Butyl alcohol',
    Heavy: 'Water',
    Min: '89',
    KS: '0.163',
  },
  {
    key: '7',
    Light: 'Methyl isobutyl keton',
    Heavy: 'Water',
    Min: '89',
    KS: '0.163',
  },
  {
    key: '8',
    Light: 'Nonyl alcohol',
    Heavy: 'Water',
    Min: '89',
    KS: '0.163',
  },
];

const columns = [
  {
    title: 'Light Phase',
    dataIndex: 'Light',
    key: 'Light',
  },
  {
    title: 'Heavy Phase',
    dataIndex: 'Heavy',
    key: 'Heavy',
  },
  {
    title: 'Min. DP (µm)',
    dataIndex: 'Min',
    key: 'Min',
  },
  {
    title: 'KS',
    dataIndex: 'KS',
    key: 'KS',
  },
];

const Table3 = ({refresh}) => {
  const [loading, setLoading] = useState(true);
  const { sizing } = useContext(ProjectContext);
  const apiClient = useApiClient();

  const [K31, setK31] = useState(0);
  useEffect(() => {
    if (sizing) {
      setLoading(true);

      processConditionApi
        .load(apiClient, sizing.id)
        .then((res) => {
          if (!res) return;
          // setResult(res);
          setK31(res.K31[0]);
        })
        .finally(() => setLoading(false));
    }
  }, [refresh]);
  const subtitle = () => {
    // Calculate the theoretical value
    const theoreticalValue = 0.0000206151 * Math.pow(K31, 2);

    // Round the value to 3 decimal places
    const roundedValue = Math.round(theoreticalValue * 10000) / 10000;

    // Format and return the result
    return `>>> Theoretical = ${roundedValue} <<<`;
  };
  if (loading) return <Spin />;

  return (
    <>
      <Table
        size="small"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        title={() => (
          <strong className="text-center m-auto block">
            Table 3 - KS Suggestion for (L. Liq. / H. Liq.) <br />
            {subtitle()}
          </strong>
        )}
      />
    </>
  );
};

export default Table3;
