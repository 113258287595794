import { Button, Spin } from 'antd';
import Typography from 'antd/es/typography/Typography';
import React, { useContext, useEffect, useRef } from 'react';
import { ProjectContext } from '../Context/ProjectContext';
import useApiClient from '../Settings/useApiClient';
import { preAnalyze} from '../helpers/Analyze';
import { sizingApi } from '../API/sizingApi';
import { useParams } from 'react-router-dom';
import useNotify from '../hooks/useNotify';
import { event_change_data, event_set_caseStudy } from '../services/events';
import { ParsError } from '../Tools/ParsError';
import { weightApi } from '../API/weightApi';

const AnalyzeLoadingPage = () => {
  const { sizing, setAnalyzeLoading, analyzeLoading ,setSizing} =
    useContext(ProjectContext);
  const workerRef = useRef(null);
  const { openNotification } = useNotify();
  const { id } = useParams();
  const apiClient = useApiClient();

  const saveDataSource = async () => {
    const data = { datasource: await localStorage.getItem('dataSource') };

    await sizingApi
      .save_datasource(apiClient,id, data)
      .then((r) => {
          setSizing({...sizing,status:2})
      })
      .finally(()=>setAnalyzeLoading(2));
  };

  const preParingAnalyzeIt = () => {
    weightApi
      .analyze(apiClient, id)
      .then((res) => {
        preAnalyze(res).then(() => {
          workerRef.current = new Worker(
            new URL('/js/analyze.js', window.location.href)
          );
          workerRef.current.onmessage = async (e) => {
            const { action, key, value } = e.data;

            if (action === 'change_data') {
              event_change_data(key);
            } else if (action === 'save_server') {
              // await saveData(key, value);
            } else if (action === 'case_study') {
              event_set_caseStudy();
            } else if (action === 'setItem') {
              localStorage.setItem(key, value);
            } else if (action === 'getItem') {
              const value = localStorage.getItem(key);
              workerRef.current.postMessage({
                action: 'receivedData',
                key,
                value,
              });
            } else if (action === 'finish') {
              saveDataSource();
              if (workerRef.current) {
                workerRef.current.terminate();
                // setAnalyzeLoading(2);
              }
            } else if (action == 'msg') {
              openNotification('Alert', key, 200);
            }
          };
        });
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          openNotification('Error', ParsError(e?.response),"Error");
          setAnalyzeLoading(-1)
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (analyzeLoading==1) preParingAnalyzeIt();
  }, [analyzeLoading]);
  const handleCancel = () => {
    setAnalyzeLoading(-1);
    if (workerRef.current) {
      workerRef.current.terminate();
    }
  };
  return (
    <>
      {analyzeLoading == 1 && (
        <div className="fixed z-50 w-full h-full  translate-x-3 grid content-center justify-center items-center bg-black opacity-70">
          <Typography.Title
            level={1}
            className="!text-white text-center m-auto"
          >
            Analyze...
          </Typography.Title>
          <p className="!text-white block ">
            Analyze in progress. Please wait a moment
          </p>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <Spin size="large" />
          </div>
          ‌
          <Button
            onClick={handleCancel}
            icon={<img src="/images/cancel.svg" />}
            className="!text-white hover:!text-black w-24 m-auto"
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default AnalyzeLoadingPage;
