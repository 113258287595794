import { Badge, Button, Form, Input, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { profileApi } from '../../API/profileApi';
import useApiClient from '../../Settings/useApiClient';
import useNotify from '../../hooks/useNotify';
import { ParsError } from '../../Tools/ParsError';
import { industries, sizes } from '../../assets/consts';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [card, setCard] = useState(false);
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [repPass, setRepPass] = useState('');
  const apiClient = useApiClient();
  const { openNotification } = useNotify();
  useEffect(() => {
    setLoading(true);
    profileApi
      .profile(apiClient)
      .then((res) => {
        setProfile(res['profile']);
        setCard(res['card']);
        setCountries(res['countries']);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);
  const changePass = () => {
    setLoading(true);
    if (password !== repPass) {
      openNotification(
        'Error',
        'The confirm password confirmation does not match!',
        'Error'
      );
      setLoading(false);
      return;
    }
    const data = {
      password: password,
      current_password: currentPassword,
      password_confirmation: repPass,
    };
    profileApi
      .change_pass(apiClient, data)
      .then((res) => {
        openNotification('Success', 'Your password changes successfully!', 200);
      })
      .catch((e) => {
        if (e?.response?.status == 422)
          openNotification('Error', ParsError(e.response), 'Error');
      })
      .finally(() => setLoading(false));
  };
  const onChangeSize = (value) => {
    let _company = profile.company;
    _company = { ..._company, company_size: value };
    setProfile({...profile,company:_company})
  };
  const onChangeWebsite = (e) => {
    let _company = profile.company;
    _company = { ..._company, website: e.target.value };
    setProfile({...profile,company:_company})
  };
  const onChangeName = (e) => {
    let _company = profile.company;
    _company = { ..._company, name: e.target.value };
    setProfile({...profile,company:_company})
  };
  const onChangeIndustry = (value) => {
    let _company = profile.company;
    _company = { ..._company, industry_sector: value };
    setProfile({...profile,company:_company})
  };
  const onChangePhone = (e) => {
    let _company = profile.company;
    _company = { ..._company, phone: e.target.value };
    setProfile({...profile,company:_company})
  };
  
  const submit=()=>{
    setLoading(true)
    const data={
        profile:profile,
        card:card
    }
    profileApi
    .save(apiClient, data)
    .then((res) => {
      openNotification('Success', 'Your profile saved successfully!', 200);
    })
    .catch((e) => {
      if (e?.response?.status == 422)
        openNotification('Error', ParsError(e.response), 'Error');
    })
    .finally(() => setLoading(false));
  }
  return (
    <Form onFinish={submit}  className=" grid bg-white p-5 rounded top-20 mt-40 w-3/5 m-auto">
      <strong className="text-[--color-2]">■ User information</strong>
      <div className="grid grid-cols-3 gap-4 m-5">
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">
            Name and Surname
          </Typography.Text>
          <Input
            value={profile?.name}
            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">Email</Typography.Text>
          <Input
            value={profile?.email}
            disabled={true}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Mobile number
          </Typography.Text>
          <Input
            value={profile?.phone}
            onChange={(e) => setProfile({ ...profile,phone: e.target.value })}
            type="number"
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">Country</Typography.Text>
          <Select
            loading={loading}
            placeholder={'Select'}
            className="focus:!border-none"
            value={profile?.region_id}
            showSearch
            onChange={(value) => setProfile({ ...profile, region_id: value })}
            options={countries.map((country) => ({
              label: country.en_short_name,
              value: country.num_code,
            }))}
          />
        </div>
        <div></div>
        <div></div>
      </div>
      <strong className="text-[--color-2]">■ Change Password</strong>
      <div className="grid grid-cols-4 gap-4 m-5">
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Current Password</Typography.Text>
          <Input
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">New Password</Typography.Text>
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Repeat New Password</Typography.Text>
          <Input
            value={repPass}
            onChange={(e) => {
              setRepPass(e.target.value);
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <Button
          onClick={changePass}
          disabled={loading}
          loading={loading}
          className=" w-auto m-auto  mt-7 rounded  text-white bg-[--color-1] hover:bg-gray-900  font-medium  text-sm px-14 py-4  "
        >
          Change Password
        </Button>
      </div>
      <strong className="text-[--color-2]">■ Company information</strong>
      <div className="grid grid-cols-3 gap-4 m-5">
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Company name</Typography.Text>
          <Input
            value={profile?.company?.name}
            onChange={onChangeName}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Industry sector
          </Typography.Text>
          <Select
            value={profile?.company?.industry_sector}
            onChange={onChangeIndustry}
            options={industries}
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Company size
          </Typography.Text>
          <Select
            value={profile?.company?.company_size}
            onChange={onChangeSize}
            className=""
            options={sizes}
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Phone number
          </Typography.Text>
          <Input
            value={profile?.company?.phone}
            type="number"
            onChange={onChangePhone}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Website</Typography.Text>
          <Input
            value={profile?.company?.website}
            onChange={onChangeWebsite}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
      </div>
      <strong className="text-[--color-2]"> ■ Card holder’s information</strong>
      <div className="grid grid-cols-3 gap-4 m-5">
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Card holder’s name
          </Typography.Text>
          <Input
            value={card?.name}
            onChange={(e) => {
              setCard({ ...card, name: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Mobile number
          </Typography.Text>
          <Input
            value={card?.mobile}
            onChange={(e) => {
              setCard({ ...card, mobile: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Email</Typography.Text>
          <Input
            value={card?.email}
            onChange={(e) => {
              setCard({ ...card, email: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
      </div>
      <strong className="text-[--color-2]">■ Billing address</strong>
      <div className="grid grid-cols-3 gap-4 m-5">
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
          Building number / name
          </Typography.Text>
          <Input
            value={card?.address_name}
            onChange={(e) => {
              setCard({ ...card, address_name: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2 col-span-2">
          <Typography.Text className="   ">
            Street / road
          </Typography.Text>
          <Input
            value={card?.address_street}
            onChange={(e) => {
              setCard({ ...card, address_street: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">
            Town / city
          </Typography.Text>
          <Input
            value={card?.address_city}
            onChange={(e) => {
              setCard({ ...card, address_city: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Country</Typography.Text>
          <Input
            value={card?.address_country}
            onChange={(e) => {
              setCard({ ...card, address_country: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   ">Postcode</Typography.Text>
          <Input
            value={card?.address_postcode}
            onChange={(e) => {
              setCard({ ...card, address_postcode: e.target.value });
            }}
            className="input-general  focus:border-none focus:outline-none"
          />
        </div>
      </div>
      <strong className="text-[--color-2]">■ Subscribtion Status</strong>
      <div className="grid  m-5">
        <Badge
          status={profile?.subscribe ? 'success' : 'default'}
          text={profile?.subscribe ? 'Active ("Raise a ticket to support team if you want cancel your subscription)' : 'Not subscribe'}
        />
      </div>
      <div className="grid   text-center m-auto">
        <Button
          htmlType="submit"
          disabled={loading}
          loading={loading}
          className=" w-auto m-auto   rounded  text-white bg-[--color-3] hover:bg-gray-900  font-medium  text-sm px-14 py-4  "
        >
          Save Changes
        </Button>
      </div>
    </Form>
  );
};

export default Profile;
