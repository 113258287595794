import React, { useContext, useState } from 'react';
import { SettingContext } from '../Context/SettingsContext';

const SubHeader=({ openSidebar, setOpenSidebar ,fullWidth,setFullWidth,title})=>{
    const context=useContext(SettingContext)
  return (
    <div className="ms-4 my-1 relative z-50 items-center flex">
    
      <a  href='/'
        // onClick={() => context.setFullWidth(!context.fullWidth)}
        // type="button"
        className=" border-0 max-[480px]:hidden inline-flex items-center pe-1 text-sm rounded-lg  hover:bg-gray-100 focus:outline-none "
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
        <span className='text-basic text-blue-700  ms-2 '>Home</span>
      </a>
      /
      <a  className=" border-0 max-[480px]:hidden inline-flex items-center ps-0 p-2 text-sm rounded-lg  hover:bg-gray-100 focus:outline-none ">
      <strong className='text-lg ms-1 '>{title}</strong>
      </a>
      {/* <button
        // data-drawer-target="logo-sidebar"
        onClick={() => setOpenSidebar(!openSidebar)}
        // data-drawer-toggle="logo-sidebar"
        // aria-controls="logo-sidebar"
        type="button"
        className="sm:hidden inline-flex items-center p-2 text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button> */}
    </div>
  );
}

export default SubHeader;
