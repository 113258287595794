import Global from '../Settings/config.json';
export const orderApi = {
  async invoice(apiClient, oid) {
    let res = [];
    await apiClient
      .get(`${Global.SERVER}user/order/invoice/${oid}`)
      .then((json) => {
        switch (json.status) {
          case 200:
            res = json.data;
            break;
        }
      });
    return res;
  },
  async order(apiClient, pid) {
    let res = [];
    await apiClient.get(`${Global.SERVER}user/order/${pid}`).then((json) => {
      switch (json.status) {
        case 200:
          res = json.data;
          break;
      }
    });
    return res;
  },
  async verify(apiClient, oid, data) {
    let res = [];
    await apiClient
      .post(`${Global.SERVER}user/paypal/verify/${oid}`, data)
      .then((json) => {
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });
    return res;
  },
};
