import Global from "../Settings/config.json";
export const sizingApi= {
    
    async load(apiClient,id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/sizing/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async schema(apiClient,id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/sizing/schema/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },

    async baseOn(apiClient,id) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/sizing/base_on/${id}`)
            .then((json) => {
                switch (json.status) {
                    case 201:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async addLabel(apiClient,id,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/sizing/label/${id}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async update(apiClient,sizingId,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/sizing/update/${sizingId}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async new(apiClient,project_id) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/${project_id}/sizing/insert`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async data(apiClient,sizing_id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/sizing/${sizing_id}/data`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async view(apiClient,sizing_id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/sizing/${sizing_id}/view`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async save(apiClient,id,index,data) {

        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/sizing/save/${id}/${index}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async save_datasource(apiClient,id,data) {

        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/sizing/save/datasource/${id}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
}