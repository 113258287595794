import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal } from 'antd';
import { Link } from 'react-router-dom';
const ModalSubscription = ({open}) => {
  const [isModalOpen, setIsModalOpen] = useState(open);
  useEffect(()=>{
    setIsModalOpen(open);
  },[open])
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      
      <Modal title="Upgrade your plan"   centered open={isModalOpen} onOk={handleOk} 
      footer={
        <div className='w-100 grid items-center justify-center'>
        <Link  className='submit rounded !py-2 !text-white  ' to={"/plans"}>
          See plans
        </Link>
        </div>
      }
      onCancel={handleCancel}>
        <Flex className='p-2 mb-4 items-start gap-2'> <img src="/images/alert.svg" />     Please select or upgrade your plan to access all the amazing features we offer.</Flex>
      </Modal>
    </>
  );
};
export default ModalSubscription;