import { Card } from 'antd'
import React, { useState } from 'react'
import CaseChart from './CaseChart'
import CaseStudy from './CaseStudy'

const ResultCaseStudy = () => {
    const [maximizeSchemaStyle, setMaximizeSchemaStyle] = useState('');
    const maximizeSchema = () => {
        if (maximizeSchemaStyle) setMaximizeSchemaStyle('');
        else
          setMaximizeSchemaStyle(
            '!fixed !w-full left-0 top-28 !h-[70vw] overflow-auto overflow-x-hidden'
          );
      };
  return (
    <Card title={'Case Study'}  className={` ${maximizeSchemaStyle} bg-white `} extra={
        <img
          src={'/images/maximize.svg'}
          role="button"
          onClick={maximizeSchema}
          className={`w-7  hover:scale-125 rounded-sm `}
        />
      }>
    <label className="text-start font-bold">Plot</label>
    <CaseChart />
    <label className="text-start font-bold">Table</label>
    <CaseStudy />
  </Card>

  )
}

export default ResultCaseStudy