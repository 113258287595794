import React from 'react'

function Case8_3({info}) {
    return (
        <>
         
          <div className="HHLL">
            <label>HHLL </label>970
          </div>
          <div className="HLL">
            <label>HLL </label>970
          </div>
          <div className="NLL">
            <label>NLL </label>970
          </div>
          <div className="LLL">
            <label>LLL </label>970
          </div>
          <div className="LLLL">
            <label>LLLL </label>970
          </div>
          <div className="HHLIL">
            <label>HHLIL </label>970
          </div>
          <div className="HLIL">
            <label>HLIL </label>970
          </div>
          <div className="NLIL">
            <label>NLIL </label>970
          </div>
          <div className="LLIL">
            <label>LLIL </label>970
          </div>
          <div className="LLLIL">
            <label>LLLIL </label>970
          </div>
          <div className="L">
            <label>L </label>970
          </div>
          <div className="D">
            <label>D </label>970
          </div>
          <div className="hd">
            <label>Hd </label>9122
          </div>
          <div className="hbn">
            <label>Hbn </label>9122
          </div>
        </>)
}

export default Case8_3