import { Spin, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { typeSpecificApi } from '../../API/typeSpecifiApi';
import useApiClient from '../../Settings/useApiClient';
import { ProjectContext } from '../../Context/ProjectContext';
import { processConditionApi } from '../../API/processConditionApi';

const Table1 = ({refresh}) => {
  const [loading, setLoading] = useState(true);
  const { sizing } = useContext(ProjectContext);
  const apiClient = useApiClient();

  const [R21, setR21] = useState(0);
  const [R22, setR22] = useState(0);
  const [R23, setR23] = useState(0);
  const [R24, setR24] = useState(0);
  const [R25, setR25] = useState(0);
  const [R26, setR26] = useState(0);
  const [R27, setR27] = useState(0);
  const [R28, setR28] = useState(0);
  useEffect(() => {
   
    if (sizing){
      setLoading(true)
 
      processConditionApi
        .load(apiClient, sizing.id)
        .then((res) => {
          if (!res) return;
          // setResult(res);
          setR21(res.K21[1]);
          setR22(res.K22[1]);
          setR23(res.K23[1]);
          setR24(res.K24[1]);
          setR25(res.K25[1]);
          setR26(res.K26[1]);
          setR27(res.K27[1]);
          setR28(res.K28[1]);
          
        })
        .finally(() => setLoading(false));
      }
  }, [refresh]);
  const w3 = () => {
    const sumR = R21 + R22;

    if (sumR >= 1 && sumR < 15) {
      return (0.1821 + 0.0029 * sumR + 0.0461 * Math.log(sumR)).toFixed(3);
    } else if (sumR >= 15 && sumR < 40) {
      return 0.35;
    } else if (sumR >= 40 && sumR <= 5500) {
      return (0.43 - 0.023 * Math.log(sumR)).toFixed(3);
    } else {
      return 'N/A';
    }
  };
  const w5 = () => {
    return (0.35 - 0.0001 * (R22 - 100)).toFixed(3);
  };
  const w7 = () => {
    try {
      // Attempt the calculation
      return (w3() * 0.7).toFixed(3);
    } catch (error) {
      // If there is an error, return "N/A"
      return 'N/A';
    }
  };
  const w8 = () => {
    try {
      // Attempt the division
      const result = (w3() / 2).toFixed(3);

      // Check if the result is a valid number
      if (isNaN(result) || !isFinite(result)) {
        throw new Error('Invalid operation');
      }

      return result;
    } catch (error) {
      // If there is an error, return "N/A"
      return 'N/A';
    }
  };
  const w9 = () => {
    try {
      // Attempt the multiplication
      const result = (w3() * 0.75).toFixed(3);

      // Check if the result is a valid number
      if (isNaN(result) || !isFinite(result)) {
        throw new Error('Invalid operation');
      }

      return result;
    } catch (error) {
      // If there is an error, return "N/A"
      return 'N/A';
    }
  };
  const w10 = () => {
    try {
      // Intermediate calculations
      const constant = (4 * 32.17 * R27) / 3;
      const numerator = 95000000 * R24 * Math.pow(R27, 3) * (R28 - R24);
      const logarithm = Math.log(numerator / Math.pow(R25, 2));

      // Check the logs to prevent errors
      if (isNaN(logarithm) || !isFinite(logarithm)) {
        throw new Error('Invalid logarithm value');
      }

      // Calculate the main expression
      const exponentPart = Math.exp(
        8.411 -
          2.243 * logarithm +
          0.273 * Math.pow(logarithm, 2) -
          0.01865 * Math.pow(logarithm, 3) +
          0.0005201 * Math.pow(logarithm, 4)
      );

      const fullExpression = Math.sqrt(constant * exponentPart);

      // Check if the full expression is valid
      if (isNaN(fullExpression) || fullExpression < 0) {
        throw new Error('The result is not a valid number or negative');
      }

      return fullExpression.toFixed(3);
    } catch (error) {
      // If an error occurs, return "N/A"
      return 'N/A';
    }
  };
  if (loading) return <Spin />;

  return (
    <>
      <table>
        <thead>
          <th colSpan={3}>Table 1 - K value Suggestion</th>
        </thead>
        <tr>
          <td
            colSpan={2}
            style={{ border: '1px solid black', padding: '3px 8px' }}
          >
            Otto. H. York Inc. (with Mist Eliminator)
          </td>
          <td
            colSpan={2}
            style={{ border: '1px solid black', padding: '3px 8px', width: 50 }}
          >
            {w3()}
          </td>
        </tr>
        <tr>
          <td
            colSpan={2}
            style={{ border: '1px solid black', padding: '3px 8px' }}
          >
            GPSA{' '}
          </td>
          <td
            colSpan={2}
            style={{ border: '1px solid black', padding: '3px 8px', width: 50 }}
          ></td>
        </tr>
        <tr>
          <td
            style={{
              border: '1px solid black',
              padding: '0px',
              textAlign: 'center',
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
              width: 20,
            }}
            rowSpan={5}
          >
            Service
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            General
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            {w5()}
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            Vapors Under Vacuum
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            0.2000
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            glycol and amine solution
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            {w7()}
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            without mist eliminator
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            {w8()}
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            Comp. Suction, mole. sieve scrubber, expander inlet
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            {w9()}
          </td>
        </tr>
        <tr>
          <td
            colSpan={2}
            style={{ border: '1px solid black', padding: '3px 8px' }}
          >
            Theoretical (no Mist Eliminator or Horizontal Separator)
          </td>
          <td style={{ border: '1px solid black', padding: '3px 8px' }}>
            {w10()}
          </td>
        </tr>
      </table>
    </>
  );
};

export default Table1;
