import React, { useEffect,  useState } from 'react';

import { Select } from 'antd';
import useAuth from '../hooks/useAuth';
const { Option } = Select;
export const UnitSelect = ({ type ,defaultUnitValue,onUnitChange}) => {
  const { units } = useAuth();
 const [value, setValue] = useState();
  useEffect(()=>{
    let _value=units[type]?.filter((unit) => unit.unit_name===defaultUnitValue)[0]?.id
    if(_value)
    setValue(_value)
    // onUnitChange(_value)
  },[type,defaultUnitValue])
  useEffect(()=>{
    onUnitChange(value)
  },[value])

  return (
    <Select  value={value}   onChange={setValue}>
      {units[type]?.map((unit) => (
        <Option key={unit.id} value={unit.id}>{unit.unit_name}</Option>
      ))}
    </Select>
  );
};
