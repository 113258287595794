import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [units, setUnits] = useState(JSON.parse(localStorage.getItem('units')));
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')|| null));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));

  const saveToken = (userToken, userRefreshToken,units,profile) => {
   
    localStorage.setItem('token', userToken);
    localStorage.setItem('refreshToken', userRefreshToken);
    localStorage.setItem('units', JSON.stringify(units));
    localStorage.setItem('profile', JSON.stringify(profile));
    setToken(userToken);
    setRefreshToken(userRefreshToken);
    setUnits(units);
    setProfile(profile);
     
    
  };

  const removeToken = () => {
    
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('units');
    localStorage.removeItem('profile');
    setToken(null);
    setUnits(null);
    setProfile(null);
    setRefreshToken(null);
    localStorage.clear();
  };

  const logout = () => {
    removeToken();
    window.location.href = '/'; // Redirect to login page
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const units = JSON.parse(localStorage.getItem('units'))??'';
    const profile = JSON.parse(localStorage.getItem('profile')||null);
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (storedToken && storedRefreshToken) {
      setToken(storedToken);
      setRefreshToken(storedRefreshToken);
      setUnits(units);
      setProfile(profile);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, refreshToken, saveToken, removeToken,units,profile, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
