import Global from "../Settings/config.json";
export const DashboardApi= {
    
    async dashboard(apiClient,pid=null,query="",orderBy="created_at",sortBy='desc') {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/dashboard/${pid}?query=${query}&order_by=${orderBy}&sort_by=${sortBy}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
}