// src/pages/Home.js
import React, { useContext, useEffect, useState } from 'react';
import { SettingContext } from '../Context/SettingsContext';
import Sidebar from '../components/Sidebar';
import Toolbar from '../components/Toolbar';
import useApiClient from '../Settings/useApiClient';
import { DashboardApi } from '../API/DashboardApi';
import SizingCard from '../components/SizingCard';
import ModalSubscription from '../modals/ModalSubscription';
import { Spin } from 'antd';
import { HomeContext } from '../Context/HomeContext';
import ModalAlertSubscribe from '../modals/ModalAlertSubscribe';

const Home = () => {
  const [projects, setProjects] = useState(null);
  const [sizings, setSizings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectIdActive, setProjectIdActive] = useState(null)
  const [orderBy, setOrderBy] = useState("created_at")
  const [sortBy, setSortBy] = useState("desc")
  const [daysRemain, setDaysRemain] = useState(1000)
  const [query, setQuery] = useState("")
  const [modalOpen, setModalOpen] = useState(false);
  const [billboard, setBillboard] = useState({
    sizings: 0,
    inprogress: 0,
    weights: 0,
  });
  const [subscription, setSubscription] = useState(null);
  const context = useContext(SettingContext);
  const apiClient = useApiClient();

  useEffect(()=>{
    loadSizings(projectIdActive)
  },[sortBy,orderBy,query])
  const loadSizings = async (project_id) => {
    setLoading(true);
    DashboardApi.dashboard(apiClient, project_id,query,orderBy,sortBy)
      .then((res) => {
        setProjects(res['projects']);
        setSizings(res['sizings']);
        setBillboard(res['billboard'])
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    (async () => {
      DashboardApi.dashboard(apiClient).then((res) => {
        setProjects(res.projects);
        setSizings(res.sizings);
        setBillboard(res['billboard'])
        setSubscription(res.subscription);
        setDaysRemain(res.days);
        if (res.subscription == null) {
          setModalOpen(true);
        }
      });
    })();
  }, []);
  // useEffect(()=>{
  //   if(days > 0 && days <8 )
  //     set
  // },[days])
  return (
    <HomeContext.Provider value={{ setProjectIdActive, projectIdActive }}>
      <div className=" mt-14">
        <ModalSubscription open={modalOpen} />
        <ModalAlertSubscribe open={(daysRemain > 0 && daysRemain <8 )} days={daysRemain} />

        <Toolbar subscription={subscription} billboard={billboard} setOrderBy={setOrderBy} sortBy={sortBy} sendQuery={setQuery} setSortBy={setSortBy} />
        <Sidebar loadSizings={loadSizings} projects={projects} />
        <Spin tip="Loading" size="large" spinning={loading}>
          <div
            className={`p-4  relative top-24 ${
              context.fullWidth ? null : 'sm:ml-64'
            } `}
          >
            <div className="p-4  text-center w-100  ">
              {sizings.length == 0 ? (
                <div className="m-auto flex items-center justify-center  ">
                  <img
                    src="/images/emptyDashboard.svg"
                    className="mt-14 top-20"
                  />
                </div>
              ) : (
                <div className="grid grid-cols-5 gap-4">
                  {sizings?.map((sizing) => (
                    <SizingCard sizing={sizing} key={sizing.id} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    </HomeContext.Provider>
  );
};

export default Home;
