import React, { useEffect, useState } from 'react';
import useApiClient from '../../Settings/useApiClient';
import { ticketApi } from '../../API/ticketApi';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Checkbox, Form, Input, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useNotify from '../../hooks/useNotify';
import TextArea from 'antd/es/input/TextArea';
import { ParsError } from '../../Tools/ParsError';

const New = () => {
  const apiClient = useApiClient();
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [urgent, setUrgent] = useState(false);
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const navigate=useNavigate()
  const { openNotification } = useNotify();
  useEffect(() => {
    setLoading(true);
    ticketApi
      .departments(apiClient)
      .then((r) => {
        let _deps = [];
        r.map((dep) =>
          _deps.push({ label: dep.name, value: dep.id })
        );
        setDepartments(_deps);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setLoading(false));
  }, []);
  const props = {
    beforeUpload: (file) => {
      const isPic =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg';
      if (!isPic) {
        openNotification('Error', `${file.name} is not a picture file`);
        return false;
      }
      setFile(file);
      return false;
    },
  };
  const handleSubmit = () => {
    if (!department) {
      openNotification('Error', 'Please select a department!');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    if(file)
    formData.append('file', file);
    formData.append('urgent', urgent);
    formData.append('department', department);
    formData.append('title', title);
    formData.append('description', text);
    ticketApi
      .insert(apiClient, formData)
      .then((r) =>
        openNotification('Success', `Your ticket sent successfully!`,"Success")
      )
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response.data));
      })
      .finally(() => setLoading(false));
  };
  return (
    <Form
      className="w-100"
      onFinish={handleSubmit}
      name="basic"
      initialValues={{
        remember: true,
      }}
      autoComplete="off"
    >
      <div className="mt-36 grid  grid-cols-12 gap-5 p-3 items-start justify-start">
        <aside className="grid col-span-2 bg-white p-5 ">
          <label className="required text-sm">Department</label>
          <Select
            placeholder={'Select a department'}
            options={departments}
            loading={loading}
            onChange={setDepartment}
          />
          <br />
          <Checkbox onChange={setUrgent} checked={urgent}>
            <div className="flex gap-2">
              Urgent <img src="/images/urgent2.svg" />
            </div>
          </Checkbox>
          <br />
          <br />
          <br />
          <label className="text-sm">Pictures and files attachment</label>
          <Upload {...props} accept=".png,.jpg,.jpeg" maxCount={1}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          <br />
          <Alert
            className="flex justify-start   items-baseline"
            message="Please avoid uploading any files that contain personal information."
            banner
          />
        </aside>
        <div className="grid col-span-8 gap-3">
          <Input
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="p-3"
            placeholder="Title"
            prefix={<img src="/images/title2.svg" />}
          />
          <TextArea
            required
            value={text}
            className="p-3"
            count={{
              show: true,
              max: 1500,
            }}
            onChange={(e) => setText(e.target.value)}
            placeholder="Please write your question text here..."
            autoSize={{
              minRows: 14,
              maxRows: 40,
            }}
          />
          <div className='grid gap-4'>
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            className="mr-auto px-16 bg-[#234E70] text-white"
          >
            Send
          </Button>
          <Button
            onClick={()=>navigate("/tickets")}
            type="primary"
            className="mr-auto px-16 bg-white border border-[#234E70] text-[#234E70]"
          >
            Back to tickets
          </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default New;
