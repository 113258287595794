import { Progress } from 'antd';
import React from 'react';
import CurrentPlan from './CurrentPlan';

function SidebarPlans({load,setQ,q}) {
  return (
    <aside className="grid !bg-white rounded-sm p-7 w-1/6  gap-3 h-full">
      <h6>■ Categories</h6>
      <div role="button" onClick={()=>setQ("")} className={`hover:bg-[#E2E9ED] flex p-3 gap-2 ${q==""?'bg-[#E2E9ED]':''} `}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 4.5H4.5V10.5H10.5V4.5Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.5 4.5H13.5V10.5H19.5V4.5Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 13.5H4.5V19.5H10.5V13.5Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.5 13.5H13.5V19.5H19.5V13.5Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        All Plans
      </div>
      <div role="button"  onClick={()=>setQ("Basic")}  className={`hover:bg-[#E2E9ED] flex p-3 gap-2 ${q=="Basic"?'bg-[#E2E9ED]':''} `}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.98116 18.6913C6.5461 17.5806 7.40739 16.6478 8.46968 15.9963C9.53197 15.3448 10.7538 15 12 15C13.2461 15 14.468 15.3448 15.5303 15.9963C16.5926 16.6478 17.4539 17.5806 18.0188 18.6913"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Basic
      </div>
      <div role="button"  onClick={()=>setQ("Individual")}  className={`hover:bg-[#E2E9ED] flex p-3 gap-2 ${q=="Individual"?'bg-[#E2E9ED]':''} `}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.98116 18.6913C6.5461 17.5806 7.40739 16.6478 8.46968 15.9963C9.53197 15.3448 10.7538 15 12 15C13.2461 15 14.468 15.3448 15.5303 15.9963C16.5926 16.6478 17.4539 17.5806 18.0188 18.6913"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Individual
      </div>
      <div role="button"  onClick={()=>setQ("Enterprise")} className={`hover:bg-[#E2E9ED] flex p-3 gap-2 ${q=="Enterprise"?'bg-[#E2E9ED]':''} `}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 20.248H22.5"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.4995 20.248V3.74805C13.4995 3.54913 13.4204 3.35837 13.2798 3.21772C13.1391 3.07706 12.9484 2.99805 12.7495 2.99805H3.74945C3.55054 2.99805 3.35977 3.07706 3.21912 3.21772C3.07847 3.35837 2.99945 3.54913 2.99945 3.74805V20.248"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.9995 20.248V9.74805C20.9995 9.54913 20.9204 9.35837 20.7798 9.21772C20.6391 9.07706 20.4484 8.99805 20.2495 8.99805H13.4995"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.99945 6.74805H8.99945"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.49945 12.748H10.4995"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.99945 16.498H8.99945"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.4995 16.498H17.9995"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.4995 12.748H17.9995"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Enterprise
      </div>
      <div role="button" onClick={()=>setQ("Promotions")}  className={`hover:bg-[#E2E9ED] flex p-3 gap-2 ${q=="Promotions"?'bg-[#E2E9ED]':''} `}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.25 7.5H3.75C3.33579 7.5 3 7.83579 3 8.25V11.25C3 11.6642 3.33579 12 3.75 12H20.25C20.6642 12 21 11.6642 21 11.25V8.25C21 7.83579 20.6642 7.5 20.25 7.5Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.5 12V18.75C19.5 18.9489 19.421 19.1397 19.2803 19.2803C19.1397 19.421 18.9489 19.5 18.75 19.5H5.25C5.05109 19.5 4.86032 19.421 4.71967 19.2803C4.57902 19.1397 4.5 18.9489 4.5 18.75V12"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 7.5V19.5"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.2426 6.43969C15.182 7.50035 12 7.50035 12 7.50035C12 7.50035 12 4.31837 13.0607 3.25771C13.4827 2.83615 14.0549 2.59944 14.6514 2.59961C15.2479 2.59978 15.8199 2.83682 16.2417 3.25862C16.6635 3.68042 16.9006 4.25245 16.9007 4.84897C16.9009 5.44548 16.6642 6.01765 16.2426 6.43969V6.43969Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.75736 6.43969C8.81802 7.50035 12 7.50035 12 7.50035C12 7.50035 12 4.31837 10.9393 3.25771C10.5173 2.83615 9.94513 2.59944 9.34862 2.59961C8.7521 2.59978 8.18007 2.83682 7.75827 3.25862C7.33647 3.68042 7.09943 4.25245 7.09926 4.84897C7.09909 5.44548 7.3358 6.01765 7.75736 6.43969V6.43969Z"
            stroke="#10122F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Promotions
      </div>
      <hr />
      <CurrentPlan />
    </aside>
  );
}

export default SidebarPlans;
