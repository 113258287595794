import React, { useEffect, useState } from 'react'
import { Alert, Divider, Radio, Table } from 'antd';
import { paymentApi } from '../../API/paymentApi';
import useApiClient from '../../Settings/useApiClient';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
const columns = [
    {
        title: '#',
        dataIndex: 'id',
      },
    {
      title: <div className='flex'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 6H20.25" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.25 12H20.2494" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.25 18H20.2494" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.125 12.75C4.53921 12.75 4.875 12.4142 4.875 12C4.875 11.5858 4.53921 11.25 4.125 11.25C3.71079 11.25 3.375 11.5858 3.375 12C3.375 12.4142 3.71079 12.75 4.125 12.75Z" fill="#1C3E58"/>
      <path d="M4.125 6.75C4.53921 6.75 4.875 6.41421 4.875 6C4.875 5.58579 4.53921 5.25 4.125 5.25C3.71079 5.25 3.375 5.58579 3.375 6C3.375 6.41421 3.71079 6.75 4.125 6.75Z" fill="#1C3E58"/>
      <path d="M4.125 18.75C4.53921 18.75 4.875 18.4142 4.875 18C4.875 17.5858 4.53921 17.25 4.125 17.25C3.71079 17.25 3.375 17.5858 3.375 18C3.375 18.4142 3.71079 18.75 4.125 18.75Z" fill="#1C3E58"/>
      </svg>
      invoice</div>,
      dataIndex: 'invoice',
      render: (text) => <a>{text}</a>,
    },
    {
      title: <div className='flex'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 21.75H15.75" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.37847 15.6574C6.48667 14.9608 5.76435 14.0712 5.26581 13.0554C4.76727 12.0396 4.50548 10.9239 4.50011 9.79237C4.47765 5.72715 7.755 2.34783 11.8192 2.25217C13.394 2.21425 14.9409 2.67317 16.2402 3.56384C17.5396 4.45451 18.5256 5.73169 19.0582 7.21424C19.5909 8.69678 19.6432 10.3094 19.2078 11.8234C18.7724 13.3373 17.8713 14.6758 16.6324 15.6488C16.3596 15.8602 16.1383 16.1309 15.9854 16.4404C15.8326 16.7499 15.7521 17.0901 15.75 17.4353L15.75 18.0001C15.75 18.199 15.671 18.3897 15.5303 18.5304C15.3897 18.671 15.1989 18.75 15 18.75H8.99998C8.80107 18.75 8.61031 18.671 8.46966 18.5304C8.32901 18.3897 8.24999 18.199 8.24998 18.0001L8.24997 17.4346C8.24912 17.0917 8.17021 16.7534 8.01921 16.4455C7.86821 16.1376 7.6491 15.868 7.37847 15.6574V15.6574Z" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7617 5.32349C13.6791 5.47942 14.5254 5.91634 15.1838 6.5739C15.8421 7.23147 16.2801 8.07724 16.4372 8.9944" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Amount</div>,
      dataIndex: 'amount',
    },
    {
      title: <div className='flex'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5 2.25V5.25" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.5 2.25V5.25" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 8.25H20.25" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
       Date</div>,
      dataIndex: 'date',
    }, {
      title: <div className='flex'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 21.75H15.75" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.37847 15.6574C6.48667 14.9608 5.76435 14.0712 5.26581 13.0554C4.76727 12.0396 4.50548 10.9239 4.50011 9.79237C4.47765 5.72715 7.755 2.34783 11.8192 2.25217C13.394 2.21425 14.9409 2.67317 16.2402 3.56384C17.5396 4.45451 18.5256 5.73169 19.0582 7.21424C19.5909 8.69678 19.6432 10.3094 19.2078 11.8234C18.7724 13.3373 17.8713 14.6758 16.6324 15.6488C16.3596 15.8602 16.1383 16.1309 15.9854 16.4404C15.8326 16.7499 15.7521 17.0901 15.75 17.4353L15.75 18.0001C15.75 18.199 15.671 18.3897 15.5303 18.5304C15.3897 18.671 15.1989 18.75 15 18.75H8.99998C8.80107 18.75 8.61031 18.671 8.46966 18.5304C8.32901 18.3897 8.24999 18.199 8.24998 18.0001L8.24997 17.4346C8.24912 17.0917 8.17021 16.7534 8.01921 16.4455C7.86821 16.1376 7.6491 15.868 7.37847 15.6574V15.6574Z" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7617 5.32349C13.6791 5.47942 14.5254 5.91634 15.1838 6.5739C15.8421 7.23147 16.2801 8.07724 16.4372 8.9944" stroke="#1C3E58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>          
       Status</div>,
      dataIndex: 'status',
    },
    , {
        title:"",
        dataIndex: 'op',
      }
  ];
export const Invoices = () => {
        const apiClient=useApiClient();
        const [data, setData] = useState([])
        const [loading, setLoading] = useState(false)
        const [currentPage, setCurrentPage] = useState(1)
      const [pageOptions, setPageOptions] = useState(null)
      useEffect(()=>{
        setLoading(true)
      
        paymentApi.invoices(apiClient,currentPage)
        .then((r)=>{
            const _data=[]
            if(!r?.data) return
            r.data.map(d=>{
                _data.push({
                      key: d.id,
                      id: d.id,
                      invoice: d.title,
                      amount: "£"+d.price,
                      date: d.date,
                      status: d.status?<Alert type='success' className='w-20 text-center' message={d.status_readable} />:<Alert className='w-20 text-center'  type='error' message={d.status_readable} />,
                      op:<div><EyeOutlined /> <Link to={`/invoice/${d.id}`} > Show</Link></div>
                    });
            })
            setData(_data)
            setPageOptions({ defaultPageSize: r.per_page,total:r.total,current:r.current_page})
        })
        .finally(()=>setLoading(false))
      },[currentPage])
      const handleTableChange = (pagination) => {
       setCurrentPage(pagination.current)
      };
      return (
        <div className='mt-40 top-20 m-20'>
          <Table  
              pagination={pageOptions}
              onChange={handleTableChange}
              loading={loading}           
            columns={columns}
            dataSource={data}
          />
        </div>
      );
    
}
