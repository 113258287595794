import React, { useEffect, useState } from 'react';
import { makeChart } from '../helpers/makeData';
import { Column } from '@ant-design/plots';

const CaseChart = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const load = () => {
    makeChart().then((res) => {
      const config = {
        data: res,
        xField: 'No',
        yField: 'Weight',
        colorField: 'name',
        color: ({ no }) => 'steelblue',
        style: {
          inset: 5,
        },
        label: {

          position: 'top', // موقعیت اولیه
          style: {
            fill: '#000000',
            fontSize: 11,
          },
          offsetY:30,
          formatter: (datum) => datum, // مقادیر سفارشی
        },

        grid: {
          line: {
            style: {
              stroke: '#000000', // Customize grid line color
              lineWidth: 2, // Customize grid line width
            },
          },
        },
      };
      setData(config);
    });
  };
  useEffect(() => {
    if (window.addEventListener) {
      //constant variablels

      window.addEventListener('case_study', load);
    }
    load();
    return () => {
      window.removeEventListener('case_study', load);
    };
  }, []);

  return (
    <div className="overflow-y-hidden overflow-x-auto m-auto text-center grid place-items-center ">
      {data && <Column className="" {...data} />}
      <strong>Case No.</strong>
    </div>
  );
};

export default CaseChart;
