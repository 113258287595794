import { Table } from 'antd';
import React from 'react';
const dataSource = [
  {
    key: '1',
    Pressure: '0<P<=250',
    L_D: '1.5 - 3.0',
  },
  {
    key: '2',
    Pressure: '250<P<=500',
    L_D: '3.0 - 4.0',
  },
  {
    key: '3',
    Pressure: '500>P',
    L_D: '4.0 - 6.0',
  },
];

const columns = [
  {
    title: 'Pressure (psig)',
    dataIndex: 'Pressure',
    key: 'Pressure',
  },
  {
    title: 'L/D',
    dataIndex: 'L_D',
    key: 'L_D',
  },
];

const Table2 = () => {
  return (
    <>
      <Table size='small'
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        title={() => (
          <strong className="text-center m-auto block">
            Table 2 - L/D ratio guidelines
          </strong>
        )}
      />
    </>
  );
};

export default Table2;
