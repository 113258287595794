export const readData = (expected=[""]) => {
  const localStorageData = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (['refreshToken', 'token', 'profile', 'units',"t1","revision","approved","prepared","checked",...expected].find((e) => e === key))
      continue;
    const value = localStorage.getItem(key);
    localStorageData[key] = value;
  }
  return localStorageData;
};
