import { Alert, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import useApiClient from '../Settings/useApiClient';
import { userApi } from '../API/userApi';
import { monthToName } from '../Tools/tools';

const CurrentPlan = () => {
    const [loading, setLoading] = useState(false)
    const apiClient = useApiClient();

    const [plan, setPlan] = useState(null)
    useEffect(()=>{
        setLoading(true)
        userApi
          .plan(apiClient)
          .then((res) => {
            setPlan(res)
              console.log(res)
          }).catch(e=>console.log(e))
          .finally(()=>setLoading(false))
      },[])
      if(loading) return <Spin />
  return (
    <div className="grid gap-3">
        <h6 >■  Your current plan</h6>
        <strong className='flex gap-3 justify-between'>{plan?.plan_name??"no"} plan <Alert  className='text-xs'   message={monthToName(plan?.months)}
      type="warning"/> </strong>
        <div className='flex gap-3 text-sm'><div>Remaining:</div>
        <div className='grid text-sm'>
            <span>{plan?.plan_day_remain??0} days</span>
            <span>{plan?.plan_sizing_remain??0} / {plan?.plan_sizing??0} sizing</span>
           
            </div>
             </div>
      </div>
  )
}

export default CurrentPlan