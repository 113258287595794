import Global from "../Settings/config.json";
export const profileApi= {
    
    async profile(apiClient) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/user`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async change_pass(apiClient,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/change_password`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async save(apiClient,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/save`,data)
            .then((json) => {
                switch (json.status) {
                    case 204:
                        res = 204
                        break;
                }
            })
        return res;
    },
}