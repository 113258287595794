import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const ModalAlertSubscribe = ({open,days}) => {
    const [isModalOpen, setIsModalOpen] = useState(open);
    useEffect(()=>{
      setIsModalOpen(open);
    },[open])
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  return (
    <>
      
    <Modal title={<div className='w-full text-center'>Your subscription is ending soon</div>}   centered open={isModalOpen} onOk={handleOk} 
    footer={
      <div className='w-100 grid items-center justify-center'>
      <Link  className='submit rounded !py-2 !text-white  ' to={"/plans"}>
        See plans
      </Link>
      </div>
    }
    onCancel={handleCancel}>
        <div className='grid text-center w-full justify-center place-items-center'>
         <img src="/images/calendar.svg" />
      <div className='p-2 mb-4 items-start gap-3 text-start'>
         <InfoCircleOutlined className='me-1' style={{color:"red"}} />     
          You have<strong>  {days} days left</strong> on your subscription. Please make sure to renew it in time to continue enjoying uninterrupted access to all features.
          </div>
      </div>
    </Modal>
  </>
  )
}

export default ModalAlertSubscribe