import React, { useEffect, useState } from 'react';
import useApiClient from '../../Settings/useApiClient';
import { ticketApi } from '../../API/ticketApi';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Upload,
} from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import useNotify from '../../hooks/useNotify';
import TextArea from 'antd/es/input/TextArea';
import { ParsError } from '../../Tools/ParsError';

const Ticket = () => {
  const [file, setFile] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();
  const [text, setText] = useState('');
  const { tid } = useParams();
  const { openNotification } = useNotify();

  useEffect(() => {
    ticketApi
      .load(apiClient, tid)
      .then((r) => setTicket(r))
      .catch((e) => console.log(e));
  }, []);
  const props = {
    beforeUpload: (file) => {
      const isPic =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg';
      if (!isPic) {
        openNotification('Error', `${file.name} is not a picture file`);
        return false;
      }
      setFile(file);
      return false;
    },
    
  };
  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    if (file) formData.append('file', file);
    formData.append('description', text);
    ticketApi
      .reply(apiClient, tid, formData)
      .then((r) => {
        openNotification('Success', `Your ticket sent successfully!`,"Success");
        setTimeout(()=>{
          document.location.reload();

        },1000)
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response.data));
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="ticket mt-36 grid  grid-cols-12 gap-5 p-3 items-start justify-start">
      <aside className="grid col-span-2 bg-white p-5 ">
        <Flex className="font-bold">Ticket {ticket?.id} </Flex>
        <br />
        <Flex className="text-sm text-[#8C8C8C ] items-center gap-2">
          <img src={'/images/title2.svg'} /> Title
        </Flex>
        <label className="text-sm ms-1 font-bold ">{ticket?.title}</label>
        <br />
        <Flex className="text-sm text-[#8C8C8C] items-center gap-2">
          <img src={'/images/status.svg'} /> Status
        </Flex>
        <Alert
          type="success"
          message={ticket?.status}
          className="mr-auto p-1 mt-1 px-4"
        />
        <br />
        <Flex className="text-sm text-[#8C8C8C] items-center gap-2">
          <img src={'/images/department2.svg'} /> Department
        </Flex>
        <label className="text-sm ms-1 font-bold"> {ticket?.dep}</label>
        <br />
        <Flex className="text-sm text-[#8C8C8C] items-center gap-2">
          <img src={'/images/date2.svg'} /> Creation date
        </Flex>
        <label className="text-sm ms-1 font-bold"> {ticket?.date_create}</label>
        <br />
        {ticket?.important ? (
          <Flex className="text-red-600 gap-2">
            <img src={'/images/urgent.svg'} /> Urgent
          </Flex>
        ) : null}
      </aside>
      <div className="grid col-span-8 gap-3">
        <Space direction="vertical" size={16}>
          <Card
            title={
              <div>
                {' '}
                <Avatar icon={<UserOutlined />} /> {ticket?.user?.name}
              </div>
            }
            extra={
              <Flex className="gap-3 items-baseline ">
                <Flex className="items-center gap-2">
                  <img src={'/images/date2.svg'} /> {ticket?.date}
                </Flex>
                <Flex className="items-center gap-2">
                  <img src={'/images/time.svg'} /> {ticket?.time}
                </Flex>
              </Flex>
            }
            bordered={false}
            className="bg-white "
            style={{ background: 'white' }}
          >
            <div className="p-4">{ticket?.description}</div>
          </Card>
          {ticket?.replies.map((reply) => (
            <Card
              title={
                <div>
                  {' '}
                  <Avatar icon={<UserOutlined />} /> {reply?.user?.name}
                </div>
              }
              extra={
                <Flex className="gap-3 items-baseline">
                  <Flex className='items-center gap-2'>
                    <img src={'/images/date2.svg'} /> {reply?.date}
                  </Flex>
                  <Flex className='items-center gap-2'>
                    <img src={'/images/time.svg'} /> {reply?.hour}
                  </Flex>
                </Flex>
              }
              bordered={false}
              className="bg-white "
              style={{ background: 'white' }}
            >
              <div className='p-4'>{reply?.message}</div>
            </Card>
          ))}
          <Form
            className="w-100"
            onFinish={handleSubmit}
            name="basic"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Card
              title={
                <div>
                  {' '}
                  <Avatar icon={<UserOutlined />} /> You{' '}
                </div>
              }
              bordered={false}
              className="bg-white "
              style={{ background: 'white' }}
            >
              <TextArea
                value={text}
                count={{
                  show: true,
                  max: 1500,
                }}
                style={{ padding: '5px' }}
                onChange={(e) => setText(e.target.value)}
                placeholder="Please write your question text here..."
                autoSize={{
                  minRows: 10,
                  maxRows: 20,
                }}
              />
              <Flex className="mt-7 justify-between">
                <Upload {...props} accept=".png,.jpg,.jpeg" maxCount={1}>
                  <Button className="back" icon={<UploadOutlined />}>
                    Attachments
                  </Button>
                </Upload>
                <Flex className="gap-2">
                  <Button className="back">Back</Button>
                  <Button
                    htmlType="submit"
                    className="submit2"
                    icon={<img src="/images/send.svg" />}
                  >
                    Send
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </Form>
        </Space>
      </div>
    </div>
  );
};
export default Ticket;
