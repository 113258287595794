import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { Link } from 'react-router-dom';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { AuthApi } from '../API/AuthApi';
import { ParsError } from '../Tools/ParsError';
const { Title } = Typography;
const ModalVerify = ({ email }) => {
    const apiClient = useApiClient();
    const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { openNotification } = useNotify();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const resend=()=>{
    setLoading(true);
    AuthApi.resend(apiClient, email)
      .then((res) => {
        openNotification('Success', "Verification link sent!");
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response.data));
      })
      .finally(() => setLoading(false));
  }
  return (
    <Modal
      title=""
      open={isModalOpen}
      onOk={handleOk}
        footer={false}
       width={"40%"}
      onCancel={handleCancel}
    >
      <div   className="grid gap-3 p-8 justify-center items-center">
        <Title  level={4} className="text-center">
          Verification link sent! <br />
          Please check your email to verify your account.
        </Title>
        <img src="/images/letter.svg" className=" block m-auto" />
        <Link to="/login" ><Button className="submit rounded block m-auto w-80 h-10"> Back to login</Button></Link>
        <Button loading={loading} onClick={resend} className="submit !bg-white rounded block m-auto border-[#C89843] !text-[#C89843] w-80 h-10">
          Resend verification email
        </Button>
        <div className='text-[#416A8A] m-auto ' role='button' onClick={handleCancel}>Change email address</div>
      </div>
    </Modal>
  );
};
export default ModalVerify;
