import Global from "../Settings/config.json";
export const processConditionApi= {
    
    async save(apiClient,sizing_id,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/process/save/${sizing_id}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async load(apiClient,sizing_id) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/process/${sizing_id}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
}