import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import useApiClient from '../../Settings/useApiClient';
import { ProjectContext } from '../../Context/ProjectContext';
import { processConditionApi } from '../../API/processConditionApi';
function Assist({I40,c}) {
  const [loading, setLoading] = useState(true);
  const { sizing } = useContext(ProjectContext);
  const apiClient = useApiClient();
const [d_f, setD_f] = useState(0)
  const qh = (I40,hliq,R32,R34) => {
    const conversionFactor = 0.028316846592;
    if (I40 === 7 || I40 === 8) {
      return 0;
    } else {
      const multiplier = hliq ? hliq : 1;
      return (R34 / R32) * conversionFactor * multiplier;
    }
  };
  const ql = (R30,R28,lLiq) => {
    const conversionFactor = 0.028316846592;

    // Determine the multiplier based on the condition of R35
    const multiplier = lLiq ? lLiq : 1;

    // Perform the calculation
    return (R30 / R28) * conversionFactor * multiplier;
  };
  const qg = (R26,R24,gas,Over_Design_Factor) => {
    const conversionFactor = 0.028316846592;

    // Determine the multiplier based on the condition of O35
    const multiplier = gas ? gas : 1;

    // Perform the calculation
    return (R26 / R24) * conversionFactor * multiplier;
  };
  const df=(S66, D62, H62, L62, K26, K30, K34)=>{
    const totalWeight = D62 + H62 + L62;
    const weightedAverage = (K26 + K30 + K34) / totalWeight;
    const sqrtWeightedAverage = Math.sqrt(totalWeight * Math.sqrt(weightedAverage));

    // Round the value before comparing
    const roundedValue = Math.round(S66 * sqrtWeightedAverage);

    // Determine the result based on the conditions provided
    let result;
    if (roundedValue === 3) {
        result = S66 * sqrtWeightedAverage;
    } else {
        result = Math.ceil(S66 * sqrtWeightedAverage / 2) * 2; // CEILING function behavior
    }

    // Return the maximum between the computed value and 2
    return Math.max(2, result);
  }
  useEffect(() => {
    if (sizing) {
      setLoading(true);
      console.log(I40,c,sizing.id)
      processConditionApi
        .load(apiClient, sizing.id)
        .then((res) => {
          if (!res) return;
          // setResult(res);
            const _qh=qh(I40,res.h_liq,res.K32[1],res.K34[1])
            const _qg=qg(res.K26[1],res.K24[1],res.gas,res.Over_Design_Factor)
            const _ql=ql(res.K30[1],res.K28[1],res.l_liq)
            const _df=df(c,_qh,_ql,_qg,res.K26[0],res.K30[0],res.K34[0])
            setD_f(_df)
        })
        .finally(() => setLoading(false));
    }
  }, [c,I40,  sizing.id]);
  if (loading) return <Spin  size='small' />;
  return <strong> {d_f}</strong>;
}

export default Assist;
