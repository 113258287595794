import React, { useEffect, useState } from 'react';
import {  makeTable, makeTable2 } from '../helpers/makeData';
import { Table } from 'antd';

const CaseTable = () => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const load=()=>{
    makeTable2().then(res=>{
      setColumns(res.columns);
    setDataSource(res.dataSource);
  });

  }
  useEffect(() => {
    if (window.addEventListener) {
      //constant variablels

      window.addEventListener('case_study',load)
      
    }
    load();
    return ()=>{window.removeEventListener('case_study',load)}
  }, []);
  
  return (
    <Table
      size={'small'}
      pagination={false} 
      className='mb-12'
      // scroll={{
      //   y: 55 * 5,
      //   // x:210*10
      // }}
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export default CaseTable;
