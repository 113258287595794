import { event_change_data } from '../services/events';
import {
  atot,
  H_DtoA_AT,
  vol_hor_L,
  vol_hor,
  vol_hor_dish,
  A_ATtoh_D,
  ceilToTen,
  max,
  ceilToFifty,
  ceiling,
} from './Helper';

export const H_bn = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const W66 = parseFloat(localStorage.getItem('W66'));
  const AC40 = parseFloat(localStorage.getItem('AC40'));
  const AG40 = parseFloat(localStorage.getItem('AG40'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const BH50 = parseFloat(localStorage.getItem('BH50'));
  let result;

  if (I40 === 1 || I40 === 2 || I40===7) {
    const maxPart = Math.max(24, (6 + ((AC40 + AG40) / 60 * (H62 + D62) / atot(BH50)) / 0.0254));
    result = Math.ceil((0.5 * W66 + maxPart) * 25.4 / 10) * 10; // CEILING معادل در JavaScript
  
  } 
 
  else {
    result='-';
  }
  const J66 = localStorage.getItem('J66');
  if (J66 == Math.round(result)) return;
  localStorage.setItem('J66', Math.round(result));
  event_change_data('H_bn_orange');
}
  export const LLIA_LLIS = () => {
  let result;
  const I40 = parseFloat(localStorage.getItem('I40'));
  const D45 = parseFloat(localStorage.getItem('D45'));
  const AW30 = parseFloat(localStorage.getItem('AW30'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const AZ30 = parseFloat(localStorage.getItem('AZ30'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BC30 = parseFloat(localStorage.getItem('BC30'));
  const BI30 = parseFloat(localStorage.getItem('BI30'));

  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else {
    if (D45 === 1) {
      if (I40 === 1 || I40 === 2) {
        result = Math.max(
          AW30,
          ((AZ30 / 60) * D62) / (0.25 * Math.PI * BF45 ** 2)
        );
      } else if (I40 === 4) {
        result = AW30;
      } else {
        result = ceilToTen(Math.max(BC30, BI30));
      }
    } else if (D45 === 0) {
      result = 0;
    }
  }
  result = result || 0;
  const D50 = localStorage.getItem('D50');
  if (D50 == Math.round(result)) return;
  localStorage.setItem('D50', Math.round(result));
  event_change_data('LLIA_LLIS_orange');
};

export const LIA = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const H45 = parseFloat(localStorage.getItem('H45'));
  const AW29 = parseFloat(localStorage.getItem('AW29'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const AZ29 = parseFloat(localStorage.getItem('AZ29'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BC29 = parseFloat(localStorage.getItem('BC29'));
  const BI29 = parseFloat(localStorage.getItem('BI29'));
  let result;
  // 
  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else {
    if (H45 === 1) {
      if (I40 === 1 || I40 === 2) {
        result = ceilToFifty(
          Math.max(AW29, ((AZ29 / 60) * D62) / (0.25 * Math.PI * BF45 ** 2))
        );
      } else if (I40 === 4) {
        result = AW29;
      } else {
        result = ceilToFifty(Math.max(BC29, BI29));
      }
    } else if (H45 === 0) {
      result = 0;
    }
  }
  // 
  result = result || 0;
  const G50 = localStorage.getItem('G50');
  if (G50 == Math.round(result)) return;
  localStorage.setItem('G50', Math.round(result));
  event_change_data('LIA_orange');
};
export const Working_Vol_Heavy = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const Y40 = parseFloat(localStorage.getItem('Y40'));
  const AG40 = parseFloat(localStorage.getItem('AG40'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const AZ28 = parseFloat(localStorage.getItem('AZ28'));
  const G66 = parseFloat(localStorage.getItem('G66'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AW28 = parseFloat(localStorage.getItem('AW28'));
  const I54 = parseFloat(localStorage.getItem('I54'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BF28 = parseFloat(localStorage.getItem('BF28'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const BC28 = parseFloat(localStorage.getItem('BC28'));
  let result;
  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else if (I40 === 1) {
    result = Math.max(
      (D62 * (Y40 + AG40)) / 60,
      (D62 * AZ28) / 60,
      (atot(BF45) * AW28) / 1000
    );
  } else if (I40 === 2) {
    result = Math.max(
      (D62 * (Y40 + AG40)) / 60,
      (D62 * AZ28) / 60,
      (atot(BF45) * H_DtoA_AT(BF45 - G66 * 0.0254, BF45) * AW28) / 1000
    );
  } else if (I40 === 4) {
    result = ((Y40 + AG40) / 60) * D62;
  } else if (I40 === 6) {
    result = Math.max(
      (D62 * (Y40 + AG40)) / 60,
      (D62 * BF28) / 60,
      vol_hor_L(BC28 + AH50, BF45, BH62) - I54
    );
  } else {
    result = Math.max(
      (D62 * (Y40 + AG40)) / 60,
      (D62 * BF28) / 60,
      vol_hor(BC28 + AH50, BF45, Q40) - I54
    );
  }

  result = result || 0;
  const J50 = localStorage.getItem('J50');
  if (J50 == result) return;
  localStorage.setItem('J50', result);
  event_change_data('Working_Vol_Heavy_orange');
};

export const HHIA_HHIS = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const L45 = parseFloat(localStorage.getItem('L45'));
  const AW27 = parseFloat(localStorage.getItem('AW27'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const AZ27 = parseFloat(localStorage.getItem('AZ27'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BC27 = parseFloat(localStorage.getItem('BC27'));
  const BI27 = parseFloat(localStorage.getItem('BI27'));
  let result;

  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else {
    if (L45 === 1) {
      if (I40 === 1 || I40 === 2) {
        result = Math.max(
          AW27,
          ((AZ27 / 60) * D62) / (0.25 * Math.PI * BF45 ** 2)
        );
      } else if (I40 === 4) {
        result = AW27;
      } else {
        result = ceilToTen(Math.max(BC27, BI27));
      }
    } else if (L45 === 0) {
      result = 0;
    }
  }

  result = result ;
  
  const N50 = localStorage.getItem('N50');
  if (N50 == Math.round(result)) return;
  localStorage.setItem('N50', Math.round(result));
  event_change_data('HHIA_HHIS_orange');
};
export const LLLA = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const P45 = parseFloat(localStorage.getItem('P45'));
  const AW26 = parseFloat(localStorage.getItem('AW26'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const AZ26 = parseFloat(localStorage.getItem('AZ26'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BC26 = parseFloat(localStorage.getItem('BC26'));
  const BI26 = parseFloat(localStorage.getItem('BI26'));
  let result;

  if (P45 === 1) {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      result = Math.max(
        AW26,
        ((AZ26 / 60) * D62) / (0.25 * Math.PI * BF45 ** 2)
      );
    } else {
      result = ceilToFifty(Math.max(BC26, BI26));
    }
  } else if (P45 === 0) {
    result = 0;
  }

  result = result || 0;
  const Q50 = localStorage.getItem('Q50');
  if (Q50 == Math.round(result)) return;
  localStorage.setItem('Q50', Math.round(result));
  event_change_data('LLLA_orange');
};
export const LLA = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const T45 = parseFloat(localStorage.getItem('T45'));
  const AW25 = parseFloat(localStorage.getItem('AW25'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const AZ25 = parseFloat(localStorage.getItem('AZ25'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BC25 = parseFloat(localStorage.getItem('BC25'));
  const BI25 = parseFloat(localStorage.getItem('BI25'));
  let result;

  if (T45 === 1) {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      const maxValue = Math.max(AW25, ((AZ25 / 60) * D62) / atot(BF45));
      result = Math.ceil(maxValue / 50) * 50;
    } else {
      result = Math.ceil(Math.max(BC25, BI25) / 50) * 50;
    }
  } else {
    result = 0;
  }

  result = result || 0;
  
  const T50 = localStorage.getItem('T50');
  if (T50 == Math.round(result)) return;
  localStorage.setItem('T50', Math.round(result));
  event_change_data('LLA_orange');
};
export const Working_Vol_Light = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const U40 = parseFloat(localStorage.getItem('U40'));
  const AC40 = parseFloat(localStorage.getItem('AC40'));
  const AZ24 = parseFloat(localStorage.getItem('AZ24'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AW24 = parseFloat(localStorage.getItem('AW24'));
  const BC24 = parseFloat(localStorage.getItem('BC24'));
  const AT50 = parseFloat(localStorage.getItem('AT50'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const I58 = parseFloat(localStorage.getItem('I58'));
  const BF24 = parseFloat(localStorage.getItem('BF24'));
  let result;

  if (I40 === 1 || I40 === 2 || I40 === 7) {
    result = Math.max(
      (H62 * (U40 + AC40)) / 60,
      (H62 * AZ24) / 60,
      (0.25 * Math.PI * Math.pow(BF45, 2) * AW24) / 1000
    );
  } else if (I40 === 5) {
    result = Math.max(
      (H62 * (U40 + AC40)) / 60,
      (H62 * BF24) / 60,
      vol_hor_L(BC24 + AT50, BF45, BH58) - I58
    );
  } else if (I40 === 6) {
    result = Math.max(
      (H62 * (U40 + AC40)) / 60,
      (H62 * BF24) / 60,
      vol_hor_L(BC24 + AT50 + 0.125 * BF45 * 1000, BF45, BH58) -
        (I58 + vol_hor_L(0.125 * BF45 * 1000, BF45, BH58))
    );
  } else {
    
    result = Math.max(
      (H62 * (U40 + AC40)) / 60,
      (H62 * BF24) / 60,
      vol_hor(BC24 + AT50, BF45, Q40) - I58
    );
  }
  result = result || 0;
  const W50 = localStorage.getItem('W50');
  if (W50 == result) return;
  localStorage.setItem('W50', result);
  event_change_data('Working_Vol_Light_orange');
};
export const HHLA_HHLS = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const X45 = parseFloat(localStorage.getItem('X45'));
  const AW23 = parseFloat(localStorage.getItem('AW23'));
  const AZ23 = parseFloat(localStorage.getItem('AZ23'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BC23 = parseFloat(localStorage.getItem('BC23'));
  const BI23 = parseFloat(localStorage.getItem('BI23'));
  let result;
  
  if (X45 === 1) {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      const maxValue = Math.max(
        AW23,
        ((AZ23 / 60) * D62) / (0.25 * Math.PI * Math.pow(BF45, 2))
      );
      result = Math.ceil(maxValue / 50) * 50;
    } else {
      result = Math.ceil(Math.max(BC23, BI23) / 50) * 50;
    }
  } else {
    result = 0;
  }

  // result = result ;
  const AA50 = localStorage.getItem('AA50');
  if (AA50 == Math.round(result)) return;
  localStorage.setItem('AA50', Math.round(result));
  event_change_data('HHLA_HHLS_orange');
};
export const LLLIL = () => {
  const D50 = parseFloat(localStorage.getItem('D50'));
  const AE50 = localStorage.getItem('AE50');
  if (AE50 == Math.round(D50)) return;
  localStorage.setItem('AE50', Math.round(D50 || 0));
  event_change_data('LLLIL_orange');
};
export const LLIL = () => {
  const AE50 = parseFloat(localStorage.getItem('AE50'));
  const G50 = parseFloat(localStorage.getItem('G50'));
  const result = AE50 + G50;
  const AH50 = localStorage.getItem('AH50');
  if (AH50 == Math.round(result)) return;
  localStorage.setItem('AH50', Math.round(result || 0));
  event_change_data('LLIL_orange');
};

export const HLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const J50 = parseFloat(localStorage.getItem('J50'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const AP66 = parseFloat(localStorage.getItem('AP66'));
  const AY54 = parseFloat(localStorage.getItem('AY54'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const I54 = parseFloat(localStorage.getItem('I54'));
  let result;

  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else if (I40 === 1 || I40 === 2) {
    result = Math.ceil(((J50 / atot(BF45)) * 1000) / 50) * 50 + AH50;
  } else if (I40 === 4) {
    result = Math.ceil((AP66 * 304.8) / 50) * 50 + AH50;
  } else if (I40 === 6) {
    result =
      Math.ceil((A_ATtoh_D(I54 + J50, atot(BF45) * BH62) * BF45 * 1000) / 50) *
      50;
  } else {
    result = Math.ceil((A_ATtoh_D(J50 + I54, AY54) * BF45 * 1000) / 50) * 50;
  }

  result = result || 0;
  
  const AK50 = localStorage.getItem('AK50');
  if (AK50 == Math.round(result)) return;
  localStorage.setItem('AK50', Math.round(result));
  event_change_data('HLIL_orange');
};
export const HHIL = () => {
  const N50 = parseFloat(localStorage.getItem('N50'));
  const AK50 = parseFloat(localStorage.getItem('AK50'));
  let result = AK50 + N50;
  
  result = result || 0;
  const AN50 = localStorage.getItem('AN50');
  if (AN50 == result) return;
  localStorage.setItem('AN50', result);
  event_change_data('HHIL_orange');
};
export const LLLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const Q50 = parseFloat(localStorage.getItem('Q50'));
  let result;
  if (I40 === 1 || I40 === 2 || I40 === 3) {
    result = AN50 + Q50;
  } else {
    result = Q50;
  }
  result = result || 0;
  const AQ50 = localStorage.getItem('AQ50');
  if (AQ50 == Math.round(result)) return;
  localStorage.setItem('AQ50', Math.round(result));
  event_change_data('LLLL_orange');
};
export const LLL = () => {
  const AQ50 = parseFloat(localStorage.getItem('AQ50'));
  const T50 = parseFloat(localStorage.getItem('T50'));

  let result;
  result = AQ50 + T50;
  
  result = result || 0;
  const AT50 = localStorage.getItem('AT50');
  if (AT50 == Math.round(result)) return;
  localStorage.setItem('AT50', Math.round(result));
  event_change_data('LLL_orange');
};

export const HLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AT50 = parseFloat(localStorage.getItem('AT50'));
  const W50 = parseFloat(localStorage.getItem('W50'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const G66 = parseFloat(localStorage.getItem('G66'));
  const I54 = parseFloat(localStorage.getItem('I54'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const I58 = parseFloat(localStorage.getItem('I58'));
  const AY54 = parseFloat(localStorage.getItem('AY54'));

  function calculate() {
    const PI = Math.PI;

    if (I40 === 1 || I40 === 7) {
      return ceilToFifty((W50 / (0.25 * PI * Math.pow(BF45, 2))) * 1000) + AT50;
    } else if (I40 === 2) {
      return (
        ceilToFifty(
          (W50 / (atot(BF45) * H_DtoA_AT(BF45 - G66 * 0.0254, BF45))) * 1000
        ) + AT50
      );
    } else if (I40 === 5) {
      return ceilToFifty(
        Math.round(
          (A_ATtoh_D(W50 + I58, atot(BF45) * BH58) * BF45 * 1000) / 10
        ) * 10
      );
    } else if (I40 === 6) {
      return ceilToFifty(
        Math.round(
          (A_ATtoh_D(
            vol_hor_L(0.125 * BF45 * 1000, BF45, BH58) + W50 + I58,
            atot(BF45) * BH58
          ) *
            BF45 *
            1000 -
            0.125 * BF45 * 1000) /
            10
        ) * 10
      );
    } else {
      return ceilToFifty(
        Math.round((A_ATtoh_D(W50 + I58, AY54) * BF45 * 1000) / 10) * 10
      );
    }
  }

  let result = calculate() || 0;
  
  

  const AW50 = localStorage.getItem('AW50');
  if (AW50 == Math.round(result)) return;
  localStorage.setItem('AW50', Math.round(result));
  event_change_data('HLL_orange');
};
export const HHLL = () => {
  const AW50 = parseFloat(localStorage.getItem('AW50'));
  const AA50 = parseFloat(localStorage.getItem('AA50'));
  let result;
  result = AW50 + AA50;
  result = result ;
  const AZ50 = localStorage.getItem('AZ50');
  if (AZ50 == Math.round(result)) return;
  localStorage.setItem('AZ50', Math.round(result));
  event_change_data('HHLL_orange');
};
export const Shell_Vol_to_LLLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const AE50 = parseFloat(localStorage.getItem('AE50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  let result =
    I40 === 1 || I40 === 2
      ? (atot(BF45) * AE50) / 1000
      : I40 === 4
      ? (atot(AS66) * AE50) / 1000
      : I40 === 6
      ? vol_hor_L(AE50, BF45, BH62)
      : vol_hor(AE50, BF45, Q40);
  // result = result || 0;
  const D54 = localStorage.getItem('D54');
  if (D54 == result) return;
  localStorage.setItem('D54', result);
  event_change_data('Shell_Vol_to_LLLIL_orange');
};
export const Shell_Vol_LLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));

  let result =
    I40 === 1 || I40 === 2
      ? (atot(BF45) * AH50) / 1000
      : I40 === 4
      ? (atot(AS66) * AH50) / 1000
      : I40 === 6
      ? vol_hor_L(AH50, BF45, BH62)
      : vol_hor(AH50, BF45, Q40);
  // result = result || 0;
  
  const I54 = localStorage.getItem('I54');
  if (I54 == result) return;
  localStorage.setItem('I54', result);
  event_change_data('Shell_Vol_to_LLIL_orange');
};
export const Shell_Vol_to_HLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const AK50 = parseFloat(localStorage.getItem('AK50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));

  let result =
    I40 === 1 || I40 === 2
      ? (atot(BF45) * AK50) / 1000
      : I40 === 4
      ? (atot(AS66) * AK50) / 1000
      : I40 === 6
      ? vol_hor_L(AK50, BF45, BH62)
      : vol_hor(AK50, BF45, Q40);
  // result = result || 0;
  // 
  const N54 = localStorage.getItem('N54');
  if (N54 == result) return;
  localStorage.setItem('N54', result);
  event_change_data('Shell_Vol_to_HLIL_orange');
};
export const Shell_Vol_to_HHLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));

  let result =
    I40 === 1 || I40 === 2
      ? (atot(BF45) * AN50) / 1000
      : I40 === 4
      ? (atot(AS66) * AN50) / 1000
      : I40 === 6
      ? vol_hor_L(AN50, BF45, BH62)
      : vol_hor(AN50, BF45, Q40);
  // result = result || 0;
  const S54 = localStorage.getItem('S54');
  if (S54 == result) return;
  localStorage.setItem('S54', result);
  event_change_data('Shell_Vol_to_HHLIL_orange');
};

//end shak
export const Vol_to_LLLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const D54 = parseFloat(localStorage.getItem('D54'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const AE50 = parseFloat(localStorage.getItem('AE50'));
  var result = 0;

  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else if (I40 === 1 || I40 === 2) {
    result = D54 + vol_hor_dish(BF45, BF45, I40, AR83);
  } else if (I40 === 4) {
    result = D54 + (Math.PI / 24) * Math.pow(AS66, 3);
  } else if (I40 === 5 || I40 === 6) {
    result = D54 + (I40 === 5 ? 1 : 2) * vol_hor_dish(AE50, BF45, I40, AR83);
  }

  // let result = result || 0;
  const AE54 = localStorage.getItem('AE54');
  if (AE54 == result) return;
  localStorage.setItem('AE54', result);
  event_change_data('Vol_to_LLLIL_orange');
};
export const Vol_to_LLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const I54 = parseFloat(localStorage.getItem('I54'));
  var result = 0;

  if (I40 === 7 || I40 === 8) {
    result = 0;
  } else if (I40 === 1 || I40 === 2) {
    result = I54 + vol_hor_dish(BF45, BF45, I40, AR83);
  } else if (I40 === 4) {
    result = I54 + (Math.PI / 24) * Math.pow(AS66, 3);
  } else if (I40 === 5 || I40 === 6) {
    result = I54 + (I40 === 5 ? 1 : 2) * vol_hor_dish(AH50, BF45, I40, AR83);
  }

  // let result = result || 0;
  const AI54 = localStorage.getItem('AI54');
  if (AI54 == result) return;
  localStorage.setItem('AI54', result);
  event_change_data('Vol_to_LLIL_orange');
};
export const Vol_to_HLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const AK50 = parseFloat(localStorage.getItem('AK50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const N54 = parseFloat(localStorage.getItem('N54'));
  var volume = 0;

  if (I40 === 7 || I40 === 8) {
    volume = 0;
  } else if (I40 === 1 || I40 === 2) {
    volume = N54 + vol_hor_dish(BF45, BF45, I40, AR83);
  } else if (I40 === 4) {
    volume = N54 + (Math.PI / 24) * Math.pow(AS66, 3);
  } else if (I40 === 5 || I40 === 6) {
    volume = N54 + (I40 === 5 ? 1 : 2) * vol_hor_dish(AK50, BF45, I40, AR83);
  }

  let result = volume || 0;
  const AM54 = localStorage.getItem('AM54');
  if (AM54 == result) return;
  localStorage.setItem('AM54', result);
  event_change_data('Vol_to_HLIL_orange');
};

export const Vol_to_HHLIL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const AS66 = parseFloat(localStorage.getItem('AS66'));
  const S54 = parseFloat(localStorage.getItem('S54'));
  var volume = 0;

  if (I40 === 7 || I40 === 8) {
    volume = 0;
  } else if (I40 === 1 || I40 === 2) {
    volume = S54 + vol_hor_dish(BF45, BF45, I40, AR83);
  } else if (I40 === 4) {
    volume = S54 + (Math.PI / 24) * Math.pow(AS66, 3);
  } else if (I40 === 5 || I40 === 6) {
    volume = S54 + (I40 === 5 ? 1 : 2) * vol_hor_dish(AN50, BF45, I40, AR83);
  }

  let result = volume || 0;
  const AQ54 = localStorage.getItem('AQ54');
  if (AQ54 == result) return;
  localStorage.setItem('AQ54', result);
  event_change_data('Vol_to_HHLIL_orange');
};
export const Total_Shell_Vol = () => {
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BH54 = parseFloat(localStorage.getItem('BH54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  var volume = 0;

  if (I40 === 1 || I40 === 2) {
    volume = 0.25 * Math.PI * Math.pow(BF45, 2) * BH54;
  } else {
    volume = 0.25 * Math.PI * Math.pow(BF45, 3) * Q40;
  }

  let result = volume || 0;
  const AY54 = localStorage.getItem('AY54');
  if (AY54 == result) return;
  localStorage.setItem('AY54', result);
  event_change_data('Total_Shell_Vol_orange');
};

export const Calculated_D_ft = () => {
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BD54 = parseFloat(localStorage.getItem('BD54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const L62 = parseFloat(localStorage.getItem('L62'));
  const T62 = parseFloat(localStorage.getItem('T62'));
  const AB45 = parseFloat(localStorage.getItem('AB45'));
  let result;
  // console.(.*)
  if (I40 === 1 || I40 === 2) {
    result = BF45 / 0.3048;
  } else if (I40 === 3 || I40 === 4 || I40 === 5 || I40 === 6 || I40 === 8) {
    result = BD54 / Q40;
  } else {
   
    const intermediate = Math.pow((4 * L62) / (Math.PI * (T62 * 3600)), 0.5) / 0.3048;
    result = intermediate + (AB45 ? 3 / 12 : 0);
  }

  result = result ;
  const BD50 = localStorage.getItem('BD50');
  if (BD50 == result) return;
  localStorage.setItem('BD50', result);
  event_change_data('Calculated_D_ft_orange');
};
export const Calculated_D_m = (props) => {
  const BD50 = parseFloat(localStorage.getItem('BD50'));
  let result = BD50 * 0.3048;
  result = result ;
  const BH50 = localStorage.getItem('BH50');
  if (BH50 == result) return;
  localStorage.setItem('BH50', result);
  event_change_data('Calculated_D_m_orange');
};
export const Calculated_L_ft = () => {
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AZ50 = parseFloat(localStorage.getItem('AZ50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const M66 = parseFloat(localStorage.getItem('M66'));
  const J66 = parseFloat(localStorage.getItem('J66'));
  const W66 = parseFloat(localStorage.getItem('W66'));
  const D66 = parseFloat(localStorage.getItem('D66'));
  const W50 = parseFloat(localStorage.getItem('W50'));
  const P66 = parseFloat(localStorage.getItem('P66'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const AT50 = parseFloat(localStorage.getItem('AT50'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const BD58 = parseFloat(localStorage.getItem('BD58'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const BD62 = parseFloat(localStorage.getItem('BD62'));
  const J50 = parseFloat(localStorage.getItem('J50'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const calculate = () => {
    if (I40 === 7) {
      return (AZ50 + M66 + J66 + W66 * 25.4) / 304.8;
    } else if (I40 === 1 || I40 === 2) {
      return AZ50 / 304.8 + (J66 + M66) / 304.8 + (I40 === 2 ? D66 : 0);
    } else if (I40 === 3 || I40 === 8) {
      return Math.abs(
        (W50 * 35.3147 + J50 * 35.3147) /
          (atot(BF45) * 10.7639104167097 -
            H_DtoA_AT(P66 / 1000, BF45) * atot(BF45) * 10.7639104167097 -
            atot(BF45) *
              H_DtoA_AT((I40 === 3 ? AH50 : AT50) / 1000, BF45) *
              10.7639104167097)
      );
    } else if (I40 === 4) {
      return (
        (W50 * 35.3147) /
        (atot(BF45) * 10.7639104167097 -
          H_DtoA_AT(P66 / 1000, BF45) * atot(BF45) * 10.7639104167097 -
          atot(BF45) * H_DtoA_AT(AT50 / 1000, BF45) * 10.7639104167097)
      );
    } else if (I40 === 5) {
      return (
        (vol_hor(AN50, BF45, Q40) + (J50 / D62) * H62) /
        (atot(BF45) * (1 - H_DtoA_AT(P66, BF45))) /
        0.3048
      );
    } else if (I40 === 6) {
      return (BD58 + BH58 + BD62 + BH62) / 0.3048;
    } else {
      // Return a default value or handle the case when I40 is none of the specified values
      return 0;
    }
  };
  let result = calculate() ;
  const BD54 = localStorage.getItem('BD54');
  if (BD54 == result) return;
  localStorage.setItem('BD54', result);
  event_change_data('Calculated_L_ft_orange');
};
export const Calculated_L_m = (props) => {
  const BD54 = parseFloat(localStorage.getItem('BD54'));
  let result = BD54 * 0.3048;
  result = result || 0;
  const BH54 = localStorage.getItem('BH54');
  if (BH54 == result) return;
  localStorage.setItem('BH54', result);
  event_change_data('Calculated_L_m_orange');
};
export const Shell_Vol_to_LLLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AQ50 = parseFloat(localStorage.getItem('AQ50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  // console.(.*)
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return (atot(BF45) * AQ50) / 1000;
    } else if (I40 === 3 || I40 === 4 || I40 === 8) {

      return vol_hor(AQ50, BF45, Q40);
    } else if (I40 === 5) {
      return vol_hor_L(AQ50, BF45, BH58);
    } else if (I40 === 6) {
      return (
        vol_hor_L(AQ50 + 0.125 * BF45 * 1000, BF45, BH58) -
        vol_hor_L(0.125 * BF45 * 1000, BF45, BH58)
      );
    }
  };
  let result = calculate();
  result = result || 0;
  const D58 = localStorage.getItem('D58');
  if (D58 == result) return;
  localStorage.setItem('D58', result);
  event_change_data('Shell_Vol_to_LLLL_orange');
};
export const Shell_Vol_LLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AT50 = parseFloat(localStorage.getItem('AT50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return (atot(BF45) * AT50) / 1000;
    } else if (I40 === 3 || I40 === 4 || I40 === 8) {
      return vol_hor(AT50, BF45, Q40);
    } else if (I40 === 5) {
      return vol_hor_L(AT50, BF45, BH58);
    } else if (I40 === 6) {
      return (
        vol_hor_L(AT50 + 0.125 * BF45 * 1000, BF45, BH58) -
        vol_hor_L(0.125 * BF45 * 1000, BF45, BH58)
      );
    } else {
      return null;
    }
  };
  let result = calculate();

  result = result || 0;
  const I58 = localStorage.getItem('I58');
  if (I58 == result) return;
  
  localStorage.setItem('I58', result);
  event_change_data('Shell_Vol_to_LLL_orange');
};
export const Shell_Vol_to_HLL = async() => {
  const I40 = await parseFloat(localStorage.getItem('I40'));
  const BF45 =await parseFloat(localStorage.getItem('BF45'));
  const AW50 =await parseFloat(localStorage.getItem('AW50'));
  const Q40 = await parseFloat(localStorage.getItem('Q40'));
  const BH58 =await  parseFloat(localStorage.getItem('BH58'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return (atot(BF45) * AW50) / 1000;
    } else if (I40 === 3 || I40 === 4 || I40 === 8) {
      return vol_hor(AW50, BF45, Q40);
    } else if (I40 === 5) {
      return vol_hor_L(AW50, BF45, BH58);
    } else if (I40 === 6) {
      return (
        vol_hor_L(AW50 + ((0.125 * BF45) * 1000), BF45, BH58) -
        vol_hor_L(0.125 * BF45 * 1000, BF45, BH58)
      );
    }
  };
  // console.(.*)

  let result = calculate();
  // result = result || 0;
  // console.(.*)
  const N58 =await localStorage.getItem('N58');
  if (N58 == result) return;
  await localStorage.setItem('N58', result);
  event_change_data('Shell_Vol_to_HLL_orange');
};
export const Shell_Vol_to_HHLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AZ50 = parseFloat(localStorage.getItem('AZ50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const calculate = () => {
    if ([1, 2, 7].includes(I40)) {
      return (atot(BF45) * AZ50) / 1000;
    } else if ([3, 4, 8].includes(I40)) {
      return vol_hor(AZ50, BF45, Q40);
    } else if (I40 === 5) {
      return vol_hor_L(AZ50, BF45, BH58);
    } else if (I40 === 6) {
      return (
        vol_hor_L(AZ50 + 0.125 * BF45 * 1000, BF45, BH58) -
        vol_hor_L(0.125 * BF45, BF45, BH58)
      );
    }
    return null;
  };
  let result = calculate();
  // result = result || 0;
  const S58 = localStorage.getItem('S58');
  if (S58 == result) return;
  localStorage.setItem('S58', result);
  event_change_data('Shell_Vol_to_HHLL_orange');
};
export const Vol_to_LLLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const D58 = parseFloat(localStorage.getItem('D58'));
  const AQ50 = parseFloat(localStorage.getItem('AQ50'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return D58 + vol_hor_dish(BF45, BF45, I40, AR83);
    } else if (I40 === 3 || I40 === 4 || I40 === 5 || I40 === 8) {
      if (I40 === 5) {
        return D58 + 1 * vol_hor_dish(AQ50, BF45, I40, AR83);
      } else {
        return D58 + 2 * vol_hor_dish(AQ50, BF45, I40, AR83);
      }
    } else {
      return '-';
    }
  };

  let result = calculate() ;
  
  const AE58 = localStorage.getItem('AE58');
  if (AE58 == result) return;
  localStorage.setItem('AE58', result);
  event_change_data('Vol_to_LLLL_orange');
};
export const Vol_to_LLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const I58 = parseFloat(localStorage.getItem('I58'));
  const AT50 = parseFloat(localStorage.getItem('AT50'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return I58 + vol_hor_dish(BF45, BF45, I40, AR83);
    } else if (I40 === 3 || I40 === 4 || I40 === 5 || I40 === 8) {
      if (I40 === 5) {
        return I58 + 1 * vol_hor_dish(AT50, BF45, I40, AR83);
      } else {
        return I58 + 2 * vol_hor_dish(AT50, BF45, I40, AR83);
      }
    } else {
      return null;
    }
  };

  let result = calculate() ;
  const AI58 = localStorage.getItem('AI58');
  if (AI58 == result) return;
  localStorage.setItem('AI58', result);
  event_change_data('Vol_to_LLL_orange');
};
export const Vol_to_HLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const N58 = parseFloat(localStorage.getItem('N58'));
  const AW50 = parseFloat(localStorage.getItem('AW50'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return N58 + vol_hor_dish(BF45, BF45, I40, AR83);
    } else if (I40 === 3 || I40 === 4 || I40 === 5 || I40 === 8) {
      let multiplier = I40 === 5 ? 1 : 2;
      return N58 + multiplier * vol_hor_dish(AW50, BF45, I40, AR83);
    } else {
      return null;
    }
  };

  let result = calculate() ;
  const AM58 = localStorage.getItem('AM58');
  if (AM58 == result) return;
  localStorage.setItem('AM58', result);
  event_change_data('Vol_to_HLL_orange');
};

export const Vol_to_HHLL = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AR83 = parseFloat(localStorage.getItem('AR83'));
  const S58 = parseFloat(localStorage.getItem('S58'));
  const AZ50 = parseFloat(localStorage.getItem('AZ50'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return S58 + vol_hor_dish(BF45, BF45, I40, AR83);
    } else if (I40 === 3 || I40 === 4 || I40 === 5 || I40 === 8) {
      let multiplier = I40 === 5 ? 1 : 2;
      return S58 + multiplier * vol_hor_dish(AZ50, BF45, I40, AR83);
    } else {
      return null;
    }
  };

  let result = calculate() ;
  const AQ58 = localStorage.getItem('AQ58');
  if (AQ58 == result) return;
  localStorage.setItem('AQ58', result);
  event_change_data('Vol_to_HHLL_orange');
};
export const Total_Vol = () => {
  const AY54 = parseFloat(localStorage.getItem('AY54'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  let result = AY54 + 2 * (Math.PI / 24) * Math.pow(BF45, 3);

  result = result ;
  const AY58 = localStorage.getItem('AY58');
  if (AY58 == result) return;
  localStorage.setItem('AY58', result);
  event_change_data('Total_Vol_orange');
};

export const L_1 = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AH45 = parseFloat(localStorage.getItem('AH45'));
  const AZ66 = parseFloat(localStorage.getItem('AZ66'));
  const BD66 = parseFloat(localStorage.getItem('BD66'));
  const BH66 = parseFloat(localStorage.getItem('BH66'));
  const BH54 = parseFloat(localStorage.getItem('BH54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const BD62 = parseFloat(localStorage.getItem('BD62'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const calculate = () => {
    if (I40 === 5) {
      let AH45Factor = AH45 ? 0.45 * BF45 : 0;
      return AH45Factor + Math.max(AZ66, BD66, BH66, BH54, BF45 * Q40);
    } else if (I40 === 6) {
      let AH45Factor = AH45 ? 0.45 * BF45 : 0;
      return (
        AH45Factor +
        Math.max(
          AZ66,
          BD66,
          BH66,
          BF45 * Q40 - (BH58 + BD62 + BH62 + AH45Factor)
        )
      );
    } else {
      return null;
    }
  };
  let result = calculate() || 0;
  const BD58 = localStorage.getItem('BD58');
  if (BD58 == result) return;
  localStorage.setItem('BD58', result);
  event_change_data('L_1_orange');
};
export const L_2 =async () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const _995_1000 = await parseFloat(localStorage.getItem('995_1000'))||0;
  const _996_1000 =await  parseFloat(localStorage.getItem('996_1000'))||0;
  const AB66 = parseFloat(localStorage.getItem('AB66'));
  const calculate = () => {
    const calculation = (1 + AB66 / 12) * 0.3048;
    // console.(.*)
    if (I40 === 5) {
      return Math.max(_995_1000, calculation);
    } else if (I40 === 6) {
      // console.(.*)
      return Math.max(_996_1000, calculation);
    } else {
      return null;
    }
  };
  
  let result = calculate() || 0;
  const BH58 = localStorage.getItem('BH58');
  if (BH58 == result) return;
  await localStorage.setItem('BH58', result);
  event_change_data('L_2_orange');
};
export const H_d = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const W66 = parseFloat(localStorage.getItem('W66'));
  const AB45 = parseFloat(localStorage.getItem('AB45'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      let baseValue = 36 +( 0.5 * W66);
      let additionalValue = AB45 ? 6 : 0;
      let result = (baseValue + additionalValue) * 25.4;
      return ceilToTen(result );
    } else {
      return null;
    }
  };
  let result = calculate() || 0;
  const M66 = localStorage.getItem('M66');
  if (M66 == Math.round(result)) return;
  localStorage.setItem('M66', Math.round(result));
  event_change_data('H_d_orange');
};

export const D_f_in1 = () => {
  const S66 = parseFloat(localStorage.getItem('S66'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const L62 = parseFloat(localStorage.getItem('L62'));
  const K26 = parseFloat(localStorage.getItem('K26'));
  const K34 = parseFloat(localStorage.getItem('K34'));
  const calculate = () => {
    let result = Math.max(
      S66 * Math.sqrt((H62 + L62) * Math.pow((K26 + K34) / (H62 + L62), 0.5)),
      2
    );
    return result;
  };
  let result = calculate() || 0;
  const U66 = localStorage.getItem('U66');
  
  if (U66 == Math.round(result)) return;
  localStorage.setItem('U66', Math.round(result));
  event_change_data('D_f_in1_orange');
};
export const D_f_in3 = () => {
  const S66 = parseFloat(localStorage.getItem('S66'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const L62 = parseFloat(localStorage.getItem('L62'));
  const K26 = parseFloat(localStorage.getItem('K26'));
  const K30 = parseFloat(localStorage.getItem('K30'));
  const K34 = parseFloat(localStorage.getItem('K34'));
  const calculate = () => {
    const sumDHL = D62 + H62 + L62;
    const ratioK = (K26 + K30 + K34) / sumDHL;
    const intermediateValue = S66 * Math.sqrt(sumDHL * Math.sqrt(ratioK));

    const roundedValue = Math.round(intermediateValue);

    if (roundedValue === 3) {
      return Math.max(2, intermediateValue);
    } else {
      return Math.max(2, Math.ceil(intermediateValue / 2) * 2);
    }
  };
  let result = calculate() || 0;
  const AT45 = localStorage.getItem('AT45');
  if (AT45 == Math.round(result)) return;
  localStorage.setItem('AT45', Math.round(result));
  event_change_data('D_f_in3_orange');
};
export const D_g = () => {
  const L62 = parseFloat(localStorage.getItem('L62'));
  const K24 = parseFloat(localStorage.getItem('K24'));

  const calculate = () => {
    const interimValue = 0.0904 * ((L62 * Math.sqrt(K24))**0.5);
    const ceilingValue = ceiling(interimValue, 2);
    return Math.max(ceilingValue, 2);
  };
  let result = calculate() || 0;
  const Y66 = localStorage.getItem('Y66');
  if (Y66 == Math.round(result)) return;
  localStorage.setItem('Y66', Math.round(result));
  event_change_data('D_g_orange');
};
export const D_ll = () => {
  const H62 = parseFloat(localStorage.getItem('H62'));
  const calculate = () => {
    let result = Math.max(Math.ceil((0.7404 * Math.sqrt(H62)) / 2) * 2, 2);
    return result;
  };
  let result = calculate() || 0;
  const AB66 = localStorage.getItem('AB66');
  if (AB66 == Math.round(result)) return;
  localStorage.setItem('AB66', Math.round(result));
  event_change_data('D_ll_orange');
};
export const D_hl = () => {
  const D62 = parseFloat(localStorage.getItem('D62'));
  const calculate = () => {
    let result = Math.max(0.7404 * Math.sqrt(D62), 2);
    return result;
  };
  let result = calculate() || 0;
  const AE66 = localStorage.getItem('AE66');
  if (AE66 == Math.round(result)) return;
  localStorage.setItem('AE66', Math.round(result));
  event_change_data('D_hl_orange');
};

export const H_w = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const P66 = parseFloat(localStorage.getItem('P66'));
  const calculate = () => {
    if (I40 === 5 || I40 === 6) {
      return 1000 * BF45 - P66;
    } else {
      return null;
    }
  };
  let result = calculate() || 0;
  const AI66 = localStorage.getItem('AI66');
  if (AI66 == result) return;
  localStorage.setItem('AI66', Math.round(result));
  event_change_data('H_w_orange');
};

export const u_g = () => {
  const u_t = parseFloat(localStorage.getItem('P62'));
  const result = 0.75 * u_t || 0;
  const T62 = localStorage.getItem('T62');
  if (T62 == result) return;
  localStorage.setItem('T62', result);
  event_change_data('u_g_orange');
};

export const T_hl = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AZ50 = parseFloat(localStorage.getItem('AZ50'));
  const AE50 = parseFloat(localStorage.getItem('AE50'));
  const X62 = parseFloat(localStorage.getItem('X62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const P66 = parseFloat(localStorage.getItem('P66'));
  const AM66 = parseFloat(localStorage.getItem('AM66'));
  const AI66 = parseFloat(localStorage.getItem('AI66'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const calculate = () => {
    if (I40 === 7 || I40 === 8) {
      return 0;
    } else if (I40 === 1 || I40 === 2) {
      return (AZ50 - AE50) / 10 / X62;
    } else if (I40 === 3) {
      return (1000 * BF45 - P66 - AE50) / 10 / X62;
    } else if (I40 === 4) {
      return (30.48 * AM66 + BF45 * 100 - P66 / 10) / X62;
    } else if (I40 === 5) {
      return (AI66 - AE50) / 10 / X62;
    } else if (I40 === 6) {
      return ((100 * BF45 - P66 / 10) * (H62 / (H62 + D62))) / X62;
    }
  };
  let result = calculate() || 0;
  const AF62 = localStorage.getItem('AF62');
  if (AF62 == result) return;
  localStorage.setItem('AF62', result);
  event_change_data('T_hl_orange');
};
export const T_lh = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const AB62 = parseFloat(localStorage.getItem('AB62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const P66 = parseFloat(localStorage.getItem('P66'));

  const calculate = () => {
    if (I40 === 7 || I40 === 8) {
      return 0;
    } else if (I40 === 1 || I40 === 2 || I40 === 3 || I40 === 4 || I40 === 5) {
      return AN50 / 10 / AB62;
    } else if (I40 === 6) {
      return ((100 * BF45 - P66 / 10) * (D62 / (H62 + D62))) / AB62;
    }
  };
  let result = calculate() || 0;
  const AJ62 = localStorage.getItem('AJ62');
  if (AJ62 == result) return;
  localStorage.setItem('AJ62', result);
  event_change_data('T_lh_orange');
};
export const L_min_hl = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AF62 = parseFloat(localStorage.getItem('AF62'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const P66 = parseFloat(localStorage.getItem('P66'));
  const AE50 = parseFloat(localStorage.getItem('AE50'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return null;
    } else if (I40 === 3 || I40 === 5) {
      return (
        ((AF62 / 60) * H62) /
        (atot(BF45) *
          (1 - H_DtoA_AT(P66 / 1000, BF45) - H_DtoA_AT(AE50 / 1000, BF45)))
      );
    } else if (I40 === 4) {
      return (
        ((AF62 / 60) * H62) / (atot(BF45) * (1 - H_DtoA_AT(P66 / 1000, BF45)))
      );
    } else {
      return (
        ((AF62 / 60) * H62) /
        (atot(BF45) *
          H_DtoA_AT((H62 / (D62 + H62)) * (BF45 - P66 / 1000), BF45))
      );
    }
  };
  let result = calculate() || 0;
  const AZ66 = localStorage.getItem('AZ66');
  if (AZ66 == result) return;
  localStorage.setItem('AZ66', result);
  event_change_data('L_min_hl_orange');
};
export const L_min_lh = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const AJ62 = parseFloat(localStorage.getItem('AJ62'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const P66 = parseFloat(localStorage.getItem('P66'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 4 || I40 === 7) {
      return null;
    } else if (I40 === 3 || I40 === 5) {
      return ((AJ62 / 60) * D62) / (atot(BF45) * H_DtoA_AT(AN50 / 1000, BF45));
    } else {
      return (
        ((AJ62 / 60) * D62) /
        (atot(BF45) *
          H_DtoA_AT(((BF45 - P66 / 1000) * D62) / (D62 + H62), BF45))
      );
    }
  };
  let result = calculate() || 0;
  const BD66 = localStorage.getItem('BD66');
  if (BD66 == result) return;
  localStorage.setItem('BD66', result);
  event_change_data('L_min_lh_orange');
};
export const L_min_v = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const L62 = parseFloat(localStorage.getItem('L62'));
  const T62 = parseFloat(localStorage.getItem('T62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const P66 = parseFloat(localStorage.getItem('P66'));

  const calculate = () => {
    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return null;
    } else if (I40 === 3 || I40 === 4 || I40 === 5 || I40 === 6 || I40 === 8) {
      return (
        ((L62 / 3600) * (P66 / 1000)) /
        (T62 * (H_DtoA_AT(P66 / 1000, BF45) * atot(BF45)))
      );
    }
  };
  let result = calculate() || 0;
  const BH66 = localStorage.getItem('BH66');
  if (BH66 == result) return;
  localStorage.setItem('BH66', result);
  event_change_data('L_min_v_orange');
};
//////////////////////
export const HHLA_HHLS_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const N58 = parseFloat(localStorage.getItem('N58'));
  const BF23 = parseFloat(localStorage.getItem('BF23'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AW50 = parseFloat(localStorage.getItem('AW50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const calculate = () => {
    if (I40 === 5) {
      return (
        A_ATtoh_D((N58 + (BF23 / 60) * H62) / BH58, atot(BF45)) * BF45 * 1000 -
        AW50
      );
    } else if (I40 === 6) {
      return (
        A_ATtoh_D(
          (vol_hor_L(0.125 * BF45 * 1000, BF45, BH58) +
            N58 +
            (BF23 / 60) * H62) /
            BH58,
          atot(BF45)
        ) *
          BF45 *
          1000 -
        (AW50 + 0.125 * BF45 * 1000)
      );
    } else {
      return (
        A_ATtoh_D((N58 + (BF23 / 60) * H62) / (BF45 * Q40), atot(BF45)) *
          BF45 *
          1000 -
        AW50
      );
    }
  };
  // // console.(.*)

  let result = calculate();
  result=(result<0 || isNaN(result))?NaN:result;
  const BI23 = localStorage.getItem('BI23');
  if (BI23 == result.toFixed(4)) return;
   
  if(result==null) localStorage.setItem('BI23', result);
  else localStorage.setItem('BI23',  result.toFixed(4));
  event_change_data('HHLA_HHLS_h_mm');
  
};
export const HLA_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const I58 = parseFloat(localStorage.getItem('I58'));
  const BF24 = parseFloat(localStorage.getItem('BF24'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const BH58 = parseFloat(localStorage.getItem('BH58'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AT50 = parseFloat(localStorage.getItem('AT50'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const calculate = () => {
    if (I40 === 5) {
      return (
        A_ATtoh_D((I58 + (BF24 / 60) * H62) / BH58, atot(BF45)) * BF45 * 1000 -
        AT50
      );
    } else if (I40 === 6) {
      return (
        A_ATtoh_D(
          (vol_hor_L(0.125 * BF45 * 1000, BF45, BH58) +
            I58 +
            (BF24 / 60) * H62) /
            BH58,
          atot(BF45)
        ) *
          BF45 *
          1000 -
        (AT50 + 0.125 * BF45 * 1000)
      );
    } else {
      return (
        A_ATtoh_D((I58 + (BF24 / 60) * H62) / (BF45 * Q40), atot(BF45)) *
          BF45 *
          1000 -
        AT50
      );
    }
  };
  let result = calculate() || 0;
  result=result<0?null:result;
  const BI24 = localStorage.getItem('BI24');
  if (BI24 == Math.round(result)) return;
  
  if(result==null) localStorage.setItem('BI24', result);
  else localStorage.setItem('BI24', Math.round(result));
  event_change_data('HLA_h_mm');
};
export const LLA_h_mm = () => {
  try {
    const I40 = parseFloat(localStorage.getItem('I40'));
    const D58 = parseFloat(localStorage.getItem('D58'));
    const BF25 = parseFloat(localStorage.getItem('BF25'));
    const H62 = parseFloat(localStorage.getItem('H62'));
    const BH58 = parseFloat(localStorage.getItem('BH58'));
    const BF45 = parseFloat(localStorage.getItem('BF45'));
    const AQ50 = parseFloat(localStorage.getItem('AQ50'));
    const Q40 = parseFloat(localStorage.getItem('Q40'));
    // // console.(.*)
    const calculate = () => {
      if (I40 === 5) {
        return (
          A_ATtoh_D((D58 + (BF25 / 60) * H62) / BH58, atot(BF45)) *
            BF45 *
            1000 -
          AQ50
        );
      } else if (I40 === 6) {
        return (
          A_ATtoh_D(
            (vol_hor_L(0.125 * BF45 * 1000, BF45, BH58) +
              D58 +
              (BF25 / 60) * H62) /
              BH58,
            atot(BF45)
          ) *
            BF45 *
            1000 -
          (AQ50 + 0.125 * BF45 * 1000)
        );
      } else {
        return (
          A_ATtoh_D((D58 + (BF25 / 60) * H62) / (BF45 * Q40), atot(BF45)) *
            BF45 *
            1000 -
          AQ50
        );
      }
    };
    let result = calculate() || 0;
    result=result<0?null:result;
    const BI25 = localStorage.getItem('BI25');
    if (BI25 == Math.round(result)) return;
    if(result==null) localStorage.setItem('BI25', result);
   else localStorage.setItem('BI25', Math.round(result));
    event_change_data('LLA_h_mm');
  } catch (e) {
    
  }
};

export const LLLA_LLLS_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BF26 = parseFloat(localStorage.getItem('BF26'));
  const H62 = parseFloat(localStorage.getItem('H62'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const S54 = parseFloat(localStorage.getItem('S54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BC26 = parseFloat(localStorage.getItem('BC26'));
  const AN50 = parseFloat(localStorage.getItem('AN50'));
  const calculate = () => {
    if (I40 === 4 || I40 === 5) {
      return (
        A_ATtoh_D(((BF26 / 60) * H62) / (BF45 * Q40), atot(BF45)) * BF45 * 1000
      );
    } else if (I40 === 6) {
      return BC26;
    } else {
      return (
        A_ATtoh_D((S54 + (BF26 / 60) * H62) / (BF45 * Q40), atot(BF45)) *
          BF45 *
          1000 -
        AN50
      );
    }
  };
  let result = calculate() || 0;
  result=result<0?null:result;
  const BI26 = localStorage.getItem('BI26');
  if (BI26 == Math.round(result)) return;
  if(result==null) localStorage.setItem('BI26', result);
   else localStorage.setItem('BI26', Math.round(result));
  event_change_data('LLLA_LLLS_h_mm');
};
export const HHIA_HHIS_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const BF27 = parseFloat(localStorage.getItem('BF27'));
  const N54 = parseFloat(localStorage.getItem('N54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AK50 = parseFloat(localStorage.getItem('AK50'));
  const calculate = () => {
    if (I40 === 4) {
      return null;
    } else if (I40 === 6) {
      return (
        A_ATtoh_D((N54 + (BF27 / 60) * D62) / BH62, atot(BF45)) * BF45 * 1000 -
        AK50
      );
    } else {
      return (
        A_ATtoh_D((N54 + (BF27 / 60) * D62) / (BF45 * Q40), atot(BF45)) *
          BF45 *
          1000 -
        AK50
      );
    }
  };
  let result = calculate() ;
  result=(result<0 || isNaN(result))?NaN:result;
  const BI27 = localStorage.getItem('BI27');
  if (BI27 == Math.round(result)) return;
  if(result==null) localStorage.setItem('BI27', result);
   else localStorage.setItem('BI27', Math.round(result));
  event_change_data('HHIA_HHIS_h_mm');
};
export const HIA_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const BF28 = parseFloat(localStorage.getItem('BF28'));
  const I54 = parseFloat(localStorage.getItem('I54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AH50 = parseFloat(localStorage.getItem('AH50'));
  const calculate = () => {
    if (I40 === 4) {
      return null;
    } else if (I40 === 6) {
      return (
        A_ATtoh_D((I54 + (BF28 / 60) * D62) / BH62, atot(BF45)) * BF45 * 1000 -
        AH50
      );
    } else {
      return (
        A_ATtoh_D((I54 + (BF28 / 60) * D62) / (BF45 * Q40), atot(BF45)) *
          BF45 *
          1000 -
        AH50
      );
    }
  };
  let result = calculate() ;
  // result=result<0?null:result;
  const BI28 = localStorage.getItem('BI28');
  if (BI28 == Math.round(result)) return;
  // if(result==null) localStorage.setItem('BI28', result);
  else localStorage.setItem('BI28', Math.round(result));
  event_change_data('HIA_h_mm');
};
export const LIA_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const BF29 = parseFloat(localStorage.getItem('BF29'));
  const D54 = parseFloat(localStorage.getItem('D54'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const AE50 = parseFloat(localStorage.getItem('AE50'));
  const calculate = () => {
    if (I40 === 4) {
      return null;
    } else if (I40 === 6) {
      return (
        A_ATtoh_D((D54 + (BF29 / 60) * D62) / BH62, atot(BF45)) * BF45 * 1000
      );
    } else {
      return (
        A_ATtoh_D((D54 + (BF29 / 60) * D62) / (BF45 * Q40), atot(BF45)) *
          BF45 *
          1000 -
        AE50
      );
    }
  };
  let result = calculate() || 0;
  result=result<0?null:result;
  const BI29 = localStorage.getItem('BI29');
  if (BI29 == Math.round(result)) return;
  if(result==null) localStorage.setItem('BI29', result);
  else localStorage.setItem('BI29', Math.round(result));
  event_change_data('LIA_h_mm');
};
export const LLIA_LLIS_h_mm = () => {
  const I40 = parseFloat(localStorage.getItem('I40'));
  const BH62 = parseFloat(localStorage.getItem('BH62'));
  const D62 = parseFloat(localStorage.getItem('D62'));
  const BF30 = parseFloat(localStorage.getItem('BF30'));
  const Q40 = parseFloat(localStorage.getItem('Q40'));
  const BF45 = parseFloat(localStorage.getItem('BF45'));
  const calculate = () => {
    if (I40 === 4) {
      return '-';
    } else if (I40 === 6) {
      return A_ATtoh_D(((BF30 / 60) * D62) / BH62, atot(BF45)) * BF45 * 1000;
    } else {
      return (
        A_ATtoh_D(((BF30 / 60) * D62) / (BF45 * Q40), atot(BF45)) * BF45 * 1000
      );
    }
  };
  let result = calculate() || 0;
  

  const BI30 = localStorage.getItem('BI30');
  if (BI30 == Math.round(result)) return;
  localStorage.setItem('BI30', Math.round(result));
  event_change_data('LLIA_LLIS_h_mm');
};
