import React, { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import { configApi } from '../API/configApi';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { ParsError } from '../Tools/ParsError';
import { Button } from 'antd';
import { AuthApi } from '../API/AuthApi';
import useAuth from '../hooks/useAuth';
import { Link } from 'react-router-dom';

function RegisterEnterprise() {
  const [countries, setCountries] = useState([]);
  const { openNotification } = useNotify();
  const [loading, setLoading] = useState(false);
  const { saveToken } = useAuth();
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [user, setUser] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
    password: '',
    password_confrimation: '',
    country: null,
    company: '',
    industry: null,
    size: null,
    phone: '',
    website: '',
  });
  const apiClient = useApiClient();
  const toggleShowPass = () => {
    setShowPass(!showPass);
  };
  const toggleShowPass2 = () => {
    setShowPass2(!showPass2);
  };
  const loadCountries = () => {
    configApi
      .countries(apiClient)
      .then((res) => setCountries(res))
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response.data));
      });
  };
  useEffect(() => {
    loadCountries();
  }, []);
  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    AuthApi.register2(apiClient, user)
      .then((res) => {
        // const { token, refreshToken, units } = res;
        // saveToken(token, refreshToken, units);
        // document.location.reload();
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response), 'Error');
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="bg-white rounded p-10 mt-5">
      <div className="p-5 bg-[#FFF5E4] flex gap-4 items-center mb-4 font-semibold ">
        <img src="/images/building.svg" />
        <span>
          Please fill out the form and leave your contact details. our sales
          team will reach out to you as soon as possible.
        </span>
      </div>
      <form onSubmit={submit}>
        <div>
          <h6 className="font-bold">■ User Information</h6>
          <div className="grid grid-cols-3 p-5 gap-14">
            <div className="">
              <label
                htmlFor="first-name"
                className="block required text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="first-name"
                  required
                  name="name"
                  type="text"
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  value={user.name}
                  placeholder="Enter your name"
                  autoComplete="given-name"
                  className="block  w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="">
              <label
                htmlFor="Surname-name"
                className="block required text-sm font-medium leading-6 text-gray-900"
              >
                Surname
              </label>
              <div className="mt-2">
                <input
                  id="Surname-name"
                  required
                  name="surname"
                  type="text"
                  onChange={(e) =>
                    setUser({ ...user, surname: e.target.value })
                  }
                  value={user.surname}
                  placeholder="Enter your Surname"
                  autoComplete="given-name"
                  className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="">
              <label
                htmlFor="Email"
                className="block required text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  required
                  id="Email"
                  name="email"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  value={user.email}
                  type="email"
                  placeholder="Enter your Email"
                  autoComplete="given-Email"
                  className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="">
              <label
                htmlFor="mobile"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                Mobile number
              </label>
              <div className="mt-2">
                <PhoneInput
                  country={'us'}
                  value={user.mobile}
                  inputStyle={{ height: '3rem', width: '100%' }}
                  className={'h-12'}
                  //   inputClass={'h-12'}
                  //   containerClass={'h-12'}
                  //   searchClass={'h-12'}
                  //   dropdownClass	={'h-12'}
                  onChange={(phone) => {
                    setUser({ ...user, mobile: phone });
                  }}
                />
              </div>
            </div>

            <div className="mb-8 relative">
              <label
                htmlFor="password"
                className="block required mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your password
              </label>
              <input
                type={showPass ? 'text' : 'password'}
                name="password"
                value={user.password}
                placeholder="Enter your password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                id="password"
                className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
              <img
                src={showPass ? '/images/eyeOpen.svg' : '/images/eyeClose.svg'}
                onClick={toggleShowPass}
                class="absolute top-2/4 inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
              />
            </div>
            <div className="mb-8 relative">
              <label
                htmlFor="password"
                className="block required mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Confirm password
              </label>
              <input
                type={showPass2 ? 'text' : 'password'}
                name="password_confirmation"
                value={user.password_confirmation}
                placeholder="Enter your password"
                onChange={(e) =>
                  setUser({ ...user, password_confirmation: e.target.value })
                }
                id="password"
                className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
                <img
                src={showPass2 ? '/images/eyeOpen.svg' : '/images/eyeClose.svg'}
                onClick={toggleShowPass2}
                class="absolute top-2/4 inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
              />
            </div>
          </div>
        </div>
        <div>
          <h6 className="font-bold">■ Company Information</h6>
          <div className="grid grid-cols-3 p-5 gap-14">
            <div className="">
              <label
                htmlFor="Company-name"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                Company Name
              </label>
              <div className="mt-2">
                <input
                  id="Company-name"
                  name="company"
                  onChange={(e) =>
                    setUser({ ...user, company: e.target.value })
                  }
                  value={user.company}
                  type="text"
                  required
                  placeholder="Enter your company name"
                  autoComplete="given-name"
                  className="block  w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="">
              <label
                htmlFor="Surname-name"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                Industry sector
              </label>
              <div className="mt-2">
                <select
                  className="block  w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  name="industry"
                  id="industry_id"
                  required
                  onChange={(e) =>
                    setUser({ ...user, industry: e.target.value })
                  }
                  value={user.industry}
                >
                  <option value="">Select Industry</option>
                  <option value="Oil">Oil and Gas</option>
                  <option value="Petrochemical">Petrochemical</option>
                  <option value="Energy">Energy</option>
                  <option value="CleanTech">CleanTech</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="">
              <label
                htmlFor="size"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                Company size
              </label>
              <div className="mt-2">
                <select
                  className="block  w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  name="size"
                  onChange={(e) => setUser({ ...user, size: e.target.value })}
                  value={user.size}
                  id="size_id"
                >
                  <option value="">Select Industry</option>
                  <option value="1-99">1-99 employees</option>
                  <option value="100">Up to 100 employees</option>
                  <option value="100-999">100-999 employees</option>
                  <option value="1000">1,000+ employees</option>
                </select>
              </div>
            </div>
            <div className="">
              <label
                htmlFor="Country"
                className="block required text-sm font-medium leading-6 text-gray-900"
              >
                Country
              </label>
              <div className="mt-2">
                <select
                  required
                  onChange={(e) =>
                    setUser({ ...user, country: e.target.value })
                  }
                  value={user.country}
                  className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  name="country"
                  id="region"
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option value={country.num_code}>
                      {country.en_short_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label
                htmlFor="Phone"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                Phone number
              </label>
              <div className="mt-2">
                <PhoneInput
                  country={'us'}
                  value={user.phone}
                  inputStyle={{ height: '3rem', width: '100%' }}
                  className={'h-12'}
                  //   inputClass={'h-12'}
                  //   containerClass={'h-12'}
                  //   searchClass={'h-12'}
                  //   dropdownClass	={'h-12'}
                  onChange={(phone) => {
                    setUser({ ...user, phone: phone });
                  }}
                />
              </div>
            </div>
            <div className="">
              <label
                htmlFor="Website"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                Website
              </label>
              <div className="mt-2">
                <input
                  id="Website"
                  name="Website"
                  onChange={(e) =>
                    setUser({ ...user, website: e.target.value })
                  }
                  value={user.website}
                  type="text"
                  placeholder="Enter your Website "
                  autoComplete="given-name"
                  className="block  w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="grid p-5 gap-3">
            <div className="">
              <input
                id="terms"
                type="checkbox"
                required
                name="terms"
                className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="terms"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                <strong className="text-red-600">{'*'}</strong> I agree to the{' '}
                <a
                  target="_blank"
                  href="https://omnisep.co.uk/terms"
                  className="!text-[--brown] underline"
                >
                  Terms of service
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  className="!text-[--brown] underline"
                  href="https://omnisep.co.uk /privacy"
                >
                  Privacy policy
                </a>
                .
              </label>
            </div>
            <div className="">
              <input
                id="communications"
                type="checkbox"
                required
                name="communications"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                for="communications"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                I agree to receive marketing communications about products,
                services, and promotions via email and other electronic means. I
                understand that I can withdraw my consent at any time by
                clicking the unsubscribe link in any marketing email or by
                contacting the company’s Support Team.
              </label>
            </div>
          </div>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="submit block m-auto rounded !bg-[#C89843]"
          >
            Submit
          </Button>
          <p className="text-center mt-4 text-sm">
            Already have an account?{' '}
            <Link to="/">
              <span className="text-brown font-semibold">Log in</span>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
}

export default RegisterEnterprise;
