import Global from "../Settings/config.json";
export const planApi= {
    
    async plans(apiClient,q,months) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/plans?q=${q}&m=${months}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
   
}