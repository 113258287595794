import React, { useEffect, useRef, useState } from 'react';
import useApiClient from '../../Settings/useApiClient';
import useAuth from '../../hooks/useAuth';
import { sizingApi } from '../../API/sizingApi';
import { preProccess, sizeIt } from '../../helpers/Process';
import { useParams } from 'react-router-dom';
import { event_change_data } from '../../services/events';
import useNotify from '../../hooks/useNotify';

const Result = () => {
  const { saveToken, token, refreshToken, units, profile } = useAuth();
  const apiClient = useApiClient();
  const { openNotification } = useNotify();

  const { id } = useParams();
  const workerRef = useRef(null);
  const [, setForce ] = useState(33)
  useEffect(() => {
    // const preParingsizeIt = () => {
    sizingApi.view(apiClient, id).then((res) => {
      //
      localStorage.clear();
      saveToken(token, refreshToken, units, profile);
      localStorage.setItem('J66', 700); //HBN
      localStorage.setItem('BF40', 0.99);
      localStorage.setItem('AS66', 1.3);
      
      localStorage.setItem('W66', 4); //df in
      preProccess(res).then(() => {
        workerRef.current = new Worker(new URL('/js/worker.js', window.location.href));
        workerRef.current.onmessage = (e) => {
          const { action, key, value } = e.data;

          if (action  === "change_data") {
            event_change_data(key)
          }
          else if (action  === "setItem") {
                 localStorage.setItem(key, value);
          }
          else   if (action === "getItem") {
            const value = localStorage.getItem(key);
            workerRef.current.postMessage({ action: "receivedData", key, value });

          
        } else if (action === 'finish') {
          if (workerRef.current) {
            workerRef.current.terminate();
          }
        } else if (action == 'msg') {
          openNotification('Alert', key, 200);
        }
        };
      });
    });
  }, []);
  useEffect(()=>{
    // setTimeout(()=>{
      setForce(Math.random())

    // },1000)
  })
  return (
    <div className="grid grid-cols-4 top-36 mt-36  ">
      <h1>init estimated {localStorage.getItem('BF45')} </h1>
      <div className="flex col-span-4   divide-x gap-2 bg-gray-400">
        <strong>Project Design Criteria:</strong>
        <div>
          HHLA/HHLS hhmm <br /> {localStorage.getItem('BI23')}{' '}
        </div>
        <div>
          HLA hhmm <br />
          {localStorage.getItem('BI24')}{' '}
        </div>
        <div>
          LLA hhmm <br /> {localStorage.getItem('BI25')}{' '}
        </div>
        <div>
          LLLA/LLLS hhmm <br />
          {localStorage.getItem('BI26')}{' '}
        </div>
        <div>
          HHIA/HHIS hhmm <br />
          {localStorage.getItem('BI27')}{' '}
        </div>
        <div>
          HIA hhmm <br />
          {localStorage.getItem('BI28')}{' '}
        </div>
        <div>
          LIA hhmm <br />
          {localStorage.getItem('BI29')}{' '}
        </div>
        <div>
          LLIA/LLIS hhmm <br />
          {localStorage.getItem('BI30')}{' '}
        </div>
      </div>

      <div className="flex col-span-4 divide-x  bg-orange-300 gap-2">
        <div> LLIA/LLIS {localStorage.getItem('D50')} </div>
        <div> LIA {localStorage.getItem('G50')} </div>
        <div>
          {' '}
          Working Vol. heavy {localStorage.getItem('J50')}{' '}
        </div>
        <div> HHIA/HHIS {localStorage.getItem('N50')} </div>
        <div> LLLA {localStorage.getItem('Q50')} </div>
        <div> LLA {localStorage.getItem('T50')} </div>
        <div>
          {' '}
          Working Vol. light {localStorage.getItem('W50')}{' '}
        </div>
        <div> HHLA/HHLS {localStorage.getItem('AA50')} </div>
        <div> LLLIL {localStorage.getItem('AE50')} </div>
        <div> LLIL {localStorage.getItem('AH50')} </div>
        <div> HLIL {localStorage.getItem('AK50')} </div>
        <div> HHIL {localStorage.getItem('AN50')} </div>
        <div> LLLL {localStorage.getItem('AQ50')} </div>
        <div> LLL {localStorage.getItem('AT50')} </div>
        <div> HLL {localStorage.getItem('AW50')} </div>
        <div> HHLL {localStorage.getItem('AZ50')} </div>
        <div> Calculated D ft {localStorage.getItem('BD50')} </div>
        <div> Calculated D m {localStorage.getItem('BH50')} </div>
      </div>
      <div className="flex col-span-4  mt-5 divide-x  bg-orange-300">
        <div>
          {' '}
          Shell Vol. to LLLIL {localStorage.getItem('D54')}{' '}
        </div>
        <div>
          {' '}
          Shell Vol. to LLIL {localStorage.getItem('I54')}{' '}
        </div>
        <div>
          {' '}
          Shell Vol. to HLIL {localStorage.getItem('N54')}{' '}
        </div>
        <div>
          Shell Vol. to HHLIL {localStorage.getItem('S54')}{' '}
        </div>
        <div> Vol. to LLLIL {localStorage.getItem('AE54')} </div>
        <div> Vol. to LLIL {localStorage.getItem('AI54')} </div>
        <div> Vol. to HLIL {localStorage.getItem('AM54')} </div>
        <div> Vol. to HHLIL {localStorage.getItem('AQ54')} </div>
        <div> Total Shell Vol. {localStorage.getItem('AY54')} </div>
        <div>Calculated L ft {localStorage.getItem('BD54')} </div>
        <div>Calculated L m {localStorage.getItem('BH54')} </div>
      </div>
      <div className="flex col-span-4  mt-5 gap-2  divide-x  bg-orange-300">
        <div>
          {' '}
          Shell Vol. to LLLL <br /> {
            localStorage.getItem('D58')
          }{' '}
        </div>
        <div>
          {' '}
          Shell Vol. to LLL <br />
          {localStorage.getItem('I58')}{' '}
        </div>
        <div>
          {' '}
          Shell Vol. to HLL
          <br /> {localStorage.getItem('N58')}{' '}
        </div>
        <div>
          {' '}
          Shell Vol. to HHLL
          <br /> {localStorage.getItem('S58')}{' '}
        </div>
        <div>
          Vol. to LLLL <br />
          {localStorage.getItem('AE58')}{' '}
        </div>
        <div>
          {' '}
          Vol. to LLL <br />
          {localStorage.getItem('AI58')}{' '}
        </div>
        <div>
          {' '}
          Vol. to HLL <br />
          {localStorage.getItem('AM58')}{' '}
        </div>
        <div>
          {' '}
          Vol. to HHLL <br />
          {localStorage.getItem('AQ58')}{' '}
        </div>
        <div>
          {' '}
          Total Vol. <br />
          {localStorage.getItem('AY58')}{' '}
        </div>
        <div>
          L1 <br />
          {localStorage.getItem('BD58')}{' '}
        </div>
        <div>
          {' '}
          L2
          <br /> {localStorage.getItem('BH58')}{' '}
        </div>
        <div>
          {' '}
          L3 <br />
          {localStorage.getItem('BD62')}{' '}
        </div>
        <div>
          {' '}
          L4 <br />
          {localStorage.getItem('BH62')}{' '}
        </div>
      </div>
      <div className="flex col-span-4  mt-5  divide-x bg-orange-300 ">
        <div>
          {' '}
          QH. Liq. <br /> {localStorage.getItem('D62')}{' '}
        </div>
        <div>
          {' '}
          QL. Liq. <br />
          {localStorage.getItem('H62')}{' '}
        </div>
        <div>
          {' '}
          QG <br />
          {localStorage.getItem('L62')}{' '}
        </div>
        <div>
          {' '}
          UT (L. Liq. In Gas)
          <br /> {localStorage.getItem('P62')}{' '}
        </div>
        <div>
          {' '}
          UG <br />
          {localStorage.getItem('T62')}{' '}
        </div>
        <div>
          {' '}
          UH. Liq. In L. Liq. <br />
          {localStorage.getItem('X62')}{' '}
        </div>
        <div>
          {' '}
          UL. Liq. In H. Liq. <br />
          {localStorage.getItem('AB62')}{' '}
        </div>
        <div>
          {' '}
          tHL <br />
          {localStorage.getItem('AF62')}{' '}
        </div>
        <div>
          {' '}
          tLH <br />
          {localStorage.getItem('AJ62')}{' '}
        </div>
      </div>
      <div className="flex col-span-4  mt-5 divide-x  bg-orange-300">
        <div>
          {' '}
          HA <br />
          {localStorage.getItem('D66')}{' '}
        </div>
        <div>
          {' '}
          WD <br />
          {localStorage.getItem('G66')}{' '}
        </div>
        <div>
          {' '}
          HBN <br />
          {localStorage.getItem('J66')}{' '}
        </div>
        <div>
          {' '}
          HD <br />
          {localStorage.getItem('M66')}{' '}
        </div>
        <div>
          {' '}
          Hv, min <br />
          {localStorage.getItem('P66')}{' '}
        </div>
        <div>
          {' '}
          dF c <br />
          {localStorage.getItem('S66')}{' '}
        </div>
        <div>
          {' '}
          dF in <br />
          {localStorage.getItem('U66')}{' '}
        </div>
        <div>
          {' '}
          dF in2 <br />
          {localStorage.getItem('W66')}{' '}
        </div>
        <div>
          {' '}
          dG <br />
          {localStorage.getItem('Y66')}{' '}
        </div>
        <div>
          {' '}
          dLL <br />
          {localStorage.getItem('AB66')}{' '}
        </div>
        <div>
          {' '}
          dHL <br />
          {localStorage.getItem('AE66')}{' '}
        </div>
        <div>
          {' '}
          HW <br />
          {localStorage.getItem('AI66')}{' '}
        </div>
        <div>
          {' '}
          HLL in boot <br />
          {localStorage.getItem('AM66')}{' '}
        </div>
        <div>
          {' '}
          HHL in Boot <br />
          {localStorage.getItem('AP66')}{' '}
        </div>
        <div>
          {' '}
          D boot <br /> {localStorage.getItem('AS66')}{' '}
        </div>
        <div>
          {' '}
          Hboot <br /> {localStorage.getItem('AV66')}{' '}
        </div>
        <div>
          {' '}
          Lmin, HL <br />
          {localStorage.getItem('AZ66')}{' '}
        </div>
        <div>
          {' '}
          Lmin, LH <br />
          {localStorage.getItem('BD66')}{' '}
        </div>
        <div>
          {' '}
          Lmin, V <br />
          {localStorage.getItem('BH66')}{' '}
        </div>
      </div>
      <div className="flex col-span-4  mt-5 divide-x gap-2  bg-blue-300">
        <div>
          LLLIL <br /> {localStorage.getItem('D71')}
        </div>
        <div>
          LLIL <br /> {localStorage.getItem('G71')}
        </div>
        <div>
          Working Vol.Heavy (m3) <br />{' '}
          {localStorage.getItem('J71')}
        </div>
        <div>
          NLIL <br /> {localStorage.getItem('N71')}
        </div>
        <div>
          HLIL <br /> {localStorage.getItem('Q71')}
        </div>
        <div>
          HHIL <br /> {localStorage.getItem('T71')}
        </div>
        <div>
          LLLL <br /> {localStorage.getItem('X71')}
        </div>
        <div>
          LLL <br /> {localStorage.getItem('AA71')}
        </div>
        <div>
          Working Vol light. <br /> {localStorage.getItem('AD71')}
        </div>
        <div>
          NLL <br /> {localStorage.getItem('AH71')}
        </div>
        <div>
          HLL <br /> {localStorage.getItem('AK71')}
        </div>
        <div>
          HHLL <br /> {localStorage.getItem('AN71')}
        </div>
        <div>
          HV <br /> {localStorage.getItem('AR71')}
        </div>
        <div>
          Hw/Bucket <br /> {localStorage.getItem('AV71')}
        </div>
        <div>
          HBN <br /> {localStorage.getItem('BD71')}
        </div>
        <div>
          HD <br /> {localStorage.getItem('BH71')}
        </div>
      </div>
      <div className="flex col-span-4  mt-5 divide-x gap-2  bg-blue-300">
        <div>
          Shell Vol. to LLLIL <br /> {localStorage.getItem('D75')}
        </div>
        <div>
          Shell Vol. to LLIL <br /> {localStorage.getItem('I75')}
        </div>
        <div>
          Shell Vol. to HLIL (m3) <br />{' '}
          {localStorage.getItem('N75')}
        </div>
        <div>
          Shell Vol. to HHLIL
          <br /> {localStorage.getItem('S75')}
        </div>
        <div>
          Vol. to LLLIL <br /> {localStorage.getItem('AA75')}
        </div>
        <div>
          Vol. to LLIL <br /> {localStorage.getItem('AE75')}
        </div>
        <div>
          Vol. to HLIL <br /> {localStorage.getItem('AI75')}
        </div>
        <div>
          Vol. to HHLIL <br /> {localStorage.getItem('AM75')}
        </div>
        <div>
          D boot
          <br /> {localStorage.getItem('AR75')}
        </div>
        <div>
          H boot <br /> {localStorage.getItem('AV75')}
        </div>
        <div>
          D <br /> {localStorage.getItem('BD75')}
        </div>
        <div>
          L <br /> {localStorage.getItem('BH75')}
        </div>
      </div>
      <div className="flex col-span-4  mt-5 divide-x gap-2  bg-blue-300">
        <div>
          Shell Vol. to LLLL <br /> {localStorage.getItem('D79')}
        </div>
        <div>
          Shell Vol. to LLL <br /> {localStorage.getItem('I79')}
        </div>
        <div>
          Shell Vol. to HLL (m3) <br />{' '}
          {localStorage.getItem('N79')}
        </div>
        <div>
          Shell Vol. to HHLL
          <br /> {localStorage.getItem('S79')}
        </div>
        <div>
          Vol. to LLLL <br /> {localStorage.getItem('AA79')}
        </div>
        <div>
          Vol. to LLL <br /> {localStorage.getItem('AE79')}
        </div>
        <div>
          Vol. to HLL
          <br /> {localStorage.getItem('AI79')}
        </div>
        <div>
          Vol. to HHLL <br /> {localStorage.getItem('AM79')}
        </div>
        <div>
          L1
          <br /> {localStorage.getItem('AR79')}
        </div>
        <div>
          L2
          <br /> {localStorage.getItem('AV79')}
        </div>
        <div>
          L3
          <br /> {localStorage.getItem('AZ79')}
        </div>
        <div>
          L4
          <br /> {localStorage.getItem('BD79')}
        </div>
        <div>
          L/D
          <br /> {localStorage.getItem('BH79')}
        </div>
      </div>
      <div className="flex col-span-4  mt-5 divide-x gap-2  bg-blue-300">
        <div>
          Total Shell Vol. <br /> {localStorage.getItem('D83')}
        </div>
        <div>
          Total Vol.
          <br /> {localStorage.getItem('AA83')}
        </div>
      </div>
    </div>
  );
};

export default Result;
