import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case8_1({info}) {
    return (
        <>
         
         <div className="HHLL-H">
        <label>HHLL </label>
        {toFixed(info.AN71, 0)}
      </div>
      <div className="HLL-H">
        <label>HLL </label>
        {toFixed(info.AK71, 0)}
      </div>
      <div className="NLL-H">
        <label>NLL </label>
        {toFixed(info.AH71, 0)}
      </div>
      <div className="LLL-H">
        <label>LLL </label>
        {toFixed(info.AA71, 0)}
      </div>
      <div className="LLLL-H">
        <label>LLLL </label>
        {toFixed(info.X71, 0)}
      </div>
   
      <div className="L-H-8">
        <label>L </label>
        {toFixed(info.BH75, 3)}
      </div>
      <div className="D-H-8">
        <label>D </label>
        {toFixed(info.BD75, 3)}
      </div>

     
     
      <div className="hv-H-8 text-center">
        <label>H<sub className="align-sub ">v</sub></label>
        <br />
        {toFixed(info.AR71, 0)}
      </div>

        </>)
}

export default Case8_1