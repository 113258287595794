export const event_change_data=(dataName)=>{

    const sendData = { name: dataName };
    const event = new CustomEvent('change_data', { detail: sendData });
    window.dispatchEvent(event);

}
export const event_set_caseStudy=()=>{
    const event = new CustomEvent('case_study');
    window.dispatchEvent(event);

}
export const event_project_modal=(dataName)=>{

    const sendData = { id: dataName };
    const event = new CustomEvent('project_modal', { detail: sendData });
    window.dispatchEvent(event);

}
