import React from 'react';
import ReactDOM from 'react-dom/client';
import {  ConfigProvider } from 'antd';
import './index.css';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  // <React.StrictMode>
     <ConfigProvider
    theme={{
      
      token: {
      
        // Seed Token
        // colorPrimary: '#00b96b',
        // borderRadius: 2,

        // Alias Token
        // colorBgContainer: '#f6ffed',
      },
    }}
  >
      <App />
  </ConfigProvider>
  
  // </React.StrictMode>
);

