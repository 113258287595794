import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { AuthProvider } from './Context/AuthContext';
import {  NotificationProvider } from './Context/NotificationContext';
import { Sizing } from './pages/Sizing/Sizing';
import Result from './pages/Result/Result';
import Register from './pages/Register';
import Tickets from './pages/Tickets/Tickets';
import New from './pages/Tickets/New';
import Ticket from './pages/Tickets/Ticket';
import SizingReport from './pages/Result/SizingReport';
import PrivateRouteHeadLess from './components/PrivateRouteyHeadless';
import Forget from './pages/forgot/Forget';
import Reset from './pages/forgot/Reset';
import Plans from './pages/Plans/Plans';
import { Payment } from './pages/Payment/Payment';
import { Invoices } from './pages/Invoices/Invoices';
import Invoice from './pages/Invoices/Invoice';
import Profile from './pages/Profile/Profile';

const App = () => {
  
 
  return (
    <Router>
    
      <NotificationProvider>
        <AuthProvider>
          <Routes>
          <Route
              path="/profile"
              element={
                <PrivateRoute title="Profile">
                  <Profile  />
                </PrivateRoute>
              }
            />
          <Route
              path="/invoices"
              element={
                <PrivateRoute title="Invoices">
                  <Invoices  />
                </PrivateRoute>
              }
            />
              <Route
              path="/invoice/:oid"
              element={
                // <PrivateRoute title="Invoice">
                  <Invoice  />
                // </PrivateRoute>
              }
            />
          <Route
              path="/payment/:pid"
              element={
                <PrivateRoute title="Payment">
                  <Payment  />
                </PrivateRoute>
              }
            />
           
          <Route
              path="/plans"
              element={
                <PrivateRoute title="Plans">
                  <Plans  />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoute title="Projects">
                  <Home  />
                </PrivateRoute>
              }
            />
              <Route
              path="/sizing/:project_id/:id?"
              element={
                <PrivateRoute title="Projects">
                  <Sizing  />
                </PrivateRoute>
              }
            />
            <Route
              path="/result/:project_id?/:id?"
              
              element={
                <PrivateRoute title="Projects">
                  <Result  />
                </PrivateRoute>
              }
            />
             <Route
              path="/report/:id?"
              
              element={
                <PrivateRouteHeadLess title="Projects">
                  <SizingReport  />
                </PrivateRouteHeadLess>
              }
            />
              <Route
              path="/tickets"
              
              element={
                <PrivateRoute title="Support">
                  <Tickets/>
                </PrivateRoute>
              }
              
            />
              <Route
              path="/tickets/new"
              
              element={
                <PrivateRoute title="Support">
                  <New/>
                </PrivateRoute>
              }
              
            />
             <Route
              path="/tickets/:tid"
              
              element={
                <PrivateRoute title="Support">
                  <Ticket/>
                </PrivateRoute>
              }
              
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
             <Route
              path="/reset/:code"
              element={
                <PublicRoute>
                  <Reset />
                </PublicRoute>
              }
            />
             <Route
              path="/forgot"
              element={
                <PublicRoute>
                  <Forget />
                </PublicRoute>
              }
            />
              <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </NotificationProvider>
    </Router>
  );
};

export default App;
