import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ProjectContext } from '../Context/ProjectContext';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Typography,
} from 'antd';
import { configApi } from '../API/configApi';
import useApiClient from '../Settings/useApiClient';
import { UnitSelect } from './UnitSelect';
import useNotify from '../hooks/useNotify';
import { Link, useParams } from 'react-router-dom';
import { designSpecificApi } from '../API/designSpecificApi';
import { list_of_material, list_of_material2 } from '../assets/consts';
import { makeTable } from '../helpers/makeData';
import { event_set_caseStudy } from '../services/events';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CaseStudySelect } from './CaseStudySelect';

export const DesignSpecification = forwardRef((props, ref) => {
  const { setAnalyzeLoading,  setCaseStudyShow } =
    useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [warning, setWarning] = useState(null);
  const { openNotification } = useNotify();
  const apiClient = useApiClient();
  const {  id } = useParams();
  const [form] = Form.useForm();
  const [designPressure, setDesignPressure] = useState(null);
  const [designTemperature, setDesignTemperature] = useState(null);
  const [corrosionAllowance, setCorrosionAllowance] = useState(null);
  const [jointEfficiency, setJointEfficiency] = useState(null);
  const [materialSpecifications, setMaterialSpecifications] = useState(null);
  const [materialDensity, setMaterialDensity] = useState(null);
  const [allowableStress, setAllowableStress] = useState(null);

  const [historyOptions, sethistoryOptions] = useState([
    { value: 'disabled', label: 'No Data', disabled: true },
  ]);
  const [historyOption, sethistoryOption] = useState();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [step, setStep] = useState(null);
  const [caseStudyId, setCaseStudyId] = useState(null);
  const [revise, setRevise] = useState(false);
  const [listOfMaterial, setListOfMaterial] = useState([]);
  const [minimumWeight, setMinimumWeight] = useState(false);

  useEffect(() => {
    console.log(materialSpecifications)
    if (materialSpecifications) {
      if (!designTemperature) {
        openNotification(
          'Error',
          'First of all set value of Design Temperature.',
          'Error'
        );
        setMaterialSpecifications(null);
        return;
      }
      // if (!materialDensity)
        setMaterialDensity(
          list_of_material2[materialSpecifications - 1]['Density (kg-m3)']
        );
      const keys = Object.keys(list_of_material);
      for (let i = 0; i < keys.length; i++) {
        if (!parseInt(keys[i])) {
          continue;
        }
        if (designTemperature > 900) {
          setWarning(
            "The design temperature is above the material's maximum allowable temperature. Please check with the material supplier."
          );
          break;
        }
        if (parseInt(keys[i]) < designTemperature) {
          continue;
        }
        if (list_of_material2[materialSpecifications - 1][keys[i]] == '') {
          setWarning(
            `material's maximum allowable temperature is ${
              list_of_material2[materialSpecifications - 1][
                'Max. Use Temp., °C'
              ]
            }`
          );
          break;
        }
        // if (!allowableStress)
          setAllowableStress(
            list_of_material2[materialSpecifications - 1][keys[i]]
          );
        if (list_of_material2[materialSpecifications - 1]['Size, mm'])
          setWarning(
            `Material thickness limitation: ${
              list_of_material2[materialSpecifications - 1]['Size, mm']
            }. Please check with the material supplier.`
          );
        else setWarning(null);
        break;
      }
      sethistoryOption(listOfMaterial[1]?.options[materialSpecifications - 1]);
    }
  }, [materialSpecifications]);
  const loadConfig = () => {
    setLoadingInit(true);
    designSpecificApi
      .load(apiClient, id)
      .then((res) => {
        /////////////////////set to storage
        for (const key in res['data']) {
          // if (res['data'].hasOwnProperty(key)) {
          localStorage.setItem(key, res['data'][key]);
          // }
        }
        if (res.dataSource){
          localStorage.setItem('dataSource', res.dataSource.datasource)
        }
        //////////////////check history
        if (res.history) {
          const _histories = [];
          res.history.map((history) => {
            _histories.push(JSON.parse(history.selected));
          });
          sethistoryOptions(_histories);
        }
        if (!res.design) return;
        setDesignPressure(res.design.design_pressure);
        setDesignTemperature(res.design.design_temperature);
        setCorrosionAllowance(res.design.corrosion_allowance);
        setJointEfficiency(res.design.joint_efficiency);
        setMaterialSpecifications(res.design.material_specifications);
      
        setTo(res.design.to);
        setFrom(res.design.from);
        setStep(res.design.step);

        setCaseStudyId(res.design.case_study);
        setRevise(res.design.revise);
        setTimeout(()=>{
          setMaterialDensity(res.design.material_density);
          setAllowableStress(res.design.allowable_stress);
        },500)
      
        setCaseStudyShow(true);
        event_set_caseStudy();
      })
      .catch((e) => {})
      .finally(() => {
        setLoadingInit(false);
      });
  };
  useEffect(() => {
    const _meterials = [
      {
        label: <span>History</span>,
        title: 'History',
        options: historyOptions,
      },
      {
        label: <span>List of Materials</span>,
        title: 'materials',
        options: [],
      },
    ];

    list_of_material2.map((material) => {
      _meterials[1].options.push({
        key:Math.random(),
        value: material['No'],
        label: material['Material'],
      });
    });
    setListOfMaterial(_meterials);
  }, [list_of_material2, historyOptions]);

  useEffect(() => {
    if (id) {
      loadConfig();
    }
  }, [id]);

  const submit = async () => {
    saveForm()
      .then(() => setAnalyzeLoading(1))
      .catch((e) => console.log(e));
  };



  const saveForm = async () => {
    const data = {
      design_pressure: designPressure,
      design_temperature: designTemperature,
      corrosion_allowance: corrosionAllowance,
      joint_efficiency: jointEfficiency,
      material_specifications: materialSpecifications,
      material_density: materialDensity,
      history_option: JSON.stringify(historyOption),
      allowable_stress: allowableStress,
      revise: revise,
      from: parseFloat(from),
      to: parseFloat(to),
      step: parseFloat(step),
      case_study: caseStudyId,
      minimum_weight: minimumWeight,
    };
    setLoading(true);

    await designSpecificApi
      .save(apiClient, id, data)
      .then((res) => {
        openNotification(
          'Success',
          'Desgin Specification saved successfully.',
          200
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };


  if (loadingInit) return <Spin />;
  return (
    <Card style={{}} type="inner" title="Design">
      <Form form={form} onFinish={submit}>
        <div className="p-3">
          <div className="mb-5 ">
            <Typography.Title level={4} className="title-1 mt-5">
              Design specifications
            </Typography.Title>
          </div>
          <div className="grid grid-cols-3 gap-5 gap-y-8 ">
            <div className="grid gap-y-2">
              <Typography.Text className="important   ">
                Design Pressure
              </Typography.Text>
              <Input
                onChange={(e) => setDesignPressure(e.target.value)}
                value={designPressure}
                addonAfter="barg"
                required
                type="number"
                className="input-addon-general focus:border-none focus:outline-none"
              />
            </div>
            <div className="grid gap-y-2 relative">
              <Typography.Text className="important   ">
                Design Temperature
              </Typography.Text>
              <Input
                onChange={(e) => setDesignTemperature(e.target.value)}
                value={designTemperature}
                required
                addonAfter="C"
                type="number"
                className="input-addon-general focus:border-none focus:outline-none"
              />
              {/* <sub className="absolute -bottom-3 text-red-600">
                Beyond maximum allowable temperature (XXX °C)
              </sub> */}
            </div>
            <div className="grid gap-y-2">
              <Typography.Text className="important   ">
                Corrosion Allowance
              </Typography.Text>
              <Input
                onChange={(e) => setCorrosionAllowance(e.target.value)}
                value={corrosionAllowance}
                addonAfter="mm"
                required
                type="number"
                className="input-addon-general focus:border-none focus:outline-none"
              />
            </div>

            <div className="grid gap-y-2">
              <Typography.Text className="important   ">
                Joint Efficiency
              </Typography.Text>
              <Input
                onChange={(e) => setJointEfficiency(e.target.value)}
                value={jointEfficiency}
                required
                addonAfter="%"
                className="input-addon-general focus:border-none focus:outline-none"
              />
            </div>
            <div className="grid gap-y-2"></div>
            <div className="grid col-span-2 relative">
              <Typography.Text className=" ">
                Material Specifications
              </Typography.Text>
              <Select
                loading={loading}
                placeholder={'Select'}
                value={materialSpecifications}
                onChange={setMaterialSpecifications}
                options={listOfMaterial}
              />
              <sub className="absolute -bottom-3 text-red-600">{warning}</sub>
            </div>
            <div className="grid gap-y-2"></div>
            <div className="grid gap-y-2">
              <Typography.Text className="important   ">
                Material density
              </Typography.Text>
              <Input
                placeholder="Type"
                required
                onChange={(e) => setMaterialDensity(e.target.value)}
                value={materialDensity}
                addonAfter="MPa"
                className="input-addon-general focus:border-none focus:outline-none"
              />
            </div>
            <div className="grid gap-y-2">
              <Typography.Text className="important   ">
                Allowable Stress
              </Typography.Text>
              <Input
                onChange={(e) => setAllowableStress(e.target.value)}
                value={allowableStress}
                placeholder="Type"
                required
                addonAfter="MPa"
                className="input-addon-general focus:border-none focus:outline-none"
              />
            </div>
            <div className="grid gap-y-2"></div>
          </div>
          <div className="mb-5 ">
            <Typography.Title level={4} className="title-1 mt-5">
              L/D Range & Increment
            </Typography.Title>
          </div>
          <div className="grid grid-cols-3 gap-3  items-center justify-center ">
            <div className="flex items-center  justify-between gap-3">
              <Input
                className="input-general w-auto ms-2"
                onChange={(e) => setFrom(e.target.value)}
                value={from}
              />

              <span>TO</span>
              <Input
                className="input-general w-auto ms-2"
                onChange={(e) => setTo(e.target.value)}
                value={to}
              />
            </div>
            <div className="flex items-center ">
              <Input
                placeholder="Steps"
                className="input-general w-80 ms-2"
                onChange={(e) => setStep(e.target.value)}
                value={step}
              />
            </div>
            <div className="flex items-end justify-center ">
              <Button
                htmlType="submit"
                disabled={loading}
                loading={loading}
                className="  rounded-md  text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8 py-4 me-2 "
              >
                Analyze
              </Button>
            </div>
          </div>
          <div className="flex items-center gap-3 mb-3 mt-4">
            <Checkbox
              checked={revise}
              onChange={(e) => setRevise(e.target.checked)}
            >
              Revise the diameter if needed to allow for sufficient Hv.
            </Checkbox>
          </div>
          
          <CaseStudySelect  setCaseStudyId={setCaseStudyId} setMinimumWeight={setMinimumWeight} />
        </div>
        <div className="w-full flex text-center items-center gap-2 justify-center mt-5 pt-5">
          <Link
            target="_blank"
            to={`/report/${id}`}
            className=" h-11 grid items-center   text-black border rounded-sm font-medium  text-sm px-8 "
          >
            Veiw Report
          </Link>
          <Button
            htmlType="button"
            onClick={saveForm}
            disabled={loading}
            loading={loading}
            className=" h-11 rounded-md text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8  "
          >
            Save
          </Button>
        </div>
      </Form>
    </Card>
  );
});
