import React, { useContext, useEffect, useState } from 'react';
import { SettingContext } from '../Context/SettingsContext';
import LabelProject from './LabelProject';
import { HomeContext } from '../Context/HomeContext';

const Sidebar = ({ projects,loadSizings }) => {
  const context = useContext(SettingContext);
  const {setProjectIdActive}=useContext(HomeContext)

   const [projectActive,setProjectActive]=useState(0)
   useEffect(()=>{
    setProjectIdActive(projectActive)
   },[projectActive])
  return (
    <>
      {projects ? (
        <aside
          // id="logo-sidebar"
          className={`fixed top-24 left-0 z-10 w-64 h-screen pt-20 transition-transform -translate-x-full   sm:translate-x-0  ${
            context.fullWidth ? 'hidden' : null
          } dark:bg-gray-800 dark:border-gray-700`}
          aria-label="Sidebar"
        >
          <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800 border-r border-gray-200 ">
            <ul className="space-y-2 font-medium divide-y divide-slate-200">
               <LabelProject project={{name:'Recents',id:0}} loadSizings={loadSizings} projectActive={projectActive} setProjectActive={setProjectActive}  />  
               {projects?.map(project=> <LabelProject key={project.id} project={project} loadSizings={loadSizings} projectActive={projectActive} setProjectActive={setProjectActive} />      )}
            </ul>
          </div>
        </aside>
      ) : null}
    </>
  );
};
export default Sidebar;
