// src/pages/Login.js
import React, { useContext, useRef, useState } from 'react';
import { AuthApi } from '../API/AuthApi';
import { ParsError } from '../Tools/ParsError';
import { Button } from 'antd';
import useAuth from '../hooks/useAuth';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false);
  const {openNotification}=useNotify()
  const form=useRef();
  const apiClient = useApiClient();
  const { saveToken } = useAuth();
  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    AuthApi.login(apiClient, { email, password })
      .then((res) => {
        const { token, refreshToken,units,profile } =res;
        saveToken(token, refreshToken, units,profile);
      document.location.reload();
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response));
      })
      .finally(() => setLoading(false));
  };
  const togglePass=()=>{
    setShowPass(!showPass)
  }
  function handleKeyUp(event) {
    // Enter
    if (event.keyCode === 13) {
      form.current.click();
    }
  }
  return (
    <div className="grid lg:grid-cols-2 h-screen gap-10 bg-red-50 p-5">
    <div className="lg:col-span-1 grid content-center rounded-3xl space-y-12 bg-white p-10 lg:p-14 m-5 lg:m-20">
      <img src="/images/logo.svg" alt="omnisep" className="m-auto block" />
      <a className="!text-[#386B91] text-center text-[18px] gap-2 items-center flex justify-center font-semibold !mt-5" href="https://omnisep.co.uk/">
        <img src="images/arrow-left.svg" alt="Back Arrow" />
        Back to homepage
      </a>
      <h1 className="text-3xl text-center font-bold">Login</h1>
      <form className="w-full p-5" onSubmit={handleLogin} onKeyUp={handleKeyUp} tabIndex={0}>
        <div className="mb-8">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="mb-8 flex items-center relative">
          <input
            type={showPass ? "text" : "password"}
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
          <button type="button" id="togglePassword" onClick={togglePass} className="absolute right-4 top-1/2 transform -translate-y-1/2">
            <img src={showPass ? "/images/eyeOpen.svg" : "/images/eyeClose.svg"} alt="Toggle Password" className="w-4" />
          </button>
        </div>
        <Button
          ref={form}
          loading={loading}
          onClick={handleLogin}
          className="text-white h-14 mb-8 bg-brown hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg rounded-lg w-full px-5 py-2.5 text-center"
        >
          Log In
        </Button>
        <p className="text-center">
          Don’t have an account? <Link to="/register"><span className="text-brown font-semibold">Register</span></Link>
        </p>
        <Link to="/forgot">
          <p className="text-center text-brown">Forgot Password?</p>
        </Link>
      </form>
    </div>
    <div className="hidden lg:block bg-gray-100"></div>
  </div>
  );
};

export default Login;
