// src/pages/Login.js
import React, { useContext, useState } from 'react';
import { AuthApi } from '../../API/AuthApi';
import { ParsError } from '../../Tools/ParsError';
import { Button } from 'antd';
import useAuth from '../../hooks/useAuth';
import useApiClient from '../../Settings/useApiClient';
import useNotify from '../../hooks/useNotify';
import { Link } from 'react-router-dom';

const Forget = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const {openNotification}=useNotify()
  const apiClient = useApiClient();

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    AuthApi.forget(apiClient, { email })
      .then((res) => {
        openNotification(
          'Success',
          res.message,
          200
        );
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          openNotification('Error', ParsError(error?.response.data));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="grid lg:grid-cols-2 h-lvh gap-10 bg-red-50 p-5 w-full">
      <div className="lg:col-span grid content-center  rounded-3xl   space-y-12 bg-white p-14 m-20 me-0 ms-40">
        <img src="/images/logo.svg" alt="omnisep"  className='m-auto block'/>
        <a className='!text-[#386B91] text-center text-[18px] text-center items-center flex justify-self-center font-semibold !mt-5' href='/'>
          <img src="images/arrow-left.svg" />
          Back to Login</a>
        <h1 className='text-3xl m-auto block font-bold'>Forgot Password</h1>
        <form className="w-100 p-10 " onSubmit={handleLogin}>
          <div className="mb-8">
            {/* <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label> */}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Enter your email"
              required
            />
          </div>
          
          <Button
            loading={loading}
            onClick={handleLogin}
            className="text-white h-14 mb-8 bg-brown hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-lg rounded-lg  w-full px-5 py-2.5 text-center "
          >
            Send 
          </Button>
        </form>
      </div>
      <div className="col-span-2 lg:col-span-1 bg-gray-100"></div>
    </div>
  );
};

export default Forget;
