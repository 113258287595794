import Global from '../Settings/config.json';
import useApiClient from '../Settings/useApiClient';
export const AuthApi = {
  async login(apiClient, data) {
    let res = [];
    await apiClient.post(`${Global.SERVER}login`, data).then((json) => {
      switch (json.status) {
        case 200:
          res = json.data;
          break;
      }
    });
    return res;
  },
  async reset(apiClient, data) {
    let res = [];
    await apiClient.post(`${Global.SERVER}password/code/check`, data).then((json) => {
      switch (json.status) {
        case 200:
          res = json.data;
          break;
      }
    });
    return res;
  },
  async forget(apiClient, data) {
    let res = [];
    await apiClient.post(`${Global.SERVER}password/email  `, data).then((json) => {
      switch (json.status) {
        case 200:
          res = json.data;
          break;
      }
    });
    return res;
  },
  async register(apiClient, data) {
    let res = [];
    await apiClient.post(`${Global.SERVER}register`, data).then((json) => {
      switch (json.status) {
        case 200:
        case 201:
          res = json.data;
          break;
      }
    });
    return res;
  },
  async register2(apiClient, data) {
    let res = [];
    await apiClient.post(`${Global.SERVER}register2`, data).then((json) => {
      switch (json.status) {
        case 200:
        case 201:
          res = json.data;
          break;
      }
    });
    return res;
  },
  async resend(apiClient, email) {
    let res = [];
    await apiClient.post(`${Global.SERVER}email/verify/resend`, {email:email}).then((json) => {
      switch (json.status) {
        case 201:
          res = json.data;
          break;
      }
    });
    return res;
  },
};
