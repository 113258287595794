export const sizes = [
  { value: '1-99', label: '1-99 employees' },
  { value: '100', label: 'Up to 100 employees' },
  { value: '100', label: '100-999 employees' },
  { value: '1000', label: '1,000+ employees' },
];
export const industries=[
    { value: 'it', label: 'Information Technology' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'construction', label: 'Construction' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'financial', label: 'Financial Services' },
    { value: 'energy', label: 'Energy' },
    { value: 'education', label: 'Education' },
    { value: 'transportation', label: 'Transportation and Logistics' },
    { value: 'retail', label: 'Retail' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'telecommunications', label: 'Telecommunications' },
    { value: 'public_Service', label: 'Public Service' },
    { value: 'environmental_sustainability', label: 'Environmental and Sustainability' },
]
export const list_of_material2 = [
  {
    No: 1,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A285',
    'Type-Grade': 'A',
    'UNS No.': 'K01700',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A285 | A | K01700 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'B',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 103,
    65: '103.00',
    100: '101.00',
    125: '97.50',
    150: '97.50',
    175: '94.60',
    200: '94.60',
    225: '90.80',
    250: '90.80',
    275: '86.10',
    300: '86.10',
    325: '83.60',
    350: '81.10',
    375: '78.60',
    400: '73.30',
    425: '64.00',
    450: '55.80',
    475: '43.90',
    500: '31.70',
    525: '21.40',
    550: '14.20',
    575: '9.40',
    600: '6.89',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 2,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A285',
    'Type-Grade': 'B',
    'UNS No.': 'K02200',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A285 | B | K02200 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'B',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 115,
    65: '115.00',
    100: '113.00',
    125: '110.00',
    150: '110.00',
    175: '106.00',
    200: '106.00',
    225: '102.00',
    250: '102.00',
    275: '96.90',
    300: '96.90',
    325: '94.10',
    350: '91.20',
    375: '84.30',
    400: '73.30',
    425: '64.00',
    450: '55.80',
    475: '43.90',
    500: '31.70',
    525: '21.40',
    550: '14.20',
    575: '9.40',
    600: '6.89',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 3,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A516',
    'Type-Grade': '55',
    'UNS No.': 'K01800',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A516 | 55 | K01800 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'C',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 126,
    65: '126.00',
    100: '126.00',
    125: '122.00',
    150: '122.00',
    175: '118.00',
    200: '118.00',
    225: '113.00',
    250: '113.00',
    275: '108.00',
    300: '108.00',
    325: '105.00',
    350: '101.00',
    375: '98.30',
    400: '89.10',
    425: '75.40',
    450: '62.60',
    475: '45.50',
    500: '31.60',
    525: '21.90',
    550: '12.70',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 4,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A285',
    'Type-Grade': 'C',
    'UNS No.': 'K02801',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A285 | C | K02801 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 126,
    65: '126.00',
    100: '126.00',
    125: '122.00',
    150: '122.00',
    175: '118.00',
    200: '118.00',
    225: '113.00',
    250: '113.00',
    275: '108.00',
    300: '108.00',
    325: '105.00',
    350: '101.00',
    375: '98.30',
    400: '89.00',
    425: '75.30',
    450: '62.10',
    475: '45.00',
    500: '31.70',
    525: '21.40',
    550: '14.20',
    575: '9.40',
    600: '6.89',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 5,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A516',
    'Type-Grade': '60',
    'UNS No.': 'K02100',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A516 | 60 | K02100 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'C',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '134.00',
    125: '130.00',
    150: '130.00',
    175: '126.00',
    200: '126.00',
    225: '121.00',
    250: '121.00',
    275: '115.00',
    300: '115.00',
    325: '111.00',
    350: '108.00',
    375: '105.00',
    400: '88.90',
    425: '75.30',
    450: '62.70',
    475: '45.50',
    500: '31.60',
    525: '21.90',
    550: '12.70',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 6,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A515',
    'Type-Grade': '60',
    'UNS No.': 'K02401',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A515 | 60 | K02401 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'B',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '134.00',
    125: '130.00',
    150: '130.00',
    175: '126.00',
    200: '126.00',
    225: '121.00',
    250: '121.00',
    275: '115.00',
    300: '115.00',
    325: '111.00',
    350: '108.00',
    375: '105.00',
    400: '88.90',
    425: '75.30',
    450: '62.70',
    475: '45.50',
    500: '31.60',
    525: '21.90',
    550: '12.70',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 7,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A696',
    'Type-Grade': 'B',
    'UNS No.': 'K03200',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A696 | B | K03200 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 371,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '132.00',
    250: '132.00',
    275: '125.00',
    300: '125.00',
    325: '122.00',
    350: '118.00',
    375: '115.00',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 8,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A516',
    'Type-Grade': '65',
    'UNS No.': 'K02403',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A516 | 65 | K02403 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'B',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 149,
    65: '149.00',
    100: '147.00',
    125: '142.00',
    150: '142.00',
    175: '138.00',
    200: '138.00',
    225: '132.00',
    250: '132.00',
    275: '126.00',
    300: '126.00',
    325: '122.00',
    350: '118.00',
    375: '113.00',
    400: '95.00',
    425: '79.60',
    450: '63.20',
    475: '45.30',
    500: '31.70',
    525: '21.90',
    550: '12.70',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 9,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A515',
    'Type-Grade': '65',
    'UNS No.': 'K02800',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A515 | 65 | K02800 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 149,
    65: '149.00',
    100: '147.00',
    125: '142.00',
    150: '142.00',
    175: '138.00',
    200: '138.00',
    225: '132.00',
    250: '132.00',
    275: '126.00',
    300: '126.00',
    325: '122.00',
    350: '118.00',
    375: '113.00',
    400: '95.00',
    425: '79.60',
    450: '63.20',
    475: '45.30',
    500: '31.70',
    525: '21.90',
    550: '12.70',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 10,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A516',
    'Type-Grade': '70',
    'UNS No.': 'K02700',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A516 | 70 | K02700 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'B',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '159.00',
    125: '154.00',
    150: '154.00',
    175: '150.00',
    200: '150.00',
    225: '144.00',
    250: '144.00',
    275: '136.00',
    300: '136.00',
    325: '132.00',
    350: '128.00',
    375: '122.00',
    400: '101.00',
    425: '83.80',
    450: '67.10',
    475: '51.00',
    500: '33.60',
    525: '21.30',
    550: '12.90',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 11,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A515',
    'Type-Grade': '70',
    'UNS No.': 'K03101',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A515 | 70 | K03101 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '159.00',
    125: '154.00',
    150: '154.00',
    175: '150.00',
    200: '150.00',
    225: '144.00',
    250: '144.00',
    275: '136.00',
    300: '136.00',
    325: '132.00',
    350: '128.00',
    375: '122.00',
    400: '101.00',
    425: '83.80',
    450: '67.10',
    475: '51.00',
    500: '33.60',
    525: '21.30',
    550: '12.90',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 12,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A696',
    'Type-Grade': 'C',
    'UNS No.': 'K03200',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A696 | C | K03200 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 371,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '158.00',
    200: '158.00',
    225: '151.00',
    250: '151.00',
    275: '144.00',
    300: '144.00',
    325: '139.00',
    350: '135.00',
    375: '131.00',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 13,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A537',
    'Type-Grade': '…',
    'UNS No.': 'K12437',
    'Class-Cond.-Temper': '1',
    Material: 'Carbon steel | A537 | … | K12437 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '≤64',
    'Min. Temp., °C': 'D',
    'Max. Use Temp., °C': 371,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '157.00',
    150: '157.00',
    175: '156.00',
    200: '156.00',
    225: '156.00',
    250: '156.00',
    275: '156.00',
    300: '156.00',
    325: '154.00',
    350: '148.00',
    375: '126.00',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 14,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A299',
    'Type-Grade': 'A',
    'UNS No.': 'K02803',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A299 | A | K02803 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '>25',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '168.00',
    125: '163.00',
    150: '163.00',
    175: '158.00',
    200: '158.00',
    225: '151.00',
    250: '151.00',
    275: '144.00',
    300: '144.00',
    325: '139.00',
    350: '135.00',
    375: '131.00',
    400: '107.00',
    425: '88.00',
    450: '67.30',
    475: '50.30',
    500: '33.20',
    525: '21.40',
    550: '14.20',
    575: '9.40',
    600: '6.89',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 15,
    'Nominal Composition': 'Carbon steel',
    'Product Form': 'Plate, bar, shps., sheet',
    'Spec. No.': 'A299',
    'Type-Grade': 'A',
    'UNS No.': 'K02803',
    'Class-Cond.-Temper': '…',
    Material: 'Carbon steel | A299 | A | K02803 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '≤25',
    'Min. Temp., °C': 'A',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '172.00',
    125: '171.00',
    150: '171.00',
    175: '165.00',
    200: '165.00',
    225: '159.00',
    250: '159.00',
    275: '151.00',
    300: '151.00',
    325: '146.00',
    350: '142.00',
    375: '131.00',
    400: '107.00',
    425: '88.00',
    450: '67.30',
    475: '50.30',
    500: '33.20',
    525: '21.40',
    550: '14.20',
    575: '9.40',
    600: '6.89',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 16,
    'Nominal Composition': '0.5 Cr–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '2',
    'UNS No.': 'K12143',
    'Class-Cond.-Temper': '1',
    Material: '0.5 Cr–0.5 Mo | A387 | 2 | K12143 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 126,
    65: '126.00',
    100: '126.00',
    125: '126.00',
    150: '126.00',
    175: '126.00',
    200: '126.00',
    225: '126.00',
    250: '126.00',
    275: '126.00',
    300: '125.00',
    325: '123.00',
    350: '121.00',
    375: '119.00',
    400: '116.00',
    425: '114.00',
    450: '110.00',
    475: '107.00',
    500: '74.40',
    525: '49.90',
    550: '40.70',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 17,
    'Nominal Composition': '1Cr–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '12',
    'UNS No.': 'K11757',
    'Class-Cond.-Temper': '1',
    Material: '1Cr–0.5 Mo | A387 | 12 | K11757 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 126,
    65: '126.00',
    100: '123.00',
    125: '122.00',
    150: '122.00',
    175: '122.00',
    200: '122.00',
    225: '121.00',
    250: '120.00',
    275: '118.00',
    300: '116.00',
    325: '115.00',
    350: '114.00',
    375: '112.00',
    400: '110.00',
    425: '109.00',
    450: '106.00',
    475: '104.00',
    500: '92.10',
    525: '61.10',
    550: '40.40',
    575: '26.40',
    600: '17.40',
    625: '11.60',
    650: '7.58',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 18,
    'Nominal Composition': '9Cr–1Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '9',
    'UNS No.': 'K90941',
    'Class-Cond.-Temper': '1',
    Material: '9Cr–1Mo | A387 | 9 | K90941 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '129.00',
    100: '124.00',
    125: '122.00',
    150: '120.00',
    175: '119.00',
    200: '119.00',
    225: '118.00',
    250: '118.00',
    275: '117.00',
    300: '117.00',
    325: '116.00',
    350: '114.00',
    375: '112.00',
    400: '110.00',
    425: '106.00',
    450: '103.00',
    475: '98.30',
    500: '83.20',
    525: '60.20',
    550: '42.90',
    575: '29.90',
    600: '20.60',
    625: '14.40',
    650: '10.30',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 19,
    'Nominal Composition': '1.25 Cr–0.5 Mo–Si',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '11',
    'UNS No.': 'K11789',
    'Class-Cond.-Temper': '1',
    Material: '1.25 Cr–0.5 Mo–Si | A387 | 11 | K11789 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '136.00',
    225: '134.00',
    250: '131.00',
    275: '129.00',
    300: '127.00',
    325: '125.00',
    350: '123.00',
    375: '121.00',
    400: '119.00',
    425: '116.00',
    450: '113.00',
    475: '104.00',
    500: '73.70',
    525: '52.00',
    550: '36.30',
    575: '25.20',
    600: '17.60',
    625: '12.30',
    650: '8.27',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 20,
    'Nominal Composition': '5Cr–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '5',
    'UNS No.': 'K41545',
    'Class-Cond.-Temper': '1',
    Material: '5Cr–0.5 Mo | A387 | 5 | K41545 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '129.00',
    100: '124.00',
    125: '122.00',
    150: '120.00',
    175: '119.00',
    200: '119.00',
    225: '118.00',
    250: '118.00',
    275: '117.00',
    300: '117.00',
    325: '116.00',
    350: '114.00',
    375: '112.00',
    400: '110.00',
    425: '106.00',
    450: '103.00',
    475: '80.60',
    500: '61.70',
    525: '46.40',
    550: '34.70',
    575: '25.50',
    600: '17.80',
    625: '11.40',
    650: '6.89',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 21,
    'Nominal Composition': '3Cr–1Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '21',
    'UNS No.': 'K31545',
    'Class-Cond.-Temper': '1',
    Material: '3Cr–1Mo | A387 | 21 | K31545 | 1',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '130.00',
    100: '126.00',
    125: '123.00',
    150: '121.00',
    175: '119.00',
    200: '117.00',
    225: '116.00',
    250: '115.00',
    275: '114.00',
    300: '112.00',
    325: '111.00',
    350: '110.00',
    375: '109.00',
    400: '107.00',
    425: '105.00',
    450: '103.00',
    475: '89.20',
    500: '68.80',
    525: '54.20',
    550: '43.40',
    575: '34.00',
    600: '25.10',
    625: '17.10',
    650: '10.30',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 22,
    'Nominal Composition': '2.25 Cr–1Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '22',
    'UNS No.': 'K21590',
    'Class-Cond.-Temper': '1',
    Material: '2.25 Cr–1Mo | A387 | 22 | K21590 | 1',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '132.00',
    100: '128.00',
    125: '126.00',
    150: '125.00',
    175: '124.00',
    200: '124.00',
    225: '124.00',
    250: '124.00',
    275: '124.00',
    300: '124.00',
    325: '124.00',
    350: '124.00',
    375: '124.00',
    400: '123.00',
    425: '122.00',
    450: '121.00',
    475: '99.60',
    500: '80.90',
    525: '63.30',
    550: '47.50',
    575: '34.20',
    600: '23.50',
    625: '15.30',
    650: '9.65',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 23,
    'Nominal Composition': '2.25 Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A203',
    'Type-Grade': 'A',
    'UNS No.': 'K21703',
    'Class-Cond.-Temper': '…',
    Material: '2.25 Ni | A203 | A | K21703 | …',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 149,
    65: '149.00',
    100: '149.00',
    125: '149.00',
    150: '149.00',
    175: '148.00',
    200: '146.00',
    225: '143.00',
    250: '140.00',
    275: '136.00',
    300: '131.00',
    325: '126.00',
    350: '120.00',
    375: '113.00',
    400: '95.10',
    425: '79.50',
    450: '64.40',
    475: '48.80',
    500: '35.40',
    525: '22.60',
    550: '17.20',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 24,
    'Nominal Composition': '3.5 Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A203',
    'Type-Grade': 'D',
    'UNS No.': 'K31718',
    'Class-Cond.-Temper': '…',
    Material: '3.5 Ni | A203 | D | K31718 | …',
    'Density (kg-m3)': '7850',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 149,
    65: '149.00',
    100: '149.00',
    125: '149.00',
    150: '149.00',
    175: '148.00',
    200: '146.00',
    225: '143.00',
    250: '140.00',
    275: '136.00',
    300: '131.00',
    325: '126.00',
    350: '120.00',
    375: '113.00',
    400: '95.10',
    425: '79.50',
    450: '64.40',
    475: '48.80',
    500: '35.40',
    525: '22.60',
    550: '17.20',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 25,
    'Nominal Composition': 'C–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A204',
    'Type-Grade': 'A',
    'UNS No.': 'K11820',
    'Class-Cond.-Temper': '…',
    Material: 'C–0.5 Mo | A204 | A | K11820 | …',
    'Density (kg-m3)': '7850',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 149,
    65: '149.00',
    100: '149.00',
    125: '149.00',
    150: '149.00',
    175: '149.00',
    200: '148.00',
    225: '146.00',
    250: '144.00',
    275: '142.00',
    300: '140.00',
    325: '138.00',
    350: '136.00',
    375: '133.00',
    400: '131.00',
    425: '127.00',
    450: '124.00',
    475: '109.00',
    500: '68.00',
    525: '42.30',
    550: '30.50',
    575: '23.20',
    600: '16.50',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 26,
    'Nominal Composition': '1Cr–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '12',
    'UNS No.': 'K11757',
    'Class-Cond.-Temper': '2',
    Material: '1Cr–0.5 Mo | A387 | 12 | K11757 | 2',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 149,
    65: '149.00',
    100: '146.00',
    125: '144.00',
    150: '144.00',
    175: '144.00',
    200: '144.00',
    225: '144.00',
    250: '144.00',
    275: '143.00',
    300: '141.00',
    325: '139.00',
    350: '138.00',
    375: '136.00',
    400: '134.00',
    425: '132.00',
    450: '129.00',
    475: '126.00',
    500: '92.10',
    525: '61.10',
    550: '40.40',
    575: '26.40',
    600: '17.40',
    625: '11.60',
    650: '7.58',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 27,
    'Nominal Composition': '2.25 Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A203',
    'Type-Grade': 'B',
    'UNS No.': 'K22103',
    'Class-Cond.-Temper': '…',
    Material: '2.25 Ni | A203 | B | K22103 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '160.00',
    200: '158.00',
    225: '155.00',
    250: '151.00',
    275: '147.00',
    300: '142.00',
    325: '136.00',
    350: '130.00',
    375: '113.00',
    400: '95.10',
    425: '79.50',
    450: '64.40',
    475: '48.80',
    500: '35.40',
    525: '22.60',
    550: '17.20',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 28,
    'Nominal Composition': '3.5 Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A203',
    'Type-Grade': 'E',
    'UNS No.': 'K32018',
    'Class-Cond.-Temper': '…',
    Material: '3.5 Ni | A203 | E | K32018 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29.00',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '160.00',
    200: '158.00',
    225: '155.00',
    250: '151.00',
    275: '147.00',
    300: '142.00',
    325: '136.00',
    350: '130.00',
    375: '122.00',
    400: '101.00',
    425: '83.80',
    450: '66.80',
    475: '49.20',
    500: '35.40',
    525: '22.60',
    550: '17.20',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 29,
    'Nominal Composition': '0.5 Cr–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '2',
    'UNS No.': 'K12143',
    'Class-Cond.-Temper': '2',
    Material: '0.5 Cr–0.5 Mo | A387 | 2 | K12143 | 2',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '161.00',
    275: '161.00',
    300: '161.00',
    325: '161.00',
    350: '161.00',
    375: '161.00',
    400: '159.00',
    425: '155.00',
    450: '151.00',
    475: '146.00',
    500: '93.50',
    525: '49.90',
    550: '40.70',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 30,
    'Nominal Composition': 'C–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A204',
    'Type-Grade': 'B',
    'UNS No.': 'K12020',
    'Class-Cond.-Temper': '…',
    Material: 'C–0.5 Mo | A204 | B | K12020 | …',
    'Density (kg-m3)': '7850',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '158.00',
    250: '156.00',
    275: '154.00',
    300: '152.00',
    325: '149.00',
    350: '147.00',
    375: '144.00',
    400: '141.00',
    425: '138.00',
    450: '134.00',
    475: '109.00',
    500: '68.00',
    525: '42.30',
    550: '30.50',
    575: '23.20',
    600: '16.50',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 31,
    'Nominal Composition': 'Mn–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A302',
    'Type-Grade': 'A',
    'UNS No.': 'K12021',
    'Class-Cond.-Temper': '…',
    Material: 'Mn–0.5 Mo | A302 | A | K12021 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '172.00',
    125: '172.00',
    150: '172.00',
    175: '172.00',
    200: '172.00',
    225: '172.00',
    250: '172.00',
    275: '172.00',
    300: '172.00',
    325: '172.00',
    350: '171.00',
    375: '168.00',
    400: '165.00',
    425: '160.00',
    450: '154.00',
    475: '104.00',
    500: '68.00',
    525: '42.30',
    550: '33.10',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 32,
    'Nominal Composition': 'C–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A204',
    'Type-Grade': 'C',
    'UNS No.': 'K12320',
    'Class-Cond.-Temper': '…',
    Material: 'C–0.5 Mo | A204 | C | K12320 | …',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 593,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '172.00',
    125: '172.00',
    150: '172.00',
    175: '172.00',
    200: '172.00',
    225: '170.00',
    250: '168.00',
    275: '165.00',
    300: '163.00',
    325: '161.00',
    350: '158.00',
    375: '155.00',
    400: '152.00',
    425: '148.00',
    450: '144.00',
    475: '109.00',
    500: '68.00',
    525: '42.30',
    550: '30.50',
    575: '23.20',
    600: '16.50',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 33,
    'Nominal Composition': '1.25 Cr–0.5 Mo–Si',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '11',
    'UNS No.': 'K11789',
    'Class-Cond.-Temper': '2',
    Material: '1.25 Cr–0.5 Mo–Si | A387 | 11 | K11789 | 2',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '172.00',
    125: '172.00',
    150: '172.00',
    175: '172.00',
    200: '172.00',
    225: '172.00',
    250: '169.00',
    275: '166.00',
    300: '164.00',
    325: '161.00',
    350: '159.00',
    375: '156.00',
    400: '153.00',
    425: '149.00',
    450: '146.00',
    475: '104.00',
    500: '73.70',
    525: '52.00',
    550: '36.30',
    575: '25.20',
    600: '17.60',
    625: '12.30',
    650: '8.27',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 34,
    'Nominal Composition': '5Cr–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '5',
    'UNS No.': 'K41545',
    'Class-Cond.-Temper': '2',
    Material: '5Cr–0.5 Mo | A387 | 5 | K41545 | 2',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '171.00',
    125: '169.00',
    150: '167.00',
    175: '166.00',
    200: '165.00',
    225: '165.00',
    250: '165.00',
    275: '164.00',
    300: '164.00',
    325: '162.00',
    350: '159.00',
    375: '156.00',
    400: '130.00',
    425: '126.00',
    450: '104.00',
    475: '80.60',
    500: '61.70',
    525: '46.40',
    550: '34.70',
    575: '25.50',
    600: '17.80',
    625: '11.40',
    650: '6.89',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 35,
    'Nominal Composition': '3Cr–1Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '21',
    'UNS No.': 'K31545',
    'Class-Cond.-Temper': '2',
    Material: '3Cr–1Mo | A387 | 21 | K31545 | 2',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '171.00',
    125: '168.00',
    150: '167.00',
    175: '167.00',
    200: '167.00',
    225: '167.00',
    250: '167.00',
    275: '167.00',
    300: '167.00',
    325: '167.00',
    350: '167.00',
    375: '167.00',
    400: '167.00',
    425: '167.00',
    450: '167.00',
    475: '98.20',
    500: '73.50',
    525: '54.70',
    550: '40.60',
    575: '29.20',
    600: '20.60',
    625: '15.20',
    650: '8.96',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 36,
    'Nominal Composition': '2.25 Cr–1Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '22',
    'UNS No.': 'K21590',
    'Class-Cond.-Temper': '2',
    Material: '2.25 Cr–1Mo | A387 | 22 | K21590 | 2',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '171.00',
    125: '168.00',
    150: '167.00',
    175: '167.00',
    200: '167.00',
    225: '167.00',
    250: '167.00',
    275: '167.00',
    300: '167.00',
    325: '167.00',
    350: '167.00',
    375: '167.00',
    400: '167.00',
    425: '167.00',
    450: '167.00',
    475: '119.00',
    500: '88.40',
    525: '64.00',
    550: '44.60',
    575: '30.00',
    600: '19.70',
    625: '12.80',
    650: '8.27',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 37,
    'Nominal Composition': 'Mn–0.5 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A302',
    'Type-Grade': 'B',
    'UNS No.': 'K12022',
    'Class-Cond.-Temper': '…',
    Material: 'Mn–0.5 Mo | A302 | B | K12022 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 184,
    65: '184.00',
    100: '184.00',
    125: '184.00',
    150: '184.00',
    175: '184.00',
    200: '184.00',
    225: '184.00',
    250: '184.00',
    275: '184.00',
    300: '184.00',
    325: '184.00',
    350: '184.00',
    375: '184.00',
    400: '183.00',
    425: '178.00',
    450: '172.00',
    475: '104.00',
    500: '68.00',
    525: '42.30',
    550: '33.10',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 38,
    'Nominal Composition': 'Mn–0.5 Mo–0.5 Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A302',
    'Type-Grade': 'C',
    'UNS No.': 'K12039',
    'Class-Cond.-Temper': '…',
    Material: 'Mn–0.5 Mo–0.5 Ni | A302 | C | K12039 | …',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 184,
    65: '184.00',
    100: '184.00',
    125: '184.00',
    150: '184.00',
    175: '184.00',
    200: '184.00',
    225: '184.00',
    250: '184.00',
    275: '184.00',
    300: '184.00',
    325: '184.00',
    350: '184.00',
    375: '184.00',
    400: '183.00',
    425: '178.00',
    450: '172.00',
    475: '104.00',
    500: '68.00',
    525: '42.30',
    550: '33.10',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 39,
    'Nominal Composition': 'Mn–0.5 Mo–0.75 Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A302',
    'Type-Grade': 'D',
    'UNS No.': 'K12054',
    'Class-Cond.-Temper': '…',
    Material: 'Mn–0.5 Mo–0.75 Ni | A302 | D | K12054 | …',
    'Density (kg-m3)': '7850',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 184,
    65: '184.00',
    100: '184.00',
    125: '184.00',
    150: '184.00',
    175: '184.00',
    200: '184.00',
    225: '184.00',
    250: '184.00',
    275: '184.00',
    300: '184.00',
    325: '184.00',
    350: '184.00',
    375: '184.00',
    400: '183.00',
    425: '178.00',
    450: '172.00',
    475: '104.00',
    500: '68.00',
    525: '42.30',
    550: '33.10',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 40,
    'Nominal Composition': '9Cr–1Mo–V',
    'Product Form': 'Plate',
    'Spec. No.': 'A387',
    'Type-Grade': '91',
    'UNS No.': 'K90901',
    'Class-Cond.-Temper': '2',
    Material: '9Cr–1Mo–V | A387 | 91 | K90901 | 2',
    'Density (kg-m3)': '7800',
    'Size, mm': '≤75',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 195,
    65: '195.00',
    100: '195.00',
    125: '195.00',
    150: '195.00',
    175: '195.00',
    200: '195.00',
    225: '195.00',
    250: '194.00',
    275: '193.00',
    300: '192.00',
    325: '190.00',
    350: '187.00',
    375: '183.00',
    400: '178.00',
    425: '172.00',
    450: '165.00',
    475: '156.00',
    500: '147.00',
    525: '137.00',
    550: '115.00',
    575: '87.00',
    600: '64.70',
    625: '45.10',
    650: '29.60',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 41,
    'Nominal Composition': '8Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A553',
    'Type-Grade': 'II',
    'UNS No.': 'K71340',
    'Class-Cond.-Temper': '…',
    Material: '8Ni | A553 | II | K71340 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-171',
    'Max. Use Temp., °C': 40,
    'Min. Temp. to 40': 230,
    65: '…',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '…',
    275: '…',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 42,
    'Nominal Composition': '5Ni–0.25 Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A645',
    'Type-Grade': 'A',
    'UNS No.': 'K41583',
    'Class-Cond.-Temper': '…',
    Material: '5Ni–0.25 Mo | A645 | A | K41583 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-171',
    'Max. Use Temp., °C': 93,
    'Min. Temp. to 40': 218,
    65: '218.00',
    100: '218.00',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '…',
    275: '…',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 43,
    'Nominal Composition': '9Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A553',
    'Type-Grade': 'I',
    'UNS No.': 'K81340',
    'Class-Cond.-Temper': '…',
    Material: '9Ni | A553 | I | K81340 | …',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-196',
    'Max. Use Temp., °C': 93,
    'Min. Temp. to 40': 230,
    65: '230.00',
    100: '230.00',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '…',
    275: '…',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 44,
    'Nominal Composition': '9Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'A353',
    'Type-Grade': '…',
    'UNS No.': 'K81340',
    'Class-Cond.-Temper': '…',
    Material: '9Ni | A353 | … | K81340 | …',
    'Density (kg-m3)': '7900',
    'Size, mm': '',
    'Min. Temp., °C': '-196',
    'Max. Use Temp., °C': 93,
    'Min. Temp. to 40': 230,
    65: '230.00',
    100: '230.00',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '…',
    275: '…',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 45,
    'Nominal Composition': '18Cr–11Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '305',
    'UNS No.': 'S30500',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–11Ni | A240 | 305 | S30500 | …',
    'Density (kg-m3)': '7990',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 40,
    'Min. Temp. to 40': 115,
    65: '…',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '…',
    275: '…',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 46,
    'Nominal Composition': '12Cr–Al',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '405',
    'UNS No.': 'S40500',
    'Class-Cond.-Temper': '…',
    Material: '12Cr–Al | A240 | 405 | S40500 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 115,
    65: '109.00',
    100: '105.00',
    125: '103.00',
    150: '102.00',
    175: '101.00',
    200: '100.00',
    225: '99.70',
    250: '99.10',
    275: '98.40',
    300: '97.50',
    325: '96.20',
    350: '94.70',
    375: '92.60',
    400: '90.10',
    425: '87.00',
    450: '83.40',
    475: '79.20',
    500: '70.10',
    525: '38.80',
    550: '27.60',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 47,
    'Nominal Composition': '18Cr–8Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '304L',
    'UNS No.': 'S30403',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–8Ni | A240 | 304L | S30403 | …',
    'Density (kg-m3)': '8000',
    'Size, mm': '',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 115,
    65: '115.00',
    100: '115.00',
    125: '115.00',
    150: '115.00',
    175: '114.00',
    200: '110.00',
    225: '106.00',
    250: '103.00',
    275: '99.90',
    300: '97.70',
    325: '95.70',
    350: '94.10',
    375: '92.60',
    400: '91.30',
    425: '90.00',
    450: '88.70',
    475: '87.30',
    500: '85.60',
    525: '83.70',
    550: '81.40',
    575: '40.40',
    600: '33.20',
    625: '26.70',
    650: '21.90',
    675: '18.20',
    700: '15.00',
    725: '12.40',
    750: '8.87',
    775: '7.20',
    800: '6.58',
    825: '6.21',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 48,
    'Nominal Composition': '16Cr–12Ni–2Mo',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '316L',
    'UNS No.': 'S31603',
    'Class-Cond.-Temper': '…',
    Material: '16Cr–12Ni–2Mo | A240 | 316L | S31603 | …',
    'Density (kg-m3)': '8027',
    'Size, mm': '',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 115,
    65: '115.00',
    100: '115.00',
    125: '115.00',
    150: '115.00',
    175: '113.00',
    200: '109.00',
    225: '106.00',
    250: '103.00',
    275: '100.00',
    300: '98.10',
    325: '96.10',
    350: '94.30',
    375: '92.60',
    400: '90.90',
    425: '89.30',
    450: '87.60',
    475: '85.90',
    500: '84.20',
    525: '82.50',
    550: '80.80',
    575: '73.00',
    600: '67.90',
    625: '58.00',
    650: '43.60',
    675: '33.00',
    700: '25.30',
    725: '18.80',
    750: '14.00',
    775: '10.40',
    800: '7.99',
    825: '6.89',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 49,
    'Nominal Composition': '18Cr–8Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '302',
    'UNS No.': 'S30200',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–8Ni | A240 | 302 | S30200 | …',
    'Density (kg-m3)': '7860',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '134.00',
    200: '129.00',
    225: '125.00',
    250: '122.00',
    275: '119.00',
    300: '116.00',
    325: '113.00',
    350: '111.00',
    375: '109.00',
    400: '107.00',
    425: '105.00',
    450: '103.00',
    475: '101.00',
    500: '99.10',
    525: '97.30',
    550: '96.40',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 50,
    'Nominal Composition': '12Cr–1Ni',
    'Product Form': 'Plate, sheet, strip',
    'Spec. No.': 'A1010',
    'Type-Grade': '40',
    'UNS No.': 'S41003',
    'Class-Cond.-Temper': '…',
    Material: '12Cr–1Ni | A1010 | 40 | S41003 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 152,
    65: '152.00',
    100: '152.00',
    125: '152.00',
    150: '152.00',
    175: '151.00',
    200: '149.00',
    225: '147.00',
    250: '145.00',
    275: '142.00',
    300: '140.00',
    325: '137.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 51,
    'Nominal Composition': '12Cr–1Ni',
    'Product Form': 'Plate, sheet, strip',
    'Spec. No.': 'A1010',
    'Type-Grade': '50',
    'UNS No.': 'S41003',
    'Class-Cond.-Temper': '…',
    Material: '12Cr–1Ni | A1010 | 50 | S41003 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 162,
    65: '162.00',
    100: '162.00',
    125: '162.00',
    150: '162.00',
    175: '161.00',
    200: '159.00',
    225: '156.00',
    250: '154.00',
    275: '152.00',
    300: '149.00',
    325: '146.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 52,
    'Nominal Composition': '13Cr',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '410S',
    'UNS No.': 'S41008',
    'Class-Cond.-Temper': '…',
    Material: '13Cr | A240 | 410S | S41008 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '130.00',
    100: '126.00',
    125: '124.00',
    150: '122.00',
    175: '121.00',
    200: '120.00',
    225: '120.00',
    250: '119.00',
    275: '118.00',
    300: '117.00',
    325: '115.00',
    350: '114.00',
    375: '111.00',
    400: '108.00',
    425: '104.00',
    450: '100.00',
    475: '92.50',
    500: '68.40',
    525: '51.10',
    550: '37.40',
    575: '26.30',
    600: '17.80',
    625: '11.40',
    650: '6.89',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 53,
    'Nominal Composition': '13Cr',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '410',
    'UNS No.': 'S41000',
    'Class-Cond.-Temper': '…',
    Material: '13Cr | A240 | 410 | S41000 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '130.00',
    100: '126.00',
    125: '124.00',
    150: '122.00',
    175: '121.00',
    200: '120.00',
    225: '120.00',
    250: '119.00',
    275: '118.00',
    300: '117.00',
    325: '115.00',
    350: '114.00',
    375: '111.00',
    400: '108.00',
    425: '104.00',
    450: '100.00',
    475: '92.50',
    500: '68.40',
    525: '51.10',
    550: '37.40',
    575: '26.30',
    600: '17.80',
    625: '11.40',
    650: '6.89',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 54,
    'Nominal Composition': '15Cr',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '429',
    'UNS No.': 'S42900',
    'Class-Cond.-Temper': '…',
    Material: '15Cr | A240 | 429 | S42900 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '130.00',
    100: '126.00',
    125: '124.00',
    150: '122.00',
    175: '121.00',
    200: '120.00',
    225: '120.00',
    250: '119.00',
    275: '118.00',
    300: '117.00',
    325: '115.00',
    350: '114.00',
    375: '111.00',
    400: '108.00',
    425: '104.00',
    450: '100.00',
    475: '88.70',
    500: '69.80',
    525: '52.60',
    550: '38.10',
    575: '27.60',
    600: '20.60',
    625: '15.90',
    650: '12.40',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 55,
    'Nominal Composition': '17Cr',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '430',
    'UNS No.': 'S43000',
    'Class-Cond.-Temper': '…',
    Material: '17Cr | A240 | 430 | S43000 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 138,
    65: '130.00',
    100: '126.00',
    125: '124.00',
    150: '122.00',
    175: '121.00',
    200: '120.00',
    225: '120.00',
    250: '119.00',
    275: '118.00',
    300: '117.00',
    325: '115.00',
    350: '114.00',
    375: '111.00',
    400: '108.00',
    425: '104.00',
    450: '100.00',
    475: '88.70',
    500: '69.80',
    525: '52.60',
    550: '38.10',
    575: '27.60',
    600: '20.60',
    625: '15.90',
    650: '12.40',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 56,
    'Nominal Composition': '18Cr–13Ni–3Mo',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '317L',
    'UNS No.': 'S31703',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–13Ni–3Mo | A240 | 317L | S31703 | …',
    'Density (kg-m3)': '8000',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '136.00',
    200: '131.00',
    225: '127.00',
    250: '123.00',
    275: '120.00',
    300: '118.00',
    325: '115.00',
    350: '113.00',
    375: '111.00',
    400: '109.00',
    425: '107.00',
    450: '105.00',
    475: '103.00',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 57,
    'Nominal Composition': '25Cr–20Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '310S',
    'UNS No.': 'S31008',
    'Class-Cond.-Temper': '…',
    Material: '25Cr–20Ni | A240 | 310S | S31008 | …',
    'Density (kg-m3)': '7980',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '137.00',
    250: '134.00',
    275: '131.00',
    300: '129.00',
    325: '127.00',
    350: '125.00',
    375: '123.00',
    400: '122.00',
    425: '120.00',
    450: '119.00',
    475: '117.00',
    500: '116.00',
    525: '84.90',
    550: '59.00',
    575: '43.50',
    600: '31.90',
    625: '23.60',
    650: '16.90',
    675: '10.70',
    700: '6.10',
    725: '3.90',
    750: '2.99',
    775: '2.36',
    800: '1.73',
    825: '1.38',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 58,
    'Nominal Composition': '18Cr–10Ni–Ti',
    'Product Form': 'Plate, sheet, strip',
    'Spec. No.': 'A240',
    'Type-Grade': '321',
    'UNS No.': 'S32100',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Ti | A240 | 321 | S32100 | …',
    'Density (kg-m3)': '7920',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '135.00',
    275: '131.00',
    300: '128.00',
    325: '125.00',
    350: '122.00',
    375: '120.00',
    400: '119.00',
    425: '117.00',
    450: '115.00',
    475: '114.00',
    500: '113.00',
    525: '112.00',
    550: '88.70',
    575: '59.20',
    600: '44.00',
    625: '32.90',
    650: '24.50',
    675: '18.30',
    700: '12.50',
    725: '8.49',
    750: '6.19',
    775: '4.28',
    800: '2.75',
    825: '1.74',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 59,
    'Nominal Composition': '23Cr–12Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '309S',
    'UNS No.': 'S30908',
    'Class-Cond.-Temper': '…',
    Material: '23Cr–12Ni | A240 | 309S | S30908 | …',
    'Density (kg-m3)': '7890',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '135.00',
    275: '133.00',
    300: '131.00',
    325: '129.00',
    350: '127.00',
    375: '125.00',
    400: '124.00',
    425: '122.00',
    450: '121.00',
    475: '119.00',
    500: '117.00',
    525: '108.00',
    550: '83.70',
    575: '64.00',
    600: '48.50',
    625: '36.30',
    650: '27.30',
    675: '21.00',
    700: '15.90',
    725: '12.50',
    750: '9.87',
    775: '7.65',
    800: '5.97',
    825: '5.17',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 60,
    'Nominal Composition': '18Cr–10Ni–Cb',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '347',
    'UNS No.': 'S34700',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Cb | A240 | 347 | S34700 | …',
    'Density (kg-m3)': '7960',
    'Size, mm': '',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '138.00',
    275: '137.00',
    300: '135.00',
    325: '132.00',
    350: '130.00',
    375: '128.00',
    400: '127.00',
    425: '126.00',
    450: '126.00',
    475: '125.00',
    500: '125.00',
    525: '125.00',
    550: '97.60',
    575: '75.90',
    600: '57.20',
    625: '40.20',
    650: '30.30',
    675: '23.20',
    700: '16.20',
    725: '11.40',
    750: '8.97',
    775: '7.08',
    800: '5.89',
    825: '5.52',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 61,
    'Nominal Composition': '18Cr–10Ni–Cb',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '348',
    'UNS No.': 'S34800',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Cb | A240 | 348 | S34800 | …',
    'Density (kg-m3)': '7960',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '138.00',
    275: '137.00',
    300: '135.00',
    325: '132.00',
    350: '130.00',
    375: '128.00',
    400: '127.00',
    425: '126.00',
    450: '126.00',
    475: '125.00',
    500: '125.00',
    525: '125.00',
    550: '97.60',
    575: '75.90',
    600: '57.20',
    625: '40.20',
    650: '30.30',
    675: '23.20',
    700: '16.20',
    725: '11.40',
    750: '8.97',
    775: '7.08',
    800: '5.89',
    825: '5.52',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 62,
    'Nominal Composition': '25Cr–20Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '310H',
    'UNS No.': 'S31009',
    'Class-Cond.-Temper': '…',
    Material: '25Cr–20Ni | A240 | 310H | S31009 | …',
    'Density (kg-m3)': '7890',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '137.00',
    250: '134.00',
    275: '131.00',
    300: '129.00',
    325: '127.00',
    350: '125.00',
    375: '123.00',
    400: '122.00',
    425: '120.00',
    450: '119.00',
    475: '117.00',
    500: '116.00',
    525: '108.00',
    550: '83.70',
    575: '64.00',
    600: '48.50',
    625: '36.30',
    650: '27.30',
    675: '21.00',
    700: '15.90',
    725: '12.50',
    750: '9.87',
    775: '7.65',
    800: '5.97',
    825: '5.17',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 63,
    'Nominal Composition': '18Cr–10Ni–Ti',
    'Product Form': 'Plate, sheet, strip',
    'Spec. No.': 'A240',
    'Type-Grade': '321',
    'UNS No.': 'S32100',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Ti | A240 | 321 | S32100 | …',
    'Density (kg-m3)': '7920',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '135.00',
    275: '131.00',
    300: '128.00',
    325: '125.00',
    350: '122.00',
    375: '120.00',
    400: '119.00',
    425: '117.00',
    450: '115.00',
    475: '114.00',
    500: '113.00',
    525: '112.00',
    550: '100.00',
    575: '76.50',
    600: '58.70',
    625: '46.00',
    650: '36.80',
    675: '28.70',
    700: '23.00',
    725: '18.40',
    750: '14.50',
    775: '11.50',
    800: '9.02',
    825: '6.83',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 64,
    'Nominal Composition': '18Cr–10Ni–Ti',
    'Product Form': 'Plate, sheet, strip',
    'Spec. No.': 'A240',
    'Type-Grade': '321H',
    'UNS No.': 'S32109',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Ti | A240 | 321H | S32109 | …',
    'Density (kg-m3)': '7920',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '135.00',
    275: '131.00',
    300: '128.00',
    325: '125.00',
    350: '122.00',
    375: '120.00',
    400: '119.00',
    425: '117.00',
    450: '115.00',
    475: '114.00',
    500: '113.00',
    525: '112.00',
    550: '100.00',
    575: '76.50',
    600: '58.70',
    625: '46.00',
    650: '36.80',
    675: '28.70',
    700: '23.00',
    725: '18.40',
    750: '14.50',
    775: '11.50',
    800: '9.02',
    825: '6.83',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 65,
    'Nominal Composition': '16Cr–12Ni–2Mo',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '316',
    'UNS No.': 'S31600',
    'Class-Cond.-Temper': '…',
    Material: '16Cr–12Ni–2Mo | A240 | 316 | S31600 | …',
    'Density (kg-m3)': '7870',
    'Size, mm': '',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '134.00',
    225: '129.00',
    250: '125.00',
    275: '122.00',
    300: '119.00',
    325: '116.00',
    350: '114.00',
    375: '112.00',
    400: '111.00',
    425: '110.00',
    450: '109.00',
    475: '108.00',
    500: '107.00',
    525: '106.00',
    550: '105.00',
    575: '97.80',
    600: '80.80',
    625: '65.00',
    650: '50.40',
    675: '38.60',
    700: '29.60',
    725: '23.00',
    750: '17.40',
    775: '13.30',
    800: '10.40',
    825: '8.96',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 66,
    'Nominal Composition': '18Cr–13Ni–3Mo',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '317',
    'UNS No.': 'S31700',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–13Ni–3Mo | A240 | 317 | S31700 | …',
    'Density (kg-m3)': '7890',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '134.00',
    225: '129.00',
    250: '125.00',
    275: '122.00',
    300: '119.00',
    325: '116.00',
    350: '114.00',
    375: '112.00',
    400: '111.00',
    425: '110.00',
    450: '109.00',
    475: '108.00',
    500: '107.00',
    525: '106.00',
    550: '105.00',
    575: '97.80',
    600: '80.80',
    625: '65.00',
    650: '50.40',
    675: '38.60',
    700: '29.60',
    725: '23.00',
    750: '17.40',
    775: '13.30',
    800: '10.40',
    825: '8.96',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 67,
    'Nominal Composition': '18Cr–10Ni–Cb',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '347',
    'UNS No.': 'S34700',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Cb | A240 | 347 | S34700 | …',
    'Density (kg-m3)': '7960',
    'Size, mm': '',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '138.00',
    275: '137.00',
    300: '135.00',
    325: '132.00',
    350: '130.00',
    375: '128.00',
    400: '127.00',
    425: '126.00',
    450: '126.00',
    475: '125.00',
    500: '125.00',
    525: '125.00',
    550: '125.00',
    575: '112.00',
    600: '90.60',
    625: '69.60',
    650: '53.80',
    675: '41.40',
    700: '31.80',
    725: '24.00',
    750: '18.80',
    775: '14.60',
    800: '10.90',
    825: '8.96',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 68,
    'Nominal Composition': '18Cr–10Ni–Cb',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '348',
    'UNS No.': 'S34800',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–10Ni–Cb | A240 | 348 | S34800 | …',
    'Density (kg-m3)': '8000',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '138.00',
    275: '137.00',
    300: '135.00',
    325: '132.00',
    350: '130.00',
    375: '128.00',
    400: '127.00',
    425: '126.00',
    450: '126.00',
    475: '125.00',
    500: '125.00',
    525: '125.00',
    550: '125.00',
    575: '112.00',
    600: '90.60',
    625: '69.60',
    650: '53.80',
    675: '41.40',
    700: '31.80',
    725: '24.00',
    750: '18.80',
    775: '14.60',
    800: '10.90',
    825: '8.96',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 69,
    'Nominal Composition': '18Cr–8Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '304',
    'UNS No.': 'S30400',
    'Class-Cond.-Temper': '…',
    Material: '18Cr–8Ni | A240 | 304 | S30400 | …',
    'Density (kg-m3)': '8000',
    'Size, mm': '',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '134.00',
    200: '129.00',
    225: '125.00',
    250: '122.00',
    275: '119.00',
    300: '116.00',
    325: '113.00',
    350: '111.00',
    375: '109.00',
    400: '107.00',
    425: '105.00',
    450: '103.00',
    475: '101.00',
    500: '99.10',
    525: '97.30',
    550: '95.50',
    575: '78.90',
    600: '63.80',
    625: '51.60',
    650: '41.60',
    675: '32.90',
    700: '26.50',
    725: '21.30',
    750: '17.20',
    775: '14.10',
    800: '11.20',
    825: '9.65',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 70,
    'Nominal Composition': '44Fe–25Ni–21Cr–Mo',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '904L',
    'UNS No.': 'N08904',
    'Class-Cond.-Temper': '…',
    Material: '44Fe–25Ni–21Cr–Mo | A240 | 904L | N08904 | …',
    'Density (kg-m3)': '7950',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 143,
    65: '143.00',
    100: '143.00',
    125: '143.00',
    150: '141.00',
    175: '135.00',
    200: '130.00',
    225: '125.00',
    250: '120.00',
    275: '116.00',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 71,
    'Nominal Composition': '23Cr–4Ni–Mo–Cu–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '2304',
    'UNS No.': 'S32304',
    'Class-Cond.-Temper': '…',
    Material: '23Cr–4Ni–Mo–Cu–N | A240 | 2304 | S32304 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 200,
    65: '200.00',
    100: '191.00',
    125: '185.00',
    150: '180.00',
    175: '175.00',
    200: '171.00',
    225: '166.00',
    250: '161.00',
    275: '153.00',
    300: '143.00',
    325: '111.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 72,
    'Nominal Composition': '22Cr–5Ni–3Mo–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S31803',
    'Class-Cond.-Temper': '…',
    Material: '22Cr–5Ni–3Mo–N | A240 | … | S31803 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '204.00',
    150: '199.00',
    175: '196.00',
    200: '193.00',
    225: '190.00',
    250: '188.00',
    275: '187.00',
    300: '186.00',
    325: '185.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 73,
    'Nominal Composition': '16Cr–4Ni–6Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '201LN',
    'UNS No.': 'S20153',
    'Class-Cond.-Temper': '…',
    Material: '16Cr–4Ni–6Mn | A240 | 201LN | S20153 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 207,
    65: '206.00',
    100: '187.00',
    125: '177.00',
    150: '170.00',
    175: '165.00',
    200: '162.00',
    225: '160.00',
    250: '159.00',
    275: '158.00',
    300: '158.00',
    325: '158.00',
    350: '157.00',
    375: '156.00',
    400: '154.00',
    425: '152.00',
    450: '149.00',
    475: '146.00',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 74,
    'Nominal Composition': '20Cr–18Ni–6Mo',
    'Product Form': 'Plate',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S31254',
    'Class-Cond.-Temper': '…',
    Material: '20Cr–18Ni–6Mo | A240 | … | S31254 | …',
    'Density (kg-m3)': '8000',
    'Size, mm': '>5.0',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '203.00',
    175: '196.00',
    200: '190.00',
    225: '184.00',
    250: '179.00',
    275: '175.00',
    300: '172.00',
    325: '169.00',
    350: '167.00',
    375: '166.00',
    400: '165.00',
    425: '164.00',
    450: '163.00',
    475: '161.00',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 75,
    'Nominal Composition': '20Cr–18Ni–6Mo',
    'Product Form': 'Sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S31254',
    'Class-Cond.-Temper': '…',
    Material: '20Cr–18Ni–6Mo | A240 | … | S31254 | …',
    'Density (kg-m3)': '8000',
    'Size, mm': '≤5.0',
    'Min. Temp., °C': '-254',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '203.00',
    175: '196.00',
    200: '190.00',
    225: '184.00',
    250: '179.00',
    275: '175.00',
    300: '172.00',
    325: '169.00',
    350: '167.00',
    375: '166.00',
    400: '165.00',
    425: '164.00',
    450: '163.00',
    475: '161.00',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 76,
    'Nominal Composition': '46Fe–24Ni–21Cr–6Mo–Cu–N',
    'Product Form': 'Plate',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'N08367',
    'Class-Cond.-Temper': '…',
    Material: '46Fe–24Ni–21Cr–6Mo–Cu–N | A240 | … | N08367 | …',
    'Density (kg-m3)': '8020',
    'Size, mm': '>5.0',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '206.00',
    175: '202.00',
    200: '198.00',
    225: '195.00',
    250: '192.00',
    275: '188.00',
    300: '184.00',
    325: '179.00',
    350: '176.00',
    375: '173.00',
    400: '170.00',
    425: '167.00',
    450: '166.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 77,
    'Nominal Composition': '46Fe–24Ni–21Cr–6Mo–Cu–N',
    'Product Form': 'Sheet & strip',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'N08367',
    'Class-Cond.-Temper': '…',
    Material: '46Fe–24Ni–21Cr–6Mo–Cu–N | A240 | … | N08367 | …',
    'Density (kg-m3)': '8020',
    'Size, mm': '≤5.0',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '207.00',
    175: '207.00',
    200: '205.00',
    225: '199.00',
    250: '194.00',
    275: '188.00',
    300: '184.00',
    325: '179.00',
    350: '176.00',
    375: '173.00',
    400: '170.00',
    425: '167.00',
    450: '166.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 78,
    'Nominal Composition': '21Cr–5Mn–1.5Ni–Cu–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32101',
    'Class-Cond.-Temper': '…',
    Material: '21Cr–5Mn–1.5Ni–Cu–N | A240 | … | S32101 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '>5.0',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 217,
    65: '217.00',
    100: '217.00',
    125: '211.00',
    150: '206.00',
    175: '203.00',
    200: '199.00',
    225: '199.00',
    250: '199.00',
    275: '199.00',
    300: '199.00',
    325: '199.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 79,
    'Nominal Composition': '24Cr–4Ni–3Mn–1.5Mo–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S82441',
    'Class-Cond.-Temper': '…',
    Material: '24Cr–4Ni–3Mn–1.5Mo–N | A240 | … | S82441 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '≥10.0',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 227,
    65: '227.00',
    100: '227.00',
    125: '227.00',
    150: '227.00',
    175: '227.00',
    200: '227.00',
    225: '227.00',
    250: '227.00',
    275: '227.00',
    300: '227.00',
    325: '227.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 80,
    'Nominal Composition': '21Cr–5Mn–1.5Ni–Cu–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32101',
    'Class-Cond.-Temper': '…',
    Material: '21Cr–5Mn–1.5Ni–Cu–N | A240 | … | S32101 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '≤5.0',
    'Min. Temp., °C': '-29',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 233,
    65: '233.00',
    100: '231.00',
    125: '227.00',
    150: '222.00',
    175: '219.00',
    200: '215.00',
    225: '214.00',
    250: '214.00',
    275: '214.00',
    300: '214.00',
    325: '214.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 81,
    'Nominal Composition': '21Cr−3.5 Ni–1.75 Mo−N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32003',
    'Class-Cond.-Temper': '…',
    Material: '21Cr−3.5 Ni–1.75 Mo−N | A240 | … | S32003 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '>5.00',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 343,
    'Min. Temp. to 40': 218,
    65: '218.00',
    100: '210.00',
    125: '203.00',
    150: '199.00',
    175: '197.00',
    200: '197.00',
    225: '197.00',
    250: '197.00',
    275: '197.00',
    300: '197.00',
    325: '197.00',
    350: '197.00',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 82,
    'Nominal Composition': '21Cr−3.5 Ni–1.75 Mo−N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32003',
    'Class-Cond.-Temper': '…',
    Material: '21Cr−3.5 Ni–1.75 Mo−N | A240 | … | S32003 | …',
    'Density (kg-m3)': '7800',
    'Size, mm': '≤5.00',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 343,
    'Min. Temp. to 40': 230,
    65: '230.00',
    100: '221.00',
    125: '214.00',
    150: '209.00',
    175: '207.00',
    200: '207.00',
    225: '207.00',
    250: '207.00',
    275: '207.00',
    300: '207.00',
    325: '207.00',
    350: '207.00',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 83,
    'Nominal Composition': '24Cr–4Ni–3Mn–1.5Mo–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S82441',
    'Class-Cond.-Temper': '…',
    Material: '24Cr–4Ni–3Mn–1.5Mo–N | A240 | … | S82441 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '<10.0',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 247,
    65: '247.00',
    100: '247.00',
    125: '247.00',
    150: '247.00',
    175: '247.00',
    200: '247.00',
    225: '247.00',
    250: '247.00',
    275: '247.00',
    300: '247.00',
    325: '247.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 84,
    'Nominal Composition': '29Cr–6.5Ni–2Mo–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32906',
    'Class-Cond.-Temper': '…',
    Material: '29Cr–6.5Ni–2Mo–N | A240 | … | S32906 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '≥10.0',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 251,
    65: '251.00',
    100: '249.00',
    125: '243.00',
    150: '238.00',
    175: '235.00',
    200: '231.00',
    225: '230.00',
    250: '228.00',
    275: '228.00',
    300: '228.00',
    325: '228.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 85,
    'Nominal Composition': '29Cr–6.5Ni–2Mo–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32906',
    'Class-Cond.-Temper': '…',
    Material: '29Cr–6.5Ni–2Mo–N | A240 | … | S32906 | …',
    'Density (kg-m3)': '7700',
    'Size, mm': '≤10.0',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 267,
    65: '267.00',
    100: '265.00',
    125: '259.00',
    150: '253.00',
    175: '250.00',
    200: '246.00',
    225: '245.00',
    250: '243.00',
    275: '243.00',
    300: '242.00',
    325: '242.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 86,
    'Nominal Composition': '25Cr–8Ni–3Mo–W–Cu–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '…',
    'UNS No.': 'S32760',
    'Class-Cond.-Temper': '…',
    Material: '25Cr–8Ni–3Mo–W–Cu–N | A240 | … | S32760 | …',
    'Density (kg-m3)': '7780',
    'Size, mm': '',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 250,
    65: '250.00',
    100: '248.00',
    125: '243.00',
    150: '238.00',
    175: '236.00',
    200: '234.00',
    225: '233.00',
    250: '233.00',
    275: '233.00',
    300: '233.00',
    325: '233.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 87,
    'Nominal Composition': '25Cr–7Ni–4Mo–N',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'A240',
    'Type-Grade': '2507',
    'UNS No.': 'S32750',
    'Class-Cond.-Temper': '…',
    Material: '25Cr–7Ni–4Mo–N | A240 | 2507 | S32750 | …',
    'Density (kg-m3)': '7750',
    'Size, mm': '',
    'Min. Temp., °C': '-51',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 267,
    65: '265.00',
    100: '264.00',
    125: '257.00',
    150: '251.00',
    175: '247.00',
    200: '243.00',
    225: '241.00',
    250: '238.00',
    275: '237.00',
    300: '237.00',
    325: '236.00',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 88,
    'Nominal Composition': '99.95Cu–P',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B152',
    'Type-Grade': 'C10200',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '…',
    Material: '99.95Cu–P | B152 | C10200 | O25 | …',
    'Density (kg-m3)': '8910',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 46,
    65: '39.10',
    100: '37.10',
    125: '36.50',
    150: '34.20',
    175: '28.00',
    200: '21.70',
    225: '16.70',
    250: '12.90',
    275: '11.60',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 89,
    'Nominal Composition': '99.95Cu–Ag',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B152',
    'Type-Grade': 'C10400',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '…',
    Material: '99.95Cu–Ag | B152 | C10400 | O25 | …',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 46,
    65: '39.10',
    100: '37.10',
    125: '36.50',
    150: '34.20',
    175: '28.00',
    200: '21.70',
    225: '16.70',
    250: '12.90',
    275: '11.60',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 90,
    'Nominal Composition': '99.95Cu–Ag',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B152',
    'Type-Grade': 'C10500',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '…',
    Material: '99.95Cu–Ag | B152 | C10500 | O25 | …',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 46,
    65: '39.10',
    100: '37.10',
    125: '36.50',
    150: '34.20',
    175: '28.00',
    200: '21.70',
    225: '16.70',
    250: '12.90',
    275: '11.60',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 91,
    'Nominal Composition': '99.95Cu–Ag',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B152',
    'Type-Grade': 'C10700',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '…',
    Material: '99.95Cu–Ag | B152 | C10700 | O25 | …',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 46,
    65: '39.10',
    100: '37.10',
    125: '36.50',
    150: '34.20',
    175: '28.00',
    200: '21.70',
    225: '16.70',
    250: '12.90',
    275: '11.60',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 92,
    'Nominal Composition': '99.9Cu–P',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B152',
    'Type-Grade': 'C12200',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '…',
    Material: '99.9Cu–P | B152 | C12200 | O25 | …',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 46,
    65: '39.10',
    100: '37.10',
    125: '36.50',
    150: '34.20',
    175: '28.00',
    200: '21.70',
    225: '16.70',
    250: '12.90',
    275: '11.60',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 93,
    'Nominal Composition': '99.9Cu–P',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B152',
    'Type-Grade': 'C12300',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '…',
    Material: '99.9Cu–P | B152 | C12300 | O25 | …',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 46,
    65: '39.10',
    100: '37.10',
    125: '36.50',
    150: '34.20',
    175: '28.00',
    200: '21.70',
    225: '16.70',
    250: '12.90',
    275: '11.60',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 94,
    'Nominal Composition': '90Cu–10Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B171',
    'Type-Grade': 'C70600',
    'UNS No.': '…',
    'Class-Cond.-Temper': '≤64 thk.',
    Material: '90Cu–10Ni | B171 | C70600 | … | ≤64 thk.',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 68.9,
    65: '67.00',
    100: '65.10',
    125: '63.70',
    150: '62.40',
    175: '61.10',
    200: '60.00',
    225: '59.10',
    250: '58.30',
    275: '51.30',
    300: '45.10',
    325: '39.30',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 95,
    'Nominal Composition': '97Cu–3Si',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B96',
    'Type-Grade': 'C65500',
    'UNS No.': 'O61',
    'Class-Cond.-Temper': '…',
    Material: '97Cu–3Si | B96 | C65500 | O61 | …',
    'Density (kg-m3)': '8530',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 82.7,
    65: '82.70',
    100: '82.70',
    125: '82.70',
    150: '82.70',
    175: '75.40',
    200: '50.80',
    225: '31.90',
    250: '…',
    275: '…',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 96,
    'Nominal Composition': '70Cu–30Ni',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B171',
    'Type-Grade': 'C71500',
    'UNS No.': '…',
    'Class-Cond.-Temper': '≤64 thk.',
    Material: '70Cu–30Ni | B171 | C71500 | … | ≤64 thk.',
    'Density (kg-m3)': '8950',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 371,
    'Min. Temp. to 40': 91.9,
    65: '88.70',
    100: '86.10',
    125: '84.30',
    150: '82.60',
    175: '81.00',
    200: '79.40',
    225: '78.00',
    250: '76.60',
    275: '75.30',
    300: '74.20',
    325: '73.20',
    350: '72.50',
    375: '71.70',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 97,
    'Nominal Composition': '90Cu–7Al–3Fe',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B169',
    'Type-Grade': 'C61400',
    'UNS No.': 'O25',
    'Class-Cond.-Temper': '≤50 thk.',
    Material: '90Cu–7Al–3Fe | B169 | C61400 | O25 | ≤50 thk.',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 138,
    65: '137.00',
    100: '136.00',
    125: '135.00',
    150: '135.00',
    175: '134.00',
    200: '133.00',
    225: '131.00',
    250: '130.00',
    275: '130.00',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 98,
    'Nominal Composition': '90Cu–7Al–3Fe',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B169',
    'Type-Grade': 'C61400',
    'UNS No.': 'O60',
    'Class-Cond.-Temper': '≤50 thk.',
    Material: '90Cu–7Al–3Fe | B169 | C61400 | O60 | ≤50 thk.',
    'Density (kg-m3)': '7800',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 260,
    'Min. Temp. to 40': 138,
    65: '137.00',
    100: '136.00',
    125: '135.00',
    150: '135.00',
    175: '134.00',
    200: '133.00',
    225: '131.00',
    250: '130.00',
    275: '130.00',
    300: '…',
    325: '…',
    350: '…',
    375: '…',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 99,
    'Nominal Composition': '99.0Ni–Low C',
    'Product Form': 'Plate',
    'Spec. No.': 'B162',
    'Type-Grade': '…',
    'UNS No.': 'N02201',
    'Class-Cond.-Temper': 'H.R. ann.',
    Material: '99.0Ni–Low C | B162 | … | N02201 | H.R. ann.',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 55.2,
    65: '53.80',
    100: '52.80',
    125: '52.30',
    150: '51.90',
    175: '51.70',
    200: '51.60',
    225: '51.60',
    250: '51.60',
    275: '51.60',
    300: '51.60',
    325: '51.60',
    350: '51.50',
    375: '51.20',
    400: '50.70',
    425: '49.90',
    450: '41.40',
    475: '33.10',
    500: '27.40',
    525: '22.80',
    550: '18.70',
    575: '15.60',
    600: '12.90',
    625: '10.00',
    650: '8.27',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 100,
    'Nominal Composition': '99.0Ni–Low C',
    'Product Form': 'Plate',
    'Spec. No.': 'B162',
    'Type-Grade': '…',
    'UNS No.': 'N02201',
    'Class-Cond.-Temper': 'H.R. as R.',
    Material: '99.0Ni–Low C | B162 | … | N02201 | H.R. as R.',
    'Density (kg-m3)': '8900',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 55.2,
    65: '53.80',
    100: '52.80',
    125: '52.30',
    150: '51.90',
    175: '51.70',
    200: '51.60',
    225: '51.60',
    250: '51.60',
    275: '51.60',
    300: '51.60',
    325: '51.60',
    350: '51.50',
    375: '51.20',
    400: '50.70',
    425: '49.90',
    450: '41.40',
    475: '33.10',
    500: '27.40',
    525: '22.80',
    550: '18.70',
    575: '15.60',
    600: '12.90',
    625: '10.00',
    650: '8.27',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 101,
    'Nominal Composition': '99.0Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'B162',
    'Type-Grade': '…',
    'UNS No.': 'N02200',
    'Class-Cond.-Temper': 'H.R. ann.',
    Material: '99.0Ni | B162 | … | N02200 | H.R. ann.',
    'Density (kg-m3)': '8890',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 68.9,
    65: '68.90',
    100: '68.90',
    125: '68.90',
    150: '68.90',
    175: '68.90',
    200: '68.90',
    225: '68.90',
    250: '68.90',
    275: '68.90',
    300: '68.90',
    325: '68.90',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 102,
    'Nominal Composition': '99.0Ni',
    'Product Form': 'Plate',
    'Spec. No.': 'B162',
    'Type-Grade': '…',
    'UNS No.': 'N02200',
    'Class-Cond.-Temper': 'H.R. as R.',
    Material: '99.0Ni | B162 | … | N02200 | H.R. as R.',
    'Density (kg-m3)': '8890',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 316,
    'Min. Temp. to 40': 91.9,
    65: '91.90',
    100: '91.90',
    125: '91.90',
    150: '91.90',
    175: '91.90',
    200: '91.90',
    225: '91.90',
    250: '91.90',
    275: '91.90',
    300: '91.90',
    325: '91.90',
    350: '…',
    375: '…',
    400: '…',
    425: '…',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 103,
    'Nominal Composition': '33Ni–42Fe–21Cr',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B409',
    'Type-Grade': '…',
    'UNS No.': 'N08810',
    'Class-Cond.-Temper': 'Annealed',
    Material: '33Ni–42Fe–21Cr | B409 | … | N08810 | Annealed',
    'Density (kg-m3)': '7940',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 899,
    'Min. Temp. to 40': 115,
    65: '115.00',
    100: '115.00',
    125: '115.00',
    150: '115.00',
    175: '115.00',
    200: '115.00',
    225: '115.00',
    250: '115.00',
    275: '115.00',
    300: '115.00',
    325: '113.00',
    350: '110.00',
    375: '108.00',
    400: '105.00',
    425: '104.00',
    450: '102.00',
    475: '100.00',
    500: '98.60',
    525: '97.10',
    550: '95.90',
    575: '91.80',
    600: '75.70',
    625: '62.60',
    650: '50.60',
    675: '41.20',
    700: '33.60',
    725: '27.70',
    750: '22.60',
    775: '18.30',
    800: '15.00',
    825: '11.90',
    850: '9.03',
    875: '7.35',
    900: '5.86',
  },
  {
    No: 104,
    'Nominal Composition': '33Ni–42Fe–21Cr–Al–Ti',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B409',
    'Type-Grade': '…',
    'UNS No.': 'N08811',
    'Class-Cond.-Temper': 'Annealed',
    Material: '33Ni–42Fe–21Cr–Al–Ti | B409 | … | N08811 | Annealed',
    'Density (kg-m3)': '7940',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 899,
    'Min. Temp. to 40': 115,
    65: '115.00',
    100: '115.00',
    125: '115.00',
    150: '115.00',
    175: '115.00',
    200: '115.00',
    225: '115.00',
    250: '115.00',
    275: '115.00',
    300: '115.00',
    325: '113.00',
    350: '110.00',
    375: '108.00',
    400: '105.00',
    425: '104.00',
    450: '102.00',
    475: '100.00',
    500: '98.60',
    525: '97.10',
    550: '96.10',
    575: '94.10',
    600: '85.50',
    625: '69.30',
    650: '56.80',
    675: '46.80',
    700: '38.60',
    725: '31.50',
    750: '25.50',
    775: '20.60',
    800: '17.10',
    825: '13.80',
    850: '10.70',
    875: '7.98',
    900: '6.20',
  },
  {
    No: 105,
    'Nominal Composition': '26Ni–22Cr–5Mo–Ti',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B620',
    'Type-Grade': '…',
    'UNS No.': 'N08320',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '26Ni–22Cr–5Mo–Ti | B620 | … | N08320 | Sol. ann.',
    'Density (kg-m3)': '8000',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 129,
    65: '129.00',
    100: '129.00',
    125: '129.00',
    150: '129.00',
    175: '129.00',
    200: '129.00',
    225: '129.00',
    250: '129.00',
    275: '129.00',
    300: '129.00',
    325: '127.00',
    350: '125.00',
    375: '122.00',
    400: '121.00',
    425: '119.00',
    450: '119.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 106,
    'Nominal Composition': '67Ni–30Cu',
    'Product Form': 'Plate',
    'Spec. No.': 'B127',
    'Type-Grade': '…',
    'UNS No.': 'N04400',
    'Class-Cond.-Temper': 'H.R. ann.',
    Material: '67Ni–30Cu | B127 | … | N04400 | H.R. ann.',
    'Density (kg-m3)': '8800',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 482,
    'Min. Temp. to 40': 129,
    65: '119.00',
    100: '112.00',
    125: '108.00',
    150: '105.00',
    175: '103.00',
    200: '102.00',
    225: '101.00',
    250: '101.00',
    275: '101.00',
    300: '101.00',
    325: '101.00',
    350: '101.00',
    375: '100.00',
    400: '99.60',
    425: '98.60',
    450: '79.70',
    475: '59.90',
    500: '55.20',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 107,
    'Nominal Composition': '47Ni–22Cr–19Fe–6Mo',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B582',
    'Type-Grade': '…',
    'UNS No.': 'N06007',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '47Ni–22Cr–19Fe–6Mo | B582 | … | N06007 | Sol. ann.',
    'Density (kg-m3)': '8910',
    'Size, mm': '>19',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '138.00',
    275: '138.00',
    300: '135.00',
    325: '134.00',
    350: '132.00',
    375: '131.00',
    400: '130.00',
    425: '129.00',
    450: '128.00',
    475: '128.00',
    500: '127.00',
    525: '127.00',
    550: '126.00',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 108,
    'Nominal Composition': '33Ni–42Fe–21Cr',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B409',
    'Type-Grade': '…',
    'UNS No.': 'N08800',
    'Class-Cond.-Temper': 'Annealed',
    Material: '33Ni–42Fe–21Cr | B409 | … | N08800 | Annealed',
    'Density (kg-m3)': '7940',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 816,
    'Min. Temp. to 40': 138,
    65: '138.00',
    100: '138.00',
    125: '138.00',
    150: '138.00',
    175: '138.00',
    200: '138.00',
    225: '138.00',
    250: '138.00',
    275: '138.00',
    300: '138.00',
    325: '138.00',
    350: '138.00',
    375: '138.00',
    400: '138.00',
    425: '138.00',
    450: '138.00',
    475: '138.00',
    500: '138.00',
    525: '138.00',
    550: '136.00',
    575: '107.00',
    600: '83.80',
    625: '63.90',
    650: '44.70',
    675: '29.80',
    700: '15.50',
    725: '11.70',
    750: '8.68',
    775: '7.20',
    800: '6.25',
    825: '5.11',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 109,
    'Nominal Composition': '31Ni–31Fe–29Cr–Mo',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B709',
    'Type-Grade': '…',
    'UNS No.': 'N08028',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '31Ni–31Fe–29Cr–Mo | B709 | … | N08028 | Sol. ann.',
    'Density (kg-m3)': '8100',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 454,
    'Min. Temp. to 40': 143,
    65: '143.00',
    100: '143.00',
    125: '143.00',
    150: '143.00',
    175: '143.00',
    200: '143.00',
    225: '143.00',
    250: '143.00',
    275: '140.00',
    300: '137.00',
    325: '133.00',
    350: '130.00',
    375: '125.00',
    400: '122.00',
    425: '119.00',
    450: '116.00',
    475: '113.00',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 110,
    'Nominal Composition': '42Ni–21.5Cr–3Mo–2.3Cu',
    'Product Form': 'Plate',
    'Spec. No.': 'B424',
    'Type-Grade': '…',
    'UNS No.': 'N08825',
    'Class-Cond.-Temper': 'Annealed',
    Material: '42Ni–21.5Cr–3Mo–2.3Cu | B424 | … | N08825 | Annealed',
    'Density (kg-m3)': '8140',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '161.00',
    275: '161.00',
    300: '161.00',
    325: '161.00',
    350: '161.00',
    375: '161.00',
    400: '160.00',
    425: '159.00',
    450: '158.00',
    475: '157.00',
    500: '156.00',
    525: '155.00',
    550: '153.00',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 111,
    'Nominal Composition': '35Ni–35Fe–20Cr–Cb',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B463',
    'Type-Grade': '…',
    'UNS No.': 'N08020',
    'Class-Cond.-Temper': 'Annealed',
    Material: '35Ni–35Fe–20Cr–Cb | B463 | … | N08020 | Annealed',
    'Density (kg-m3)': '8100',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '161.00',
    275: '161.00',
    300: '161.00',
    325: '161.00',
    350: '161.00',
    375: '161.00',
    400: '160.00',
    425: '157.00',
    450: '156.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 112,
    'Nominal Composition': '40Ni–29Cr–15Fe–5Mo',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B582',
    'Type-Grade': '…',
    'UNS No.': 'N06030',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '40Ni–29Cr–15Fe–5Mo | B582 | … | N06030 | Sol. ann.',
    'Density (kg-m3)': '8500',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '157.00',
    250: '154.00',
    275: '151.00',
    300: '148.00',
    325: '146.00',
    350: '143.00',
    375: '141.00',
    400: '138.00',
    425: '136.00',
    450: '136.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 113,
    'Nominal Composition': '47Ni–22Cr–19Fe–6Mo',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B582',
    'Type-Grade': '…',
    'UNS No.': 'N06007',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '47Ni–22Cr–19Fe–6Mo | B582 | … | N06007 | Sol. ann.',
    'Density (kg-m3)': '8400',
    'Size, mm': '≤19',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 538,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '161.00',
    275: '160.00',
    300: '158.00',
    325: '156.00',
    350: '154.00',
    375: '153.00',
    400: '152.00',
    425: '151.00',
    450: '150.00',
    475: '149.00',
    500: '136.00',
    525: '132.00',
    550: '130.00',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 114,
    'Nominal Composition': '47Ni–22Cr–9Mo–18Fe',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B435',
    'Type-Grade': '…',
    'UNS No.': 'N06002',
    'Class-Cond.-Temper': 'H.R sol. ann.',
    Material: '47Ni–22Cr–9Mo–18Fe | B435 | … | N06002 | H.R sol. ann.',
    'Density (kg-m3)': '8220',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '156.00',
    275: '152.00',
    300: '148.00',
    325: '145.00',
    350: '142.00',
    375: '140.00',
    400: '138.00',
    425: '137.00',
    450: '137.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 115,
    'Nominal Composition': '72Ni–15Cr–8Fe',
    'Product Form': 'Plate',
    'Spec. No.': 'B168',
    'Type-Grade': '…',
    'UNS No.': 'N06600',
    'Class-Cond.-Temper': 'H.R. ann.',
    Material: '72Ni–15Cr–8Fe | B168 | … | N06600 | H.R. ann.',
    'Density (kg-m3)': '8470',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '161.00',
    275: '161.00',
    300: '161.00',
    325: '161.00',
    350: '161.00',
    375: '161.00',
    400: '161.00',
    425: '161.00',
    450: '161.00',
    475: '161.00',
    500: '84.80',
    525: '58.40',
    550: '39.70',
    575: '27.00',
    600: '19.20',
    625: '15.00',
    650: '13.80',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 116,
    'Nominal Composition': '72Ni–15Cr–8Fe',
    'Product Form': 'Plate',
    'Spec. No.': 'B168',
    'Type-Grade': '…',
    'UNS No.': 'N06600',
    'Class-Cond.-Temper': 'H.R. as R.',
    Material: '72Ni–15Cr–8Fe | B168 | … | N06600 | H.R. as R.',
    'Density (kg-m3)': '8470',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '161.00',
    225: '161.00',
    250: '161.00',
    275: '161.00',
    300: '161.00',
    325: '161.00',
    350: '161.00',
    375: '161.00',
    400: '161.00',
    425: '161.00',
    450: '161.00',
    475: '161.00',
    500: '84.80',
    525: '58.40',
    550: '39.70',
    575: '27.00',
    600: '19.20',
    625: '15.00',
    650: '13.80',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 117,
    'Nominal Composition': '58Ni–29Cr–9Fe',
    'Product Form': 'Plate',
    'Spec. No.': 'B168',
    'Type-Grade': '…',
    'UNS No.': 'N06690',
    'Class-Cond.-Temper': 'Annealed',
    Material: '58Ni–29Cr–9Fe | B168 | … | N06690 | Annealed',
    'Density (kg-m3)': '8200',
    'Size, mm': '≥5',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 482,
    'Min. Temp. to 40': 160,
    65: '160.00',
    100: '160.00',
    125: '160.00',
    150: '160.00',
    175: '160.00',
    200: '160.00',
    225: '160.00',
    250: '160.00',
    275: '160.00',
    300: '160.00',
    325: '160.00',
    350: '160.00',
    375: '160.00',
    400: '160.00',
    425: '160.00',
    450: '160.00',
    475: '160.00',
    500: '160.00',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 118,
    'Nominal Composition': '58Ni–29Cr–9Fe',
    'Product Form': 'Sheet',
    'Spec. No.': 'B168',
    'Type-Grade': '…',
    'UNS No.': 'N06690',
    'Class-Cond.-Temper': 'H.R. ann. orC.R. ann.',
    Material: '58Ni–29Cr–9Fe | B168 | … | N06690 | H.R. ann. orC.R. ann.',
    'Density (kg-m3)': '8200',
    'Size, mm': '0.5 to 6',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 482,
    'Min. Temp. to 40': 160,
    65: '160.00',
    100: '160.00',
    125: '160.00',
    150: '160.00',
    175: '160.00',
    200: '160.00',
    225: '160.00',
    250: '160.00',
    275: '160.00',
    300: '160.00',
    325: '160.00',
    350: '160.00',
    375: '160.00',
    400: '160.00',
    425: '160.00',
    450: '160.00',
    475: '160.00',
    500: '160.00',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 119,
    'Nominal Composition': '67Ni–30Cu',
    'Product Form': 'Plate',
    'Spec. No.': 'B127',
    'Type-Grade': '…',
    'UNS No.': 'N04400',
    'Class-Cond.-Temper': 'H.R. as R.',
    Material: '67Ni–30Cu | B127 | … | N04400 | H.R. as R.',
    'Density (kg-m3)': '8800',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 482,
    'Min. Temp. to 40': 172,
    65: '172.00',
    100: '172.00',
    125: '171.00',
    150: '170.00',
    175: '168.00',
    200: '165.00',
    225: '164.00',
    250: '162.00',
    275: '161.00',
    300: '160.00',
    325: '159.00',
    350: '158.00',
    375: '156.00',
    400: '136.00',
    425: '102.00',
    450: '63.80',
    475: '33.50',
    500: '27.60',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 120,
    'Nominal Composition': '37Ni–33Fe–25Cr',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B409',
    'Type-Grade': '…',
    'UNS No.': 'N08120',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '37Ni–33Fe–25Cr | B409 | … | N08120 | Sol. ann.',
    'Density (kg-m3)': '8200',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 899,
    'Min. Temp. to 40': 184,
    65: '184.00',
    100: '184.00',
    125: '184.00',
    150: '184.00',
    175: '184.00',
    200: '184.00',
    225: '180.00',
    250: '175.00',
    275: '170.00',
    300: '166.00',
    325: '163.00',
    350: '160.00',
    375: '158.00',
    400: '156.00',
    425: '154.00',
    450: '153.00',
    475: '153.00',
    500: '152.00',
    525: '151.00',
    550: '151.00',
    575: '151.00',
    600: '117.00',
    625: '96.20',
    650: '79.30',
    675: '65.60',
    700: '54.40',
    725: '45.00',
    750: '37.20',
    775: '30.60',
    800: '25.10',
    825: '20.50',
    850: '16.50',
    875: '13.00',
    900: '9.80',
  },
  {
    No: 121,
    'Nominal Composition': '31Ni–33Fe–27Cr–6.5Mo–Cu–N',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B625',
    'Type-Grade': '…',
    'UNS No.': 'N08031',
    'Class-Cond.-Temper': 'Annealed',
    Material: '31Ni–33Fe–27Cr–6.5Mo–Cu–N | B625 | … | N08031 | Annealed',
    'Density (kg-m3)': '8050',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 184,
    65: '184.00',
    100: '184.00',
    125: '184.00',
    150: '184.00',
    175: '177.00',
    200: '171.00',
    225: '166.00',
    250: '162.00',
    275: '158.00',
    300: '155.00',
    325: '152.00',
    350: '149.00',
    375: '146.00',
    400: '144.00',
    425: '141.00',
    450: '140.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 122,
    'Nominal Composition': '61Ni–16Mo–16Cr',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B575',
    'Type-Grade': '…',
    'UNS No.': 'N06455',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '61Ni–16Mo–16Cr | B575 | … | N06455 | Sol. ann.',
    'Density (kg-m3)': '8640',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 184,
    65: '184.00',
    100: '184.00',
    125: '184.00',
    150: '184.00',
    175: '184.00',
    200: '184.00',
    225: '184.00',
    250: '184.00',
    275: '184.00',
    300: '184.00',
    325: '184.00',
    350: '184.00',
    375: '182.00',
    400: '180.00',
    425: '178.00',
    450: '176.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 123,
    'Nominal Composition': '54Ni–16Mo–15Cr',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B575',
    'Type-Grade': '…',
    'UNS No.': 'N10276',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '54Ni–16Mo–15Cr | B575 | … | N10276 | Sol. ann.',
    'Density (kg-m3)': '8890',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 677,
    'Min. Temp. to 40': 188,
    65: '188.00',
    100: '188.00',
    125: '188.00',
    150: '188.00',
    175: '188.00',
    200: '188.00',
    225: '188.00',
    250: '187.00',
    275: '183.00',
    300: '177.00',
    325: '172.00',
    350: '169.00',
    375: '165.00',
    400: '162.00',
    425: '159.00',
    450: '157.00',
    475: '156.00',
    500: '155.00',
    525: '154.00',
    550: '143.00',
    575: '118.00',
    600: '99.10',
    625: '81.60',
    650: '67.00',
    675: '54.60',
    700: '42.20',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 124,
    'Nominal Composition': '60Ni–22Cr–9Mo–3.5Cb',
    'Product Form': 'Plate',
    'Spec. No.': 'B443',
    'Type-Grade': '1',
    'UNS No.': 'N06625',
    'Class-Cond.-Temper': 'Annealed',
    Material: '60Ni–22Cr–9Mo–3.5Cb | B443 | 1 | N06625 | Annealed',
    'Density (kg-m3)': '8400',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 649,
    'Min. Temp. to 40': 253,
    65: '253.00',
    100: '253.00',
    125: '251.00',
    150: '250.00',
    175: '249.00',
    200: '248.00',
    225: '247.00',
    250: '245.00',
    275: '243.00',
    300: '241.00',
    325: '238.00',
    350: '236.00',
    375: '233.00',
    400: '231.00',
    425: '230.00',
    450: '228.00',
    475: '227.00',
    500: '225.00',
    525: '222.00',
    550: '215.00',
    575: '194.00',
    600: '156.00',
    625: '136.00',
    650: '91.00',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 125,
    'Nominal Composition': '57Ni–22Cr–14W–2Mo–La',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B435',
    'Type-Grade': '…',
    'UNS No.': 'N06230',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '57Ni–22Cr–14W–2Mo–La | B435 | … | N06230 | Sol. ann.',
    'Density (kg-m3)': '8960',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 899,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '207.00',
    175: '207.00',
    200: '207.00',
    225: '207.00',
    250: '207.00',
    275: '207.00',
    300: '207.00',
    325: '203.00',
    350: '199.00',
    375: '197.00',
    400: '196.00',
    425: '195.00',
    450: '195.00',
    475: '195.00',
    500: '195.00',
    525: '195.00',
    550: '195.00',
    575: '183.00',
    600: '153.00',
    625: '128.00',
    650: '107.00',
    675: '89.70',
    700: '74.70',
    725: '61.90',
    750: '50.80',
    775: '41.10',
    800: '32.60',
    825: '25.20',
    850: '18.90',
    875: '13.80',
    900: '10.20',
  },
  {
    No: 126,
    'Nominal Composition': '55Ni–21Cr–13.5Mo',
    'Product Form': 'Sheet',
    'Spec. No.': 'B575',
    'Type-Grade': '…',
    'UNS No.': 'N06022',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '55Ni–21Cr–13.5Mo | B575 | … | N06022 | Sol. ann.',
    'Density (kg-m3)': '8610',
    'Size, mm': '<5',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '207.00',
    175: '207.00',
    200: '207.00',
    225: '204.00',
    250: '202.00',
    275: '197.00',
    300: '193.00',
    325: '189.00',
    350: '185.00',
    375: '182.00',
    400: '180.00',
    425: '177.00',
    450: '177.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 127,
    'Nominal Composition': '58Ni–33Cr–8Mo',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B575',
    'Type-Grade': '…',
    'UNS No.': 'N06035',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '58Ni–33Cr–8Mo | B575 | … | N06035 | Sol. ann.',
    'Density (kg-m3)': '8220',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 161,
    65: '161.00',
    100: '161.00',
    125: '161.00',
    150: '161.00',
    175: '161.00',
    200: '154.00',
    225: '149.00',
    250: '144.00',
    275: '140.00',
    300: '137.00',
    325: '135.00',
    350: '133.00',
    375: '132.00',
    400: '131.00',
    425: '129.00',
    450: '128.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 128,
    'Nominal Composition': '46Fe–24Ni–21Cr–6Mo–Cu–N',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B688',
    'Type-Grade': '…',
    'UNS No.': 'N08367',
    'Class-Cond.-Temper': 'Annealed',
    Material:
      '46Fe–24Ni–21Cr–6Mo–Cu–N | B688 | … | N08367 | Annealed,8030,>5,-198,427.00,207.00,207.00,207.00,207.00,206.00,202.00,198.00,195.00,192.00,188.00,183.00,179.00,176.00,173.00,170.00,168.00,167.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n129,46Fe–24Ni–21Cr–6Mo–Cu–N,Pl. & sht.,B688,…,N08367,Annealed,46Fe–24Ni–21Cr–6Mo–Cu–N | B688 | … | N08367 | Annealed,8030,≤5,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,206.00,199.00,193.00,188.00,183.00,179.00,176.00,173.00,170.00,168.00,167.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n130,59Ni–23Cr–16Mo,Pl. & sht.,B575,…,N06059,Sol. ann.,59Ni–23Cr–16Mo | B575 | … | N06059 | Sol. ann.,8600,,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,206.00,202.00,197.00,192.00,188.00,184.00,180.00,176.00,176.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n131,59Ni–23Cr–16Mo–1.6Cu,Pl. & sht.,B575,…,N06200,Sol. ann.,59Ni–23Cr–16Mo–1.6Cu | B575 | … | N06200 | Sol. ann.,8870,,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,200.00,194.00,188.00,184.00,180.00,177.00,175.00,174.00,173.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n132,62Ni–22Mo–15Cr,Pl. & sht.,B575,…,N10362,Sol. ann.,62Ni–22Mo–15Cr | B575 | … | N10362 | Sol. ann.,8830,,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,204.00,202.00,199.00,197.00,193.00,190.00,188.00,186.00,184.00,182.00,180.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n133,62Ni–28Mo–5Fe,Plate,B333,…,N10001,Sol. ann.,62Ni–28Mo–5Fe | B333 | … | N10001 | Sol. ann.,9240,',
    'Density (kg-m3)': ' ≤64"',
    'Size, mm': '-198',
    'Min. Temp., °C': '427.00',
    'Max. Use Temp., °C': 207,
    'Min. Temp. to 40': 207,
    65: '207.00',
    100: '207.00',
    125: '207.00',
    150: '207.00',
    175: '207.00',
    200: '207.00',
    225: '207.00',
    250: '207.00',
    275: '207.00',
    300: '207.00',
    325: '207.00',
    350: '207.00',
    375: '207.00',
    400: '206.00',
    425: '206.00',
    450: '…',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '',
  },
  {
    No: 134,
    'Nominal Composition': '62Ni–28Mo–5Fe',
    'Product Form': 'Sheet',
    'Spec. No.': 'B333',
    'Type-Grade': '…',
    'UNS No.': 'N10001',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '62Ni–28Mo–5Fe | B333 | … | N10001 | Sol. ann.',
    'Density (kg-m3)': '9240',
    'Size, mm': '≤5',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 230,
    65: '230.00',
    100: '230.00',
    125: '230.00',
    150: '230.00',
    175: '230.00',
    200: '230.00',
    225: '230.00',
    250: '230.00',
    275: '230.00',
    300: '230.00',
    325: '230.00',
    350: '230.00',
    375: '230.00',
    400: '230.00',
    425: '229.00',
    450: '229.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 135,
    'Nominal Composition': '65Ni–28Mo–2Fe',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B333',
    'Type-Grade': '…',
    'UNS No.': 'N10665',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '65Ni–28Mo–2Fe | B333 | … | N10665 | Sol. ann.',
    'Density (kg-m3)': '8720',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 234,
    65: '234.00',
    100: '234.00',
    125: '234.00',
    150: '234.00',
    175: '234.00',
    200: '234.00',
    225: '234.00',
    250: '234.00',
    275: '234.00',
    300: '234.00',
    325: '234.00',
    350: '234.00',
    375: '234.00',
    400: '234.00',
    425: '234.00',
    450: '234.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 136,
    'Nominal Composition': '65Ni–29.5Mo–2Fe–2Cr',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B333',
    'Type-Grade': '…',
    'UNS No.': 'N10675',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material: '65Ni–29.5Mo–2Fe–2Cr | B333 | … | N10675 | Sol. ann.',
    'Density (kg-m3)': '9220',
    'Size, mm': '',
    'Min. Temp., °C': '-198',
    'Max. Use Temp., °C': 427,
    'Min. Temp. to 40': 234,
    65: '234.00',
    100: '234.00',
    125: '234.00',
    150: '234.00',
    175: '234.00',
    200: '234.00',
    225: '234.00',
    250: '234.00',
    275: '234.00',
    300: '234.00',
    325: '234.00',
    350: '234.00',
    375: '234.00',
    400: '233.00',
    425: '231.00',
    450: '230.00',
    475: '…',
    500: '…',
    525: '…',
    550: '…',
    575: '…',
    600: '…',
    625: '…',
    650: '…',
    675: '…',
    700: '…',
    725: '…',
    750: '…',
    775: '…',
    800: '…',
    825: '…',
    850: '…',
    875: '…',
    900: '…',
  },
  {
    No: 137,
    'Nominal Composition': '33Cr–31Ni–32Fe–1.5Mo–0.6Cu–N',
    'Product Form': 'Pl. & sht.',
    'Spec. No.': 'B625',
    'Type-Grade': '…',
    'UNS No.': 'R20033',
    'Class-Cond.-Temper': 'Sol. ann.',
    Material:
      '33Cr–31Ni–32Fe–1.5Mo–0.6Cu–N | B625 | … | R20033 | Sol. ann.,7900,,-198,427.00,250.00,231.00,209.00,200.00,193.00,187.00,181.00,176.00,172.00,169.00,165.00,163.00,161.00,159.00,157.00,156.00,155.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n138,99.2Zr,Plate, sheet,strip,B551,…,R60702,…,99.2Zr | B551 | … | R60702 | …,6510,,-59,371.00,126.00,119.00,103.00,92.40,82.50,73.60,65.70,58.80,52.80,47.80,43.70,40.40,37.80,36.10,,,,,,,,,,,,,,,,,,,,,\n139,95.5Zr + 2.5Nb,Plate, sheet,strip,B551,…,R60705,…,95.5Zr + 2.5Nb | B551 | … | R60705 | …,6640,,-59,371.00,184.00,169.00,149.00,139.00,130.00,123.00,116.00,111.00,106.00,101.00,97.60,94.60,92.40,91.00,,,,,,,,,,,,,,,,,,,,,\n140,Al–Mn–Cu,Plate & sheet,B209,Alclad 3003,A83003,O,Al–Mn–Cu | B209 | Alclad 3003 | A83003 | O,2730,',
    'Density (kg-m3)': ' <13"',
    'Size, mm': '-269',
    'Min. Temp., °C': '204.00',
    'Max. Use Temp., °C': 20.7,
    'Min. Temp. to 40': 19.9,
    65: '19.30',
    100: '18.40',
    125: '17.30',
    150: '13.60',
    175: '10.90',
    200: '10.50',
    225: '',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 141,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3003',
    'UNS No.': 'A83003',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | O',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 23,
    65: '22.20',
    100: '21.30',
    125: '20.40',
    150: '18.20',
    175: '13.60',
    200: '10.90',
    225: '10.50',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 142,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3003',
    'UNS No.': 'A83003',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H112',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥13, ≤50',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 27.6,
    65: '26.60',
    100: '25.60',
    125: '24.40',
    150: '18.20',
    175: '13.60',
    200: '10.90',
    225: '10.50',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 143,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3003',
    'UNS No.': 'A83003',
    'Class-Cond.-Temper': 'H12',
    Material: 'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H12',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥0.43, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 36.8,
    65: '36.80',
    100: '35.90',
    125: '33.70',
    150: '29.00',
    175: '21.10',
    200: '16.70',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 144,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3003',
    'UNS No.': 'A83003',
    'Class-Cond.-Temper': 'H12',
    Material: 'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H12',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥13, ≤50',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 39.1,
    65: '39.10',
    100: '39.10',
    125: '39.10',
    150: '29.00',
    175: '21.10',
    200: '16.70',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 145,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3003',
    'UNS No.': 'A83003',
    'Class-Cond.-Temper': 'H14',
    Material: 'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H14',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥0.23, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 43.7,
    65: '43.70',
    100: '43.70',
    125: '41.70',
    150: '29.00',
    175: '21.10',
    200: '16.70',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 146,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3003',
    'UNS No.': 'A83003',
    'Class-Cond.-Temper': 'H14',
    Material: 'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H14',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥13, ≤25',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 46,
    65: '46.00',
    100: '46.00',
    125: '43.90',
    150: '29.00',
    175: '21.10',
    200: '16.70',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 147,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | O',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥0.15, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 36.8,
    65: '36.80',
    100: '36.80',
    125: '36.80',
    150: '36.80',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 148,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | O',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 39.1,
    65: '39.10',
    100: '39.10',
    125: '39.10',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 149,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H112',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥6, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 39.1,
    65: '39.10',
    100: '39.10',
    125: '39.10',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 150,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H112',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 41.4,
    65: '41.40',
    100: '41.40',
    125: '41.40',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 151,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H32',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥0.43, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 62.1,
    65: '62.10',
    100: '62.10',
    125: '60.40',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 152,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H32',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥13, ≤50',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 64.4,
    65: '64.40',
    100: '64.40',
    125: '60.40',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 153,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H34',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥0.23, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 71.2,
    65: '71.20',
    100: '71.20',
    125: '71.20',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 154,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 3004',
    'UNS No.': 'A83004',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H34',
    'Density (kg-m3)': '2720',
    'Size, mm': '≥13, ≤25',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 73.5,
    65: '73.50',
    100: '73.50',
    125: '73.50',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 155,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T4 wld.',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T4 wld.',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 55.2,
    65: '55.20',
    100: '55.20',
    125: '54.30',
    150: '52.00',
    175: '46.30',
    200: '35.30',
    225: '34.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 156,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T6 wld.',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T6 wld.',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 55.2,
    65: '55.20',
    100: '55.20',
    125: '54.30',
    150: '52.00',
    175: '46.30',
    200: '35.30',
    225: '34.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 157,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T4',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T4',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 62.1,
    65: '62.10',
    100: '62.10',
    125: '61.00',
    150: '58.30',
    175: '52.10',
    200: '39.70',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 158,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T451',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T451',
    'Density (kg-m3)': '2700',
    'Size, mm': '≥6, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 62.1,
    65: '62.10',
    100: '62.10',
    125: '61.00',
    150: '58.30',
    175: '52.10',
    200: '39.70',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 159,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T451',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T451',
    'Density (kg-m3)': '2700',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 68.9,
    65: '68.90',
    100: '68.90',
    125: '67.80',
    150: '64.80',
    175: '57.90',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 160,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T6',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T6',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 87.3,
    65: '87.30',
    100: '87.30',
    125: '83.80',
    150: '72.30',
    175: '57.20',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 161,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T651',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T651',
    'Density (kg-m3)': '2700',
    'Size, mm': '≥6, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 87.3,
    65: '87.30',
    100: '87.30',
    125: '83.80',
    150: '72.30',
    175: '57.20',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 162,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': 'Alclad 6061',
    'UNS No.': 'A86061',
    'Class-Cond.-Temper': 'T651',
    Material: 'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T651',
    'Density (kg-m3)': '2700',
    'Size, mm': '≥13, ≤100',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 96.5,
    65: '96.50',
    100: '96.50',
    125: '92.50',
    150: '79.90',
    175: '63.10',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 163,
    'Nominal Composition': '99.60Al',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1060',
    'UNS No.': 'A91060',
    'Class-Cond.-Temper': 'O',
    Material: '99.60Al | B209 | 1060 | A91060 | O',
    'Density (kg-m3)': '2705',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 11.5,
    65: '11.50',
    100: '10.90',
    125: '9.80',
    150: '8.80',
    175: '7.50',
    200: '5.80',
    225: '5.50',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 164,
    'Nominal Composition': '99.60Al',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1060',
    'UNS No.': 'A91060',
    'Class-Cond.-Temper': 'H112',
    Material: '99.60Al | B209 | 1060 | A91060 | H112',
    'Density (kg-m3)': '2705',
    'Size, mm': '≥13, ≤25',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 23,
    65: '21.80',
    100: '19.10',
    125: '17.00',
    150: '15.00',
    175: '11.80',
    200: '7.50',
    225: '5.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 165,
    'Nominal Composition': '99.60Al',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1060',
    'UNS No.': 'A91060',
    'Class-Cond.-Temper': 'H12',
    Material: '99.60Al | B209 | 1060 | A91060 | H12',
    'Density (kg-m3)': '2705',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 25.3,
    65: '25.30',
    100: '23.10',
    125: '21.00',
    150: '18.10',
    175: '12.70',
    200: '8.40',
    225: '7.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 166,
    'Nominal Composition': '99.60Al',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1060',
    'UNS No.': 'A91060',
    'Class-Cond.-Temper': 'H14',
    Material: '99.60Al | B209 | 1060 | A91060 | H14',
    'Density (kg-m3)': '2705',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 27.6,
    65: '27.60',
    100: '27.60',
    125: '26.60',
    150: '18.10',
    175: '12.70',
    200: '8.40',
    225: '7.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 167,
    'Nominal Composition': '99.0Al–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1100',
    'UNS No.': 'A91100',
    'Class-Cond.-Temper': 'O',
    Material: '99.0Al–Cu | B209 | 1100 | A91100 | O',
    'Density (kg-m3)': '2710',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 16.1,
    65: '16.10',
    100: '16.00',
    125: '15.60',
    150: '11.80',
    175: '9.30',
    200: '7.20',
    225: '6.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 168,
    'Nominal Composition': '99.0Al–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1100',
    'UNS No.': 'A91100',
    'Class-Cond.-Temper': 'H112',
    Material: '99.0Al–Cu | B209 | 1100 | A91100 | H112',
    'Density (kg-m3)': '2710',
    'Size, mm': '≥13, ≤50',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 23,
    65: '23.00',
    100: '22.70',
    125: '21.60',
    150: '16.30',
    175: '11.80',
    200: '7.50',
    225: '6.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 169,
    'Nominal Composition': '99.0Al–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1100',
    'UNS No.': 'A91100',
    'Class-Cond.-Temper': 'H12',
    Material: '99.0Al–Cu | B209 | 1100 | A91100 | H12',
    'Density (kg-m3)': '2710',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 32.2,
    65: '32.20',
    100: '31.30',
    125: '25.20',
    150: '19.00',
    175: '13.60',
    200: '8.50',
    225: '7.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 170,
    'Nominal Composition': '99.0Al–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '1100',
    'UNS No.': 'A91100',
    'Class-Cond.-Temper': 'H14',
    Material: '99.0Al–Cu | B209 | 1100 | A91100 | H14',
    'Density (kg-m3)': '2710',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 36.8,
    65: '36.80',
    100: '36.10',
    125: '33.10',
    150: '19.00',
    175: '13.60',
    200: '8.50',
    225: '7.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 171,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3003',
    'UNS No.': 'A93003',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–Mn–Cu | B209 | 3003 | A93003 | O',
    'Density (kg-m3)': '2730',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 23,
    65: '22.10',
    100: '21.40',
    125: '20.50',
    150: '18.20',
    175: '13.60',
    200: '10.90',
    225: '10.50',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 172,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3003',
    'UNS No.': 'A93003',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–Mn–Cu | B209 | 3003 | A93003 | H112',
    'Density (kg-m3)': '2730',
    'Size, mm': '≥13, ≤50',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 27.6,
    65: '26.60',
    100: '25.60',
    125: '24.40',
    150: '18.20',
    175: '13.60',
    200: '10.90',
    225: '10.50',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 173,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3003',
    'UNS No.': 'A93003',
    'Class-Cond.-Temper': 'H12',
    Material: 'Al–Mn–Cu | B209 | 3003 | A93003 | H12',
    'Density (kg-m3)': '2730',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 39.1,
    65: '39.10',
    100: '38.10',
    125: '35.80',
    150: '29.00',
    175: '21.10',
    200: '16.70',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 174,
    'Nominal Composition': 'Al–Mn–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3003',
    'UNS No.': 'A93003',
    'Class-Cond.-Temper': 'H14',
    Material: 'Al–Mn–Cu | B209 | 3003 | A93003 | H14',
    'Density (kg-m3)': '2730',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 46,
    65: '46.00',
    100: '46.00',
    125: '43.90',
    150: '29.00',
    175: '21.10',
    200: '16.70',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 175,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3004',
    'UNS No.': 'A93004',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–Mn–Mg | B209 | 3004 | A93004 | O',
    'Density (kg-m3)': '2600',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 39.1,
    65: '39.10',
    100: '39.10',
    125: '39.00',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 176,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3004',
    'UNS No.': 'A93004',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–Mn–Mg | B209 | 3004 | A93004 | H112',
    'Density (kg-m3)': '2600',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 41.4,
    65: '41.40',
    100: '41.40',
    125: '41.40',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 177,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3004',
    'UNS No.': 'A93004',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–Mn–Mg | B209 | 3004 | A93004 | H32',
    'Density (kg-m3)': '2600',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 64.4,
    65: '64.40',
    100: '64.40',
    125: '60.40',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 178,
    'Nominal Composition': 'Al–Mn–Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '3004',
    'UNS No.': 'A93004',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–Mn–Mg | B209 | 3004 | A93004 | H34',
    'Density (kg-m3)': '2600',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 73.5,
    65: '73.50',
    100: '73.50',
    125: '73.50',
    150: '38.90',
    175: '26.90',
    200: '17.40',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 179,
    'Nominal Composition': 'Al–1.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5050',
    'UNS No.': 'A95050',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–1.5Mg | B209 | 5050 | A95050 | O',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 27.6,
    65: '27.60',
    100: '27.50',
    125: '27.50',
    150: '27.50',
    175: '20.10',
    200: '10.80',
    225: '9.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 180,
    'Nominal Composition': 'Al–1.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5050',
    'UNS No.': 'A95050',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–1.5Mg | B209 | 5050 | A95050 | H112',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 36.8,
    65: '36.70',
    100: '36.70',
    125: '36.60',
    150: '35.80',
    175: '20.10',
    200: '10.80',
    225: '9.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 181,
    'Nominal Composition': 'Al–1.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5050',
    'UNS No.': 'A95050',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–1.5Mg | B209 | 5050 | A95050 | H32',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 50.6,
    65: '50.60',
    100: '50.60',
    125: '50.60',
    150: '35.80',
    175: '20.10',
    200: '10.80',
    225: '9.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 182,
    'Nominal Composition': 'Al–1.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5050',
    'UNS No.': 'A95050',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–1.5Mg | B209 | 5050 | A95050 | H34',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 57.5,
    65: '57.50',
    100: '57.50',
    125: '57.50',
    150: '35.80',
    175: '20.10',
    200: '10.80',
    225: '9.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 183,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5052',
    'UNS No.': 'A95052',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–2.5Mg | B209 | 5052 | A95052 | O',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 43.7,
    65: '43.70',
    100: '43.70',
    125: '43.60',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 184,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5052',
    'UNS No.': 'A95052',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–2.5Mg | B209 | 5052 | A95052 | H112',
    'Density (kg-m3)': '2690',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 43.7,
    65: '43.70',
    100: '43.70',
    125: '43.60',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 185,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5052',
    'UNS No.': 'A95052',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–2.5Mg | B209 | 5052 | A95052 | H32',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 71.2,
    65: '71.20',
    100: '71.20',
    125: '71.00',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 186,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5052',
    'UNS No.': 'A95052',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–2.5Mg | B209 | 5052 | A95052 | H34',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 78.1,
    65: '78.10',
    100: '78.10',
    125: '78.10',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 187,
    'Nominal Composition': 'Al–4.4Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5083',
    'UNS No.': 'A95083',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–4.4Mg–Mn | B209 | 5083 | A95083 | O',
    'Density (kg-m3)': '2650',
    'Size, mm': '≥1.3, ≤38',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 82.7,
    65: '82.70',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 188,
    'Nominal Composition': 'Al–4.4Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5083',
    'UNS No.': 'A95083',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–4.4Mg–Mn | B209 | 5083 | A95083 | H32',
    'Density (kg-m3)': '2650',
    'Size, mm': '≥5, ≤38',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 101.1,
    65: '101.10',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 189,
    'Nominal Composition': 'Al–4.0Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5086',
    'UNS No.': 'A95086',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–4.0Mg–Mn | B209 | 5086 | A95086 | O',
    'Density (kg-m3)': '2650',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 64.4,
    65: '64.40',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 190,
    'Nominal Composition': 'Al–4.0Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5086',
    'UNS No.': 'A95086',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–4.0Mg–Mn | B209 | 5086 | A95086 | H112',
    'Density (kg-m3)': '2650',
    'Size, mm': '≥13, ≤25',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 73.5,
    65: '73.50',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 191,
    'Nominal Composition': 'Al–4.0Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5086',
    'UNS No.': 'A95086',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–4.0Mg–Mn | B209 | 5086 | A95086 | H32',
    'Density (kg-m3)': '2650',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 91.9,
    65: '91.90',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 192,
    'Nominal Composition': 'Al–4.0Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5086',
    'UNS No.': 'A95086',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–4.0Mg–Mn | B209 | 5086 | A95086 | H34',
    'Density (kg-m3)': '2650',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 101.1,
    65: '101.10',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 193,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5154',
    'UNS No.': 'A95154',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–3.5Mg | B209 | 5154 | A95154 | O',
    'Density (kg-m3)': '2660',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 50.6,
    65: '50.40',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 194,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5154',
    'UNS No.': 'A95154',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–3.5Mg | B209 | 5154 | A95154 | H112',
    'Density (kg-m3)': '2660',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 50.6,
    65: '50.40',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 195,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5154',
    'UNS No.': 'A95154',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–3.5Mg | B209 | 5154 | A95154 | H32',
    'Density (kg-m3)': '2660',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 82.7,
    65: '82.70',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 196,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5154',
    'UNS No.': 'A95154',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–3.5Mg | B209 | 5154 | A95154 | H34',
    'Density (kg-m3)': '2660',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 89.6,
    65: '89.60',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 197,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5254',
    'UNS No.': 'A95254',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–3.5Mg | B209 | 5254 | A95254 | O',
    'Density (kg-m3)': '2660',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 50.6,
    65: '50.40',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 198,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5254',
    'UNS No.': 'A95254',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–3.5Mg | B209 | 5254 | A95254 | H112',
    'Density (kg-m3)': '2660',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 50.6,
    65: '50.40',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 199,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5254',
    'UNS No.': 'A95254',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–3.5Mg | B209 | 5254 | A95254 | H32',
    'Density (kg-m3)': '2660',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 82.7,
    65: '82.70',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 200,
    'Nominal Composition': 'Al–3.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5254',
    'UNS No.': 'A95254',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–3.5Mg | B209 | 5254 | A95254 | H34',
    'Density (kg-m3)': '2660',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 89.6,
    65: '89.60',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 201,
    'Nominal Composition': 'Al–2.7Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5454',
    'UNS No.': 'A95454',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–2.7Mg–Mn | B209 | 5454 | A95454 | O',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 55.2,
    65: '55.20',
    100: '55.20',
    125: '48.90',
    150: '37.50',
    175: '28.60',
    200: '21.70',
    225: '20.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 202,
    'Nominal Composition': 'Al–2.7Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5454',
    'UNS No.': 'A95454',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–2.7Mg–Mn | B209 | 5454 | A95454 | H112',
    'Density (kg-m3)': '2690',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 55.2,
    65: '55.20',
    100: '55.20',
    125: '48.90',
    150: '37.50',
    175: '28.60',
    200: '21.70',
    225: '20.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 203,
    'Nominal Composition': 'Al–2.7Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5454',
    'UNS No.': 'A95454',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–2.7Mg–Mn | B209 | 5454 | A95454 | H32',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 82.7,
    65: '82.70',
    100: '82.70',
    125: '49.50',
    150: '37.50',
    175: '28.60',
    200: '21.70',
    225: '20.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 204,
    'Nominal Composition': 'Al–2.7Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5454',
    'UNS No.': 'A95454',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–2.7Mg–Mn | B209 | 5454 | A95454 | H34',
    'Density (kg-m3)': '2690',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 89.6,
    65: '89.60',
    100: '89.60',
    125: '49.50',
    150: '37.50',
    175: '28.60',
    200: '21.70',
    225: '20.70',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 205,
    'Nominal Composition': 'Al–5.1Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5456',
    'UNS No.': 'A95456',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–5.1Mg–Mn | B209 | 5456 | A95456 | O',
    'Density (kg-m3)': '2660',
    'Size, mm': '≥1.3, ≤38',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 87.3,
    65: '87.30',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 206,
    'Nominal Composition': 'Al–5.1Mg–Mn',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5456',
    'UNS No.': 'A95456',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–5.1Mg–Mn | B209 | 5456 | A95456 | H32',
    'Density (kg-m3)': '2660',
    'Size, mm': '≥5, <13',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 65,
    'Min. Temp. to 40': 105.7,
    65: '105.70',
    100: '…',
    125: '…',
    150: '…',
    175: '…',
    200: '…',
    225: '…',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 207,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5652',
    'UNS No.': 'A95652',
    'Class-Cond.-Temper': 'O',
    Material: 'Al–2.5Mg | B209 | 5652 | A95652 | O',
    'Density (kg-m3)': '2670',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 43.7,
    65: '43.70',
    100: '43.70',
    125: '43.60',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 208,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5652',
    'UNS No.': 'A95652',
    'Class-Cond.-Temper': 'H112',
    Material: 'Al–2.5Mg | B209 | 5652 | A95652 | H112',
    'Density (kg-m3)': '2670',
    'Size, mm': '≥13, ≤75',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 43.7,
    65: '43.70',
    100: '43.70',
    125: '43.60',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 209,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5652',
    'UNS No.': 'A95652',
    'Class-Cond.-Temper': 'H32',
    Material: 'Al–2.5Mg | B209 | 5652 | A95652 | H32',
    'Density (kg-m3)': '2670',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 71.2,
    65: '71.20',
    100: '71.20',
    125: '71.00',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 210,
    'Nominal Composition': 'Al–2.5Mg',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '5652',
    'UNS No.': 'A95652',
    'Class-Cond.-Temper': 'H34',
    Material: 'Al–2.5Mg | B209 | 5652 | A95652 | H34',
    'Density (kg-m3)': '2670',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 78.1,
    65: '78.10',
    100: '78.10',
    125: '78.10',
    150: '41.60',
    175: '28.80',
    200: '17.60',
    225: '16.10',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 211,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '6061',
    'UNS No.': 'A96061',
    'Class-Cond.-Temper': 'T4 wld.',
    Material: 'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T4 wld.',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 55.2,
    65: '55.20',
    100: '55.20',
    125: '54.30',
    150: '52.00',
    175: '46.30',
    200: '35.30',
    225: '34.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 212,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '6061',
    'UNS No.': 'A96061',
    'Class-Cond.-Temper': 'T6 wld.',
    Material: 'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T6 wld.',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 55.2,
    65: '55.20',
    100: '55.20',
    125: '54.30',
    150: '52.00',
    175: '46.30',
    200: '35.30',
    225: '34.80',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 213,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '6061',
    'UNS No.': 'A96061',
    'Class-Cond.-Temper': 'T4',
    Material: 'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T4',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 68.9,
    65: '68.90',
    100: '68.90',
    125: '67.80',
    150: '64.80',
    175: '57.90',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 214,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '6061',
    'UNS No.': 'A96061',
    'Class-Cond.-Temper': 'T6',
    Material: 'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T6',
    'Density (kg-m3)': '2700',
    'Size, mm': '',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 96.5,
    65: '96.50',
    100: '96.50',
    125: '92.50',
    150: '79.90',
    175: '63.10',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
  {
    No: 215,
    'Nominal Composition': 'Al–Mg–Si–Cu',
    'Product Form': 'Plate & sheet',
    'Spec. No.': 'B209',
    'Type-Grade': '6061',
    'UNS No.': 'A96061',
    'Class-Cond.-Temper': 'T651',
    Material: 'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T651',
    'Density (kg-m3)': '2700',
    'Size, mm': '≥6, ≤100',
    'Min. Temp., °C': '-269',
    'Max. Use Temp., °C': 204,
    'Min. Temp. to 40': 96.5,
    65: '96.50',
    100: '96.50',
    125: '92.50',
    150: '79.90',
    175: '63.10',
    200: '40.20',
    225: '35.90',
    250: '',
    275: '',
    300: '',
    325: '',
    350: '',
    375: '',
    400: '',
    425: '',
    450: '',
    475: '',
    500: '',
    525: '',
    550: '',
    575: '',
    600: '',
    625: '',
    650: '',
    675: '',
    700: '',
    725: '',
    750: '',
    775: '',
    800: '',
    825: '',
    850: '',
    875: '',
    900: '',
  },
];
export const list_of_material = {
  No: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 134, 135, 136, 137, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
    151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165,
    166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180,
    181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195,
    196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
    211, 212, 213, 214, 215,
  ],
  'Nominal Composition': [
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    'Carbon steel',
    '0.5 Cr–0.5 Mo',
    '1Cr–0.5 Mo',
    '9Cr–1Mo',
    '1.25 Cr–0.5 Mo–Si',
    '5Cr–0.5 Mo',
    '3Cr–1Mo',
    '2.25 Cr–1Mo',
    '2.25 Ni',
    '3.5 Ni',
    'C–0.5 Mo',
    '1Cr–0.5 Mo',
    '2.25 Ni',
    '3.5 Ni',
    '0.5 Cr–0.5 Mo',
    'C–0.5 Mo',
    'Mn–0.5 Mo',
    'C–0.5 Mo',
    '1.25 Cr–0.5 Mo–Si',
    '5Cr–0.5 Mo',
    '3Cr–1Mo',
    '2.25 Cr–1Mo',
    'Mn–0.5 Mo',
    'Mn–0.5 Mo–0.5 Ni',
    'Mn–0.5 Mo–0.75 Ni',
    '9Cr–1Mo–V',
    '8Ni',
    '5Ni–0.25 Mo',
    '9Ni',
    '9Ni',
    '18Cr–11Ni',
    '12Cr–Al',
    '18Cr–8Ni',
    '16Cr–12Ni–2Mo',
    '18Cr–8Ni',
    '12Cr–1Ni',
    '12Cr–1Ni',
    '13Cr',
    '13Cr',
    '15Cr',
    '17Cr',
    '18Cr–13Ni–3Mo',
    '25Cr–20Ni',
    '18Cr–10Ni–Ti',
    '23Cr–12Ni',
    '18Cr–10Ni–Cb',
    '18Cr–10Ni–Cb',
    '25Cr–20Ni',
    '18Cr–10Ni–Ti',
    '18Cr–10Ni–Ti',
    '16Cr–12Ni–2Mo',
    '18Cr–13Ni–3Mo',
    '18Cr–10Ni–Cb',
    '18Cr–10Ni–Cb',
    '18Cr–8Ni',
    '44Fe–25Ni–21Cr–Mo',
    '23Cr–4Ni–Mo–Cu–N',
    '22Cr–5Ni–3Mo–N',
    '16Cr–4Ni–6Mn',
    '20Cr–18Ni–6Mo',
    '20Cr–18Ni–6Mo',
    '46Fe–24Ni–21Cr–6Mo–Cu–N',
    '46Fe–24Ni–21Cr–6Mo–Cu–N',
    '21Cr–5Mn–1.5Ni–Cu–N',
    '24Cr–4Ni–3Mn–1.5Mo–N',
    '21Cr–5Mn–1.5Ni–Cu–N',
    '21Cr−3.5 Ni–1.75 Mo−N',
    '21Cr−3.5 Ni–1.75 Mo−N',
    '24Cr–4Ni–3Mn–1.5Mo–N',
    '29Cr–6.5Ni–2Mo–N',
    '29Cr–6.5Ni–2Mo–N',
    '25Cr–8Ni–3Mo–W–Cu–N',
    '25Cr–7Ni–4Mo–N',
    '99.95Cu–P',
    '99.95Cu–Ag',
    '99.95Cu–Ag',
    '99.95Cu–Ag',
    '99.9Cu–P',
    '99.9Cu–P',
    '90Cu–10Ni',
    '97Cu–3Si',
    '70Cu–30Ni',
    '90Cu–7Al–3Fe',
    '90Cu–7Al–3Fe',
    '99.0Ni–Low C',
    '99.0Ni–Low C',
    '99.0Ni',
    '99.0Ni',
    '33Ni–42Fe–21Cr',
    '33Ni–42Fe–21Cr–Al–Ti',
    '26Ni–22Cr–5Mo–Ti',
    '67Ni–30Cu',
    '47Ni–22Cr–19Fe–6Mo',
    '33Ni–42Fe–21Cr',
    '31Ni–31Fe–29Cr–Mo',
    '42Ni–21.5Cr–3Mo–2.3Cu',
    '35Ni–35Fe–20Cr–Cb',
    '40Ni–29Cr–15Fe–5Mo',
    '47Ni–22Cr–19Fe–6Mo',
    '47Ni–22Cr–9Mo–18Fe',
    '72Ni–15Cr–8Fe',
    '72Ni–15Cr–8Fe',
    '58Ni–29Cr–9Fe',
    '58Ni–29Cr–9Fe',
    '67Ni–30Cu',
    '37Ni–33Fe–25Cr',
    '31Ni–33Fe–27Cr–6.5Mo–Cu–N',
    '61Ni–16Mo–16Cr',
    '54Ni–16Mo–15Cr',
    '60Ni–22Cr–9Mo–3.5Cb',
    '57Ni–22Cr–14W–2Mo–La',
    '55Ni–21Cr–13.5Mo',
    '58Ni–33Cr–8Mo',
    '46Fe–24Ni–21Cr–6Mo–Cu–N',
    '62Ni–28Mo–5Fe',
    '65Ni–28Mo–2Fe',
    '65Ni–29.5Mo–2Fe–2Cr',
    '33Cr–31Ni–32Fe–1.5Mo–0.6Cu–N',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    '99.60Al',
    '99.60Al',
    '99.60Al',
    '99.60Al',
    '99.0Al–Cu',
    '99.0Al–Cu',
    '99.0Al–Cu',
    '99.0Al–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Cu',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–Mn–Mg',
    'Al–1.5Mg',
    'Al–1.5Mg',
    'Al–1.5Mg',
    'Al–1.5Mg',
    'Al–2.5Mg',
    'Al–2.5Mg',
    'Al–2.5Mg',
    'Al–2.5Mg',
    'Al–4.4Mg–Mn',
    'Al–4.4Mg–Mn',
    'Al–4.0Mg–Mn',
    'Al–4.0Mg–Mn',
    'Al–4.0Mg–Mn',
    'Al–4.0Mg–Mn',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–3.5Mg',
    'Al–2.7Mg–Mn',
    'Al–2.7Mg–Mn',
    'Al–2.7Mg–Mn',
    'Al–2.7Mg–Mn',
    'Al–5.1Mg–Mn',
    'Al–5.1Mg–Mn',
    'Al–2.5Mg',
    'Al–2.5Mg',
    'Al–2.5Mg',
    'Al–2.5Mg',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
    'Al–Mg–Si–Cu',
  ],
  'Product Form': [
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate, bar, shps., sheet',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate, sheet, strip',
    'Plate, sheet, strip',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate, sheet, strip',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate, sheet, strip',
    'Plate, sheet, strip',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate',
    'Sheet',
    'Plate',
    'Sheet & strip',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate',
    'Plate',
    'Plate',
    'Plate',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Plate',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Plate',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Plate',
    'Plate',
    'Plate',
    'Sheet',
    'Plate',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Plate',
    'Pl. & sht.',
    'Sheet',
    'Pl. & sht.',
    'Pl. & sht.',
    'Sheet',
    'Pl. & sht.',
    'Pl. & sht.',
    'Pl. & sht.',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
    'Plate & sheet',
  ],
  'Spec. No.': [
    'A285',
    'A285',
    'A516',
    'A285',
    'A516',
    'A515',
    'A696',
    'A516',
    'A515',
    'A516',
    'A515',
    'A696',
    'A537',
    'A299',
    'A299',
    'A387',
    'A387',
    'A387',
    'A387',
    'A387',
    'A387',
    'A387',
    'A203',
    'A203',
    'A204',
    'A387',
    'A203',
    'A203',
    'A387',
    'A204',
    'A302',
    'A204',
    'A387',
    'A387',
    'A387',
    'A387',
    'A302',
    'A302',
    'A302',
    'A387',
    'A553',
    'A645',
    'A553',
    'A353',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A1010',
    'A1010',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'A240',
    'B152',
    'B152',
    'B152',
    'B152',
    'B152',
    'B152',
    'B171',
    'B96',
    'B171',
    'B169',
    'B169',
    'B162',
    'B162',
    'B162',
    'B162',
    'B409',
    'B409',
    'B620',
    'B127',
    'B582',
    'B409',
    'B709',
    'B424',
    'B463',
    'B582',
    'B582',
    'B435',
    'B168',
    'B168',
    'B168',
    'B168',
    'B127',
    'B409',
    'B625',
    'B575',
    'B575',
    'B443',
    'B435',
    'B575',
    'B575',
    'B688',
    'B333',
    'B333',
    'B333',
    'B625',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
    'B209',
  ],
  'Type-Grade': [
    'A',
    'B',
    '55',
    'C',
    '60',
    '60',
    'B',
    '65',
    '65',
    '70',
    '70',
    'C',
    '…',
    'A',
    'A',
    '2',
    '12',
    '9',
    '11',
    '5',
    '21',
    '22',
    'A',
    'D',
    'A',
    '12',
    'B',
    'E',
    '2',
    'B',
    'A',
    'C',
    '11',
    '5',
    '21',
    '22',
    'B',
    'C',
    'D',
    '91',
    'II',
    'A',
    'I',
    '…',
    '305',
    '405',
    '304L',
    '316L',
    '302',
    '40',
    '50',
    '410S',
    '410',
    '429',
    '430',
    '317L',
    '310S',
    '321',
    '309S',
    '347',
    '348',
    '310H',
    '321',
    '321H',
    '316',
    '317',
    '347',
    '348',
    '304',
    '904L',
    '2304',
    '…',
    '201LN',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '2507',
    'C10200',
    'C10400',
    'C10500',
    'C10700',
    'C12200',
    'C12300',
    'C70600',
    'C65500',
    'C71500',
    'C61400',
    'C61400',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '1',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    'Alclad 3003',
    'Alclad 3003',
    'Alclad 3003',
    'Alclad 3003',
    'Alclad 3003',
    'Alclad 3003',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 3004',
    'Alclad 6061',
    'Alclad 6061',
    'Alclad 6061',
    'Alclad 6061',
    'Alclad 6061',
    'Alclad 6061',
    'Alclad 6061',
    'Alclad 6061',
    '1060',
    '1060',
    '1060',
    '1060',
    '1100',
    '1100',
    '1100',
    '1100',
    '3003',
    '3003',
    '3003',
    '3003',
    '3004',
    '3004',
    '3004',
    '3004',
    '5050',
    '5050',
    '5050',
    '5050',
    '5052',
    '5052',
    '5052',
    '5052',
    '5083',
    '5083',
    '5086',
    '5086',
    '5086',
    '5086',
    '5154',
    '5154',
    '5154',
    '5154',
    '5254',
    '5254',
    '5254',
    '5254',
    '5454',
    '5454',
    '5454',
    '5454',
    '5456',
    '5456',
    '5652',
    '5652',
    '5652',
    '5652',
    '6061',
    '6061',
    '6061',
    '6061',
    '6061',
  ],
  'UNS No.': [
    'K01700',
    'K02200',
    'K01800',
    'K02801',
    'K02100',
    'K02401',
    'K03200',
    'K02403',
    'K02800',
    'K02700',
    'K03101',
    'K03200',
    'K12437',
    'K02803',
    'K02803',
    'K12143',
    'K11757',
    'K90941',
    'K11789',
    'K41545',
    'K31545',
    'K21590',
    'K21703',
    'K31718',
    'K11820',
    'K11757',
    'K22103',
    'K32018',
    'K12143',
    'K12020',
    'K12021',
    'K12320',
    'K11789',
    'K41545',
    'K31545',
    'K21590',
    'K12022',
    'K12039',
    'K12054',
    'K90901',
    'K71340',
    'K41583',
    'K81340',
    'K81340',
    'S30500',
    'S40500',
    'S30403',
    'S31603',
    'S30200',
    'S41003',
    'S41003',
    'S41008',
    'S41000',
    'S42900',
    'S43000',
    'S31703',
    'S31008',
    'S32100',
    'S30908',
    'S34700',
    'S34800',
    'S31009',
    'S32100',
    'S32109',
    'S31600',
    'S31700',
    'S34700',
    'S34800',
    'S30400',
    'N08904',
    'S32304',
    'S31803',
    'S20153',
    'S31254',
    'S31254',
    'N08367',
    'N08367',
    'S32101',
    'S82441',
    'S32101',
    'S32003',
    'S32003',
    'S82441',
    'S32906',
    'S32906',
    'S32760',
    'S32750',
    'O25',
    'O25',
    'O25',
    'O25',
    'O25',
    'O25',
    '…',
    'O61',
    '…',
    'O25',
    'O60',
    'N02201',
    'N02201',
    'N02200',
    'N02200',
    'N08810',
    'N08811',
    'N08320',
    'N04400',
    'N06007',
    'N08800',
    'N08028',
    'N08825',
    'N08020',
    'N06030',
    'N06007',
    'N06002',
    'N06600',
    'N06600',
    'N06690',
    'N06690',
    'N04400',
    'N08120',
    'N08031',
    'N06455',
    'N10276',
    'N06625',
    'N06230',
    'N06022',
    'N06035',
    'N08367',
    'N10001',
    'N10665',
    'N10675',
    'R20033',
    'A83003',
    'A83003',
    'A83003',
    'A83003',
    'A83003',
    'A83003',
    'A83004',
    'A83004',
    'A83004',
    'A83004',
    'A83004',
    'A83004',
    'A83004',
    'A83004',
    'A86061',
    'A86061',
    'A86061',
    'A86061',
    'A86061',
    'A86061',
    'A86061',
    'A86061',
    'A91060',
    'A91060',
    'A91060',
    'A91060',
    'A91100',
    'A91100',
    'A91100',
    'A91100',
    'A93003',
    'A93003',
    'A93003',
    'A93003',
    'A93004',
    'A93004',
    'A93004',
    'A93004',
    'A95050',
    'A95050',
    'A95050',
    'A95050',
    'A95052',
    'A95052',
    'A95052',
    'A95052',
    'A95083',
    'A95083',
    'A95086',
    'A95086',
    'A95086',
    'A95086',
    'A95154',
    'A95154',
    'A95154',
    'A95154',
    'A95254',
    'A95254',
    'A95254',
    'A95254',
    'A95454',
    'A95454',
    'A95454',
    'A95454',
    'A95456',
    'A95456',
    'A95652',
    'A95652',
    'A95652',
    'A95652',
    'A96061',
    'A96061',
    'A96061',
    'A96061',
    'A96061',
  ],
  'Class-Cond.-Temper': [
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '1',
    '…',
    '…',
    '1',
    '1',
    '1',
    '1',
    '1',
    '1',
    '1',
    '…',
    '…',
    '…',
    '2',
    '…',
    '…',
    '2',
    '…',
    '…',
    '…',
    '2',
    '2',
    '2',
    '2',
    '…',
    '…',
    '…',
    '2',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '≤64 thk.',
    '…',
    '≤64 thk.',
    '≤50 thk.',
    '≤50 thk.',
    'H.R. ann.',
    'H.R. as R.',
    'H.R. ann.',
    'H.R. as R.',
    'Annealed',
    'Annealed',
    'Sol. ann.',
    'H.R. ann.',
    'Sol. ann.',
    'Annealed',
    'Sol. ann.',
    'Annealed',
    'Annealed',
    'Sol. ann.',
    'Sol. ann.',
    'H.R sol. ann.',
    'H.R. ann.',
    'H.R. as R.',
    'Annealed',
    'H.R. ann. orC.R. ann.',
    'H.R. as R.',
    'Sol. ann.',
    'Annealed',
    'Sol. ann.',
    'Sol. ann.',
    'Annealed',
    'Sol. ann.',
    'Sol. ann.',
    'Sol. ann.',
    'Annealed',
    'Sol. ann.',
    'Sol. ann.',
    'Sol. ann.',
    'Sol. ann.',
    'O',
    'H112',
    'H12',
    'H12',
    'H14',
    'H14',
    'O',
    'O',
    'H112',
    'H112',
    'H32',
    'H32',
    'H34',
    'H34',
    'T4 wld.',
    'T6 wld.',
    'T4',
    'T451',
    'T451',
    'T6',
    'T651',
    'T651',
    'O',
    'H112',
    'H12',
    'H14',
    'O',
    'H112',
    'H12',
    'H14',
    'O',
    'H112',
    'H12',
    'H14',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H32',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H112',
    'H32',
    'H34',
    'O',
    'H32',
    'O',
    'H112',
    'H32',
    'H34',
    'T4 wld.',
    'T6 wld.',
    'T4',
    'T6',
    'T651',
  ],
  Material: [
    'Carbon steel | A285 | A | K01700 | …',
    'Carbon steel | A285 | B | K02200 | …',
    'Carbon steel | A516 | 55 | K01800 | …',
    'Carbon steel | A285 | C | K02801 | …',
    'Carbon steel | A516 | 60 | K02100 | …',
    'Carbon steel | A515 | 60 | K02401 | …',
    'Carbon steel | A696 | B | K03200 | …',
    'Carbon steel | A516 | 65 | K02403 | …',
    'Carbon steel | A515 | 65 | K02800 | …',
    'Carbon steel | A516 | 70 | K02700 | …',
    'Carbon steel | A515 | 70 | K03101 | …',
    'Carbon steel | A696 | C | K03200 | …',
    'Carbon steel | A537 | … | K12437 | 1',
    'Carbon steel | A299 | A | K02803 | …',
    'Carbon steel | A299 | A | K02803 | …',
    '0.5 Cr–0.5 Mo | A387 | 2 | K12143 | 1',
    '1Cr–0.5 Mo | A387 | 12 | K11757 | 1',
    '9Cr–1Mo | A387 | 9 | K90941 | 1',
    '1.25 Cr–0.5 Mo–Si | A387 | 11 | K11789 | 1',
    '5Cr–0.5 Mo | A387 | 5 | K41545 | 1',
    '3Cr–1Mo | A387 | 21 | K31545 | 1',
    '2.25 Cr–1Mo | A387 | 22 | K21590 | 1',
    '2.25 Ni | A203 | A | K21703 | …',
    '3.5 Ni | A203 | D | K31718 | …',
    'C–0.5 Mo | A204 | A | K11820 | …',
    '1Cr–0.5 Mo | A387 | 12 | K11757 | 2',
    '2.25 Ni | A203 | B | K22103 | …',
    '3.5 Ni | A203 | E | K32018 | …',
    '0.5 Cr–0.5 Mo | A387 | 2 | K12143 | 2',
    'C–0.5 Mo | A204 | B | K12020 | …',
    'Mn–0.5 Mo | A302 | A | K12021 | …',
    'C–0.5 Mo | A204 | C | K12320 | …',
    '1.25 Cr–0.5 Mo–Si | A387 | 11 | K11789 | 2',
    '5Cr–0.5 Mo | A387 | 5 | K41545 | 2',
    '3Cr–1Mo | A387 | 21 | K31545 | 2',
    '2.25 Cr–1Mo | A387 | 22 | K21590 | 2',
    'Mn–0.5 Mo | A302 | B | K12022 | …',
    'Mn–0.5 Mo–0.5 Ni | A302 | C | K12039 | …',
    'Mn–0.5 Mo–0.75 Ni | A302 | D | K12054 | …',
    '9Cr–1Mo–V | A387 | 91 | K90901 | 2',
    '8Ni | A553 | II | K71340 | …',
    '5Ni–0.25 Mo | A645 | A | K41583 | …',
    '9Ni | A553 | I | K81340 | …',
    '9Ni | A353 | … | K81340 | …',
    '18Cr–11Ni | A240 | 305 | S30500 | …',
    '12Cr–Al | A240 | 405 | S40500 | …',
    '18Cr–8Ni | A240 | 304L | S30403 | …',
    '16Cr–12Ni–2Mo | A240 | 316L | S31603 | …',
    '18Cr–8Ni | A240 | 302 | S30200 | …',
    '12Cr–1Ni | A1010 | 40 | S41003 | …',
    '12Cr–1Ni | A1010 | 50 | S41003 | …',
    '13Cr | A240 | 410S | S41008 | …',
    '13Cr | A240 | 410 | S41000 | …',
    '15Cr | A240 | 429 | S42900 | …',
    '17Cr | A240 | 430 | S43000 | …',
    '18Cr–13Ni–3Mo | A240 | 317L | S31703 | …',
    '25Cr–20Ni | A240 | 310S | S31008 | …',
    '18Cr–10Ni–Ti | A240 | 321 | S32100 | …',
    '23Cr–12Ni | A240 | 309S | S30908 | …',
    '18Cr–10Ni–Cb | A240 | 347 | S34700 | …',
    '18Cr–10Ni–Cb | A240 | 348 | S34800 | …',
    '25Cr–20Ni | A240 | 310H | S31009 | …',
    '18Cr–10Ni–Ti | A240 | 321 | S32100 | …',
    '18Cr–10Ni–Ti | A240 | 321H | S32109 | …',
    '16Cr–12Ni–2Mo | A240 | 316 | S31600 | …',
    '18Cr–13Ni–3Mo | A240 | 317 | S31700 | …',
    '18Cr–10Ni–Cb | A240 | 347 | S34700 | …',
    '18Cr–10Ni–Cb | A240 | 348 | S34800 | …',
    '18Cr–8Ni | A240 | 304 | S30400 | …',
    '44Fe–25Ni–21Cr–Mo | A240 | 904L | N08904 | …',
    '23Cr–4Ni–Mo–Cu–N | A240 | 2304 | S32304 | …',
    '22Cr–5Ni–3Mo–N | A240 | … | S31803 | …',
    '16Cr–4Ni–6Mn | A240 | 201LN | S20153 | …',
    '20Cr–18Ni–6Mo | A240 | … | S31254 | …',
    '20Cr–18Ni–6Mo | A240 | … | S31254 | …',
    '46Fe–24Ni–21Cr–6Mo–Cu–N | A240 | … | N08367 | …',
    '46Fe–24Ni–21Cr–6Mo–Cu–N | A240 | … | N08367 | …',
    '21Cr–5Mn–1.5Ni–Cu–N | A240 | … | S32101 | …',
    '24Cr–4Ni–3Mn–1.5Mo–N | A240 | … | S82441 | …',
    '21Cr–5Mn–1.5Ni–Cu–N | A240 | … | S32101 | …',
    '21Cr−3.5 Ni–1.75 Mo−N | A240 | … | S32003 | …',
    '21Cr−3.5 Ni–1.75 Mo−N | A240 | … | S32003 | …',
    '24Cr–4Ni–3Mn–1.5Mo–N | A240 | … | S82441 | …',
    '29Cr–6.5Ni–2Mo–N | A240 | … | S32906 | …',
    '29Cr–6.5Ni–2Mo–N | A240 | … | S32906 | …',
    '25Cr–8Ni–3Mo–W–Cu–N | A240 | … | S32760 | …',
    '25Cr–7Ni–4Mo–N | A240 | 2507 | S32750 | …',
    '99.95Cu–P | B152 | C10200 | O25 | …',
    '99.95Cu–Ag | B152 | C10400 | O25 | …',
    '99.95Cu–Ag | B152 | C10500 | O25 | …',
    '99.95Cu–Ag | B152 | C10700 | O25 | …',
    '99.9Cu–P | B152 | C12200 | O25 | …',
    '99.9Cu–P | B152 | C12300 | O25 | …',
    '90Cu–10Ni | B171 | C70600 | … | ≤64 thk.',
    '97Cu–3Si | B96 | C65500 | O61 | …',
    '70Cu–30Ni | B171 | C71500 | … | ≤64 thk.',
    '90Cu–7Al–3Fe | B169 | C61400 | O25 | ≤50 thk.',
    '90Cu–7Al–3Fe | B169 | C61400 | O60 | ≤50 thk.',
    '99.0Ni–Low C | B162 | … | N02201 | H.R. ann.',
    '99.0Ni–Low C | B162 | … | N02201 | H.R. as R.',
    '99.0Ni | B162 | … | N02200 | H.R. ann.',
    '99.0Ni | B162 | … | N02200 | H.R. as R.',
    '33Ni–42Fe–21Cr | B409 | … | N08810 | Annealed',
    '33Ni–42Fe–21Cr–Al–Ti | B409 | … | N08811 | Annealed',
    '26Ni–22Cr–5Mo–Ti | B620 | … | N08320 | Sol. ann.',
    '67Ni–30Cu | B127 | … | N04400 | H.R. ann.',
    '47Ni–22Cr–19Fe–6Mo | B582 | … | N06007 | Sol. ann.',
    '33Ni–42Fe–21Cr | B409 | … | N08800 | Annealed',
    '31Ni–31Fe–29Cr–Mo | B709 | … | N08028 | Sol. ann.',
    '42Ni–21.5Cr–3Mo–2.3Cu | B424 | … | N08825 | Annealed',
    '35Ni–35Fe–20Cr–Cb | B463 | … | N08020 | Annealed',
    '40Ni–29Cr–15Fe–5Mo | B582 | … | N06030 | Sol. ann.',
    '47Ni–22Cr–19Fe–6Mo | B582 | … | N06007 | Sol. ann.',
    '47Ni–22Cr–9Mo–18Fe | B435 | … | N06002 | H.R sol. ann.',
    '72Ni–15Cr–8Fe | B168 | … | N06600 | H.R. ann.',
    '72Ni–15Cr–8Fe | B168 | … | N06600 | H.R. as R.',
    '58Ni–29Cr–9Fe | B168 | … | N06690 | Annealed',
    '58Ni–29Cr–9Fe | B168 | … | N06690 | H.R. ann. orC.R. ann.',
    '67Ni–30Cu | B127 | … | N04400 | H.R. as R.',
    '37Ni–33Fe–25Cr | B409 | … | N08120 | Sol. ann.',
    '31Ni–33Fe–27Cr–6.5Mo–Cu–N | B625 | … | N08031 | Annealed',
    '61Ni–16Mo–16Cr | B575 | … | N06455 | Sol. ann.',
    '54Ni–16Mo–15Cr | B575 | … | N10276 | Sol. ann.',
    '60Ni–22Cr–9Mo–3.5Cb | B443 | 1 | N06625 | Annealed',
    '57Ni–22Cr–14W–2Mo–La | B435 | … | N06230 | Sol. ann.',
    '55Ni–21Cr–13.5Mo | B575 | … | N06022 | Sol. ann.',
    '58Ni–33Cr–8Mo | B575 | … | N06035 | Sol. ann.',
    '46Fe–24Ni–21Cr–6Mo–Cu–N | B688 | … | N08367 | Annealed,8030,>5,-198,427.00,207.00,207.00,207.00,207.00,206.00,202.00,198.00,195.00,192.00,188.00,183.00,179.00,176.00,173.00,170.00,168.00,167.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n129,46Fe–24Ni–21Cr–6Mo–Cu–N,Pl. & sht.,B688,…,N08367,Annealed,46Fe–24Ni–21Cr–6Mo–Cu–N | B688 | … | N08367 | Annealed,8030,≤5,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,206.00,199.00,193.00,188.00,183.00,179.00,176.00,173.00,170.00,168.00,167.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n130,59Ni–23Cr–16Mo,Pl. & sht.,B575,…,N06059,Sol. ann.,59Ni–23Cr–16Mo | B575 | … | N06059 | Sol. ann.,8600,,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,206.00,202.00,197.00,192.00,188.00,184.00,180.00,176.00,176.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n131,59Ni–23Cr–16Mo–1.6Cu,Pl. & sht.,B575,…,N06200,Sol. ann.,59Ni–23Cr–16Mo–1.6Cu | B575 | … | N06200 | Sol. ann.,8870,,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,207.00,200.00,194.00,188.00,184.00,180.00,177.00,175.00,174.00,173.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n132,62Ni–22Mo–15Cr,Pl. & sht.,B575,…,N10362,Sol. ann.,62Ni–22Mo–15Cr | B575 | … | N10362 | Sol. ann.,8830,,-198,427.00,207.00,207.00,207.00,207.00,207.00,207.00,204.00,202.00,199.00,197.00,193.00,190.00,188.00,186.00,184.00,182.00,180.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n133,62Ni–28Mo–5Fe,Plate,B333,…,N10001,Sol. ann.,62Ni–28Mo–5Fe | B333 | … | N10001 | Sol. ann.,9240,',
    '62Ni–28Mo–5Fe | B333 | … | N10001 | Sol. ann.',
    '65Ni–28Mo–2Fe | B333 | … | N10665 | Sol. ann.',
    '65Ni–29.5Mo–2Fe–2Cr | B333 | … | N10675 | Sol. ann.',
    '33Cr–31Ni–32Fe–1.5Mo–0.6Cu–N | B625 | … | R20033 | Sol. ann.,7900,,-198,427.00,250.00,231.00,209.00,200.00,193.00,187.00,181.00,176.00,172.00,169.00,165.00,163.00,161.00,159.00,157.00,156.00,155.00,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…,…\n138,99.2Zr,Plate, sheet,strip,B551,…,R60702,…,99.2Zr | B551 | … | R60702 | …,6510,,-59,371.00,126.00,119.00,103.00,92.40,82.50,73.60,65.70,58.80,52.80,47.80,43.70,40.40,37.80,36.10,,,,,,,,,,,,,,,,,,,,,\n139,95.5Zr + 2.5Nb,Plate, sheet,strip,B551,…,R60705,…,95.5Zr + 2.5Nb | B551 | … | R60705 | …,6640,,-59,371.00,184.00,169.00,149.00,139.00,130.00,123.00,116.00,111.00,106.00,101.00,97.60,94.60,92.40,91.00,,,,,,,,,,,,,,,,,,,,,\n140,Al–Mn–Cu,Plate & sheet,B209,Alclad 3003,A83003,O,Al–Mn–Cu | B209 | Alclad 3003 | A83003 | O,2730,',
    'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | O',
    'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H112',
    'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H12',
    'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H12',
    'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H14',
    'Al–Mn–Cu | B209 | Alclad 3003 | A83003 | H14',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | O',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | O',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H112',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H112',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H32',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H32',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H34',
    'Al–Mn–Mg | B209 | Alclad 3004 | A83004 | H34',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T4 wld.',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T6 wld.',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T4',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T451',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T451',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T6',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T651',
    'Al–Mg–Si–Cu | B209 | Alclad 6061 | A86061 | T651',
    '99.60Al | B209 | 1060 | A91060 | O',
    '99.60Al | B209 | 1060 | A91060 | H112',
    '99.60Al | B209 | 1060 | A91060 | H12',
    '99.60Al | B209 | 1060 | A91060 | H14',
    '99.0Al–Cu | B209 | 1100 | A91100 | O',
    '99.0Al–Cu | B209 | 1100 | A91100 | H112',
    '99.0Al–Cu | B209 | 1100 | A91100 | H12',
    '99.0Al–Cu | B209 | 1100 | A91100 | H14',
    'Al–Mn–Cu | B209 | 3003 | A93003 | O',
    'Al–Mn–Cu | B209 | 3003 | A93003 | H112',
    'Al–Mn–Cu | B209 | 3003 | A93003 | H12',
    'Al–Mn–Cu | B209 | 3003 | A93003 | H14',
    'Al–Mn–Mg | B209 | 3004 | A93004 | O',
    'Al–Mn–Mg | B209 | 3004 | A93004 | H112',
    'Al–Mn–Mg | B209 | 3004 | A93004 | H32',
    'Al–Mn–Mg | B209 | 3004 | A93004 | H34',
    'Al–1.5Mg | B209 | 5050 | A95050 | O',
    'Al–1.5Mg | B209 | 5050 | A95050 | H112',
    'Al–1.5Mg | B209 | 5050 | A95050 | H32',
    'Al–1.5Mg | B209 | 5050 | A95050 | H34',
    'Al–2.5Mg | B209 | 5052 | A95052 | O',
    'Al–2.5Mg | B209 | 5052 | A95052 | H112',
    'Al–2.5Mg | B209 | 5052 | A95052 | H32',
    'Al–2.5Mg | B209 | 5052 | A95052 | H34',
    'Al–4.4Mg–Mn | B209 | 5083 | A95083 | O',
    'Al–4.4Mg–Mn | B209 | 5083 | A95083 | H32',
    'Al–4.0Mg–Mn | B209 | 5086 | A95086 | O',
    'Al–4.0Mg–Mn | B209 | 5086 | A95086 | H112',
    'Al–4.0Mg–Mn | B209 | 5086 | A95086 | H32',
    'Al–4.0Mg–Mn | B209 | 5086 | A95086 | H34',
    'Al–3.5Mg | B209 | 5154 | A95154 | O',
    'Al–3.5Mg | B209 | 5154 | A95154 | H112',
    'Al–3.5Mg | B209 | 5154 | A95154 | H32',
    'Al–3.5Mg | B209 | 5154 | A95154 | H34',
    'Al–3.5Mg | B209 | 5254 | A95254 | O',
    'Al–3.5Mg | B209 | 5254 | A95254 | H112',
    'Al–3.5Mg | B209 | 5254 | A95254 | H32',
    'Al–3.5Mg | B209 | 5254 | A95254 | H34',
    'Al–2.7Mg–Mn | B209 | 5454 | A95454 | O',
    'Al–2.7Mg–Mn | B209 | 5454 | A95454 | H112',
    'Al–2.7Mg–Mn | B209 | 5454 | A95454 | H32',
    'Al–2.7Mg–Mn | B209 | 5454 | A95454 | H34',
    'Al–5.1Mg–Mn | B209 | 5456 | A95456 | O',
    'Al–5.1Mg–Mn | B209 | 5456 | A95456 | H32',
    'Al–2.5Mg | B209 | 5652 | A95652 | O',
    'Al–2.5Mg | B209 | 5652 | A95652 | H112',
    'Al–2.5Mg | B209 | 5652 | A95652 | H32',
    'Al–2.5Mg | B209 | 5652 | A95652 | H34',
    'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T4 wld.',
    'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T6 wld.',
    'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T4',
    'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T6',
    'Al–Mg–Si–Cu | B209 | 6061 | A96061 | T651',
  ],
  'Density (kg-m3)': [
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7800',
    '7900',
    '7800',
    '7900',
    '7850',
    '7850',
    '7800',
    '7800',
    '7800',
    '7900',
    '7850',
    '7800',
    '7900',
    '7800',
    '7800',
    '7900',
    '7900',
    '7800',
    '7900',
    '7850',
    '7800',
    '7800',
    '7800',
    '7900',
    '7900',
    '7990',
    '7800',
    '8000',
    '8027',
    '7860',
    '7800',
    '7800',
    '7700',
    '7700',
    '7700',
    '7800',
    '8000',
    '7980',
    '7920',
    '7890',
    '7960',
    '7960',
    '7890',
    '7920',
    '7920',
    '7870',
    '7890',
    '7960',
    '8000',
    '8000',
    '7950',
    '7800',
    '7800',
    '7800',
    '8000',
    '8000',
    '8020',
    '8020',
    '7700',
    '7700',
    '7800',
    '7800',
    '7800',
    '7700',
    '7700',
    '7700',
    '7780',
    '7750',
    '8910',
    '8900',
    '8900',
    '8900',
    '8900',
    '8900',
    '8900',
    '8530',
    '8950',
    '7800',
    '7800',
    '8900',
    '8900',
    '8890',
    '8890',
    '7940',
    '7940',
    '8000',
    '8800',
    '8910',
    '7940',
    '8100',
    '8140',
    '8100',
    '8500',
    '8400',
    '8220',
    '8470',
    '8470',
    '8200',
    '8200',
    '8800',
    '8200',
    '8050',
    '8640',
    '8890',
    '8400',
    '8960',
    '8610',
    '8220',
    ' ≤64"',
    '9240',
    '8720',
    '9220',
    ' <13"',
    '2730',
    '2730',
    '2730',
    '2730',
    '2730',
    '2730',
    '2720',
    '2720',
    '2720',
    '2720',
    '2720',
    '2720',
    '2720',
    '2720',
    '2700',
    '2700',
    '2700',
    '2700',
    '2700',
    '2700',
    '2700',
    '2700',
    '2705',
    '2705',
    '2705',
    '2705',
    '2710',
    '2710',
    '2710',
    '2710',
    '2730',
    '2730',
    '2730',
    '2730',
    '2600',
    '2600',
    '2600',
    '2600',
    '2690',
    '2690',
    '2690',
    '2690',
    '2690',
    '2690',
    '2690',
    '2690',
    '2650',
    '2650',
    '2650',
    '2650',
    '2650',
    '2650',
    '2660',
    '2660',
    '2660',
    '2660',
    '2660',
    '2660',
    '2660',
    '2660',
    '2690',
    '2690',
    '2690',
    '2690',
    '2660',
    '2660',
    '2670',
    '2670',
    '2670',
    '2670',
    '2700',
    '2700',
    '2700',
    '2700',
    '2700',
  ],
  'Size, mm': [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '≤64',
    '>25',
    '≤25',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '≤75',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '>5.0',
    '≤5.0',
    '>5.0',
    '≤5.0',
    '>5.0',
    '≥10.0',
    '≤5.0',
    '>5.00',
    '≤5.00',
    '<10.0',
    '≥10.0',
    '≤10.0',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '>19',
    '',
    '',
    '',
    '',
    '',
    '≤19',
    '',
    '',
    '',
    '≥5',
    '0.5 to 6',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '<5',
    '',
    '-198',
    '≤5',
    '',
    '',
    '-269',
    '≥13, ≤75',
    '≥13, ≤50',
    '≥0.43, <13',
    '≥13, ≤50',
    '≥0.23, <13',
    '≥13, ≤25',
    '≥0.15, <13',
    '≥13, ≤75',
    '≥6, <13',
    '≥13, ≤75',
    '≥0.43, <13',
    '≥13, ≤50',
    '≥0.23, <13',
    '≥13, ≤25',
    '',
    '',
    '',
    '≥6, <13',
    '≥13, ≤75',
    '',
    '≥6, <13',
    '≥13, ≤100',
    '',
    '≥13, ≤25',
    '',
    '',
    '',
    '≥13, ≤50',
    '',
    '',
    '',
    '≥13, ≤50',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '≥13, ≤75',
    '',
    '',
    '≥1.3, ≤38',
    '≥5, ≤38',
    '',
    '≥13, ≤25',
    '',
    '',
    '',
    '≥13, ≤75',
    '',
    '',
    '',
    '≥13, ≤75',
    '',
    '',
    '',
    '≥13, ≤75',
    '',
    '',
    '≥1.3, ≤38',
    '≥5, <13',
    '',
    '≥13, ≤75',
    '',
    '',
    '',
    '',
    '',
    '',
    '≥6, ≤100',
  ],
  'Min. Temp., °C': [
    'B',
    'B',
    'C',
    'A',
    'C',
    'B',
    'A',
    'B',
    'A',
    'B',
    'A',
    'A',
    'D',
    'A',
    'A',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29.00',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-171',
    '-171',
    '-196',
    '-196',
    '-198',
    '-29',
    '-254',
    '-254',
    '-198',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-29',
    '-198',
    '-198',
    '-198',
    '-198',
    '-254',
    '-198',
    '-198',
    '-198',
    '-198',
    '-254',
    '-198',
    '-254',
    '-198',
    '-254',
    '-198',
    '-51',
    '-51',
    '-198',
    '-254',
    '-254',
    '-198',
    '-198',
    '-29',
    '-51',
    '-29',
    '-51',
    '-51',
    '-51',
    '-51',
    '-51',
    '-51',
    '-51',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '-198',
    '427.00',
    '-198',
    '-198',
    '-198',
    '204.00',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
    '-269',
  ],
  'Max. Use Temp., °C': [
    593, 593, 454, 593, 454, 538, 371, 454, 538, 454, 538, 371, 371, 593, 593,
    538, 649, 649, 649, 649, 649, 649, 538, 538, 593, 649, 538, 538, 538, 593,
    538, 593, 649, 649, 649, 649, 538, 538, 538, 649, 40, 93, 93, 93, 40, 538,
    816, 816, 538, 316, 316, 649, 649, 649, 649, 454, 816, 816, 816, 816, 816,
    816, 816, 816, 816, 816, 816, 816, 816, 260, 316, 316, 454, 454, 454, 427,
    427, 316, 316, 316, 343, 343, 316, 316, 316, 316, 316, 260, 260, 260, 260,
    260, 260, 316, 204, 371, 260, 260, 649, 649, 316, 316, 899, 899, 427, 482,
    538, 816, 454, 538, 427, 427, 538, 427, 649, 649, 482, 482, 482, 899, 427,
    427, 677, 649, 899, 427, 427, 207, 427, 427, 427, 20.7, 204, 204, 204, 204,
    204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204,
    204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204,
    204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 204, 65, 65, 65, 65,
    65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 204, 204, 204, 204, 65, 65, 204,
    204, 204, 204, 204, 204, 204, 204, 204,
  ],
  'Min. Temp. to 40': [
    103, 115, 126, 126, 138, 138, 138, 149, 149, 161, 161, 161, 161, 172, 172,
    126, 126, 138, 138, 138, 138, 138, 149, 149, 149, 149, 161, 161, 161, 161,
    172, 172, 172, 172, 172, 172, 184, 184, 184, 195, 230, 218, 230, 230, 115,
    115, 115, 115, 138, 152, 162, 138, 138, 138, 138, 138, 138, 138, 138, 138,
    138, 138, 138, 138, 138, 138, 138, 138, 138, 143, 200, 207, 207, 207, 207,
    207, 207, 217, 227, 233, 218, 230, 247, 251, 267, 250, 267, 46, 46, 46, 46,
    46, 46, 68.9, 82.7, 91.9, 138, 138, 55.2, 55.2, 68.9, 91.9, 115, 115, 129,
    129, 138, 138, 143, 161, 161, 161, 161, 161, 161, 161, 160, 160, 172, 184,
    184, 184, 188, 253, 207, 207, 161, 207, 230, 234, 234, 19.9, 23, 27.6, 36.8,
    39.1, 43.7, 46, 36.8, 39.1, 39.1, 41.4, 62.1, 64.4, 71.2, 73.5, 55.2, 55.2,
    62.1, 62.1, 68.9, 87.3, 87.3, 96.5, 11.5, 23, 25.3, 27.6, 16.1, 23, 32.2,
    36.8, 23, 27.6, 39.1, 46, 39.1, 41.4, 64.4, 73.5, 27.6, 36.8, 50.6, 57.5,
    43.7, 43.7, 71.2, 78.1, 82.7, 101.1, 64.4, 73.5, 91.9, 101.1, 50.6, 50.6,
    82.7, 89.6, 50.6, 50.6, 82.7, 89.6, 55.2, 55.2, 82.7, 89.6, 87.3, 105.7,
    43.7, 43.7, 71.2, 78.1, 55.2, 55.2, 68.9, 96.5, 96.5,
  ],
  65: [
    '103.00',
    '115.00',
    '126.00',
    '126.00',
    '138.00',
    '138.00',
    '138.00',
    '149.00',
    '149.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '126.00',
    '126.00',
    '129.00',
    '138.00',
    '129.00',
    '130.00',
    '132.00',
    '149.00',
    '149.00',
    '149.00',
    '149.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '172.00',
    '172.00',
    '172.00',
    '172.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '218.00',
    '230.00',
    '230.00',
    '…',
    '109.00',
    '115.00',
    '115.00',
    '138.00',
    '152.00',
    '162.00',
    '130.00',
    '130.00',
    '130.00',
    '130.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '143.00',
    '200.00',
    '207.00',
    '206.00',
    '207.00',
    '207.00',
    '207.00',
    '207.00',
    '217.00',
    '227.00',
    '233.00',
    '218.00',
    '230.00',
    '247.00',
    '251.00',
    '267.00',
    '250.00',
    '265.00',
    '39.10',
    '39.10',
    '39.10',
    '39.10',
    '39.10',
    '39.10',
    '67.00',
    '82.70',
    '88.70',
    '137.00',
    '137.00',
    '53.80',
    '53.80',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '119.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '172.00',
    '184.00',
    '184.00',
    '184.00',
    '188.00',
    '253.00',
    '207.00',
    '207.00',
    '161.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '19.30',
    '22.20',
    '26.60',
    '36.80',
    '39.10',
    '43.70',
    '46.00',
    '36.80',
    '39.10',
    '39.10',
    '41.40',
    '62.10',
    '64.40',
    '71.20',
    '73.50',
    '55.20',
    '55.20',
    '62.10',
    '62.10',
    '68.90',
    '87.30',
    '87.30',
    '96.50',
    '11.50',
    '21.80',
    '25.30',
    '27.60',
    '16.10',
    '23.00',
    '32.20',
    '36.80',
    '22.10',
    '26.60',
    '39.10',
    '46.00',
    '39.10',
    '41.40',
    '64.40',
    '73.50',
    '27.60',
    '36.70',
    '50.60',
    '57.50',
    '43.70',
    '43.70',
    '71.20',
    '78.10',
    '82.70',
    '101.10',
    '64.40',
    '73.50',
    '91.90',
    '101.10',
    '50.40',
    '50.40',
    '82.70',
    '89.60',
    '50.40',
    '50.40',
    '82.70',
    '89.60',
    '55.20',
    '55.20',
    '82.70',
    '89.60',
    '87.30',
    '105.70',
    '43.70',
    '43.70',
    '71.20',
    '78.10',
    '55.20',
    '55.20',
    '68.90',
    '96.50',
    '96.50',
  ],
  100: [
    '101.00',
    '113.00',
    '126.00',
    '126.00',
    '134.00',
    '134.00',
    '138.00',
    '147.00',
    '147.00',
    '159.00',
    '159.00',
    '161.00',
    '161.00',
    '168.00',
    '172.00',
    '126.00',
    '123.00',
    '124.00',
    '138.00',
    '124.00',
    '126.00',
    '128.00',
    '149.00',
    '149.00',
    '149.00',
    '146.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '172.00',
    '171.00',
    '171.00',
    '171.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '218.00',
    '230.00',
    '230.00',
    '…',
    '105.00',
    '115.00',
    '115.00',
    '138.00',
    '152.00',
    '162.00',
    '126.00',
    '126.00',
    '126.00',
    '126.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '143.00',
    '191.00',
    '207.00',
    '187.00',
    '207.00',
    '207.00',
    '207.00',
    '207.00',
    '217.00',
    '227.00',
    '231.00',
    '210.00',
    '221.00',
    '247.00',
    '249.00',
    '265.00',
    '248.00',
    '264.00',
    '37.10',
    '37.10',
    '37.10',
    '37.10',
    '37.10',
    '37.10',
    '65.10',
    '82.70',
    '86.10',
    '136.00',
    '136.00',
    '52.80',
    '52.80',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '112.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '172.00',
    '184.00',
    '184.00',
    '184.00',
    '188.00',
    '253.00',
    '207.00',
    '207.00',
    '161.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '18.40',
    '21.30',
    '25.60',
    '35.90',
    '39.10',
    '43.70',
    '46.00',
    '36.80',
    '39.10',
    '39.10',
    '41.40',
    '62.10',
    '64.40',
    '71.20',
    '73.50',
    '55.20',
    '55.20',
    '62.10',
    '62.10',
    '68.90',
    '87.30',
    '87.30',
    '96.50',
    '10.90',
    '19.10',
    '23.10',
    '27.60',
    '16.00',
    '22.70',
    '31.30',
    '36.10',
    '21.40',
    '25.60',
    '38.10',
    '46.00',
    '39.10',
    '41.40',
    '64.40',
    '73.50',
    '27.50',
    '36.70',
    '50.60',
    '57.50',
    '43.70',
    '43.70',
    '71.20',
    '78.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '55.20',
    '55.20',
    '82.70',
    '89.60',
    '…',
    '…',
    '43.70',
    '43.70',
    '71.20',
    '78.10',
    '55.20',
    '55.20',
    '68.90',
    '96.50',
    '96.50',
  ],
  125: [
    '97.50',
    '110.00',
    '122.00',
    '122.00',
    '130.00',
    '130.00',
    '138.00',
    '142.00',
    '142.00',
    '154.00',
    '154.00',
    '161.00',
    '157.00',
    '163.00',
    '171.00',
    '126.00',
    '122.00',
    '122.00',
    '138.00',
    '122.00',
    '123.00',
    '126.00',
    '149.00',
    '149.00',
    '149.00',
    '144.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '172.00',
    '169.00',
    '168.00',
    '168.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '103.00',
    '115.00',
    '115.00',
    '138.00',
    '152.00',
    '162.00',
    '124.00',
    '124.00',
    '124.00',
    '124.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '143.00',
    '185.00',
    '204.00',
    '177.00',
    '207.00',
    '207.00',
    '207.00',
    '207.00',
    '211.00',
    '227.00',
    '227.00',
    '203.00',
    '214.00',
    '247.00',
    '243.00',
    '259.00',
    '243.00',
    '257.00',
    '36.50',
    '36.50',
    '36.50',
    '36.50',
    '36.50',
    '36.50',
    '63.70',
    '82.70',
    '84.30',
    '135.00',
    '135.00',
    '52.30',
    '52.30',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '108.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '171.00',
    '184.00',
    '184.00',
    '184.00',
    '188.00',
    '251.00',
    '207.00',
    '207.00',
    '161.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '17.30',
    '20.40',
    '24.40',
    '33.70',
    '39.10',
    '41.70',
    '43.90',
    '36.80',
    '39.10',
    '39.10',
    '41.40',
    '60.40',
    '60.40',
    '71.20',
    '73.50',
    '54.30',
    '54.30',
    '61.00',
    '61.00',
    '67.80',
    '83.80',
    '83.80',
    '92.50',
    '9.80',
    '17.00',
    '21.00',
    '26.60',
    '15.60',
    '21.60',
    '25.20',
    '33.10',
    '20.50',
    '24.40',
    '35.80',
    '43.90',
    '39.00',
    '41.40',
    '60.40',
    '73.50',
    '27.50',
    '36.60',
    '50.60',
    '57.50',
    '43.60',
    '43.60',
    '71.00',
    '78.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '48.90',
    '48.90',
    '49.50',
    '49.50',
    '…',
    '…',
    '43.60',
    '43.60',
    '71.00',
    '78.10',
    '54.30',
    '54.30',
    '67.80',
    '92.50',
    '92.50',
  ],
  150: [
    '97.50',
    '110.00',
    '122.00',
    '122.00',
    '130.00',
    '130.00',
    '138.00',
    '142.00',
    '142.00',
    '154.00',
    '154.00',
    '161.00',
    '157.00',
    '163.00',
    '171.00',
    '126.00',
    '122.00',
    '120.00',
    '138.00',
    '120.00',
    '121.00',
    '125.00',
    '149.00',
    '149.00',
    '149.00',
    '144.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '172.00',
    '167.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '102.00',
    '115.00',
    '115.00',
    '138.00',
    '152.00',
    '162.00',
    '122.00',
    '122.00',
    '122.00',
    '122.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '141.00',
    '180.00',
    '199.00',
    '170.00',
    '203.00',
    '203.00',
    '206.00',
    '207.00',
    '206.00',
    '227.00',
    '222.00',
    '199.00',
    '209.00',
    '247.00',
    '238.00',
    '253.00',
    '238.00',
    '251.00',
    '34.20',
    '34.20',
    '34.20',
    '34.20',
    '34.20',
    '34.20',
    '62.40',
    '82.70',
    '82.60',
    '135.00',
    '135.00',
    '51.90',
    '51.90',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '105.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '170.00',
    '184.00',
    '184.00',
    '184.00',
    '188.00',
    '250.00',
    '207.00',
    '207.00',
    '161.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '13.60',
    '18.20',
    '18.20',
    '29.00',
    '29.00',
    '29.00',
    '29.00',
    '36.80',
    '38.90',
    '38.90',
    '38.90',
    '38.90',
    '38.90',
    '38.90',
    '38.90',
    '52.00',
    '52.00',
    '58.30',
    '58.30',
    '64.80',
    '72.30',
    '72.30',
    '79.90',
    '8.80',
    '15.00',
    '18.10',
    '18.10',
    '11.80',
    '16.30',
    '19.00',
    '19.00',
    '18.20',
    '18.20',
    '29.00',
    '29.00',
    '38.90',
    '38.90',
    '38.90',
    '38.90',
    '27.50',
    '35.80',
    '35.80',
    '35.80',
    '41.60',
    '41.60',
    '41.60',
    '41.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '37.50',
    '37.50',
    '37.50',
    '37.50',
    '…',
    '…',
    '41.60',
    '41.60',
    '41.60',
    '41.60',
    '52.00',
    '52.00',
    '64.80',
    '79.90',
    '79.90',
  ],
  175: [
    '94.60',
    '106.00',
    '118.00',
    '118.00',
    '126.00',
    '126.00',
    '138.00',
    '138.00',
    '138.00',
    '150.00',
    '150.00',
    '158.00',
    '156.00',
    '158.00',
    '165.00',
    '126.00',
    '122.00',
    '119.00',
    '138.00',
    '119.00',
    '119.00',
    '124.00',
    '148.00',
    '148.00',
    '149.00',
    '144.00',
    '160.00',
    '160.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '172.00',
    '166.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '101.00',
    '114.00',
    '113.00',
    '134.00',
    '151.00',
    '161.00',
    '121.00',
    '121.00',
    '121.00',
    '121.00',
    '136.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '134.00',
    '135.00',
    '175.00',
    '196.00',
    '165.00',
    '196.00',
    '196.00',
    '202.00',
    '207.00',
    '203.00',
    '227.00',
    '219.00',
    '197.00',
    '207.00',
    '247.00',
    '235.00',
    '250.00',
    '236.00',
    '247.00',
    '28.00',
    '28.00',
    '28.00',
    '28.00',
    '28.00',
    '28.00',
    '61.10',
    '75.40',
    '81.00',
    '134.00',
    '134.00',
    '51.70',
    '51.70',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '103.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '168.00',
    '184.00',
    '177.00',
    '184.00',
    '188.00',
    '249.00',
    '207.00',
    '207.00',
    '161.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '10.90',
    '13.60',
    '13.60',
    '21.10',
    '21.10',
    '21.10',
    '21.10',
    '26.90',
    '26.90',
    '26.90',
    '26.90',
    '26.90',
    '26.90',
    '26.90',
    '26.90',
    '46.30',
    '46.30',
    '52.10',
    '52.10',
    '57.90',
    '57.20',
    '57.20',
    '63.10',
    '7.50',
    '11.80',
    '12.70',
    '12.70',
    '9.30',
    '11.80',
    '13.60',
    '13.60',
    '13.60',
    '13.60',
    '21.10',
    '21.10',
    '26.90',
    '26.90',
    '26.90',
    '26.90',
    '20.10',
    '20.10',
    '20.10',
    '20.10',
    '28.80',
    '28.80',
    '28.80',
    '28.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '28.60',
    '28.60',
    '28.60',
    '28.60',
    '…',
    '…',
    '28.80',
    '28.80',
    '28.80',
    '28.80',
    '46.30',
    '46.30',
    '57.90',
    '63.10',
    '63.10',
  ],
  200: [
    '94.60',
    '106.00',
    '118.00',
    '118.00',
    '126.00',
    '126.00',
    '138.00',
    '138.00',
    '138.00',
    '150.00',
    '150.00',
    '158.00',
    '156.00',
    '158.00',
    '165.00',
    '126.00',
    '122.00',
    '119.00',
    '136.00',
    '119.00',
    '117.00',
    '124.00',
    '146.00',
    '146.00',
    '148.00',
    '144.00',
    '158.00',
    '158.00',
    '161.00',
    '161.00',
    '172.00',
    '172.00',
    '172.00',
    '165.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '100.00',
    '110.00',
    '109.00',
    '129.00',
    '149.00',
    '159.00',
    '120.00',
    '120.00',
    '120.00',
    '120.00',
    '131.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '134.00',
    '134.00',
    '138.00',
    '138.00',
    '129.00',
    '130.00',
    '171.00',
    '193.00',
    '162.00',
    '190.00',
    '190.00',
    '198.00',
    '205.00',
    '199.00',
    '227.00',
    '215.00',
    '197.00',
    '207.00',
    '247.00',
    '231.00',
    '246.00',
    '234.00',
    '243.00',
    '21.70',
    '21.70',
    '21.70',
    '21.70',
    '21.70',
    '21.70',
    '60.00',
    '50.80',
    '79.40',
    '133.00',
    '133.00',
    '51.60',
    '51.60',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '102.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '165.00',
    '184.00',
    '171.00',
    '184.00',
    '188.00',
    '248.00',
    '207.00',
    '207.00',
    '154.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '10.50',
    '10.90',
    '10.90',
    '16.70',
    '16.70',
    '16.70',
    '16.70',
    '17.40',
    '17.40',
    '17.40',
    '17.40',
    '17.40',
    '17.40',
    '17.40',
    '17.40',
    '35.30',
    '35.30',
    '39.70',
    '39.70',
    '40.20',
    '40.20',
    '40.20',
    '40.20',
    '5.80',
    '7.50',
    '8.40',
    '8.40',
    '7.20',
    '7.50',
    '8.50',
    '8.50',
    '10.90',
    '10.90',
    '16.70',
    '16.70',
    '17.40',
    '17.40',
    '17.40',
    '17.40',
    '10.80',
    '10.80',
    '10.80',
    '10.80',
    '17.60',
    '17.60',
    '17.60',
    '17.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '21.70',
    '21.70',
    '21.70',
    '21.70',
    '…',
    '…',
    '17.60',
    '17.60',
    '17.60',
    '17.60',
    '35.30',
    '35.30',
    '40.20',
    '40.20',
    '40.20',
  ],
  225: [
    '90.80',
    '102.00',
    '113.00',
    '113.00',
    '121.00',
    '121.00',
    '132.00',
    '132.00',
    '132.00',
    '144.00',
    '144.00',
    '151.00',
    '156.00',
    '151.00',
    '159.00',
    '126.00',
    '121.00',
    '118.00',
    '134.00',
    '118.00',
    '116.00',
    '124.00',
    '143.00',
    '143.00',
    '146.00',
    '144.00',
    '155.00',
    '155.00',
    '161.00',
    '158.00',
    '172.00',
    '170.00',
    '172.00',
    '165.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '99.70',
    '106.00',
    '106.00',
    '125.00',
    '147.00',
    '156.00',
    '120.00',
    '120.00',
    '120.00',
    '120.00',
    '127.00',
    '137.00',
    '138.00',
    '138.00',
    '138.00',
    '138.00',
    '137.00',
    '138.00',
    '138.00',
    '129.00',
    '129.00',
    '138.00',
    '138.00',
    '125.00',
    '125.00',
    '166.00',
    '190.00',
    '160.00',
    '184.00',
    '184.00',
    '195.00',
    '199.00',
    '199.00',
    '227.00',
    '214.00',
    '197.00',
    '207.00',
    '247.00',
    '230.00',
    '245.00',
    '233.00',
    '241.00',
    '16.70',
    '16.70',
    '16.70',
    '16.70',
    '16.70',
    '16.70',
    '59.10',
    '31.90',
    '78.00',
    '131.00',
    '131.00',
    '51.60',
    '51.60',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '101.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '157.00',
    '161.00',
    '161.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '164.00',
    '180.00',
    '166.00',
    '184.00',
    '188.00',
    '247.00',
    '207.00',
    '204.00',
    '149.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '10.50',
    '10.50',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '34.80',
    '34.80',
    '35.90',
    '35.90',
    '35.90',
    '35.90',
    '35.90',
    '35.90',
    '5.50',
    '5.90',
    '7.80',
    '7.80',
    '6.90',
    '6.90',
    '7.80',
    '7.80',
    '10.50',
    '10.50',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '9.70',
    '9.70',
    '9.70',
    '9.70',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '20.70',
    '20.70',
    '20.70',
    '20.70',
    '…',
    '…',
    '16.10',
    '16.10',
    '16.10',
    '16.10',
    '34.80',
    '34.80',
    '35.90',
    '35.90',
    '35.90',
  ],
  250: [
    '90.80',
    '102.00',
    '113.00',
    '113.00',
    '121.00',
    '121.00',
    '132.00',
    '132.00',
    '132.00',
    '144.00',
    '144.00',
    '151.00',
    '156.00',
    '151.00',
    '159.00',
    '126.00',
    '120.00',
    '118.00',
    '131.00',
    '118.00',
    '115.00',
    '124.00',
    '140.00',
    '140.00',
    '144.00',
    '144.00',
    '151.00',
    '151.00',
    '161.00',
    '156.00',
    '172.00',
    '168.00',
    '169.00',
    '165.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '194.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '99.10',
    '103.00',
    '103.00',
    '122.00',
    '145.00',
    '154.00',
    '119.00',
    '119.00',
    '119.00',
    '119.00',
    '123.00',
    '134.00',
    '135.00',
    '135.00',
    '138.00',
    '138.00',
    '134.00',
    '135.00',
    '135.00',
    '125.00',
    '125.00',
    '138.00',
    '138.00',
    '122.00',
    '120.00',
    '161.00',
    '188.00',
    '159.00',
    '179.00',
    '179.00',
    '192.00',
    '194.00',
    '199.00',
    '227.00',
    '214.00',
    '197.00',
    '207.00',
    '247.00',
    '228.00',
    '243.00',
    '233.00',
    '238.00',
    '12.90',
    '12.90',
    '12.90',
    '12.90',
    '12.90',
    '12.90',
    '58.30',
    '…',
    '76.60',
    '130.00',
    '130.00',
    '51.60',
    '51.60',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '101.00',
    '138.00',
    '138.00',
    '143.00',
    '161.00',
    '161.00',
    '154.00',
    '161.00',
    '156.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '162.00',
    '175.00',
    '162.00',
    '184.00',
    '187.00',
    '245.00',
    '207.00',
    '202.00',
    '144.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  275: [
    '86.10',
    '96.90',
    '108.00',
    '108.00',
    '115.00',
    '115.00',
    '125.00',
    '126.00',
    '126.00',
    '136.00',
    '136.00',
    '144.00',
    '156.00',
    '144.00',
    '151.00',
    '126.00',
    '118.00',
    '117.00',
    '129.00',
    '117.00',
    '114.00',
    '124.00',
    '136.00',
    '136.00',
    '142.00',
    '143.00',
    '147.00',
    '147.00',
    '161.00',
    '154.00',
    '172.00',
    '165.00',
    '166.00',
    '164.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '193.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '98.40',
    '99.90',
    '100.00',
    '119.00',
    '142.00',
    '152.00',
    '118.00',
    '118.00',
    '118.00',
    '118.00',
    '120.00',
    '131.00',
    '131.00',
    '133.00',
    '137.00',
    '137.00',
    '131.00',
    '131.00',
    '131.00',
    '122.00',
    '122.00',
    '137.00',
    '137.00',
    '119.00',
    '116.00',
    '153.00',
    '187.00',
    '158.00',
    '175.00',
    '175.00',
    '188.00',
    '188.00',
    '199.00',
    '227.00',
    '214.00',
    '197.00',
    '207.00',
    '247.00',
    '228.00',
    '243.00',
    '233.00',
    '237.00',
    '11.60',
    '11.60',
    '11.60',
    '11.60',
    '11.60',
    '11.60',
    '51.30',
    '…',
    '75.30',
    '130.00',
    '130.00',
    '51.60',
    '51.60',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '101.00',
    '138.00',
    '138.00',
    '140.00',
    '161.00',
    '161.00',
    '151.00',
    '160.00',
    '152.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '161.00',
    '170.00',
    '158.00',
    '184.00',
    '183.00',
    '243.00',
    '207.00',
    '197.00',
    '140.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  300: [
    '86.10',
    '96.90',
    '108.00',
    '108.00',
    '115.00',
    '115.00',
    '125.00',
    '126.00',
    '126.00',
    '136.00',
    '136.00',
    '144.00',
    '156.00',
    '144.00',
    '151.00',
    '125.00',
    '116.00',
    '117.00',
    '127.00',
    '117.00',
    '112.00',
    '124.00',
    '131.00',
    '131.00',
    '140.00',
    '141.00',
    '142.00',
    '142.00',
    '161.00',
    '152.00',
    '172.00',
    '163.00',
    '164.00',
    '164.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '192.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '97.50',
    '97.70',
    '98.10',
    '116.00',
    '140.00',
    '149.00',
    '117.00',
    '117.00',
    '117.00',
    '117.00',
    '118.00',
    '129.00',
    '128.00',
    '131.00',
    '135.00',
    '135.00',
    '129.00',
    '128.00',
    '128.00',
    '119.00',
    '119.00',
    '135.00',
    '135.00',
    '116.00',
    '…',
    '143.00',
    '186.00',
    '158.00',
    '172.00',
    '172.00',
    '184.00',
    '184.00',
    '199.00',
    '227.00',
    '214.00',
    '197.00',
    '207.00',
    '247.00',
    '228.00',
    '242.00',
    '233.00',
    '237.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '45.10',
    '…',
    '74.20',
    '…',
    '…',
    '51.60',
    '51.60',
    '68.90',
    '91.90',
    '115.00',
    '115.00',
    '129.00',
    '101.00',
    '135.00',
    '138.00',
    '137.00',
    '161.00',
    '161.00',
    '148.00',
    '158.00',
    '148.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '160.00',
    '166.00',
    '155.00',
    '184.00',
    '177.00',
    '241.00',
    '207.00',
    '193.00',
    '137.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  325: [
    '83.60',
    '94.10',
    '105.00',
    '105.00',
    '111.00',
    '111.00',
    '122.00',
    '122.00',
    '122.00',
    '132.00',
    '132.00',
    '139.00',
    '154.00',
    '139.00',
    '146.00',
    '123.00',
    '115.00',
    '116.00',
    '125.00',
    '116.00',
    '111.00',
    '124.00',
    '126.00',
    '126.00',
    '138.00',
    '139.00',
    '136.00',
    '136.00',
    '161.00',
    '149.00',
    '172.00',
    '161.00',
    '161.00',
    '162.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '190.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '96.20',
    '95.70',
    '96.10',
    '113.00',
    '137.00',
    '146.00',
    '115.00',
    '115.00',
    '115.00',
    '115.00',
    '115.00',
    '127.00',
    '125.00',
    '129.00',
    '132.00',
    '132.00',
    '127.00',
    '125.00',
    '125.00',
    '116.00',
    '116.00',
    '132.00',
    '132.00',
    '113.00',
    '…',
    '111.00',
    '185.00',
    '158.00',
    '169.00',
    '169.00',
    '179.00',
    '179.00',
    '199.00',
    '227.00',
    '214.00',
    '197.00',
    '207.00',
    '247.00',
    '228.00',
    '242.00',
    '233.00',
    '236.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '39.30',
    '…',
    '73.20',
    '…',
    '…',
    '51.60',
    '51.60',
    '68.90',
    '91.90',
    '113.00',
    '113.00',
    '127.00',
    '101.00',
    '134.00',
    '138.00',
    '133.00',
    '161.00',
    '161.00',
    '146.00',
    '156.00',
    '145.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '159.00',
    '163.00',
    '152.00',
    '184.00',
    '172.00',
    '238.00',
    '203.00',
    '189.00',
    '135.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  350: [
    '81.10',
    '91.20',
    '101.00',
    '101.00',
    '108.00',
    '108.00',
    '118.00',
    '118.00',
    '118.00',
    '128.00',
    '128.00',
    '135.00',
    '148.00',
    '135.00',
    '142.00',
    '121.00',
    '114.00',
    '114.00',
    '123.00',
    '114.00',
    '110.00',
    '124.00',
    '120.00',
    '120.00',
    '136.00',
    '138.00',
    '130.00',
    '130.00',
    '161.00',
    '147.00',
    '171.00',
    '158.00',
    '159.00',
    '159.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '187.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '94.70',
    '94.10',
    '94.30',
    '111.00',
    '…',
    '…',
    '114.00',
    '114.00',
    '114.00',
    '114.00',
    '113.00',
    '125.00',
    '122.00',
    '127.00',
    '130.00',
    '130.00',
    '125.00',
    '122.00',
    '122.00',
    '114.00',
    '114.00',
    '130.00',
    '130.00',
    '111.00',
    '…',
    '…',
    '…',
    '157.00',
    '167.00',
    '167.00',
    '176.00',
    '176.00',
    '…',
    '…',
    '…',
    '197.00',
    '207.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '72.50',
    '…',
    '…',
    '51.50',
    '51.50',
    '…',
    '…',
    '110.00',
    '110.00',
    '125.00',
    '101.00',
    '132.00',
    '138.00',
    '130.00',
    '161.00',
    '161.00',
    '143.00',
    '154.00',
    '142.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '158.00',
    '160.00',
    '149.00',
    '184.00',
    '169.00',
    '236.00',
    '199.00',
    '185.00',
    '133.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  375: [
    '78.60',
    '84.30',
    '98.30',
    '98.30',
    '105.00',
    '105.00',
    '115.00',
    '113.00',
    '113.00',
    '122.00',
    '122.00',
    '131.00',
    '126.00',
    '131.00',
    '131.00',
    '119.00',
    '112.00',
    '112.00',
    '121.00',
    '112.00',
    '109.00',
    '124.00',
    '113.00',
    '113.00',
    '133.00',
    '136.00',
    '113.00',
    '122.00',
    '161.00',
    '144.00',
    '168.00',
    '155.00',
    '156.00',
    '156.00',
    '167.00',
    '167.00',
    '184.00',
    '184.00',
    '184.00',
    '183.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '92.60',
    '92.60',
    '92.60',
    '109.00',
    '…',
    '…',
    '111.00',
    '111.00',
    '111.00',
    '111.00',
    '111.00',
    '123.00',
    '120.00',
    '125.00',
    '128.00',
    '128.00',
    '123.00',
    '120.00',
    '120.00',
    '112.00',
    '112.00',
    '128.00',
    '128.00',
    '109.00',
    '…',
    '…',
    '…',
    '156.00',
    '166.00',
    '166.00',
    '173.00',
    '173.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '71.70',
    '…',
    '…',
    '51.20',
    '51.20',
    '…',
    '…',
    '108.00',
    '108.00',
    '122.00',
    '100.00',
    '131.00',
    '138.00',
    '125.00',
    '161.00',
    '161.00',
    '141.00',
    '153.00',
    '140.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '156.00',
    '158.00',
    '146.00',
    '182.00',
    '165.00',
    '233.00',
    '197.00',
    '182.00',
    '132.00',
    '207.00',
    '230.00',
    '234.00',
    '234.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  400: [
    '73.30',
    '73.30',
    '89.10',
    '89.00',
    '88.90',
    '88.90',
    '…',
    '95.00',
    '95.00',
    '101.00',
    '101.00',
    '…',
    '…',
    '107.00',
    '107.00',
    '116.00',
    '110.00',
    '110.00',
    '119.00',
    '110.00',
    '107.00',
    '123.00',
    '95.10',
    '95.10',
    '131.00',
    '134.00',
    '95.10',
    '101.00',
    '159.00',
    '141.00',
    '165.00',
    '152.00',
    '153.00',
    '130.00',
    '167.00',
    '167.00',
    '183.00',
    '183.00',
    '183.00',
    '178.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '90.10',
    '91.30',
    '90.90',
    '107.00',
    '…',
    '…',
    '108.00',
    '108.00',
    '108.00',
    '108.00',
    '109.00',
    '122.00',
    '119.00',
    '124.00',
    '127.00',
    '127.00',
    '122.00',
    '119.00',
    '119.00',
    '111.00',
    '111.00',
    '127.00',
    '127.00',
    '107.00',
    '…',
    '…',
    '…',
    '154.00',
    '165.00',
    '165.00',
    '170.00',
    '170.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '50.70',
    '50.70',
    '…',
    '…',
    '105.00',
    '105.00',
    '121.00',
    '99.60',
    '130.00',
    '138.00',
    '122.00',
    '160.00',
    '160.00',
    '138.00',
    '152.00',
    '138.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '136.00',
    '156.00',
    '144.00',
    '180.00',
    '162.00',
    '231.00',
    '196.00',
    '180.00',
    '131.00',
    '206.00',
    '230.00',
    '234.00',
    '233.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  425: [
    '64.00',
    '64.00',
    '75.40',
    '75.30',
    '75.30',
    '75.30',
    '…',
    '79.60',
    '79.60',
    '83.80',
    '83.80',
    '…',
    '…',
    '88.00',
    '88.00',
    '114.00',
    '109.00',
    '106.00',
    '116.00',
    '106.00',
    '105.00',
    '122.00',
    '79.50',
    '79.50',
    '127.00',
    '132.00',
    '79.50',
    '83.80',
    '155.00',
    '138.00',
    '160.00',
    '148.00',
    '149.00',
    '126.00',
    '167.00',
    '167.00',
    '178.00',
    '178.00',
    '178.00',
    '172.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '87.00',
    '90.00',
    '89.30',
    '105.00',
    '…',
    '…',
    '104.00',
    '104.00',
    '104.00',
    '104.00',
    '107.00',
    '120.00',
    '117.00',
    '122.00',
    '126.00',
    '126.00',
    '120.00',
    '117.00',
    '117.00',
    '110.00',
    '110.00',
    '126.00',
    '126.00',
    '105.00',
    '…',
    '…',
    '…',
    '152.00',
    '164.00',
    '164.00',
    '167.00',
    '167.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '49.90',
    '49.90',
    '…',
    '…',
    '104.00',
    '104.00',
    '119.00',
    '98.60',
    '129.00',
    '138.00',
    '119.00',
    '159.00',
    '157.00',
    '136.00',
    '151.00',
    '137.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '102.00',
    '154.00',
    '141.00',
    '178.00',
    '159.00',
    '230.00',
    '195.00',
    '177.00',
    '129.00',
    '206.00',
    '229.00',
    '234.00',
    '231.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  450: [
    '55.80',
    '55.80',
    '62.60',
    '62.10',
    '62.70',
    '62.70',
    '…',
    '63.20',
    '63.20',
    '67.10',
    '67.10',
    '…',
    '…',
    '67.30',
    '67.30',
    '110.00',
    '106.00',
    '103.00',
    '113.00',
    '103.00',
    '103.00',
    '121.00',
    '64.40',
    '64.40',
    '124.00',
    '129.00',
    '64.40',
    '66.80',
    '151.00',
    '134.00',
    '154.00',
    '144.00',
    '146.00',
    '104.00',
    '167.00',
    '167.00',
    '172.00',
    '172.00',
    '172.00',
    '165.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '83.40',
    '88.70',
    '87.60',
    '103.00',
    '…',
    '…',
    '100.00',
    '100.00',
    '100.00',
    '100.00',
    '105.00',
    '119.00',
    '115.00',
    '121.00',
    '126.00',
    '126.00',
    '119.00',
    '115.00',
    '115.00',
    '109.00',
    '109.00',
    '126.00',
    '126.00',
    '103.00',
    '…',
    '…',
    '…',
    '149.00',
    '163.00',
    '163.00',
    '166.00',
    '166.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '41.40',
    '41.40',
    '…',
    '…',
    '102.00',
    '102.00',
    '119.00',
    '79.70',
    '128.00',
    '138.00',
    '116.00',
    '158.00',
    '156.00',
    '136.00',
    '150.00',
    '137.00',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '63.80',
    '153.00',
    '140.00',
    '176.00',
    '157.00',
    '228.00',
    '195.00',
    '177.00',
    '128.00',
    '…',
    '229.00',
    '234.00',
    '230.00',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  475: [
    '43.90',
    '43.90',
    '45.50',
    '45.00',
    '45.50',
    '45.50',
    '…',
    '45.30',
    '45.30',
    '51.00',
    '51.00',
    '…',
    '…',
    '50.30',
    '50.30',
    '107.00',
    '104.00',
    '98.30',
    '104.00',
    '80.60',
    '89.20',
    '99.60',
    '48.80',
    '48.80',
    '109.00',
    '126.00',
    '48.80',
    '49.20',
    '146.00',
    '109.00',
    '104.00',
    '109.00',
    '104.00',
    '80.60',
    '98.20',
    '119.00',
    '104.00',
    '104.00',
    '104.00',
    '156.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '79.20',
    '87.30',
    '85.90',
    '101.00',
    '…',
    '…',
    '92.50',
    '92.50',
    '88.70',
    '88.70',
    '103.00',
    '117.00',
    '114.00',
    '119.00',
    '125.00',
    '125.00',
    '117.00',
    '114.00',
    '114.00',
    '108.00',
    '108.00',
    '125.00',
    '125.00',
    '101.00',
    '…',
    '…',
    '…',
    '146.00',
    '161.00',
    '161.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '33.10',
    '33.10',
    '…',
    '…',
    '100.00',
    '100.00',
    '…',
    '59.90',
    '128.00',
    '138.00',
    '113.00',
    '157.00',
    '…',
    '…',
    '149.00',
    '…',
    '161.00',
    '161.00',
    '160.00',
    '160.00',
    '33.50',
    '153.00',
    '…',
    '…',
    '156.00',
    '227.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  500: [
    '31.70',
    '31.70',
    '31.60',
    '31.70',
    '31.60',
    '31.60',
    '…',
    '31.70',
    '31.70',
    '33.60',
    '33.60',
    '…',
    '…',
    '33.20',
    '33.20',
    '74.40',
    '92.10',
    '83.20',
    '73.70',
    '61.70',
    '68.80',
    '80.90',
    '35.40',
    '35.40',
    '68.00',
    '92.10',
    '35.40',
    '35.40',
    '93.50',
    '68.00',
    '68.00',
    '68.00',
    '73.70',
    '61.70',
    '73.50',
    '88.40',
    '68.00',
    '68.00',
    '68.00',
    '147.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '70.10',
    '85.60',
    '84.20',
    '99.10',
    '…',
    '…',
    '68.40',
    '68.40',
    '69.80',
    '69.80',
    '…',
    '116.00',
    '113.00',
    '117.00',
    '125.00',
    '125.00',
    '116.00',
    '113.00',
    '113.00',
    '107.00',
    '107.00',
    '125.00',
    '125.00',
    '99.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '27.40',
    '27.40',
    '…',
    '…',
    '98.60',
    '98.60',
    '…',
    '55.20',
    '127.00',
    '138.00',
    '…',
    '156.00',
    '…',
    '…',
    '136.00',
    '…',
    '84.80',
    '84.80',
    '160.00',
    '160.00',
    '27.60',
    '152.00',
    '…',
    '…',
    '155.00',
    '225.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  525: [
    '21.40',
    '21.40',
    '21.90',
    '21.40',
    '21.90',
    '21.90',
    '…',
    '21.90',
    '21.90',
    '21.30',
    '21.30',
    '…',
    '…',
    '21.40',
    '21.40',
    '49.90',
    '61.10',
    '60.20',
    '52.00',
    '46.40',
    '54.20',
    '63.30',
    '22.60',
    '22.60',
    '42.30',
    '61.10',
    '22.60',
    '22.60',
    '49.90',
    '42.30',
    '42.30',
    '42.30',
    '52.00',
    '46.40',
    '54.70',
    '64.00',
    '42.30',
    '42.30',
    '42.30',
    '137.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '38.80',
    '83.70',
    '82.50',
    '97.30',
    '…',
    '…',
    '51.10',
    '51.10',
    '52.60',
    '52.60',
    '…',
    '84.90',
    '112.00',
    '108.00',
    '125.00',
    '125.00',
    '108.00',
    '112.00',
    '112.00',
    '106.00',
    '106.00',
    '125.00',
    '125.00',
    '97.30',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '22.80',
    '22.80',
    '…',
    '…',
    '97.10',
    '97.10',
    '…',
    '…',
    '127.00',
    '138.00',
    '…',
    '155.00',
    '…',
    '…',
    '132.00',
    '…',
    '58.40',
    '58.40',
    '…',
    '…',
    '…',
    '151.00',
    '…',
    '…',
    '154.00',
    '222.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  550: [
    '14.20',
    '14.20',
    '12.70',
    '14.20',
    '12.70',
    '12.70',
    '…',
    '12.70',
    '12.70',
    '12.90',
    '12.90',
    '…',
    '…',
    '14.20',
    '14.20',
    '40.70',
    '40.40',
    '42.90',
    '36.30',
    '34.70',
    '43.40',
    '47.50',
    '17.20',
    '17.20',
    '30.50',
    '40.40',
    '17.20',
    '17.20',
    '40.70',
    '30.50',
    '33.10',
    '30.50',
    '36.30',
    '34.70',
    '40.60',
    '44.60',
    '33.10',
    '33.10',
    '33.10',
    '115.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '27.60',
    '81.40',
    '80.80',
    '96.40',
    '…',
    '…',
    '37.40',
    '37.40',
    '38.10',
    '38.10',
    '…',
    '59.00',
    '88.70',
    '83.70',
    '97.60',
    '97.60',
    '83.70',
    '100.00',
    '100.00',
    '105.00',
    '105.00',
    '125.00',
    '125.00',
    '95.50',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '18.70',
    '18.70',
    '…',
    '…',
    '95.90',
    '96.10',
    '…',
    '…',
    '126.00',
    '136.00',
    '…',
    '153.00',
    '…',
    '…',
    '130.00',
    '…',
    '39.70',
    '39.70',
    '…',
    '…',
    '…',
    '151.00',
    '…',
    '…',
    '143.00',
    '215.00',
    '195.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  575: [
    '9.40',
    '9.40',
    '…',
    '9.40',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '9.40',
    '9.40',
    '…',
    '26.40',
    '29.90',
    '25.20',
    '25.50',
    '34.00',
    '34.20',
    '…',
    '…',
    '23.20',
    '26.40',
    '…',
    '…',
    '…',
    '23.20',
    '…',
    '23.20',
    '25.20',
    '25.50',
    '29.20',
    '30.00',
    '…',
    '…',
    '…',
    '87.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '40.40',
    '73.00',
    '…',
    '…',
    '…',
    '26.30',
    '26.30',
    '27.60',
    '27.60',
    '…',
    '43.50',
    '59.20',
    '64.00',
    '75.90',
    '75.90',
    '64.00',
    '76.50',
    '76.50',
    '97.80',
    '97.80',
    '112.00',
    '112.00',
    '78.90',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '15.60',
    '15.60',
    '…',
    '…',
    '91.80',
    '94.10',
    '…',
    '…',
    '…',
    '107.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '27.00',
    '27.00',
    '…',
    '…',
    '…',
    '151.00',
    '…',
    '…',
    '118.00',
    '194.00',
    '183.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  600: [
    '6.89',
    '6.89',
    '…',
    '6.89',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '6.89',
    '6.89',
    '…',
    '17.40',
    '20.60',
    '17.60',
    '17.80',
    '25.10',
    '23.50',
    '…',
    '…',
    '16.50',
    '17.40',
    '…',
    '…',
    '…',
    '16.50',
    '…',
    '16.50',
    '17.60',
    '17.80',
    '20.60',
    '19.70',
    '…',
    '…',
    '…',
    '64.70',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '33.20',
    '67.90',
    '…',
    '…',
    '…',
    '17.80',
    '17.80',
    '20.60',
    '20.60',
    '…',
    '31.90',
    '44.00',
    '48.50',
    '57.20',
    '57.20',
    '48.50',
    '58.70',
    '58.70',
    '80.80',
    '80.80',
    '90.60',
    '90.60',
    '63.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '12.90',
    '12.90',
    '…',
    '…',
    '75.70',
    '85.50',
    '…',
    '…',
    '…',
    '83.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '19.20',
    '19.20',
    '…',
    '…',
    '…',
    '117.00',
    '…',
    '…',
    '99.10',
    '156.00',
    '153.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  625: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '11.60',
    '14.40',
    '12.30',
    '11.40',
    '17.10',
    '15.30',
    '…',
    '…',
    '…',
    '11.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '12.30',
    '11.40',
    '15.20',
    '12.80',
    '…',
    '…',
    '…',
    '45.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '26.70',
    '58.00',
    '…',
    '…',
    '…',
    '11.40',
    '11.40',
    '15.90',
    '15.90',
    '…',
    '23.60',
    '32.90',
    '36.30',
    '40.20',
    '40.20',
    '36.30',
    '46.00',
    '46.00',
    '65.00',
    '65.00',
    '69.60',
    '69.60',
    '51.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '10.00',
    '10.00',
    '…',
    '…',
    '62.60',
    '69.30',
    '…',
    '…',
    '…',
    '63.90',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '15.00',
    '15.00',
    '…',
    '…',
    '…',
    '96.20',
    '…',
    '…',
    '81.60',
    '136.00',
    '128.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  650: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '7.58',
    '10.30',
    '8.27',
    '6.89',
    '10.30',
    '9.65',
    '…',
    '…',
    '…',
    '7.58',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '8.27',
    '6.89',
    '8.96',
    '8.27',
    '…',
    '…',
    '…',
    '29.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '21.90',
    '43.60',
    '…',
    '…',
    '…',
    '6.89',
    '6.89',
    '12.40',
    '12.40',
    '…',
    '16.90',
    '24.50',
    '27.30',
    '30.30',
    '30.30',
    '27.30',
    '36.80',
    '36.80',
    '50.40',
    '50.40',
    '53.80',
    '53.80',
    '41.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '8.27',
    '8.27',
    '…',
    '…',
    '50.60',
    '56.80',
    '…',
    '…',
    '…',
    '44.70',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '13.80',
    '13.80',
    '…',
    '…',
    '…',
    '79.30',
    '…',
    '…',
    '67.00',
    '91.00',
    '107.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  675: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '18.20',
    '33.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '10.70',
    '18.30',
    '21.00',
    '23.20',
    '23.20',
    '21.00',
    '28.70',
    '28.70',
    '38.60',
    '38.60',
    '41.40',
    '41.40',
    '32.90',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '41.20',
    '46.80',
    '…',
    '…',
    '…',
    '29.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '65.60',
    '…',
    '…',
    '54.60',
    '…',
    '89.70',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  700: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '15.00',
    '25.30',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '6.10',
    '12.50',
    '15.90',
    '16.20',
    '16.20',
    '15.90',
    '23.00',
    '23.00',
    '29.60',
    '29.60',
    '31.80',
    '31.80',
    '26.50',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '33.60',
    '38.60',
    '…',
    '…',
    '…',
    '15.50',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '54.40',
    '…',
    '…',
    '42.20',
    '…',
    '74.70',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  725: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '12.40',
    '18.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '3.90',
    '8.49',
    '12.50',
    '11.40',
    '11.40',
    '12.50',
    '18.40',
    '18.40',
    '23.00',
    '23.00',
    '24.00',
    '24.00',
    '21.30',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '27.70',
    '31.50',
    '…',
    '…',
    '…',
    '11.70',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '45.00',
    '…',
    '…',
    '…',
    '…',
    '61.90',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  750: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '8.87',
    '14.00',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '2.99',
    '6.19',
    '9.87',
    '8.97',
    '8.97',
    '9.87',
    '14.50',
    '14.50',
    '17.40',
    '17.40',
    '18.80',
    '18.80',
    '17.20',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '22.60',
    '25.50',
    '…',
    '…',
    '…',
    '8.68',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '37.20',
    '…',
    '…',
    '…',
    '…',
    '50.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  775: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '7.20',
    '10.40',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '2.36',
    '4.28',
    '7.65',
    '7.08',
    '7.08',
    '7.65',
    '11.50',
    '11.50',
    '13.30',
    '13.30',
    '14.60',
    '14.60',
    '14.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '18.30',
    '20.60',
    '…',
    '…',
    '…',
    '7.20',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '30.60',
    '…',
    '…',
    '…',
    '…',
    '41.10',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  800: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '6.58',
    '7.99',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '1.73',
    '2.75',
    '5.97',
    '5.89',
    '5.89',
    '5.97',
    '9.02',
    '9.02',
    '10.40',
    '10.40',
    '10.90',
    '10.90',
    '11.20',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '15.00',
    '17.10',
    '…',
    '…',
    '…',
    '6.25',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '25.10',
    '…',
    '…',
    '…',
    '…',
    '32.60',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  825: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '6.21',
    '6.89',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '1.38',
    '1.74',
    '5.17',
    '5.52',
    '5.52',
    '5.17',
    '6.83',
    '6.83',
    '8.96',
    '8.96',
    '8.96',
    '8.96',
    '9.65',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '11.90',
    '13.80',
    '…',
    '…',
    '…',
    '5.11',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '20.50',
    '…',
    '…',
    '…',
    '…',
    '25.20',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  850: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '9.03',
    '10.70',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '16.50',
    '…',
    '…',
    '…',
    '…',
    '18.90',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  875: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '7.35',
    '7.98',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '13.00',
    '…',
    '…',
    '…',
    '…',
    '13.80',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  900: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '…',
    '…',
    '…',
    '…',
    '5.86',
    '6.20',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '…',
    '9.80',
    '…',
    '…',
    '…',
    '…',
    '10.20',
    '…',
    '…',
    '',
    '…',
    '…',
    '…',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
};
export const w_d=(
  <>
  <p>For Vertical Separators with Baffle</p>

<p>Minimum of 4 in.is recommended.</p>
  </>
)

export const H_a=(
  <>
  <p>For 3-Phase Vertical Separators </p>

  <p>Minimum 0.5 ft is recommended.</p>
  </>
)
export const tip_L_liq_Gas_Particle_Dia = (
  <div>
    <strong>Refer to Table 5</strong>
    <br />
    <p>ExxonMobil Practice:</p>
    <div>130 for Sp.Gr {'>'} 0.85</div>
    <div>89 for Sp.Gr {'<'} 0.85</div>
    <div>(Heavy Liq.: Water, Caustic or Amine)</div>
  </div>
);
export const Init_Estimated_D = (
  <div>
    <p>
    Input an estimation of diameter (D)</p>
    
  </div>
);
