import React, { useContext, useEffect, useState } from 'react';
import { sizingApi } from '../API/sizingApi';
import useNotify from '../hooks/useNotify';
import { ProjectContext } from '../Context/ProjectContext';
import { useParams } from 'react-router-dom';
import useApiClient from '../Settings/useApiClient';
import { Spin } from 'antd';
import { AF19f, AL39f, K32, K32f, K33f, K34f } from '../helpers/reportHelp';
import { toFixed } from '../helpers/Converts';

const ReportTables = ({I40,res}) => {



  return (
    <table style={{ borderCollapse: 'collapse', width: '90%', margin: 'auto' ,margin:20}}>
      <thead>
        <tr>
          <td><img style={{width:50,height:50}} src={res['project']['logo1']} /></td>
          <td colSpan={3} className="text-center p-5 pb-0">
            <h1 className="!text-[16px] font-bold text-center">
              Three-Phase Seperator <br />
              Sizing Report
            </h1>
          </td>
          <td ><img style={{width:50,height:50,marginLeft:"auto"}} src={res['project']['logo2']} /></td>
        </tr>
        <tr className=" text-base">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">CLIENT</span>
              <span className="border-b p-1">
                -
              </span>
              <span className="border-b p-1">PROJ No</span>
              <span className="border-b p-1">-</span>
            </div>
          </td>
        </tr>
        <tr className="head">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">PROJECT</span>
              <span className="border-b p-1">
                -
              </span>
              <span className="border-b p-1">CALC No</span>
              <span className="border-b p-1"> {res?.sizing?.calc_no??"-"}</span>
            </div>
          </td>
        </tr>
        <tr className="head">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">SUBJECT</span>
              <span className="border-b p-1">
                {res.project.subject}
              </span>
              <span className="border-b p-1">SHEET</span>
              <span className="border-b p-1">1 {'    '} of {     } 3</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="text-center mt-3">
            <table className="w-full">
              <tr>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  REVISION
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  DATE
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Approved
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Prepared
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Checked
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.sizing?.revision??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.date??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.approved??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.prepared??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.checked??"-"}
                </td>
              </tr>
            </table>
          </td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr className="p-5 " style={{ height: 10 }}>
          <td colSpan={5}> </td>
        </tr>
        <tr>
          <td colSpan={2} className="!text-[11px]">
            <strong> Process Data</strong>
          </td>
          <td>
        
          </td>
          <td colSpan={2} className="!text-[11px]">
            <strong>Sizing Assumptions</strong>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Si
                  </th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Field
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Brometric Press
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K21[0]??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    bar
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K21[1].toFixed(4)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    psi
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Pressure
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K22[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    barg
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K22[1].toFixed(4)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    psig
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Temperature
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K23[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    °C
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K23[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    °F
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Act. Gas Density
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K24[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    kg/m3
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K24[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    lb/ft3
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Gas Viscosity
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K25[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    cP
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K25[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    cP
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Gas Flowrate
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K26[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    kg/h
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   {res?.process?.K26[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    lb/h
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Act. L. Liq. Density
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K28[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    kg/m3
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K28[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    lb/ft3
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L. Liq. Viscosity
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K29[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    cP
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K29[1].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    cP
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L. Liq. Flowrate
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    kg/h
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K30[0].toFixed(2)??"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    lb/h
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.process?.K30[1].toFixed(2)??"-"}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Act. H. Liq. Density
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    kg/m3
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    {K32f(I40,res?.process?.K32)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    lb/ft3
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {(K32f(I40,res?.process?.K32)*0.0624279606).toFixed(4)||"-"}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    H. Liq. Viscosity
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {K33f(I40,res?.process?.K33)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    cP
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {K33f(I40,res?.process?.K33)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    cP
                  </td>
                </tr>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    H. Liq. Flowrate
                  </th>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {K34f(I40,res?.process?.K34)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    kg/h{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(K34f(I40,res?.process?.K34)*2.20462).toFixed(2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    lb/h
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                marginTop: 20,
              }}
            >
              <thead>
                <strong> Material & Design Condition </strong>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Design Press. / Temp.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {toFixed(res?.design_specification?.design_pressure,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   barg
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.design_specification?.design_temperature,0)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  °C
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Corr. Allo. / Joint Eff.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.design_specification?.corrosion_allowance,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.design_specification?.joint_efficiency,0)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    %
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Allow. Stress / Density
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.design_specification?.allowable_stress 	,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Mpa
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.design_specification?.material_density	,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  kg/m3
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{ width: 50 }}> </td>
          <td colSpan={2}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Si
                  </th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Field
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    K Value
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.config_specific?.K_value.toFixed(2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    K<sub className='align-sub'>s</sub>
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed( res?.config_specific?.K_s,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Hv as ratio of D
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.config_specific?.H_v_ratio_of_D,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L. Liq. / Gas Particle Dia.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.process?.K27[0],2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  µm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.process?.K27[1],2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L. Liq. / H. Liq. Particle Dia.{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.process?.K31[0],2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  µm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.process?.K31[1],2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Over Design Factor
                  </td>
                  <td
                    colSpan={4}
                    style={{ border: '1px solid black', padding: '3px 8px',textAlign:"center" }}
                  >
                  { AF19f(I40,res?.process?.gas,res?.process?.l_liq,res?.process?.h_liq,res?.process?.Over_Design_Factor)}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L. Liq. Level below weir
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.config_specific?.L_Liq_level,4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {(res?.config_specific?.L_Liq_level/25.4).toFixed(2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    H. Liq. Level below weir
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res?.config_specific?.H_Liq_level_below_weir,4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.L_Liq_level/25.4).toFixed(2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    HA
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.H_a*304.8)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.config_specific?.H_a||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    WD
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.W_d/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.W_d)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    HLL in Boot
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.HL_l_in_Boot*304.8)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.HL_l_in_Boot)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    HHL in Boot
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.HH_L_in_Boot)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.config_specific?.HH_L_in_Boot/304.8)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   ft
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={5}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    Holdup Time{' '}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Light{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.configuration?.Holdup_Time_L_Lig)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                min.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  -
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Heavy{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.configuration?.Holdup_Time_H_Lig)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                   min.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={5}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    Surge Time
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Light
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.configuration?.Surge_Time_L_Liq)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    min.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Heavy{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res?.configuration?.Surge_Time_H_Liq)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   min.
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <tr style={{ height: 10 }}>
          <td colSpan={5}></td>
        </tr>
        {/* ///////////////////////////////////////////////////////////////////////////// */}
        <tr>
          <td colSpan={2}>
            <strong>Dimensions - General </strong>
          </td>
          <td style={{ width: 50 }}></td>
          <td colSpan={2}>
            <strong>Dimensions - Compartments </strong>{' '}
          </td>
        </tr>

        <tr>
          <td colSpan={2}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan={2}></th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Si
                  </th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Field
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    Separator Tag{' '}
                  </td>
                  <td
                    colSpan={4}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                   {res?.sizing?.separator_tag}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    Separator Type{' '}
                  </td>
                  <td
                    colSpan={4}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {res?.configuration?.configuration?.name}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    Diameter{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {toFixed(res.data?.BD75,2)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    m{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    {toFixed(res.data?.BD75/0.3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    ft{' '}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    Length{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    {toFixed(res.data?.BH75,2)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    m{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    {toFixed(res.data?.BH75/0.3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    ft{' '}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    L/D{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    {' '}
                    {toFixed(res.data?.BH79,2)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  -
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    Total Shell Volume{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.D83,3)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  m<sub className='align-top'>3</sub>
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.D83*35.31467,4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    ft<sub className='align-top'>3</sub>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    {' '}
                    Total Volume{' '}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AA83,3)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    m<sub className='align-top'>3</sub>
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AA83*35.31467,3)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    ft    <sub className='align-top'>3</sub>             
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '0px',
                      textAlign: 'center',
                      writingMode: 'vertical-lr',
                      transform: 'rotate(180deg)',
                    }}
                    rowSpan={5}
                  >
                    Light Liquid
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    LLL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.X71,0)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.X71/25.4,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    LL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AA71,0)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AA71/25.4,0)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    NL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AH71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AH71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    HL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AK71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AK71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    HHL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AN71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AN71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   in.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '0px',
                      textAlign: 'center',
                      writingMode: 'vertical-lr',
                      transform: 'rotate(180deg)',
                    }}
                    rowSpan={5}
                  >
                    Heavy Liquid
                  </td>

                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   LLL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.D71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.D71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  LL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.G71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  mm 
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.G71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    NL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.N71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.N71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    HL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.Q71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.Q71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   HHL
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.T71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.T71/25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   in.
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td></td>
          <td colSpan={2}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Si
                  </th>
                  <th
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                    colSpan={2}
                  >
                    Field
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={5}
                    style={{ border: '1px solid black', padding: '3px 8px' }}
                  >
                    Recom. Nozzle Size{' '}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Feed (d<sub className='align-sub'>F</sub>)
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {AL39f(I40,res.data?.AV45,res.data?.AT45)}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {AL39f(I40,res.data?.AV45,res.data?.AT45)*25.4}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Gas (d<sub className='align-sub'>G</sub>)
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.Y66*25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.Y66)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Light Liq. (d<sub className='align-sub'>LL</sub>)
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AB66*25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AB66)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    Heavy Liq. (d<sub className='align-sub'>HL</sub>)
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AE66*25.4)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AE66)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  in.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '3px 8px',
                      height: 15,
                    }}
                    colSpan={5}
                  ></td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   H<sub className='align-sub'>v</sub>
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AR71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AR71/304.8)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   H boot
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AV75)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AV75/304.8,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                 D boot
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AR75)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   m
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AR75/0.3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  H W/Bucket
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AV71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AVR71/25.4,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  H bn
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.BD71)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.BD71/25.4,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    H D
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.BH71,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   mm
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.BH71/25.4,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    in.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '3px 8px',
                      height: 15,
                    }}
                    colSpan={5}
                  ></td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L1
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AR79,1)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  m
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AR79/3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                      ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L2
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AV79,1)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  m
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AV79/3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L3
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AZ79,1)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    m
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.AZ79/3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    ft
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    L4
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.BD79,1)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  m
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {toFixed(res.data?.BD79/3048,2)||"-"}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  ft
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        {/* /////////////////////////////////////////// */}
        <tr style={{ height: 10 }}>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={2} className="!text-[11px] !font-bold">
            Separator Configurations
          </td>
          <td colSpan={3}></td>
        </tr>
        {/* ////////////////////////////// */}
        <tr>
          <td colSpan={5}>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '90%',
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                    LLIA/LLIS
                  </th>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  LIA
                  </th>

               

                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  HHIA_HHIS
                  </th>

                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  LLLA_LLLS
                  </th>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  LLA
                  </th>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  HHLA_HHLS
                  </th>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Demister
                  </th>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Diverter
                  </th>
                  <th style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Coalescer
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                   {(I40==7 || I40==8)?"":(res.configuration?.LLIA_LLIS==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==7 || I40==8)?"":(res.configuration?.LIA==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==7 || I40==8)?"":(res.configuration?.HHIA_HHIS==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.LLLA_LLLS==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.LLA==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.HHLA_HHLS==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.Demister==1)?"✔":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.Diverter==1)?"✔":""}
                  </td>
                
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==1 || I40==2 || I40==7)?"":(res.configuration?.Coalescer==1)?"✔":""}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==8 || I40==7)?"":(res.configuration?.LLIA_LLIS==1)?res.data?.D50+" mm":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==8 || I40==7)?"":(res.configuration?.LIA==1)?res.data?.G50+" mm":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==8 || I40==7)?"":(res.configuration?.HHIA_HHIS==1)?res.data?.N50+" mm":""} 
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.LLLA_LLLS==1)?res.data?.Q50+" mm":""} 
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.LLA==1)?res.data?.T50+" mm":""} 
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.HHLA_HHLS==1)?res.data?.AA50+" mm":""}  
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                 -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==8 || I40==7)?"":(res.configuration?.LLIA_LLIS==1)?(Math.round((res.data?.D50 / 25.4) * 100) / 100) + " in.":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==8 || I40==7)?"":(res.configuration?.LIA==1)?(Math.round((res.data?.G50 / 25.4) * 100) / 100) + " in.":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(I40==8 || I40==7)?"":(res.configuration?.HHIA_HHIS==1)?(Math.round((res.data?.N50 / 25.4) * 100) / 100) + " in.":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.LLLA_LLLS==1)?(Math.round((res.data?.Q50 / 25.4) * 100) / 100) + " in.":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.LLA==1)?(Math.round((res.data?.T50 / 25.4) * 100) / 100) + " in.":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {(res.configuration?.HHLA_HHLS==1)?(Math.round((res.data?.AA50 / 25.4) * 100) / 100) + " in.":""}
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                  <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                    -
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReportTables;
