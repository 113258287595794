import React from 'react'
import {  fetchSchema, fetchSchemaData } from '../Tools/tools'
import { toFixed } from '../helpers/Converts'
import { I106f, I111f, O106f, O111f } from '../helpers/reportHelp'

function ReportRoller({I40,res}) {
  const schema=()=>{
 
    return fetchSchema(I40,res.configuration?.Demister,res.configuration?.Deverter,res.configuration?.Coalescer,"configs")
    // return fetchSchema(5,1,1,1,"configs")

  }
  return (
    <table className='report'
    style={{
      borderCollapse: 'collapse',
      width: '90%',
      margin: 'auto',
      overflow: 'hidden',
    }}
  >
     <thead>
        <tr>
          <td><img style={{width:50,height:50}} src={res['project']['logo1']} /></td>
          <td colSpan={3} className="text-center p-5 pb-0">
            <h1 className="!text-[16px] font-bold text-center">
              Three-Phase Seperator <br />
              Sizing Report
            </h1>
          </td>
          <td ><img style={{width:50,height:50,marginLeft:"auto"}} src={res['project']['logo2']} /></td>
        </tr>
        <tr className=" text-base">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">CLIENT</span>
              <span className="border-b p-1">
                -
              </span>
              <span className="border-b p-1">PROJ No</span>
              <span className="border-b p-1">-</span>
            </div>
          </td>
        </tr>
        <tr className="head">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">PROJECT</span>
              <span className="border-b p-1">
                -
              </span>
              <span className="border-b p-1">CALC No</span>
              <span className="border-b p-1"> {res?.sizing?.calc_no??"-"}</span>
            </div>
          </td>
        </tr>
        <tr className="head">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">SUBJECT</span>
              <span className="border-b p-1">
                {res.project.subject}
              </span>
              <span className="border-b p-1">SHEET</span>
              <span className="border-b p-1">2 {'    '} of {     } 3</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="text-center mt-3">
            <table className="w-full">
              <tr>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  REVISION
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  DATE
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Approved
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Prepared
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Checked
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.sizing?.revision??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.date??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.approved??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.prepared??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.checked??"-"}
                </td>
              </tr>
            </table>
          </td>
          <td></td>
          <td></td>
        </tr>
      </thead>
    <tbody>
      <tr className="p-5 " style={{ height: 50 }}>
        <td colSpan={5}> </td>
      </tr>
      <tr>
        <td colSpan={5} className="!text-[11px]">
          <strong> Drawing</strong>
        </td>
      </tr>

      <tr className="">
        <td colSpan={5} className="relative  w-full h-[400px] ">
          {/* <div className="absolute top-0 aspect-[16/9]   h-[700px] w-[500px] !-right-14"> */}
          <div
          className={`absolute top-0 aspect-[16/9]   ${
    
               [1, 2, 7].find((x) => x === I40)
              ? '!w-full grid !h-[645px] text-end justify-end items-end left-0 '
              : '!w-full '
          } m-auto`}
        >
          <img src={`${schema()}`} className="m-auto h-full " />
          {fetchSchemaData(res.data,I40,res.configuration?.Demister,res.configuration?.Deverter,res.configuration?.Coalescer)}
          {/* {fetchSchemaData(res.data,5,1,1,1)} */}
        </div>
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th
                  colSpan={5}
                  className="text-start !text-[11px] !font-bold"
                >
                  Volumes
                </th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <th
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                  colSpan={2}
                >
                  Si
                </th>
                <th
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                  colSpan={2}
                >
                  Field
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '0px',
                    textAlign: 'center',
                    writingMode: 'vertical-lr',
                    transform: 'rotate(180deg)',
                  }}
                  rowSpan={5}
                >
                  Light Liquid
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  HHL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.S79,2)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 m<sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.AM79,2)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
               m<sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 HL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.N79,2)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  m<sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.AI79,3)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  m<sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  NL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed(I106f(I40,res.data.BD75, res.data.AH71, res.data.BH79, res.data.AV79),3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  m<sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 {toFixed(O106f(I40,I106f(I40,res.data.BD75, res.data.AH71, res.data.BH79, res.data.AV79), res.data.BD75, res.data.AH71, res.data?.AR83),3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 m<sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  LL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                   {toFixed( res.data?.I79,3)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  m<sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.AE79,3)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m <sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 LLL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 {toFixed( res.data?.D79,3)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
              m <sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                   {toFixed( res.data?.AA79,3)}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m<sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '0px',
                    textAlign: 'center',
                    writingMode: 'vertical-lr',
                    transform: 'rotate(180deg)',
                  }}
                  rowSpan={5}
                >
                  Heavy Liquid
                </td>

                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  HHL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                    {toFixed( res.data?.S75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m <sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.AM75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
              m   <sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  HL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                     {toFixed( res.data?.N75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m  <sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                    {toFixed( res.data?.AI75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m <sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 NL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 {toFixed( I111f(I40, res.data?.N71,res.data?. BD75, res.data?.BH79, res.data?.AR79, res.data?.BD79, res.data?.AR75),3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m <sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {   toFixed( O111f(I40,I111f(I40, res.data?.N71,res.data?. BD75, res.data?.BH79, res.data?.AR79, res.data?.BD79, res.data?.AR75),res.data?.AR75, res.data?.BD75, res.data?.N71, res.data?.AR83),3)||"-"}

                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m<sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  LL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                   {toFixed( res.data?.I75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
              m   <sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                {toFixed( res.data?.AE75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m  <sub className='align-top'>3</sub>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 LLL
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                 {toFixed( res.data?.D75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
               m <sub className='align-top'>3</sub>
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                  {toFixed( res.data?.AA75,3)||"-"}
                </td>
                <td
                  style={{ border: '1px solid black', padding: '3px 8px' }}
                >
                m <sub className='align-top'>3</sub>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td colSpan={3} className="w-full"></td>
      </tr>
      <tr>
        <td className="h-[100px]"></td>
      </tr>
      <tr>
        <td colSpan={2} className="!text-[11px] !font-bold">
          Notes
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="text-wrap">
          
          <br />
          {res.sizing.note}
          
          <br />
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export default ReportRoller