import { event_change_data } from '../services/events';
import { H_DtoA_AT, atot, fetchData } from './Helper';
import { line1, line11 } from './Process_lines';

export const preProccess = async (data) => {
  ////////////information ////////////////
  await localStorage.setItem('prepared', parseFloat(data['sizing'].prepared));
  await localStorage.setItem('date', parseFloat(data['sizing'].date));
  await localStorage.setItem('prepared', parseFloat(data['sizing'].calc_no));
  await localStorage.setItem('checked', parseFloat(data['sizing'].checked));
  await localStorage.setItem('revision', parseFloat(data['sizing'].revision));
  await localStorage.setItem('approved', parseFloat(data['sizing'].approved));
  ////////////////////////////////////////
  await localStorage.setItem('AW23', parseFloat(data['design'].AW23));
  await localStorage.setItem('AW24', parseFloat(data['design'].AW24));
  await localStorage.setItem('AW25', parseFloat(data['design'].AW25));
  await localStorage.setItem('AW26', parseFloat(data['design'].AW26));
  await localStorage.setItem('AW27', parseFloat(data['design'].AW27));
  await localStorage.setItem('AW28', parseFloat(data['design'].AW28));
  await localStorage.setItem('AW29', parseFloat(data['design'].AW29));
  await localStorage.setItem('AW30', parseFloat(data['design'].AW30));

  await localStorage.setItem('AZ23', parseFloat(data['design'].AZ23));
  await localStorage.setItem('AZ24', parseFloat(data['design'].AZ24));
  await localStorage.setItem('AZ25', parseFloat(data['design'].AZ25));
  await localStorage.setItem('AZ26', parseFloat(data['design'].AZ26));
  await localStorage.setItem('AZ27', parseFloat(data['design'].AZ27));
  await localStorage.setItem('AZ28', parseFloat(data['design'].AZ28));
  await localStorage.setItem('AZ29', parseFloat(data['design'].AZ29));
  await localStorage.setItem('AZ30', parseFloat(data['design'].AZ30));

  await localStorage.setItem('BC23', parseFloat(data['design'].BC23));
  await localStorage.setItem('BC24', parseFloat(data['design'].BC24));
  await localStorage.setItem('BC25', parseFloat(data['design'].BC25));
  await localStorage.setItem('BC26', parseFloat(data['design'].BC26));
  await localStorage.setItem('BC27', parseFloat(data['design'].BC27));
  await localStorage.setItem('BC28', parseFloat(data['design'].BC28));
  await localStorage.setItem('BC29', parseFloat(data['design'].BC29));
  await localStorage.setItem('BC30', parseFloat(data['design'].BC30));

  await localStorage.setItem('BF23', parseFloat(data['design'].BF23));
  await localStorage.setItem('BF24', parseFloat(data['design'].BF24));
  await localStorage.setItem('BF25', parseFloat(data['design'].BF25));
  await localStorage.setItem('BF26', parseFloat(data['design'].BF26));
  await localStorage.setItem('BF27', parseFloat(data['design'].BF27));
  await localStorage.setItem('BF28', parseFloat(data['design'].BF28));
  await localStorage.setItem('BF29', parseFloat(data['design'].BF29));
  await localStorage.setItem('BF30', parseFloat(data['design'].BF30));

  //////////////////////////////////////////////////////////
  //////////process
  //////////////////////////////////////////////////////////
  await localStorage.setItem('K21', parseFloat(data['process'].K21[0]));
  await localStorage.setItem('K22', parseFloat(data['process'].K22[0]));
  await localStorage.setItem('K23', parseFloat(data['process'].K23[0]));
  await localStorage.setItem('K24', parseFloat(data['process'].K24[0]));
  await localStorage.setItem('K25', parseFloat(data['process'].K25[0]));
  await localStorage.setItem('K26', parseFloat(data['process'].K26[0]));
  await localStorage.setItem('K27', parseFloat(data['process'].K27[0]));
  await localStorage.setItem('K28', parseFloat(data['process'].K28[0]));
  await localStorage.setItem('K29', parseFloat(data['process'].K29[0]));
  await localStorage.setItem('K30', parseFloat(data['process'].K30[0]));
  await localStorage.setItem('K31', parseFloat(data['process'].K31[0]));
  await localStorage.setItem('K32', parseFloat(data['process'].K32[0]));
  await localStorage.setItem('K33', parseFloat(data['process'].K33[0]));
  await localStorage.setItem('K34', parseFloat(data['process'].K34[0]));

  await localStorage.setItem('R21', parseFloat(data['process'].K21[1]));
  await localStorage.setItem('R22', parseFloat(data['process'].K22[1]));
  await localStorage.setItem('R23', parseFloat(data['process'].K23[1]));
  await localStorage.setItem('R24', parseFloat(data['process'].K24[1]));
  await localStorage.setItem('R25', parseFloat(data['process'].K25[1]));
  await localStorage.setItem('R26', parseFloat(data['process'].K26[1]));
  await localStorage.setItem('R27', parseFloat(data['process'].K27[1]));
  await localStorage.setItem('R28', parseFloat(data['process'].K28[1]));
  await localStorage.setItem('R29', parseFloat(data['process'].K29[1]));
  await localStorage.setItem('R30', parseFloat(data['process'].K30[1]));
  await localStorage.setItem('R31', parseFloat(data['process'].K31[1]));
  await localStorage.setItem('R32', parseFloat(data['process'].K32[1]));
  await localStorage.setItem('R33', parseFloat(data['process'].K33[1]));
  await localStorage.setItem('R34', parseFloat(data['process'].K34[1]));

  await localStorage.setItem('K35', parseFloat(data['process'].Over_Design_Factor));
  await localStorage.setItem('O35', parseFloat(data['process'].gas));
  await localStorage.setItem('R35', parseFloat(data['process'].l_liq));
  await localStorage.setItem('V35', parseFloat(data['process'].h_liq));

  //////////////////////////////////////////////////////////
  //////////configuration
  //////////////////////////////////////////////////////////
  await localStorage.setItem('U40', parseFloat(data['configuration'].U40));
  await localStorage.setItem('Y40', parseFloat(data['configuration'].Y40));
  await localStorage.setItem('AC40', parseFloat(data['configuration'].AC40));
  await localStorage.setItem('AG40', parseFloat(data['configuration'].AG40));

  await localStorage.setItem(
    'I40',
    parseFloat(data['configuration'].configuration.value)
  );
  await localStorage.setItem('Q40', parseFloat(data['configuration'].l_OR_D));
  await localStorage.setItem(
    'BF45',
    parseFloat(data['configuration'].Init_Estimated_D)
  );
  await localStorage.setItem('D45', parseFloat(data['configuration'].LLIA_LLIS));
  await localStorage.setItem('H45', parseFloat(data['configuration'].LIA));
  await localStorage.setItem('L45', parseFloat(data['configuration'].HHIA_HHIS));
  await localStorage.setItem('P45', parseFloat(data['configuration'].LLLA_LLLS));
  await localStorage.setItem('T45', parseFloat(data['configuration'].LLA));
  await localStorage.setItem('X45', parseFloat(data['configuration'].HHLA_HHLS));
  await localStorage.setItem('AB45', parseFloat(data['configuration'].Demister));
  await localStorage.setItem('AE45', parseFloat(data['configuration'].Diverter));
  await localStorage.setItem('AH45', parseFloat(data['configuration'].Coalescer));
  await localStorage.setItem('AR83', parseFloat(data['configuration'].dish_type));

  //////////////////////////////////////////////////////////
  //////////config_specific
  //////////////////////////////////////////////////////////
  await localStorage.setItem('AK45', parseFloat(data['config_specific'].AK45));
  await localStorage.setItem('BB45', parseFloat(data['config_specific'].BB45));
  await localStorage.setItem('AX45', parseFloat(data['config_specific'].AX45));
  await localStorage.setItem('AN40', parseFloat(data['config_specific'].AN40));
  await localStorage.setItem('AS40', parseFloat(data['config_specific'].AS40));

  await localStorage.setItem('AN45', parseFloat(data['config_specific'].W_d));

  await localStorage.setItem('AR45', parseFloat(data['config_specific'].C_in));
  await localStorage.setItem('AV45', parseFloat(data['config_specific'].D_f));
  await localStorage.setItem('revise', parseFloat(data['config_specific'].revise));
  await localStorage.setItem(
    'AK40',
    parseFloat(data['config_specific'].H_v_ratio_of_D)
  );
  await localStorage.setItem('AW40', parseFloat(data['config_specific'].K_value));
  await localStorage.setItem('BA40', parseFloat(data['config_specific'].K_s));

  event_change_data('AW23');
  event_change_data('AW24');
  event_change_data('AW25');
  event_change_data('AW26');
  event_change_data('AW27');
  event_change_data('AW28');
  event_change_data('AW29');
  event_change_data('AW30');

  event_change_data('AZ24');
  event_change_data('AZ25');
  event_change_data('AZ26');
  event_change_data('AZ27');
  event_change_data('AZ28');
  event_change_data('AZ29');
  event_change_data('AZ30');

  event_change_data('BC23');
  event_change_data('BC24');
  event_change_data('BC25');
  event_change_data('BC26');
  event_change_data('BC27');
  event_change_data('BC28');
  event_change_data('BC29');
  event_change_data('BC30');

  event_change_data('BF23');
  event_change_data('BF24');
  event_change_data('BF25');
  event_change_data('BF26');
  event_change_data('BF27');
  event_change_data('BF28');
  event_change_data('BF29');
  event_change_data('BF30');

  event_change_data('K24');
  event_change_data('K26');
  event_change_data('K30');
  event_change_data('K34');
  event_change_data('K35');
  event_change_data('R24');
  event_change_data('R28');
  event_change_data('R29');
  event_change_data('R30');
  event_change_data('R32');
  event_change_data('R33');
  event_change_data('R34');
  event_change_data('R35');
  event_change_data('O35');
  event_change_data('V35');

  event_change_data('I40');
  event_change_data('Q40');
  event_change_data('U40');
  event_change_data('Y40');
  event_change_data('AC40');
  event_change_data('AG40');
  event_change_data('I40');

  event_change_data('D45');
  event_change_data('H45');
  event_change_data('L45');
  event_change_data('P45');
  event_change_data('T45');
  event_change_data('X45');
  event_change_data('AB45');
  event_change_data('AE45');
  event_change_data('AH45');

  event_change_data('AK40');
  event_change_data('AW40');
  event_change_data('BA40');

  event_change_data('AK45');
  event_change_data('AN45');
  event_change_data('AR45');
  event_change_data('AV45');
  event_change_data('AX45');
  event_change_data('BB45');

  event_change_data('BF45');
};
export const change_value = (name, value) => {
  localStorage.setItem(name, value);
  event_change_data(name);
};
function isError(value) {
  return typeof value === 'undefined' || value === null || isNaN(value);
}
var RepSelec=false;

