import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ProjectContext } from '../Context/ProjectContext';
import { Button, Card, Checkbox, Form, Input, Select, Tooltip, Typography } from 'antd';
import { configApi } from '../API/configApi';
import useApiClient from '../Settings/useApiClient';
import { UnitSelect } from './UnitSelect';
import useNotify from '../hooks/useNotify';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Init_Estimated_D } from '../assets/consts';

export const GeneralConfigurations = forwardRef(({ setGcFinish,setI40 }, ref) => {
  const { sizing, setConfig,setTipTableNumber,dataInput } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [configuration, setConfiguration] = useState();
  const [l_d, setL_d] = useState();
  const [estimated, setEstimated] = useState('2.1');
  const [dishType, setDishType] = useState();
  const [holdupLightLiquid, setHoldupLightLiquid] = useState();
  const [holdupLightLiquidUnit, setHoldupLightLiquidUnit] = useState();
  const [holdupHeavyLiquidUnit, setHoldupHeavyLiquidUnit] = useState();
  const [holdupHeavyLiquid, setHoldupHeavyLiquid] = useState();
  const [surgeLightLiquid, setSurgeLightLiquid] = useState();
  const [surgeLightLiquidUnit, setSurgeLightLiquidUnit] = useState();
  const [surgeHeavyLiquid, setSurgeHeavyLiquid] = useState();
  const [surgeHeavyLiquidUnit, setSurgeHeavyLiquidUnit] = useState();
  const [lLIA_LLIS, setLLIA_LLIS] = useState(false);
  const [lIA, setLIA] = useState(false);
  const [lLA, setLLA] = useState(false);
  const [hHIA_HHIS, setHHIA_HHIS] = useState(false);
  const [lLLA_LLLS, setLLLA_LLLS] = useState(false);
  const [hHLA_HHLS, setHHLA_HHLS] = useState(false);
  const [demister, setDemister] = useState(false);
  const [diverter, setDiverter] = useState(false);
  const [coalescer, setCoalescer] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const { openNotification } = useNotify();
  const apiClient = useApiClient();

  const [res, setRes] = useState();
  const [form] = Form.useForm();
  const loadConfig = () => {
    setLoading(true);
    configApi
      .configurations(apiClient)
      .then((res) => {
        const _configs = res.map((c) => {
          return {
            label: c.name,
            value: c.id,
            amount: c.value,
          };
        });
        setConfigurations(_configs);
      })

      .catch((e) => {})
      .finally(() => setLoading(false));
  };
  useEffect(()=>{
    setI40(configuration)
  },[configuration])
  const initialize = () => {
    configApi.load(apiClient, sizing.id).then((res) => {
      if (!res) return;
      setRes(res);
      setConfiguration(res.configuration_id);
      setL_d(res.l_OR_D);
      setEstimated(res.Init_Estimated_D);
      setHoldupLightLiquid(res.Holdup_Time_L_Lig);
      setHoldupLightLiquidUnit(res.Holdup_Time_L_Lig_unit);
      setHoldupHeavyLiquidUnit(res.Holdup_Time_H_Lig_unit);
      setHoldupHeavyLiquid(res.Holdup_Time_H_Lig);
      setSurgeLightLiquid(res.Surge_Time_L_Liq);
      setSurgeLightLiquidUnit(res.Surge_Time_L_Liq_unit);
      setSurgeHeavyLiquid(res.Surge_Time_H_Liq);
      setSurgeHeavyLiquidUnit(res.Surge_Time_H_Liq_unit);
      setLLIA_LLIS(res.LLIA_LLIS);
      setLIA(res.LIA);
      setLLA(res.LLA);
      setHHIA_HHIS(res.HHIA_HHIS);
      setLLLA_LLLS(res.LLLA_LLLS);
      setHHLA_HHLS(res.HHLA_HHLS);
      setDemister(res.Demister);
      setCoalescer(res.Coalescer);
      setDishType(res.dish_type);
      setDiverter(res.Diverter);
      if(dataInput?.section=="config"){
        setL_d(dataInput.Nominal)
      }
    });
  };
  useEffect(() => {
    if (sizing) {
      loadConfig();
      initialize();
    }
  }, [sizing]);
  useEffect(() => {
    if (configuration) {
      setConfig(configurations.find((c) => c?.value == configuration)?.amount);
    }
  }, [configuration]);
  const submit = () => {
    const data = {
      configuration_id: configuration,
      l_OR_d: l_d,
      Init_Estimated_D: estimated,
      Holdup_Time_L_Lig: holdupLightLiquid,
      Holdup_Time_L_Lig_unit: holdupLightLiquidUnit,
      Holdup_Time_H_Lig: holdupHeavyLiquid,
      Holdup_Time_H_Lig_unit: holdupHeavyLiquidUnit,
      Surge_Time_L_Liq: surgeLightLiquid,
      Surge_Time_L_Liq_unit: surgeLightLiquidUnit,
      Surge_Time_H_Liq: surgeHeavyLiquid,
      Surge_Time_H_Liq_unit: surgeHeavyLiquidUnit,
      LLIA_LLIS: lLIA_LLIS,
      LIA: lIA,
      LLA: lLA,
      HHIA_HHIS: hHIA_HHIS,
      LLLA_LLLS: lLLA_LLLS,
      HHLA_HHLS: hHLA_HHLS,
      Demister: demister,
      Diverter: diverter,
      Coalescer: coalescer,
      dish_type: dishType,
    };
    setLoading(true);

    configApi
      .save(apiClient, sizing.id, data)
      .then((res) => {
        openNotification(
          'Success',
          'General Configurations saved successfully.',
          200
        );
        setGcFinish(true);
      })
      .catch((e) => {
        setGcFinish(false);
      })
      .finally(() => setLoading(false));
  };
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  return (
    <Form form={form} onFinish={submit}>
      <div className="p-3">
        <div className="mb-5 ">
          <Typography.Title level={4} className="title-1 mt-5">
            General Configurations
          </Typography.Title>
        </div>
        <div className="grid grid-cols-4 gap-3">
          <div className="grid">
            <Typography.Text className="important ">
              Configuration
            </Typography.Text>
            <Select
              required
              loading={loading}
              placeholder={'Select'}
              value={configuration}
              onChange={setConfiguration}
              options={configurations}
            />
          </div>
          <div className="grid ">
            <Typography.Text className="important">
              L/D 
              <Tooltip title={"Refer to Table 2"}>
          <InfoCircleOutlined  onMouseOver={()=>setTipTableNumber({id:2})} role='button' className='ms-1' style={{color:"#808080"}} />
          </Tooltip>
            </Typography.Text>
            <Input
              required
              className="input-general  "
              placeholder="Type..."
              onChange={(e) => setL_d(e.target.value)}
              value={l_d}
            />
          </div>
          <div className="grid ">
            <Typography.Text className="important">
              Estimated D (m) 
              <Tooltip title={Init_Estimated_D}>
          <InfoCircleOutlined   role='button' className='ms-1' style={{color:"#808080"}} />
          </Tooltip>
            </Typography.Text>
            <Input
              required
              className="input-general  "
              onChange={(e) => setEstimated(e.target.value)}
              value={estimated}
              placeholder="Type..."
              type="number"
            />
          </div>
          <div className="grid  relative">
            <Typography.Text className=" important ">Dish Type</Typography.Text>
            <Select
              placeholder={'Select'}
              required
              value={dishType}
              onChange={setDishType}
              options={[
                { label: 'Eliptical', value: 1 },
                { label: 'Torispherical', value: 2 },
                { label: 'Hemispherical', value: 3 },
              ]}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="grid mt-5">
            <Typography.Title level={5}>Holdup Time </Typography.Title>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <Typography.Text className="important ">
                  Light Liquid
                  
                </Typography.Text>
                <Input
                  onChange={(e) => setHoldupLightLiquid(e.target.value)}
                  value={holdupLightLiquid}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setHoldupLightLiquidUnit}
                      type={'Time'}
                      defaultUnitValue={
                        res?.holdup__time__l__lig_unit?.unit_name ?? 'min'
                      }
                    />
                  }
                  type="number"
                  className="input-addon-general focus:border-none focus:outline-none"
                />
              </div>
              <div>
                <Typography.Text className="important ">
                  Heavy Liquid
                </Typography.Text>
                <Input
                  onChange={(e) => setHoldupHeavyLiquid(e.target.value)}
                  value={holdupHeavyLiquid}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setHoldupHeavyLiquidUnit}
                      type={'Time'}
                      defaultUnitValue={
                        res?.holdup__time__h__lig_unit?.unit_name ?? 'min'
                      }
                    />
                  }
                  type="number"
                  className="input-addon-general focus:border-none focus:outline-none"
                />
              </div>
            </div>
          </div>
          <div className="grid mt-5">
            <Typography.Title level={5}>Surge Time </Typography.Title>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <Typography.Text className="important ">
                  Light Liquid
                </Typography.Text>
                <Input
                  onChange={(e) => setSurgeLightLiquid(e.target.value)}
                  value={surgeLightLiquid}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setSurgeLightLiquidUnit}
                      type={'Time'}
                      defaultUnitValue={
                        res?.surge__time__l__liq_unit?.unit_name ?? 'min'
                      }
                    />
                  }
                  type="number"
                  className="input-addon-general focus:border-none focus:outline-none"
                />{' '}
              </div>
              <div>
                <Typography.Text className="important ">
                  Heavy Liquid
                </Typography.Text>
                <Input
                  onChange={(e) => setSurgeHeavyLiquid(e.target.value)}
                  value={surgeHeavyLiquid}
                  addonAfter={
                    <UnitSelect
                      onUnitChange={setSurgeHeavyLiquidUnit}
                      type={'Time'}
                      defaultUnitValue={
                        res?.surge__time__h__liq_unit?.unit_name ?? 'min'
                      }
                    />
                  }
                  type="number"
                  className="input-addon-general focus:border-none focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 grid">
          <Typography.Title level={5}>Alarms/Switches </Typography.Title>
          <div className="grid grid-cols-6">
            <Checkbox
              checked={lLIA_LLIS}
              onChange={(e) => setLLIA_LLIS(e.target.checked)}
            >
              LLIA/LLIS
            </Checkbox>
            <Checkbox checked={lIA} onChange={(e) => setLIA(e.target.checked)}>
              LIA
            </Checkbox>
            <Checkbox checked={lLA} onChange={(e) => setLLA(e.target.checked)}>
              LLA
            </Checkbox>
            <Checkbox
              checked={hHIA_HHIS}
              onChange={(e) => setHHIA_HHIS(e.target.checked)}
            >
              HHIA/HHIS
            </Checkbox>
            <Checkbox
              checked={lLLA_LLLS}
              onChange={(e) => setLLLA_LLLS(e.target.checked)}
            >
              LLLA/LLLS
            </Checkbox>
            <Checkbox
              checked={hHLA_HHLS}
              onChange={(e) => setHHLA_HHLS(e.target.checked)}
            >
              HHLA/HHLS
            </Checkbox>
          </div>
        </div>
        <div className="mt-5 grid">
          <Typography.Title level={5}>Internals </Typography.Title>
          <div className="grid grid-cols-5">
            <Checkbox
              checked={demister}
              onChange={(e) => setDemister(e.target.checked)}
            >
              Demister
            </Checkbox>
            <Checkbox
              checked={diverter}
              onChange={(e) => setDiverter(e.target.checked)}
            >
              Diverter
            </Checkbox>
            <Checkbox
              checked={coalescer}
              onChange={(e) => setCoalescer(e.target.checked)}
            >
              Coalescer
            </Checkbox>
          </div>
        </div>
        {/* <div className='w-100 flex text-center justify-center mt-5 pt-5'>
          <Button
            htmlType="submit"
            disabled={loading}
            loading={loading}
            className="  rounded-none mb-5 mt-5 text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8 py-5 me-2 "
          >
            Save and Next
          </Button>
          </div> */}
      </div>
    </Form>
  );
});
