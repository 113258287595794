import Global from "../Settings/config.json";
export const paymentApi= {
    
    async load(apiClient) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/payment`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async invoices(apiClient,page) {
        let res = [];
        await apiClient
            .get(`${Global.SERVER}user/orders?page=${page}`)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
    async save(apiClient,pid,data) {
        let res = [];
        await apiClient
            .post(`${Global.SERVER}user/payment/${pid}`,data)
            .then((json) => {
                switch (json.status) {
                    case 200:
                        res = json.data
                        break;
                }
            })
        return res;
    },
   
}