import React from 'react';
import { Link } from 'react-router-dom';

const Plan = ({ plan }) => {
  const check = (month, section,price=0) => {
    if (section ==1) {
      switch (month) {
        case 1:
          return price;
        case 3:
          return (price/3).toFixed(0);
        case 6:
          return (price/6).toFixed(0);
        case 12:
          return (price/12).toFixed(0);
      }
    }

   else if (section == 2) {
    switch (month) {
      case 1:
        return (price* 12).toFixed(0);
      case 3:
        return (price ).toFixed(0);
      case 6:
        return (price).toFixed(0);
      case 12:
        return (price).toFixed(0);
    }
    }
    else if (section ==3) {
      switch (month) {
        case 1:
          return 'yearly';
        case 3:
          return 'quarterly ';
        case 6:
          return 'semi-annually';
        case 12:
          return 'yearly';
      }
    }
    else if (section ==4) {
      switch (month) {
        case 1:
          return 'Monthly';
        case 3:
          return 'Quarterly ';
        case 6:
          return 'Semi-annually';
        case 12:
          return 'Yearly';
      }
    }
  };
  return (
    <div className="grid text-center items-center  " key={plan.id}>
      
      <div className="grid gap-y-5 border bg-white shadow px-12 py-12 h-[500px] content-between rounded-2xl">
        <strong className="text-xl ">
          {plan.plan.name == 'Enterprise' ? (
            <div className="relative flex justify-center">
              <img
                src={'/images/star.svg'}
                alt={'Enterprise'}
                className="w-4 mb-2 me-1 align-top"
              />{' '}
              {plan.plan.name}
            </div>
          ) : (
            plan.plan.name
          )}
        </strong>
        {plan.plan.name == 'Enterprise' ? (
          <>
          <img
            src={'/images/building2.svg'}
            alt={'contact'}
            className="m-auto block text-center"
          />
         <strong className=''> {check(plan.months,4)}</strong>
          </>
        ) : (
          <div>
            <strong className="text-3xl align-top">£</strong>
            <strong className="text-7xl">
            {check(plan.months,1,parseFloat( plan.price))}
            </strong>{' '}
            <span>/ month</span>
            <div>
              <strong> £{check(plan.months,2,parseFloat( plan.price))}</strong>  {check(plan.months,3)}
            </div>
          </div>
        )}
        <ul className="leading-10 text-center">
          <li className="flex gap-5">
            <img src={'/images/check.svg'} alt={'features'} />{' '}
            <span>
              {plan.limit_project ? plan.limit_project : 'Unlimit'} Project
            </span>
          </li>
          <li className="flex  gap-5">
            <img src={'/images/check.svg'} alt={'features'} />{' '}
            <span>
              {plan.limit_sizing ? plan.limit_sizing : 'Unlimit'} Sizing
            </span>
          </li>
          <li className="flex  gap-5">
            <img src={'/images/check.svg'} alt={'features'} />{' '}
            <span>
              {plan.limit_user ? plan.limit_user : 'Unlimit'} membership{' '}
            </span>
          </li>
        </ul>
        {plan.name == 'enterprice' ? (
          <a
            role="button"
            href="/#contact"
            className="w-full px-10 py-2 bg-[--button-color] text-white text-[--button-color] border border-[--button-color] rounded-2xl"
          >
            <div>Contact Sales</div>
          </a>
        ) : (
          <Link
          to={`/payment/${plan.id}`}
            role="button"
            className="w-full px-10 py-2 hover:bg-[--button-color] !text-[--color-3] font-semibold  border border-[--color-3] rounded-2xl"
          >
            <div>Select</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Plan;
