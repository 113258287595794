import React from 'react'
import CaseChart from './CaseChart'
import CaseTable from './CaseTable'

function ReportChart({I40,res}) {
  return (
    <table
    style={{
      borderCollapse: 'collapse',
      width: '90%',
      margin: 'auto',
      overflow: 'hidden',
    }}
  >
    <thead>
        <tr>
          <td><img style={{width:50,height:50}} src={res['project']['logo1']} /></td>
          <td colSpan={3} className="text-center p-5 pb-0">
            <h1 className="!text-[16px] font-bold text-center">
              Three-Phase Seperator <br />
              Sizing Report
            </h1>
          </td>
          <td ><img style={{width:50,height:50,marginLeft:"auto"}} src={res['project']['logo2']} /></td>
        </tr>
        <tr className=" text-base">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">CLIENT</span>
              <span className="border-b p-1">
                -
              </span>
              <span className="border-b p-1">PROJ No</span>
              <span className="border-b p-1">-</span>
            </div>
          </td>
        </tr>
        <tr className="head">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">PROJECT</span>
              <span className="border-b p-1">
                -
              </span>
              <span className="border-b p-1">CALC No</span>
              <span className="border-b p-1"> {res?.sizing?.calc_no??"-"}</span>
            </div>
          </td>
        </tr>
        <tr className="head">
          <td colSpan={5} className="text-center">
            <div
              style={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: '10% 50% 10% 10%',
                columnGap: '1%',
                justifyContent: 'space-around',
              }}
            >
              <span className="border-b p-1">SUBJECT</span>
              <span className="border-b p-1">
                {res.project.subject}
              </span>
              <span className="border-b p-1">SHEET</span>
              <span className="border-b p-1">1 {'    '} of {     } 3</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="text-center mt-3">
            <table className="w-full">
              <tr>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  REVISION
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  DATE
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Approved
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Prepared
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  Checked
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                  {res?.sizing?.revision??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.date??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.approved??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.prepared??"-"}
                </td>
                <td style={{ border: '1px solid black', padding: '3px 8px' }}>
                {res?.sizing?.checked??"-"}
                </td>
              </tr>
            </table>
          </td>
          <td></td>
          <td></td>
        </tr>
      </thead>
    <tbody>
      <tr>
        <td className="h-[50px]"></td>
      </tr>
      <tr>
        <td className="!text-[11px] !font-bold">Weight Analysis</td>
      </tr>
      <tr>
        <td colSpan={5} className='!w-[400px] !max-w-[400px] overflow-hidden '>
        <CaseTable/>
        </td>
      </tr>
      <tr>
        <td colSpan={5} style={{textAlign:"center"}}>
        <CaseChart />
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export default ReportChart