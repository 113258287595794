import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useApiClient from '../../Settings/useApiClient';
import useAuth from '../../hooks/useAuth';
import { sizingApi } from '../../API/sizingApi';
import { preProccess } from '../../helpers/Process';
import { useParams } from 'react-router-dom';
import ReportTables from '../../components/ReportTables';
import ReportRoller from '../../components/ReportRoller';
import ReportChart from '../../components/ReportChart';
import { WA, preAnalyze } from '../../helpers/Analyze';
import { ArrowLeftOutlined, BackwardOutlined, PrinterOutlined } from '@ant-design/icons';
import { Spin, Watermark } from 'antd';

const Table = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true)
  const [I40, setI40] = useState("")
  const [res, setRes] = useState(null)
  const apiClient = useApiClient();
  const { id } = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const wa = urlParams.get('wa');
  useEffect(() => {
    setLoading(true)
    sizingApi.view(apiClient, id).then((res) => {
      console.log(res)
      setRes(res)
      setI40(res?.configuration?.configuration_id )
    })
    .finally(()=>setLoading(false));
  },[]);
  if(loading) return <Spin />
  return (
    <Watermark content="OmniSEP" gap={[500,500]}>
    <div ref={ref}>
      <ReportTables I40={I40} res={res}  />
     <ReportRoller    I40={I40} res={res} />
     {wa=='true'?<ReportChart  I40={I40} res={res} /> :null }
    </div>
    </Watermark>
  );
});

const SizingReport = () => {
  const componentRef = useRef();

  const handleDownloadPdf = () => {
    const input = componentRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('download.pdf');
    });
  };

  return (
    <div className=" report a4-page">
      <div className="absolute start-1 flex items-center gap-3 print">
        {/* <ReactToPrint
          trigger={() => (
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Print
            </button>
          )}
          content={() => componentRef.current}
        /> */}
        <button onClick={()=>window.print() }
        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <PrinterOutlined className='me-2'  size={32}/>  Print
      </button>
      <button onClick={()=>document.location.href="/" }
        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <ArrowLeftOutlined className='me-2'  size={32}/>  Back to Panel
      </button>
        <button
          onClick={handleDownloadPdf}
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            class="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>PDF</span>
        </button>
      </div>
      <Table ref={componentRef} />
    </div>
  );
};

export default SizingReport;
