import {
  D_blue,
  D_boot_blue,
  HHIL_blue,
  HHLL_blue,
  HLIL_blue,
  HLL_blue,
  H_bn_blue,
  H_boot_blue,
  H_d_blue,
  H_w_blue,
  LLIL_blue,
  LLLIL_blue,
  LLLL_blue,
  LLL_blue,
  L_1_blue,
  L_2_blue,
  L_3_blue,
  L_4_blue,
  L_blue,
  L_d_blue,
  NLIL_blue,
  NLL_blue,
  Shell_Vol_HHLIL_bule,
  Shell_Vol_HHLL_blue,
  Shell_Vol_HLIL_bule,
  Shell_Vol_HLL_blue,
  Shell_Vol_LLIL_bule,
  Shell_Vol_LLLIL_bule,
  Shell_Vol_LLLL_blue,
  Shell_Vol_LLL_blue,
  Total_Shell_Vol_blue,
  Total_Vol_blue,
  Vol_to_HHLIL_blue,
  Vol_to_HHLL_blue,
  Vol_to_HLIL_blue,
  Vol_to_HLL_blue,
  Vol_to_LLIL_blue,
  Vol_to_LLLIL_blue,
  Vol_to_LLLL_blue,
  Vol_to_LLL_blue,
  Working_Vol_Heavy_blue,
  Working_Vol_Light_blue,
  h_v_blue,
} from '../helpers/BlueFunctions';
import {
  Calculated_D_ft,
  Calculated_D_m,
  Calculated_L_ft,
  Calculated_L_m,
  D_f_in1,
  D_f_in3,
  D_g,
  D_hl,
  D_ll,
  HHIA_HHIS,
  HHIA_HHIS_h_mm,
  HHIL,
  HHLA_HHLS,
  HHLA_HHLS_h_mm,
  HHLL,
  HIA_h_mm,
  HLA_h_mm,
  HLIL,
  HLL,
  H_bn,
  H_d,
  H_w,
  LIA,
  LIA_h_mm,
  LLA,
  LLA_h_mm,
  LLIA_LLIS,
  LLIA_LLIS_h_mm,
  LLIL,
  LLL,
  LLLA,
  LLLA_LLLS_h_mm,
  LLLIL,
  LLLL,
  L_1,
  L_2,
  L_min_hl,
  L_min_lh,
  L_min_v,
  Shell_Vol_LLIL,
  Shell_Vol_LLL,
  Shell_Vol_to_HHLIL,
  Shell_Vol_to_HHLL,
  Shell_Vol_to_HLIL,
  Shell_Vol_to_HLL,
  Shell_Vol_to_LLLIL,
  Shell_Vol_to_LLLL,
  T_hl,
  T_lh,
  Total_Shell_Vol,
  Total_Vol,
  Vol_to_HHLIL,
  Vol_to_HHLL,
  Vol_to_HLIL,
  Vol_to_HLL,
  Vol_to_LLIL,
  Vol_to_LLL,
  Vol_to_LLLIL,
  Vol_to_LLLL,
  Working_Vol_Heavy,
  Working_Vol_Light,
  u_g,
} from '../helpers/DependentFunctions';
import {
  D_f_c,
  D_f_in2,
  HHL_in_Boot,
  HLL_in_Boot,
  H_a,
  H_boot,
  H_v,
  L_3,
  L_4,
  Q_g,
  Q_h_Liq,
  Q_l_Liq,
  W_d,
  u_h,
  u_l,
  u_t,
} from '../helpers/IndepentFunctions';

//attach your function with change event
window.onload = function () {
  if (window.addEventListener) {
    //constant variablels

    window.addEventListener(
      'change_data',
      (e) => {
        switch (e?.detail?.name) {
          case 'HHLA_HHLS_h_mm':
            HHLA_HHLS();

            break;
          case 'HLA_h_mm':
            NLL_blue();
            break;
          case 'LLA_h_mm':
            LLA();
            break;
          case 'LLLA_LLLS_h_mm':
            LLLA();
            break;
          case 'HHIA_HHIS_h_mm':
            HHIA_HHIS();
            break;
          case 'HIA_h_mm':
            NLIL_blue();
            break;
          case 'LIA_h_mm':
            LIA();
            break;
          case 'LLIA_LLIS_h_mm':
            LLIA_LLIS();
            break;

          case 'I40':
            HHLA_HHLS_h_mm();
            HLA_h_mm();
            LLA_h_mm();
            LLLA_LLLS_h_mm();
            HHIA_HHIS_h_mm();
            HIA_h_mm();
            LIA_h_mm();
            LLIA_LLIS_h_mm();
            LLIA_LLIS();
            LIA();
            Working_Vol_Heavy();
            Working_Vol_Light();
            HHIA_HHIS();
            LLLA();
            LLA();
            HHLA_HHLS();
            HLIL();
            LLLL();
            HLL();
            Calculated_D_ft();
            Calculated_L_ft();
            L_1();
            L_2();
            L_3();
            L_4();
            Shell_Vol_to_LLLIL();
            Shell_Vol_LLIL();
            Shell_Vol_to_HLIL();
            Shell_Vol_to_HHLIL();
            Total_Shell_Vol();
            Shell_Vol_to_LLLL();
            Shell_Vol_LLL();
            Shell_Vol_to_HLL();
            Shell_Vol_to_HHLL();
            Vol_to_LLLL();
            Vol_to_LLLIL();
            Vol_to_LLIL();
            Vol_to_HLIL();
            Vol_to_HHLIL();
            Vol_to_LLL();
            Vol_to_HLL();
            Vol_to_HHLL();
            Q_h_Liq();
            u_h();
            u_l();
            T_hl();
            T_lh();
            L_min_hl();
            L_min_lh();
            L_min_v();
            H_w();
            H_d();
            H_v();

            Working_Vol_Heavy_blue();
            NLIL_blue();
            HLIL_blue();
            LLLL_blue();
            Working_Vol_Light_blue();
            NLL_blue();
            HLL_blue();
            h_v_blue();
            H_w_blue();
            H_bn_blue();
            H_bn()
            H_d_blue();
            L_blue();
            Shell_Vol_HHLIL_bule();
            Shell_Vol_HHLL_blue();
            Shell_Vol_HLIL_bule();
            Shell_Vol_HLL_blue();
            Shell_Vol_LLLL_blue();
            Shell_Vol_LLIL_bule();
            Shell_Vol_LLL_blue();
            Shell_Vol_HLL_blue();
            Vol_to_HHLIL_blue();
            Vol_to_HHLL_blue();
            Vol_to_HLIL_blue();
            Vol_to_HLL_blue();
            Vol_to_LLIL_blue();
            Vol_to_LLLIL_blue();
            Vol_to_LLLL_blue();
            Vol_to_LLL_blue();
            L_1_blue();
            L_2_blue();
            L_3_blue();
            L_4_blue();
            D_blue();
            H_boot_blue();
            Total_Vol_blue();
            break;
          case 'K21':
            break;
          case 'K22':
            break;
          case 'K23':
            break;
          case 'K24':
            D_g();
            break;
          case 'K25':
            break;
          case 'K26':
            D_f_in1();
            D_f_in3();
            break;

          case 'K27':
            break;
          case 'K28':
            break;
          case 'K29':
            break;
          case 'K30':
            D_f_in3();
            break;
          case 'K31':
            break;
          case 'K32':
            break;
          case 'K33':
            break;
          case 'K34':
            D_f_in1();
            D_f_in3();
            break;
          case 'K35':
            Q_h_Liq();
            Q_l_Liq();
            Q_g();
            break;
          case 'Q40':
            Working_Vol_Heavy();
            Working_Vol_Light();

            HHLA_HHLS_h_mm();
            HLA_h_mm();
            LLA_h_mm();
            LLLA_LLLS_h_mm();
            HHIA_HHIS_h_mm();
            HIA_h_mm();
            LIA_h_mm();
            LLIA_LLIS_h_mm();
            Shell_Vol_to_LLLIL();
            Shell_Vol_LLIL();
            Shell_Vol_to_HLIL();
            Shell_Vol_to_HHLIL();

            Shell_Vol_to_LLLL();
            Shell_Vol_LLL();
            Shell_Vol_to_HLL();
            Shell_Vol_to_HHLL();
            Total_Shell_Vol();
            Calculated_L_ft();
            Calculated_D_ft();
            L_1();
            Working_Vol_Heavy_blue();
            Working_Vol_Light_blue();
            D_blue();
            L_blue();
            break;
          case 'U40':
            Working_Vol_Light();
            NLL_blue();
            Working_Vol_Light_blue();
            break;
          case 'Y40':
            Working_Vol_Heavy();
            Working_Vol_Heavy_blue();
            NLIL_blue();

            break;
          case 'AC40':
            Working_Vol_Light();
            Working_Vol_Light_blue();
            H_bn_blue();
            H_bn()
            break;
          case 'AG40':
            Working_Vol_Heavy();
            H_bn_blue();
            Working_Vol_Heavy_blue();
            H_bn()
            break;
          case 'D45':
            LLIA_LLIS();
            break;
          case 'H45':
            LIA();
            break;
          case 'L45':
            HHIA_HHIS();
            break;
          case 'P45':
            LLLA();
            break;
          case 'T45':
            LLA();
            break;
          case 'X45':
            HHLA_HHLS();
            h_v_blue();
            break;
          case 'AB45':
            Calculated_D_ft();
            H_d();
            H_v();
            h_v_blue();
            H_d_blue();
            break;
          case 'AE45':
            break;
          case 'AH45':
            L_1();
            L_blue();
            break;
          case 'AK40':
            H_v();
            h_v_blue();
            break;

          case 'AK45':
            H_a();

            break;
          case 'AN45':
            W_d();

            break;
          case 'AR45':
            D_f_c();
            break;
          case 'AR83':
            Vol_to_LLIL();
            Vol_to_LLLIL();
            Vol_to_HLIL();
            Vol_to_HHLIL();
            Vol_to_LLLL();
            Vol_to_LLL();
            Vol_to_HLL();
            Vol_to_HHLL();
            break;
          case 'AV45':
            D_f_in2();

            break;
          case 'AX45':
            HLL_in_Boot();

            break;
          case 'BB45':
            HHL_in_Boot();

            break;
          case 'AW23':
            HHLA_HHLS();
            break;
          case 'AW24':
            Working_Vol_Light();

            Working_Vol_Light_blue();
            NLL_blue();
            break;
          case 'AW25':
            LLA();
            break;
          case 'AW26':
            LLLA();
            break;
          case 'AW27':
            HHIA_HHIS();
            break;
          case 'AW28':
            Working_Vol_Heavy();
            HLL_in_Boot();
            Working_Vol_Heavy_blue();
            NLIL_blue();

            break;

          case 'AW29':
            LIA();
            break;
          case 'AW30':
            LLIA_LLIS();
            break;
          case 'AW40':
            u_t();
            break;
          case 'BW40':
            u_h();
            u_l();
            break;
          case 'AZ23':
            HHLA_HHLS();
            break;
          case 'AZ24':
            Working_Vol_Light();
            Working_Vol_Light_blue();
            NLL_blue();

            break;
          case 'AZ25':
            LLA();
            break;
          case 'AZ26':
            LLLA();
            break;
          case 'AZ27':
            HHIA_HHIS();
            break;
          case 'AZ28':
            Working_Vol_Heavy();
            Working_Vol_Heavy_blue();
            NLIL_blue();
            break;

          case 'AZ29':
            LIA();
            break;
          case 'AZ30':
            LLIA_LLIS();
            break;
          case 'BC23':
            HHLA_HHLS();
            break;
          case 'BC24':
            Working_Vol_Light();
            Working_Vol_Light_blue();
            NLL_blue();
            break;
          case 'BC25':
            LLA();
            break;
          case 'BC26':
            LLLA();
            LLLA_LLLS_h_mm();
            break;
          case 'BC27':
            HHIA_HHIS();
            break;
          case 'BC28':
            Working_Vol_Heavy();
            NLIL_blue();
            Working_Vol_Heavy_blue();
            break;

          case 'BC29':
            LIA();
            break;
          case 'BC30':
            LLIA_LLIS();
            break;
          case 'BF23':
            HHLA_HHLS_h_mm();

            break;
          case 'BF24':
            HLA_h_mm();
            Working_Vol_Light();
            Working_Vol_Light_blue();
            break;
          case 'BF25':
            LLA_h_mm();
            break;
          case 'BF26':
            LLLA_LLLS_h_mm();
            break;
          case 'BF27':
            HHIA_HHIS();
            break;
          case 'BF28':
            HIA_h_mm();
            Working_Vol_Heavy();
            Working_Vol_Heavy_blue();
            break;
          case 'BF45':
            HHLA_HHLS_h_mm();
            HLA_h_mm();
            LLA_h_mm();
            LLLA_LLLS_h_mm();
            HIA_h_mm();
            LIA();
            LLIA_LLIS_h_mm();
            LLIA_LLIS();
            LIA();
            Working_Vol_Heavy();
            HHIA_HHIS();
            LLLA();
            LLA();
            Working_Vol_Light();
            HHLA_HHLS();
            HLIL();
            HLL();
            Calculated_D_ft();
            Calculated_L_ft();
            L_1();
            L_3();
            Shell_Vol_LLIL();
            Shell_Vol_LLL();
            Shell_Vol_to_HHLIL();
            Shell_Vol_to_HHLL();
            Shell_Vol_to_HLIL();
            Shell_Vol_to_HLL();
            Shell_Vol_to_LLLIL();
            Shell_Vol_to_LLLL();
            Vol_to_HHLIL();
            Vol_to_HHLL();
            Vol_to_HLIL();
            Vol_to_HLIL();
            Vol_to_LLIL();
            Vol_to_LLL();
            Vol_to_LLLIL();
            Vol_to_LLLL();
            Total_Shell_Vol();
            Total_Vol();
            T_hl();
            T_lh();
            H_v();
            H_w();
            L_min_hl();
            L_min_lh();
            L_min_v();
            D_blue();
            L_blue();
            break;
          case 'BF29':
            LIA_h_mm();

            break;
          case 'BF30':
            LLIA_LLIS_h_mm();
            break;
          case 'R21':
            break;
          case 'R22':
            break;
          case 'R23':
            break;
          case 'R24':
            u_t();
            Q_g();

            break;
          case 'R25':
            break;
          case 'R26':
            Q_g();
            break;
          case 'R27':
            break;
          case 'R28':
            u_t();
            Q_l_Liq();
            u_h();
            u_l();
            break;
          case 'R29':
            u_l();
            break;
          case 'R30':
            Q_l_Liq();
            break;
          case 'R31':
            break;
          case 'R32':
            Q_h_Liq();
            u_h();
            u_l();
            break;
          case 'R33':
            u_l();
            break;
          case 'R34':
            Q_h_Liq();
            break;
          case 'R35':
            Q_l_Liq();
            break;
          case 'O35':
            Q_g();
            break;
          case 'V35':
            Q_h_Liq();
            break;
          case 'HLIL_orange':
            HHIL();
            Vol_to_HLIL();
            HHIA_HHIS_h_mm();
            HHIL();
            Shell_Vol_to_HLIL();
            break;
          case 'HHIA_HHIS_orange':
            HHIL();
            HHIL_blue();
            break;
          case 'LLLA_orange':
            LLLL();
            LLLL_blue();
            break;
          case 'LLA_orange':
            LLL();
            LLL_blue();
            break;
          case 'Working_Vol_Heavy_orange':
            Calculated_L_ft();
            break;
          case 'Working_Vol_Light_orange':
            HLL();
            Calculated_L_ft();
            break;
          case 'HHLA_HHLS_orange':
            HHLL();
            HHLL_blue();
            break;

          case 'LLIA_LLIS_orange':
            LLLIL();
            LLLIL_blue();
            break;
          case 'LIA_orange':
            LLIL();
            LLIL_blue();
            break;
          case 'LLLIL_orange':
            LLIL();
            Shell_Vol_to_LLLIL();
            Vol_to_LLLIL();
            T_hl();
            L_min_hl();
            LIA_h_mm();
            break;
          case 'LLIL_orange':
            Working_Vol_Heavy();
            HLIL();
            Shell_Vol_LLIL();
            Vol_to_LLIL();
            Calculated_L_ft();
            HIA_h_mm();
            LIA_h_mm();
            break;
          case 'H_Boot_orange':
            H_boot_blue();
            break;
          case 'HHIL_orange':
            LLLL();
            Shell_Vol_to_HHLIL();
            Vol_to_HHLIL();
            Calculated_L_ft();
            T_lh();
            L_min_lh();
            LLLA_LLLS_h_mm();
            H_boot();
            break;
          case 'LLLL_orange':
            LLL();
            Shell_Vol_to_LLLL();
            Vol_to_LLLL();
            LLA_h_mm();
            break;
          case 'LLL_orange':
            HLL();
            Working_Vol_Light();
            Shell_Vol_LLL();
            Calculated_L_ft();
            Vol_to_LLL();
            HLA_h_mm();
            break;

          case 'HLL_orange':
            HHLA_HHLS_h_mm();
            HHLL();
            Shell_Vol_to_HLL();
            Vol_to_HLL();
            break;
          case 'HHLL_orange':
            Shell_Vol_to_HHLL();
            Vol_to_HHLL();
            Calculated_L_ft();
            T_hl();
            break;

          case 'u_t_orange':
            u_g();
            break;
          case 'u_g_orange':
            Calculated_D_ft();
            L_min_v();
            break;
          case 'Q_h_orange':
            LLIA_LLIS();
            LIA();
            Working_Vol_Heavy();
            HHIA_HHIS();
            LLA();
            HHLA_HHLS();
            LLLA();
            Calculated_L_ft();
            D_hl();
            T_hl();
            T_lh();
            L_min_hl();
            L_min_lh();
            HHIA_HHIS_h_mm();
            HIA_h_mm();
            LIA_h_mm();
            LLIA_LLIS_h_mm();
            Working_Vol_Heavy_blue();
            NLIL_blue();
            break;
          case 'Q_l_orange':
            Working_Vol_Light();
            Calculated_L_ft();
            D_f_in1();
            D_ll();
            T_hl();
            T_lh();
            L_min_hl();
            L_min_lh();
            HHLA_HHLS_h_mm();
            HLA_h_mm();
            LLA_h_mm();
            LLLA_LLLS_h_mm();
            Working_Vol_Light_blue();
            NLL_blue();
            H_bn_blue();
            H_bn()
            break;
          case 'Q_g_orange':
            Calculated_D_ft();
            D_f_in1();
            D_g();
            L_min_v();
            break;
          case 'u_h_orange':
            T_hl();
            break;
          case 'u_l_orange':
            T_lh();
            break;
          case 'T_hl':
            L_min_hl();
            break;
          case 'T_lh':
            L_min_lh();
            break;
          case 'Shell_Vol_to_LLLIL_orange':
            Vol_to_LLLIL();
            LIA_h_mm();
            break;
          case 'Shell_Vol_to_LLIL_orange':
            Working_Vol_Heavy();
            HLIL();
            Vol_to_LLIL();
            HIA_h_mm();
            break;
          case 'Shell_Vol_to_HLIL_orange':
            Vol_to_HLIL();
            HHIA_HHIS_h_mm();
            break;
          case 'Shell_Vol_to_HHLIL_orange':
            Vol_to_HHLIL();
            LLLA_LLLS_h_mm();
            break;
          case 'Total_Shell_Vol_orange':
            HLIL();
            HLL();
            Total_Vol();
            break;
          case 'Shell_Vol_to_LLLL_orange':
            Vol_to_LLLL();
            LLA_h_mm();
            break;
          case 'Shell_Vol_to_LLL_orange':
            HLL();
            Working_Vol_Light();
            Vol_to_LLL();
            HLA_h_mm();
            break;
          case 'Shell_Vol_to_HLL_orange':
            Vol_to_HLL();
            HHLA_HHLS_h_mm();
            break;
          case 'Shell_Vol_to_HHLL_orange':
            Vol_to_HHLL();
            break;
          case 'Calculated_D_ft_orange':
            Calculated_D_m();
            break;
          case 'Calculated_D_m_orange':
            D_blue();
            break;
          case 'Calculated_L_m_orange':
            Total_Shell_Vol();
            L_1();
            D_blue();
            L_blue();
            break;
          case 'Calculated_L_ft_orange':
            Calculated_D_ft();
            Calculated_L_m();
            break;
          case 'L_4_orange':
            HLIL();
            Shell_Vol_to_LLLIL();
            Shell_Vol_LLIL();
            Shell_Vol_to_HLIL();
            Shell_Vol_to_HHLIL();
            Calculated_L_ft();
            HHIA_HHIS_h_mm();
            L_1();
            HIA_h_mm();
            LIA_h_mm();
            LLIA_LLIS_h_mm();
            L_4_blue()
            break;
          case 'L_1_orange':
            Calculated_L_ft();
            L_1_blue();
            break;
          case 'L_2_orange':
            HLL();
            Shell_Vol_to_LLLL();
            Shell_Vol_LLL();
            Shell_Vol_to_HLL();
            Shell_Vol_to_HHLL();
            Calculated_L_ft();
            L_1();
            HHLA_HHLS_h_mm();
            HLA_h_mm();
            LLA_h_mm();
            L_2_blue();
            break;
          case 'L_3_orange':
            Calculated_L_ft();
            L_1();
            L_3_blue();
            break;
          case 'H_a_orange':
            Calculated_L_ft();

            break;

          case 'W_d_orange':
            HLL();
            Working_Vol_Heavy();
            HLL_blue();

            break;
          case 'H_bn_orange':
            Calculated_L_ft();
            break;
          case 'H_d_orange':
            Calculated_L_ft();
            break;
          case 'H_v_orange':
            Calculated_L_ft();
            H_w();
            T_hl();
            T_lh();
            L_min_hl();
            L_min_lh();
            L_min_v();
            break;
          case 'D_f_c_orange':
            D_f_in1();
            break;
          case 'D_f_in1_orange':
            break;
          case 'D_f_in2_orange':
            Calculated_L_ft();
            H_d();
            H_bn_blue();
            H_bn()
            H_d_blue();
            break;
          case 'H_w':
            T_hl();
            break;
          case 'D_ll_orange':
            L_2();
            break;
          case 'HLL_in_Boot_orange':
            H_boot();
            T_hl();
            break;
          case 'HHL_in_Boot_orange':
            HLIL();
            NLIL_blue();
            HLIL_blue();
            break;
          case 'D_boot_orange':
            Shell_Vol_to_LLLIL();
            Shell_Vol_LLIL();
            Shell_Vol_to_HLIL();
            Shell_Vol_to_HHLIL();
            Vol_to_LLLIL();
            Vol_to_LLIL();
            Vol_to_HLIL();
            Vol_to_HHLIL();
            D_boot_blue();
            break;
          case 'L_min_hl_orange':
            L_1();
            D_blue();
            L_blue();
            break;
          case 'L_min_lh_orange':
            L_1();
            D_blue();
            L_blue();
            break;
          case 'L_min_v_orange':
            L_1();
            D_blue();
            L_blue();
            break;

          case 'Dish_Type':
            Vol_to_LLLIL();
            Vol_to_LLIL();
            Vol_to_HLIL();
            Vol_to_HHLIL();
            Vol_to_LLLL();
            Vol_to_LLL();
            Vol_to_HLL();
            Vol_to_HHLL();
            break;

          case 'LLLIL_blue':
            LLIL_blue();
            Vol_to_LLIL_blue();
            Shell_Vol_LLLIL_bule();
            break;
          case 'LLIL_blue':
            Working_Vol_Heavy_blue();
            NLIL_blue();
            HLIL_blue();
            Shell_Vol_LLIL_bule();
            Vol_to_LLIL_blue();
            break;
          case 'Working_Vol_Heavy_blue':
            HLIL_blue();
            break;
          case 'HLIL_blue':
            HHIL_blue();
            Vol_to_HLIL_blue();
            Shell_Vol_HLIL_bule();
            break;
          case 'HHIL_blue':
            LLLL_blue();
            Vol_to_HHLIL_blue();
            Shell_Vol_HHLIL_bule();
            break;
          // case 'LLLL':
          //   LLL_blue();
          //   Vol_to_LLLL_blue();
          //   Shell_Vol_LLLL_blue();
          // break;
          // case 'LLL_blue':
          //   Working_Vol_Light_blue();
          //   NLL_blue();
          //   HLL_blue();
          //   Vol_to_LLL_blue();
          //   Shell_Vol_LLL_blue();
          //   break;
          case 'LLL_blue':
            Shell_Vol_LLL_blue();
            Vol_to_LLL_blue();
            Working_Vol_Light_blue();
            NLL_blue();
            HLL_blue();

            break;
          case 'LLLL_blue':
            LLL_blue();
            Vol_to_LLLL_blue();
            Shell_Vol_LLLL_blue();
            break;
          case 'Working_Vol_Light_blue':
            HLL_blue();
            break;
          case 'HLL_blue':
            HHLL_blue();
            h_v_blue();
            Vol_to_HLL_blue();
            Shell_Vol_HLL_blue();
            break;
          case 'HHLL_blue':
            h_v_blue();
            H_d_blue();
            Vol_to_HHLL_blue();
            Shell_Vol_HHLL_blue();
            break;
          case 'h_v_blue':
            H_w_blue();
            break;
          case 'H_bn_blue':
            H_d_blue();
            break;
          case 'Shell_Vol_LLLIL_bule':
            Vol_to_LLLIL_blue();
            break;
          case 'Shell_Vol_LLIL_bule':
            Vol_to_LLIL_blue();
            HLIL_blue();
            NLIL_blue();
            Working_Vol_Heavy_blue();
            break;
          case 'Shell_Vol_HLIL_bule':
            Vol_to_HLIL_blue();
            break;
          case 'Shell_Vol_HHLIL_bule':
            Vol_to_HHLIL_blue();
            break;
          case 'D_boot_blue':
            Working_Vol_Heavy_blue();
            NLIL_blue();
            Shell_Vol_LLIL_bule();
            Shell_Vol_LLLIL_bule();
            Shell_Vol_LLIL_bule();
            Shell_Vol_HLIL_bule();
            Shell_Vol_HHLIL_bule();
            Shell_Vol_LLLL_blue();
            Shell_Vol_LLL_blue();
            Shell_Vol_HLL_blue();
            Shell_Vol_HHLL_blue();

            Vol_to_LLLIL_blue();
            Vol_to_LLIL_blue();
            Vol_to_HHLIL_blue();
            Vol_to_HLIL_blue();
            Vol_to_LLLL_blue();
            Vol_to_LLL_blue();
            Vol_to_HLL_blue();
            Vol_to_HHLL_blue();
            break;
          case 'D_blue':
            H_bn_blue();
            L_d_blue();
            Working_Vol_Heavy_blue();
            NLIL_blue();
            HLIL_blue();
            HHIL_blue();
            Working_Vol_Light_blue();
            NLL_blue();
            HLL_blue();
            h_v_blue();
            H_w_blue();
            Shell_Vol_LLLIL_bule();
            Shell_Vol_LLIL_bule();
            Shell_Vol_to_HLIL();
            Shell_Vol_HHLIL_bule();
            Vol_to_LLIL_blue();
            Vol_to_HLIL_blue();
            Vol_to_HHLIL_blue();
            Vol_to_LLLIL_blue();
            Total_Vol_blue();
            Shell_Vol_LLL_blue();
            Shell_Vol_HLL_blue();
            Shell_Vol_HHLL_blue();
            Shell_Vol_LLLL_blue();
            Vol_to_LLLL();
            Vol_to_LLL();
            Vol_to_HLL();
            Vol_to_HHLL();

            break;
          case 'L_blue':
            D_blue();
            H_d_blue();
            L_d_blue();
            Total_Shell_Vol_blue();
            break;
          case 'Shell_Vol_LLLL_blue':
            Vol_to_LLLL();
            break;
          case 'Shell_Vol_LLL_blue':
            Vol_to_LLL();
            Working_Vol_Light();
            NLL_blue();
            HLL_blue();
            break;
          case 'Shell_Vol_HLL_blue':
            Vol_to_HLL();
            break;
          case 'Shell_Vol_HHLL_blue':
            Vol_to_HHLL();
            break;
          case 'L_1_blue':
            D_blue();
            L_blue();
            NLIL_blue();
            HLIL_blue();
            Shell_Vol_LLLIL_bule();
            Shell_Vol_LLIL_bule();
            Shell_Vol_HLIL_bule();
            Shell_Vol_HHLIL_bule();

            break;
          case 'L_2_blue':
            D_blue();
            L_blue();
            Working_Vol_Light_blue();
            NLL_blue();
            HLL_blue();
            Shell_Vol_LLLL_blue();
            Shell_Vol_HLL_blue();
            Shell_Vol_LLL_blue();
            Shell_Vol_HHLIL_bule();
            break;
          case 'L_3_blue':
            D_blue();
            L_blue();

            break;
          case 'L_4_blue':
            D_blue();
            L_blue();
            Shell_Vol_LLLIL_bule();
            Shell_Vol_LLIL_bule();
            Shell_Vol_HLIL_bule();
            Shell_Vol_HHLIL_bule();
            NLIL_blue();
            HLIL_blue();
            Working_Vol_Heavy_blue();
            L_4_blue();

            break;
          case 'L_d_blue':
            Shell_Vol_LLLL_blue();
            Shell_Vol_HLL_blue();
            Shell_Vol_LLL_blue();
            Shell_Vol_HHLIL_bule();
            Shell_Vol_LLLIL_bule();
            Shell_Vol_LLIL_bule();
            Shell_Vol_HLIL_bule();
            Shell_Vol_HHLIL_bule();
            break;
          case 'Total_Shell_Vol_blue':
            NLIL_blue();
            HLIL_blue();
            NLL_blue();
            HLL_blue();
            Total_Vol_blue();

            break;
          case 'Total_Vol_blue':
            Total_Shell_Vol_blue();
            break;
          case '995_1000':
          case '996_1000':
            L_2();
            break;
          case '997_1000':
            L_4();
            break;
          default:
            break;
        }
      },
      false
    );
  }
};
