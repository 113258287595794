import React, { useRef, useState } from 'react';
import useApiClient from '../../Settings/useApiClient';
import { AsidePayment } from '../../components/AsidePayment';
import PaymentInfo from '../../components/PaymentInfo';
import { Button } from 'antd';

export const Payment = () => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef()
  const submit=(type)=>{
    formRef.current.submitForm(type)
  }
  return (
    <div className="mt-40 top-20 grid gap-8 items-start m-5 grid-cols-4 p-5 ">
    
      <PaymentInfo   ref={formRef } setLoading={setLoading} loading={loading}/>
      <AsidePayment submit={submit}  setLoading={setLoading} loading={loading}/>
    
    </div>
  );
};
