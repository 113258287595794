import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case7_1({info}) {
    return (
        <>
          
          <div className="HHLL grid gap-x-2 text-start">
            <label>HHLL{toFixed(info.AN71,0)} </label>
            <label>HLL {toFixed(info.AK71,0)} </label>
            <label>NLL {toFixed(info.AH71,0)} </label>
            <label>LLL {toFixed(info.AA71,0)} </label>
            <label>LLLL {toFixed(info.X71,0)} </label>
          </div>
     
          <div className="L">
            <label>L </label>{toFixed(info.BH75,3)}
          </div>
          <div className="D">
            <label>D </label>{toFixed(info.BD75,3)}
          </div>
          <div className="hd">
            <label>Hd </label>{info.BH71}
          </div>
          <div className="hbn">
            <label>H<sub className="align-sub ">bn</sub> </label>{info.BD71}
          </div>
        </>)
}

export default Case7_1