import axios from 'axios';
import useAuth from '../hooks/useAuth';
import Global from "./config.json";

// import { toaster } from "rsuite";
// import message from "../components/common/Message";
// import storage from "./storage";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Accept'] = 'application/json';
// axios.defaults.headers.post['Accept-language'] = 'fa';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.get['Content-Type'] = 'application/json';
// axios.defaults.headers.get['Accept'] = 'application/json';
// axios.defaults.headers.get['Accept-language'] = 'fa';
// axios.defaults.headers.get['Content-Type'] = 'application/json';

const useApiClient = () => {
  const { token, refreshToken, saveToken, removeToken } = useAuth();

  const apiClient = axios.create({
    baseURL: 'https://api.yourserver.com',
  });

  apiClient.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  apiClient.interceptors.response.use(null,async error => {
  const originalRequest = error.config;
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status != 401 && error.response.status != 403 &&
    error.response.status < 500;

  if (!expectedErrors) {

    if (error.response.status === 401 && !originalRequest._retry && refreshToken) {

      originalRequest._retry = true;
      try {
        const response = await axios.post( `${Global.SERVER}auth/refresh`, { token: refreshToken });
        const newToken = response.data.token;
        const newRefreshToken = response.data.refreshToken;
        saveToken(newToken, newRefreshToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
        return axios(originalRequest);
      } catch (e) {
        removeToken();
        window.location.href = '/';
      }
    }


  }else
  
    return Promise.reject(error);
});
return apiClient;
}
export default  useApiClient;
 