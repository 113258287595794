import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from './Header';
import { SettingContext } from '../Context/SettingsContext';

const PrivateRoute = ({ children ,title}) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [fullWidth, setFullWidth] = useState(false);
  return isAuthenticated ? (

    <SettingContext.Provider
      value={{
        setFullWidth,
        fullWidth,
        setOpenSidebar,
        openSidebar,
        token: localStorage.getItem('token'),
      }}
    >
      <div className=" m-auto">
        <Header title={title} />

        {children}
      </div>
    </SettingContext.Provider>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
