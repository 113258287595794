import { Checkbox, Modal, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApiClient from '../Settings/useApiClient';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { weightApi } from '../API/weightApi';
import { ProjectContext } from '../Context/ProjectContext';

export const CaseStudySelect = (props) => {
    const { setDataInput,setActive} =
    useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [caseStudies, setCaseStudies] = useState([]);
  const [caseStudySelected, setCaseStudySelected] = useState(null);
  const [minimumWeight, setMinimumWeight] = useState(false);

  const apiClient = useApiClient();
  useEffect(() => {
    setLoading(true);
    weightApi
      .load(apiClient, id)
      .then((r) => {
        let _data = [];
        let _caseStudyselected = {};
        const dataSource= JSON.parse(r?.weights?.datasource)|| [];
        dataSource?.map((d) => {
          _data.push({ value: d.id, label: d.id, ...d });
          if (d.id == r.design_specific.case_study)
            _caseStudyselected = { value: d.id, label: d.id, ...d };
        });
        setCaseStudies(_data);
        setCaseStudySelected(_caseStudyselected);
        setMinimumWeight(r.design_specific.minimum_weight);

      })
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    if (minimumWeight) {
      caseStudies.sort((a, b) => a.Separator_weight_kg - b.Separator_weight_kg);
      if (
        caseStudySelected?.Separator_weight_kg !==
        caseStudies[0]?.Separator_weight_kg
      )
        setMinimumWeight(false);
    }
    props.setCaseStudyId(caseStudySelected?.value)
  }, [caseStudySelected]);
  useEffect(() => {
    if (minimumWeight) {
      caseStudies.sort((a, b) => a.Separator_weight_kg - b.Separator_weight_kg);
      setCaseStudySelected(caseStudies[0]);
    }
    props.setMinimumWeight(minimumWeight)

  }, [minimumWeight]);
  const minimumWeightChange = (value) => {
    setMinimumWeight(value);
  };
  const onChangeCaseStudy = (value, e) => {
    setCaseStudySelected(e);
    const { confirm } = Modal;
    confirm({
      title: 'Three Phase Separator Calculations',
      icon: <ExclamationCircleFilled />,
      content: 'Are you ready to run the selected case?',
      okText: 'Yes',
      cancelText: 'No',
      async onOk() {
        let dataSource = localStorage.getItem('dataSource');
        if (dataSource) {
          dataSource = JSON.parse(dataSource);
          //   setSizingLoading(1)
          setDataInput({section:"config",Nominal:dataSource[value].Nominal.toFixed(2)})
            setActive("type")
        } else alert('You need first click on analyze button');
      },
      onCancel() {},
    });
  };
  return (
    <>
      <div className="mb-5 ">
        <Typography.Title level={4} className="title-1 mt-5">
          Preferred case
        </Typography.Title>
      </div>

      <div className="grid grid-cols-3 gap-3 items-end">
        <div className="grid  ">
          <Typography.Text className="important ">Case study</Typography.Text>
          <Select
            loading={loading}
            placeholder={'Select'}
            value={caseStudySelected}
            onChange={onChangeCaseStudy}
            options={caseStudies}
          />
        </div>
        <div className="flex col-span-2 bg-gray-300 p-2 rounded-md">
          <Checkbox
            className="w-full"
            checked={minimumWeight}
            onChange={(e) => minimumWeightChange(e.target.checked)}
          >
            Minimum weight
          </Checkbox>
          <div className="text-end w-full px-5 text-[#234E70]">
            Case {caseStudySelected?.id}:{' '}
            {caseStudySelected?.Separator_weight_kg} kg
          </div>
        </div>
      </div>
    </>
  );
};
